'use strict';

angular.module('portailWebApp')
    .controller('TemplateRelationsAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', 'TokenRestAngularMS', '$timeout', '_', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, TokenRestAngularMS, $timeout, _, Monitoring) => {

        //Layout
        $rootScope.pageClass = '';
        //Flash
        Flash.clear();
        //Title
        $rootScope.subTitle = 'templaterelations_admin';
        $rootScope.helpLink = '';
        $rootScope.initDropdown('.yp-toolbar');
        //Endpoint
        let endpoint = TokenRestAngular.all('admin/tplrelations'),
            endpoint2 = TokenRestAngular.all('admin/relationtypes'),
            endpoint3 = TokenRestAngular.all('admin/organizations');

        $scope.creationForm = () => {
            $scope.creation = true;
            endpoint2.getList().then(res => {
                $scope.relations = res;
                $scope.relation_type_id = res[0].id;
                $scope.relation_type_id_target = res[0].id;
            });
            endpoint3.getList().then(res => {
                $scope.organizations = res;
                $scope.organization_id = res[0].id;
            });
        };

        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                $scope.gridData = res;
            });
        };
        $scope.refresh();

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {
                field: 'relationtype.name',
                displayName: 'relationtype',
                headerCellFilter: 'translate'
            },
            {
                field: 'organization.name',
                displayName: 'organization',
                headerCellFilter: 'translate'
            },
            {
                field: 'son_is',
                displayName: 'son_is',
                headerCellFilter: 'translate'
            },
            {
                field: 'father_is',
                displayName: 'father_is',
                headerCellFilter: 'translate'
            },
            {
                field: 'relationtypetarget.name',
                displayName: 'relationtype',
                headerCellFilter: 'translate'
            }
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };

        $scope.record = () => {
            let data = {
                'relation_type_id': $scope.relation_type_id,
                'relation_type_id_target': $scope.relation_type_id_target,
                'organization_id': $scope.organization_id,
                'son_is': $scope.son_is,
                'father_is': $scope.father_is
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.relation_id = null;
                $scope.app_log_type_id = null;
                $scope.right_id = null;
                $scope.alert_type_id = null;
                $scope.creation = false;
            });
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove().then(res => {
                    Flash.show(res.success, 'success');
                });
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };
    }]);
