'use strict';

angular.module('portailWebApp')
    .controller('NewsAdmController', ['_', '$scope', '$location', '$rootScope', 'Flash', 'apiRest', '$timeout', 'moment', (_, $scope, $location, $rootScope, Flash, apiRest, $timeout, moment) => {
        let ep_flashes = apiRest('admin/flash', true);
        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';

        $scope.toggleDatePicker = () => {
            $scope.sdatepicker = !$scope.sdatepicker;
        };

        //Title
        $rootScope.subTitle = 'news_admin';
        $rootScope.helpLink = '';
        //Endpoint

        $scope.newPost = {
            trad: []
        };

        apiRest('lang/languages').customGET('').then(res => {
            $scope.i18n = res.langs;
            res.langs.forEach(v => {
                $scope.newPost.trad.push({
                    i18n_id: v.id,
                    i18n_name: v.name
                });
            });
        });

        $scope.refresh = () => {
            ep_flashes.getList().then(res => {
                $scope.flashes = res;
                $scope.flashes.forEach(v => {
                    v.date_end = v.date_end.split(' ');
                    v.created_at = v.created_at.split(' ');
                    v.translations.forEach(w => {
                        w.i18n_name = _.findWhere($scope.i18n, {
                            id: w.i18nlanguage_id
                        }).name;
                    });
                });
            });
        };
        $scope.refresh();
        $scope.record = () => {
            $scope.newPost.date_end = moment($scope.newPost.date_end).format('YYYY-MM-DD HH:mm');
            ep_flashes.post($scope.newPost).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.newPost = {};
                $scope.newPost.trad = [];
            });
        };
        $scope.delete = item => {
            $rootScope.xhr = true;
            item.remove();
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };
    }]);
