'use strict';

angular.module('portailWebApp')
    .controller('UsersAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', 'userService', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring, userService) => {
        let endpoint = TokenRestAngular.all('admin/users');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'users_admin';
        $rootScope.helpLink = '';

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(data => {
                angular.forEach(data, item => {
                    item.admin = !!+item.admin;
                });
                $scope.gridData = data;
            });
        };
        $scope.refresh();

        $scope.current = [];

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {
                field: 'name',
                displayName: 'name',
                headerCellFilter: 'translate'
            },
            {
                field: 'email',
                displayName: 'email',
                headerCellFilter: 'translate'
            },
            {
                field: 'phone',
                displayName: 'phone',
                headerCellFilter: 'translate'
            },
            {
                field: 'gsm',
                displayName: 'gsm',
                headerCellFilter: 'translate'
            },
            {
                field: 'fax',
                displayName: 'fax',
                headerCellFilter: 'translate'
            },
            {
                field: 'admin',
                type: 'boolean',
                displayName: 'admin',
                headerCellFilter: 'translate'
            }
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
            $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity => {
                $scope.update(rowEntity);
            });
        };


        $scope.update = element => {
            $rootScope.xhr = true;
            element.save();
            $timeout(() => {
                $rootScope.xhr = false;
            }, 1000);
        };

        $scope.record = () => {
            let data = {
                'name': $scope.name,
                'email': $scope.email,
                'i18nlanguage_id': userService.user().i18nlanguage_id,
                'url': $location.host()
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.name = null;
                $scope.email = null;
                $scope.creation = false;
            });
        };

        $scope.reset = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                let endpoint2 = TokenRestAngular.all('admin/users/reset/' + item.id);
                item.url = $location.host();
                endpoint2.post(item).then(data => {
                    Flash.show(data.success, 'success');
                });
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove().then(data => {
                    Flash.show(data.success, 'success');
                });
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };
    }]);
