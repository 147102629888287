((function () {
    angular.module('portailWebApp')
        .controller('EmailsAdmController', EmailsAdmController);

    EmailsAdmController.$inject = ['$scope', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring'];

    function EmailsAdmController($scope, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) {
        let endpoint = TokenRestAngular.all('admin/emails');

        // binding
        ////////////////////////////////////

        $scope.gridData = [];
        $scope.inputs = {};
        $scope.refresh = refresh;
        $scope.delete = deleteTemplate;
        $scope.record = record;
        $scope.typeList = ['email', 'document', 'misc'];
        $scope.inputs.type = 'email';

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
            {field: 'type'},
            {field: 'var', displayName: 'code', headerCellFilter: 'translate'}
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = onRegisterApi;


        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'emails_admin';
        $rootScope.helpLink = '';

        // init
        /////////////////////////////////////////////////////

        refresh();

        // functions
        /////////////////////////////

        function deleteTemplate() {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        }

        function onRegisterApi(gridApi) {
            $scope.gridApi = gridApi;
        }

        function record() {
            endpoint.post($scope.inputs).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.name = null;
                $scope.var = null;
                $scope.creation = false;
            });
        }

        function refresh() {
            endpoint.getList().then(data => {
                $scope.gridData = data;
            });
        }

    }
})());
