angular.module('portailWebApp')
    .controller('SupportTicketMonitoringController', ['$scope', '$interval', '$rootScope', 'Flash', 'apiRest', ($scope, $interval, $rootScope, Flash, apiRest) => {
        let endpoint = apiRest('supportv2/monitoring', 1),
            promise;

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.subTitle = 'ticket_monitoring';
        $rootScope.helpLink = '';
        //Endpoint


        $scope.autorefresh = {
            interval: 30000,
            intervalArray: [{
                value: false,
                label: 'static',
                id: 'refn'
            }, {
                value: 30000,
                label: '30s',
                id: 'ref1'
            }, {
                value: 60000,
                label: '1min',
                id: 'ref2'
            }, {
                value: 180000,
                label: '3min',
                id: 'ref3'
            }]
        };
        /* reinit autorefresh processus on change */
        $scope.$watch('autorefresh.interval', () => {
            let int = $scope.autorefresh.interval;
            if (promise) {
                $interval.cancel(promise);
            }
            if (int) {
                promise = $interval(() => {
                    $scope.refresh();
                }, int);
            }
        });
        $scope.$on('$destroy', () => {
            $interval.cancel(promise);
        });
        $scope.refresh = () => {

            endpoint.getList().then(data => {
                $scope.todo = data[0].todo;
                $scope.frozen = data[0].frozen;
                $scope.metrics = data[0].metrics;
                $scope.today = data[0].metrics.today;
                $scope.today.avggit = parseInt($scope.today.avggit, 10);
                $scope.today.avggrt = parseInt($scope.today.avggrt, 10);
                $scope.outsla = data[0].todo.sla;

                $scope.todayStats = () => {
                    let d = $scope.metrics.today,
                        bal = parseInt(d.opened, 10) - parseInt(d.closed, 10),
                        level = 'info',
                        icn = 'trending_up';
                    if (bal > 0) {
                        level = 'warning';
                        icn = 'trending_down';
                        bal = '+' + bal;
                    }
                    d.diff = {
                        balance: bal,
                        lvl: level,
                        icn: icn
                    };
                };
                $scope.todayStats();
                $scope.baskets = [];
                angular.forEach($scope.todo.baskets, (v) => {
                    let lvl = 'info';
                    if (v.count > 5) {
                        lvl = 'warning';
                    }
                    if (v.count > 10) {
                        lvl = 'error';
                    }
                    $scope.baskets.push({
                        name: v.name,
                        nb: v.count,
                        nbFreeze: v.count_frozen,
                        level: lvl
                    });
                });
            });
        };

        $scope.refresh();
    }]);
