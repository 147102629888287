/**
 * Created by acorre on 27/06/2016.
 */
/*eslint-env es6*/

((function () {
    let ypFillSearchConfig = {
        template: `<button title="{{::$ctrl.terms}}"
                        ng-click="$ctrl.fill($event)">
                        <i class="material-icons">filter_list</i>
                        <i ng-transclude></i>
                   </button>`,
        controller: ypFillSearchController,
        transclude: true,
        bindings: {
            terms: '@'
        }
    };

    angular.module('portailWebApp')
        .component('ypFillSearch', ypFillSearchConfig);

    ypFillSearchController.$inject = ['$rootScope'];

    function ypFillSearchController($rootScope) {
        const ctrl = this;

        // method
        ///////////////////////////////////////////////////


        ctrl.$onInit = init;

        function init() {
            ctrl.fill = fill;
        }

        function fill(e) {
            $rootScope.$broadcast('perform:search', ctrl.terms);
            e.stopPropagation();
        }
    }

})());
