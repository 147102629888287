'use strict';

angular.module('portailWebApp')
    .controller('BlacklistMailAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        let endpoint = TokenRestAngular.all('admin/blmail');
        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'blacklistmail_admin';
        $rootScope.helpLink = '';

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                $scope.gridData = res;
            });
        };
        $scope.refresh();

        $scope.current = [];

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
                {field: 'email', displayName: 'email', headerCellFilter: 'translate'},
                {field: 'date_end', displayName: 'date_fin', headerCellFilter: 'translate'}
        ], false, true, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };

        $scope.record = () => {
            let data = {
                'email': $scope.email,
                'date_end': $scope.date_end
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.email = null;
                $scope.date_end = null;
                $scope.creation = false;
            });
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                console.log(item);
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };
    }]);
