angular.module('portailWebApp').controller('NotifsLAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', 'moment', '_', 'brand', '$filter', '$log', ($scope, $location, $rootScope, Flash, TokenRestAngularMS, $timeout, moment, _, brand, $filter, $log) => {
    let endpoint = TokenRestAngularMS.all('ladmin/notifs');

    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'events_admin';
    //Endpoint

    //hide secondary filters by default
    $scope.showAllFilters = false;

    //Data
    $scope.data = [];

    $scope.showFilters = () => {
        $scope.filters = !$scope.filters;
        $scope.datePickers = $rootScope.initDropdown('.yp-dateMod__wrapper', {
            closeOut: true,
            multi: false
        });
    };
    $scope.closeDatepicker = key => {
        $scope.datePickers.forEach(v => {
            v.closeAll();
        });
        $scope[key] = moment($scope[key]).toDate();
    };
    $scope.filterRefresh = () => {
        $rootScope.xhr = true;
        $scope.data = [];
        $scope.refresh();
    };
    $scope.refresh = () => {
        var last = $scope.data.length,
            data = {};
        $rootScope.xhr = true;

        if (last < 0) {
            last = 0;
        }
        if (last) {
            data.sp = last;
        }
        if ($scope.user_id) {
            data.user_id = $scope.user_id;
        }
        if ($scope.type_id) {
            data.type_id = $scope.type_id;
        }
        if ($scope.categorie_id) {
            data.categorie_id = $scope.categorie_id;
        }
        if ($scope.service_id) {
            data.service_id = $scope.service_id;
        }
        if ($scope.organization_id) {
            data.organization_id = $scope.organization_id;
        }
        if ($scope.date_deb && moment($scope.date_deb).isValid() && angular.isDefined($scope.date_deb)) {
            data.date_deb = moment.utc($scope.date_deb).format('YYYY-MM-DDTHH:mm');
        }
        if ($scope.date_fin && moment($scope.date_fin).isValid() && angular.isDefined($scope.date_fin)) {
            data.date_fin = moment.utc($scope.date_fin).format('YYYY-MM-DDTHH:mm');
        }
        if ($scope.licence_id) {
            data.licence_id = $scope.licence_id;
        }
        endpoint.getList(
            data
        ).then(res => {
            angular.forEach(res, item => {
                if (!item.user) {
                    item.user = 'portal';
                }
                switch (item.categorie_var) {
                    case 'accounts':
                        item.icon = 'person';
                        break;
                    case 'alert':
                        item.icon = 'warning';
                        break;
                    case 'api_ext':
                        item.icon = 'extension';
                        break;
                    case 'dashboardaction':
                        item.icon = 'dashboard';
                        break;
                    case 'monitoring':
                        item.icon = 'poll';
                        break;
                    case 'other':
                        item.icon = 'label';
                        break;
                    case 'technical':
                        item.icon = 'build';
                        break;
                    case 'support':
                        item.icon = 'headset_mic';
                        break;
                    default:
                        item.icon = 'info';
                }
                item.service = brand.filter(item.service);
                item.type = brand.filter(item.type);
                item.created_at = $filter('utc2local')(item.created_at);
                item.updated_at = $filter('utc2local')(item.updated_at);
                item.detail = false;

                try {
                    item.context = angular.fromJson(item.context);
                    item.jsonContext = true;
                } catch(e) {
                    $log.log('legacy context format', item.context);
                }

                $scope.data.push(item);
            });
            $rootScope.xhr = false;
        });

    };
    $scope.$watch('categorie_id', () => {
        if ($scope.categorie_id && $scope.categorie_id !== 0) {
            $scope.types = _.find($scope.categories, {
                id: $scope.categorie_id
            }).types;
            $scope.types.unshift({
                id: 0,
                label: '----'
            });
            $scope.type_id = 0;
        } else {
            $scope.types = false;
        }
    });

    $scope.getFilters = () => {
        //should share endpoint with monitoring and ticket
        endpoint.get('filters').then(data => {
            $scope.categories = data.categories;
            $scope.categories.unshift({
                id: 0,
                label: '----'
            });
            $scope.categorie_id = 0;
            $scope.users = data.users;
            $scope.users.unshift({
                id: 0,
                name: '----'
            });
            $scope.user_id = 0;
            //$scope.date_fin = moment().format('YYYY/MM/DD HH:mm');
            $scope.services = data.services;
            $scope.services.unshift({
                id: 0,
                name: '----'
            });
            $scope.service_id = 0;
            $scope.organizations = data.organizations;
            $scope.organizations.unshift({
                id: 0,
                name: '----'
            });
            $scope.organization_id = 0;
            $scope.licences = data.licences;
            $scope.licences.unshift({
                id: 0,
                licence: '----'
            });
            $scope.licence_id = 0;
        });
    };
    $scope.onSelect = ($item, $model) => {
        $scope.licence_id = $model;
        $scope.licence_id2 = '';
    };

    $scope.getFilters();
    $scope.refresh();
}]);
