/* eslint-disable no-unused-vars */
(function () {
  angular.module('portailWebApp')
    .controller('MonitoringLinaContinuityController', MonitoringLinaContinuityController);

  MonitoringLinaContinuityController.$inject = ['$scope', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', 'Monitoring', '$window', 'brand', '$routeParams', '$filter', 'moment'];

  function MonitoringLinaContinuityController($scope, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, Monitoring, $window, brand, $routeParams, $filter, moment) {
    // ----------------------------------------- Define Endpoints ----------------------------------------- //
    const 
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations'),
      licences_endpoint = TokenRestAngularMS.all('monitoring/lina/continuity/licences'),
      lina_agents_endpoint = TokenRestAngularMS.all('monitoring/lina/continuity'),
      lina_tenants_endpoint = TokenRestAngularMS.all('monitoring/lina/continuity/tenants'),
      gendpoint = TokenRestAngularMS.all('monitoring/lina/continuity/remote'),

      // ----------------------------------------- Define values in datatable ----------------------------------------- //
      data_grid = {
        licences: [
          {
            field: 'licence',
            type: 'number',
            displayName: 'licence',
            headerCellFilter: 'translate',
            filter: {
              placeholder: 'licence'
            },
            cellTemplate: `<yp-fill-search class="yp-grid__btn ui-grid-cell-contents" terms="{{row.entity.licence}}">{{COL_FIELD CUSTOM_FILTERS}}</yp-fill-search>`
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><span class="glyphicon glyphicon-info-sign"></a></span></div>'
          },
          {
            field: 'seller',
            displayName: 'reseller',
            headerCellFilter: 'translate',
            visible: false,
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certifiedSeller ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certifiedSeller" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.seller }}</div>'
          },
          {
            field: 'expiration',
            type: 'date',
            displayName: 'expiration',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'updated_at',
            type: 'date',
            displayName: 'updated_at',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'remote_link',
            displayName: 'remote_link',
            headerCellFilter: 'translate',
            enableFiltering: false,
            cellTemplate: '<div class="ui-grid-cell-contents text-center" ng-click="grid.appScope.remote(row.entity)"><i ng-hide="row.entity.standalone" class="material-icons md-18">build</i> <span ng-hide="row.entity.standalone" translate="remote_link"></span></div>'
          },
          {
            field: 'lina_continuity[0].container_version',
            displayName: 'version',
            headerCellFilter: 'translate',
            enableFiltering: true
          },
          {
            field: 'contracts',
            displayName: 'contracts',
            enableFiltering: true,
            headerCellFilter: 'translate'
          }
        ],
        lina_tenants: [
          // Tenant name
          {
            field: 'name',
            type: 'string',
            displayName: 'tenant_name',
            headerCellFilter: 'translate'
          },
          // NB Agents
          {
            field: 'nb_agents',
            type: 'string',
            displayName: 'count_agents',
            headerCellFilter: 'translate'
          },
          {
            field: 'nb_protected',
            type: 'string',
            displayName: 'count_protected',
            headerCellFilter: 'translate'
          },
          {
            field: 'nb_alert',
            type: 'string',
            displayName: 'count_alert',
            headerCellFilter: 'translate'
          }
        ],
        lina_agents: [
          // Object Name
          {
            field: 'name',
            type: 'string',
            displayName: 'object_name',
            headerCellFilter: 'translate'
          },
          // Tenant Name
          {
            field: 'tenant.name',
            type: 'string',
            displayName: 'tenant_name',
            headerCellFilter: 'translate'
          },
          // Fqdn
          {
            field: 'fqdn',
            type: 'string',
            displayName: 'fqdn',
            headerCellFilter: 'translate'
          },
          // OS
          {
            field: 'os',
            type: 'string',
            displayName: 'os',
            headerCellFilter: 'translate'
          },
          // Last Auth
          {
            field: 'last_auth_relative',
            type: 'string',
            displayName: 'last_connection',
            headerCellFilter: 'translate',
            cellTooltip: function (row) {
              return moment(Number(row.entity.last_auth)).format('ddd DD MMM YYYY HH:mm:ss');
            }
          },
          // Status
          {
            field: 'status',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            displayName: 'agent_status',
            headerCellFilter: 'translate',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          // Last backup
          {
            field: 'last_backup_relative',
            type: 'string',
            displayName: 'last_backup',
            headerCellFilter: 'translate',
            cellTooltip: function (row) {
              return moment(Number(row.entity.last_backup)).format('ddd DD MMM YYYY HH:mm:ss');
            }
          },
          // Last Log
          {
            field: 'last_login',
            type: 'string',
            displayName: 'last_login',
            headerCellFilter: 'translate'
          },
          // Protection Name
          {
            field: 'protections.name',
            type: 'string',
            displayName: 'protection_name',
            headerCellFilter: 'translate',
            cellTooltip: function (row) {
              return `BMR : ${!!row.entity.protections.bmr}`;
            }
          },
          // Strategie Name
          {
            field: 'strategies.name',
            type: 'string',
            displayName: 'strategie_name',
            headerCellFilter: 'translate'
          },
          // Alarms
          {
            field: 'alarms',
            type: 'string',
            displayName: 'alarms',
            headerCellFilter: 'translate'
          },
          // Activity status
          {
            field: 'activity_status',
            type: 'string',
            displayName: 'activity_status',
            headerCellFilter: 'translate'
          },
          // Prot size
          {
            field: 'prot_size',
            type: 'string',
            displayName: 'prot_size',
            headerCellFilter: 'translate'
          },
          // Prot Obj
          {
            field: 'prot_objs',
            type: 'string',
            displayName: 'prot_objs',
            headerCellFilter: 'translate'
          },
          // Last Sync Duration
          {
            field: 'last_sync_duration',
            type: 'string',
            displayName: 'last_sync_duration',
            headerCellFilter: 'translate'
          },
          // Last Update
          {
            field: 'updated_at',
            type: 'date',
            displayName: 'last_updated',
            headerCellFilter: 'translate',
            visible: false
          },
          // Email agent
          {
            field: 'agent_email',
            type: 'string',
            displayName: 'agent_email',
            headerCellFilter: 'translate'
          }
        ]
      };

    // ----------------------------------------- Define Root Scope ----------------------------------------- //
    $rootScope.pageClass = '';
    
    $rootScope.initDropdown('.yp-table--dd');
    $rootScope.initDropdown('.yp-accordion', {
      closeOut: true
    });
    $rootScope.subTitle = 'monitoring_lina_continuity';

    // ----------------------------------------- Define Scope ----------------------------------------- //
    $scope.relation_id = null;
    $scope.filters = [];

    $scope.organizations = [];
    $scope.grid_organizations = Monitoring.setGridOption('organizations');

    $scope.licences = [];
    $scope.grid_licences = Monitoring.setGridOption('licences', data_grid.licences, true, true);

    $scope.lina_tenants = [];
    $scope.grid_lina_tenants = Monitoring.setGridOption('lina_tenants', data_grid.lina_tenants, false, true);

    $scope.lina_agents = [];
    $scope.grid_lina_agents = Monitoring.setGridOption('lina_agents', data_grid.lina_agents, false, true);

    // ----------------------------------------- Define Function ----------------------------------------- //

    $scope.convert_bytes = (bytes) => {
      if (!+bytes) {
        return '0 Bytes';
      }

      const k = 1024,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      let dm = 0;
      if (i >= 4) {
        dm = 2;
      }
      if (i === 3) {
        dm = 1;
      }

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    $scope.remote = licence => {
      gendpoint.get(licence.id).then(data => {
        $window.open(`${data.url}lina/`, '_blank');
      });
    };

    // ***************** Organizations ***************** //
    $scope.select_all = () => {
      $scope.selected_organizations.selection.selectAllVisibleRows();
    };

    $scope.clear_all = () => {
      $scope.selected_organizations.selection.clearSelectedRows();
    };

    $scope.unselect_row = (parent, row) => {
      let scope = null;

      switch (parent) {
      case 'licences':
        scope = $scope.selected_licences;
        break;

      default:
        scope = $scope.selected_organizations;
      }
      
      scope.selection.unSelectRow(row);
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };

    // ***************** Filters ***************** //
    $scope.set_filter = (array) => {
      const { length } = `${array}`;

      if (length > 100) {
        Flash.show('no_more_selection');
        return false;
      }

      if (length > 0) {
        let filters = '';
        angular.forEach(array, (row) => {
          filters += `${row.id},`;
        });
        return filters;
      }

      return false;
    };

    $scope.set_lina_filter = (array) => {
      const { length } = `${array}`;

      if (length > 100) {
        Flash.show('no_more_selection');
        return false;
      }

      if (length > 0) {
        let res = [];

        angular.forEach(array, (row) => {
          res.push(
            {
              types: row.id[0],
              organizations: row.id[1],
              licences: row.id[2]
            }
          );
        });

        return res;
      }

      return false;
    };

    // ***************** Convert Data Bytes ***************** //
    // eslint-disable-next-line consistent-return
    $scope.convert_bytes = (bytes) => {
      if (!+bytes) {
        return '0 Bytes';
      }

      const k = 1024,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      let dm = 0;
      if (i >= 4) {
        dm = 2;
      }
      if (i === 3) {
        dm = 1;
      }

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    // ***************** Define type in datatable ***************** //
    $scope.table_license = (data) => {
      const lic = [];

      angular.forEach(data, row => {
        let status = 2;
        
        angular.forEach(row.lina_continuity, r => {
          if (r.status !== status && r.status < status) {
            status -= 1;
          }
        });
        
        row.status = Monitoring.convert_status(status);
        lic.push(row);
      });

      return lic;
    };

    $scope.table_tenants = (data) => {
      const tenants = [];

      angular.forEach(data, (row) => {
        tenants.push(row);
      });

      return tenants;
    };

    $scope.table_lina = (data) => {
      const agents = [];
      angular.forEach(data, (row) => {
        const last_backup = moment(Number(row.last_backup)).fromNow(),
          hours = Math.floor(row.last_sync_duration / 60 / 60),
          minutes = Math.floor(row.last_sync_duration / 60 - 60),
          seconds = row.last_sync_duration - (3600 + (minutes * 60)),
          last_auth = moment(Number(row.last_auth)).fromNow();
        
        row.fqdn = row.fqdn ? row.fqdn : '';
        row.os = row.os ? row.os : '';
        row.status = Monitoring.convert_status(row.status);
        row.last_backup_relative = last_backup;

        let alarms = '';
        angular.forEach(Object.keys(row.alarms), key => {
          if (row.alarms[key]) {
            alarms += `${$filter('translate')('lina_' + key)}, `;
          }
        });

        if (alarms === '') {
          row.alarms = $filter('translate')('No alarm');
        } else {
          row.alarms = alarms.slice(0, -2);
        }
        row.prot_objs = new Intl.NumberFormat('fr-FR').format(row.prot_objs);
        row.prot_size = $scope.convert_bytes(row.prot_size);


        row.last_sync_duration = `${hours !== '00' && hours > 0 ? hours + 'h' : ''} ${minutes !== '00' && minutes > 0 ? minutes + 'm' : ''} ${seconds !== '00' && seconds > 0 ? seconds + 's' : ''}`;
        if (!row.last_sync_duration) {
          row.last_sync_duration = 'NA';
        }

        row.last_auth_relative = last_auth;

        agents.push(row);
      });

      return agents;
    };
    
    // ----------------------------------------- Fetch array ----------------------------------------- //
    $scope.fetch_filters = () => {
      Monitoring.refreshFilters('monitoring_yoobackup', data => {
        $scope.filters = data;
      });
    };

    // ***************** Fetch and Set Organizations ***************** //
    $scope.fetch_organizations = () => {
      organizations_endpoint.getList()
        .then(data => {
          $scope.organizations = data;
          $scope.fetch_licences();
        });
    };

    $scope.grid_organizations.onRegisterApi = grid_api => {
      grid_api.selection.on.rowSelectionChanged($scope, () => {
        $scope.fetch_licences();
      });

      $scope.selected_organizations = grid_api;
    };

    
    // ***************** Fetch and Set Licences ***************** //
    $scope.fetch_licences = () => {
      $scope.lina_agents = [];
      $scope.licences = [];
      let organizations = $scope.selected_organizations.selection.getSelectedRows(),
        filters = $scope.set_filter(organizations);

      if (filters) {
        filters = {organization_id: filters};
      }

      licences_endpoint.getList(filters)
        .then(data => {
          $scope.licences = $scope.table_license(data);
        });
    };

    $scope.grid_licences.onRegisterApi = grid_api => {
      grid_api.selection.on.rowSelectionChanged($scope, () => {
        $scope.fetch_lina_tenants();
        $scope.fetch_lina_agents();
      });

      $scope.selected_licences = grid_api;
    };

    // ***************** Fetch Lina Tenants ***************** //
    $scope.fetch_lina_tenants = () => {
      let licences = $scope.selected_licences.selection.getSelectedRows(),
        filters = $scope.set_filter(licences);
      
      if (filters) {
        filters = {licence_id: filters};
      }

      lina_tenants_endpoint.getList(filters)
        .then(data => {
          $scope.lina_tenants = $scope.table_tenants(data);
        });
    };

    // ***************** Fetch Lina Agents ***************** //
    $scope.fetch_lina_agents = () => {
      let licences = $scope.selected_licences.selection.getSelectedRows(),
        filters = $scope.set_filter(licences);
      
      if (filters) {
        filters = {licence_id: filters};
      }

      lina_agents_endpoint.getList(filters)
        .then(data => {
          $scope.lina_agents = $scope.table_lina(data);
        });
    };

    $scope.grid_lina_agents.onRegisterApi = grid_api => {
      $scope.selected_lina_agents = grid_api;
    };

    // ----------------------------------------- Starting Components ----------------------------------------- //
    $scope.fetch_organizations();
  }
}());
