((function () {
    angular.module('portailWebApp')
        .directive('ypContact', ypContactDirective);

    ypContactDirective.$inject = ['Person', 'Flash'];

    function ypContactDirective(Person, Flash) {


        return {
            restrict: 'E',
            scope: {
                controls: '=',
                contact: '=data',
                extra: '=',
                disabled: '=',
                onClear: '&',
                onSave: '&',
                onMerge: '&'
            },
            templateUrl: '../scripts/directives/contact.tpl.html',
            link: link,
            controller: YpContactController
        };

        ///////////////////////////////////////////////

        function YpContactController($scope) {
            if ($scope.contact && $scope.disabled) {
                $scope.contact.disabled = $scope.disabled;
            }
        }

        function link(scope, element) {
            function reset(data) {
                scope.contact.reset(data.data.contact);
            }

            function error(res) {
                let failed = res.data.failed,
                    mess = '';
                if (failed) {
                    for (let text in failed) {
                        if (failed.hasOwnProperty(text)) {
                            mess += failed[text] + ' ';
                        }
                    }
                }
                Flash.show(mess, 'warning');
            }

            function errorDel(res) {
                reset(res);
                error(res);
            }

            function clear() {
                if (!scope.contact.isSame(Person({}))) {
                    scope.contact.clear();
                } else if (scope.contact.id) {
                    scope.contact.remove()
                        .then(scope.onClear)
                        .catch(errorDel);
                } else {
                    scope.onClear();
                }

            }

            function edit(contact) {
                contact.disabled = !contact.disabled;
            }

            function save() {
                scope.contact.save()
                    .then(scope.onSave)
                    .catch(error);
            }

            element.on('$destroy', () => {

            });

            scope.save = save;
            scope.clear = clear;
            scope.toggleEdit = edit;
        }

    }

})());

