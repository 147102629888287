((function () {
    angular.module('portailWebApp')
        .factory('Pin', PinService);

    PinService.$inject = ['$rootScope', '_'];

    function PinService($rootScope, _) {
        return {
            pinIt: pinIt,
            add: add,
            remove: remove,
            clean: clean
        };

        function pinIt(data, name) {
            if (angular.isUndefined(name)) {
                name = 'Information';
            }
            this.add({name: name, data: convertData(data), selected: []});
        }

        function add(information) {
            $rootScope.pins.push(information);
            $rootScope.$broadcast('PINS_CHANGED', $rootScope.pins);
        }

        function remove(information) {
            $rootScope.pins = _.without($rootScope.pins, information);
            $rootScope.$broadcast('PINS_CHANGED', $rootScope.pins);
        }

        function clean() {
            $rootScope.pins = [];
            $rootScope.$broadcast('PINS_CHANGED', $rootScope.pins);
        }

        function convertData(data) {
            let thedata = [];
            if (angular.isObject(data) || angular.isArray(data)) {
                if (_.has(data, 'plain')) {
                    data = data.plain();
                }
                angular.forEach(data, (val, key) => {

                    if (angular.isObject(val) || angular.isArray(val)) {
                        val = convertData(val);
                        angular.forEach(val, row => {
                            thedata.push(row);
                        });
                    } else if (angular.isString(val) || angular.isNumber(val)) {
                        if (key !== 'id' && key !== '$$hashKey' && key.indexOf('_id') === -1) {
                            thedata.push({name: key, val: val});
                        }

                    }
                });
            } else if (angular.isString(data) || angular.isNumber(data)) {
                thedata.push({name: 'Information', val: data});
            }
            return thedata;
        }
    }
})());

