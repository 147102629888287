'use strict';
// eslint-disable-next-line
angular.module('portailWebApp')
    .controller('i18nController', ['$scope', '$translate', 'apiRest', ($scope, $translate, apiRest) => {
        $scope.changeLanguage = langKey => {
            $translate.use(langKey);
        };
        //Go query lang api for languages list
        apiRest('lang/languages').customGET('').then(data => {
            $scope.languages = data.langs;
        });
    }]);
