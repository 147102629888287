((function () {
  angular.module('portailWebApp')
    .factory('apiRest', apiRest);

  apiRest.$inject = ['Restangular', 'TokenRestAngularMS'];

  function apiRest(Restangular, TokenRestAngularMS) {
    return (endpoint, auth, obj) => {
      let service = auth ? TokenRestAngularMS : Restangular, r;
      if (endpoint) {
        r = obj ? service.oneUrl(endpoint) : service.all(endpoint);
      } else {
        r = service;
      }
      return r;
    };
  }
})());
