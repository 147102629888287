'use strict';

angular.module('portailWebApp')
    .controller('Forget2Controller', ['$sanitize', '$routeParams', '$scope', '$rootScope', 'localStorageService', 'apiRest', '$timeout', 'Flash',
        function ($sanitize, $routeParams, $scope, $rootScope, localStorageService, apiRest, $timeout, Flash) {
            var key = $routeParams.key;
            $rootScope.pageClass = 'login-page';

            //Flash
            Flash.clear();
            //Title
            $rootScope.subTitle = '';
            $rootScope.helpLink = 'yooportal/generalites/authentification.html';
            //for all


            //Go try
            $scope.its0kay = () => {
                var endpoint = apiRest('passwords');
                endpoint.get(key).then(data => {
                    if (data.error) {
                        Flash.show(data.error);
                    }
                });
            };
            $scope.resetPassword = () => {
                var data = {
                        'password': $scope.password,
                        'password2': $scope.passwordconfirm
                    },
                    endpoint = apiRest('passwords/' + key);
                endpoint.post(data).then(res => {
                    Flash.show(res.success, 'success');
                    $rootScope.redirector('/login');
                });
            };
            $scope.its0kay();
        }]);
