(function () {
    /* eslint-disable no-use-before-define */
    angular.module('portailWebApp')
        .controller('ProfileController', ProfileController);

    ProfileController.$inject = ['$rootScope', 'Flash', 'apiRest', '$translate', '_', 'userService', '$interval'];

    function ProfileController($rootScope, Flash, apiRest, $translate, _, userService, $interval) {
        let vm = this,
            ep = {
                origins: apiRest('support/ressources/origin', true),
                categories: apiRest('support/ressources/category', true),
                baskets: apiRest('support/ressources/baskets', true),
                sendToken: apiRest('accounts/sendtoken', true)
            };

        vm.update = goUpdate;
        vm.timer = {
            timeBank: 500,
            cancel: timerCancel,
            start: timerStart,
            isActive: false,
            init: initTimer
        };
        vm.token = {
            validate: validTokenRequest,
            view: false,
            isValid: false
        };
        vm.phoneIsChanged = phoneIsChanged;
        vm.updatePhone = updatePhone;

        getLanguages();
        getUser();

        /////////////////////////////////////////////////////////////


        $rootScope.pageClass = '';
        $rootScope.subTitle = 'myprofile';
        $rootScope.helpLink = 'yooportal/generalites/profil.html';

        function phoneIsChanged() {
            return vm.useri.phone && vm.useri.phone !== vm.oldPhone;
        }

        function getSupportData() {
            vm.inputs = {};
            $translate('undefined')
                .then(res => {
                    ep.origins.getList()
                        .then(data => {
                            vm.inputs.origins = _.map(data, v => {
                                return {id: v.id, value: v.value};
                            });
                            vm.inputs.origins.unshift({value: res});
                            vm.useri.origin = _.findWhere(vm.inputs.origins, {id: vm.useri.origin_id});
                        });

                    ep.categories.getList()
                        .then(data => {
                            vm.inputs.categories = _.map(data, v => {
                                return {id: v.id, value: v.value};
                            });
                            vm.inputs.categories.unshift({value: res});
                            vm.useri.category = _.findWhere(vm.inputs.categories, {id: vm.useri.category_id});
                        });

                    ep.baskets.getList()
                        .then(data => {
                            vm.inputs.baskets = _.map(data, v => {
                                return {id: v.id, value: v.name};
                            });
                            vm.inputs.baskets.unshift({value: res});
                            vm.useri.basket = _.findWhere(vm.inputs.baskets, {id: vm.useri.basket_id});
                        });
                });
        }

        function goUpdate(form) {
            if (!vm.useri.phone) {
                vm.useri.check_sms = false;
            }
            if (!vm.useri.check_sms || vm.token.isValid || vm.useri.phoneCertified) {
                apiRest('accounts', true)
                    .post(vm.useri)
                    .then(data => {
                        form.$setPristine();
                        setValid({old: false});
                        vm.useri = updatePhoneStatus(vm.useri);
                        Flash.show(data.success, 'info');
                        vm.oldPhone = angular.copy(vm.useri.phone);
                        userService.setUser(vm.useri);
                    })
                    .catch(Flash.show);
            } else {
                $translate('token_criteria')
                    .then(Flash.show)
                    .catch(Flash.show);

            }
        }

        function updatePhone() {
            var user = angular.copy(vm.useri);
            if (phoneIsChanged()) {
                user.phoneCertified = false;
                vm.useri = updatePhoneStatus(user);
                setInvalid();
            } else {
                setValid({old: true});
                vm.useri = updatePhoneStatus(user);
            }
        }

        function initTimer() {
            if (!phoneIsChanged()) {
                if (vm.useri.check_sms) {
                    vm.timer.start(vm.timer.timeBank);
                } else {
                    vm.timer.cancel();
                }
            }
        }

        function sendTokenRequest() {
            $interval.cancel(vm.interval);
            vm.timer.isActive = false;
            vm.token.view = true;
            ep.sendToken.get('')
                .then(() => {
                    Flash.show('token_sent', 'success');
                })
                .catch(setInvalid);
        }

        function validTokenRequest() {
            ep.sendToken
                .post({
                    'token': vm.token.value
                })
                .then(setValid)
                .then(() => {
                    vm.useri = updatePhoneStatus(vm.useri);
                })
                .catch(setInvalid);
        }

        function timerLoop() {
            vm.timer.count = vm.timer.count - 1;
            if (vm.timer.count === 0) {
                sendTokenRequest();
            }
        }

        function timerStart(int) {
            if (!vm.useri.phoneCertified) {
                vm.timer.count = int;
                vm.checkIfTimer = true;
                vm.interval = $interval(timerLoop, 10, int);
            }
        }

        function timerCancel() {
            $interval.cancel(vm.interval);
            vm.useri.check_sms = false;
            delete vm.timer.count;
            vm.viewToken = false;
            vm.tokensms = '';
            vm.timer.isActive = false;
        }

        function setValid(res) {
            vm.token.view = false;
            if (res.success) {
                vm.token.isValid = true;
                vm.useri.phoneCertified = true;
            }
            if (res.old) {
                vm.token.isValid = vm.wasCertified;
            }
            return res;
        }

        function setInvalid(res) {
            vm.token.view = false;
            vm.useri.check_sms = false;
            vm.token.isValid = false;
            vm.useri.phoneCertified = false;
            vm.useri.check_sms = false;
            if (res) {
                Flash.show(res.data.error.message);
            }
        }

        function getLanguages() {
            apiRest('lang/languages').get('')
                .then(data => {
                    vm.languages = data.langs;
                })
                .catch(Flash.show);
        }

        function getUser() {
            userService.getUser()
                .then(processUser)
                .catch(Flash.show);
        }

        function getUserLog() {
            userService
                .getAuthHistory()
                .then(logs => {
                    vm.authLogs = logs;
                })
                .catch(Flash.show);
        }

        function processUser(data) {
            vm.isSupport = userService.isSupport();

            data.check_sms = !!data.check_sms;
            data.pinpanel = !!data.pinpanel;
            vm.oldPhone = angular.copy(data.phone);
            vm.wasCertified = angular.copy(data.phoneCertified);
            vm.useri = updatePhoneStatus(data);

            if (vm.isSupport) {
                getSupportData();
            }
            getUserLog();
        }

        function updatePhoneStatus(data) {
            data.phoneStatus = {
                icon: data.phoneCertified ? 'verified_user' : 'warning',
                message: data.phoneCertified ? 'verified_phone' : 'unverified_phone'
            };
            return data;
        }
    }
}());
