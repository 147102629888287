'use strict';

angular.module('portailWebApp')
    .controller('LicencesAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        //Endpoint
        let endpoint = TokenRestAngular.all('admin/licences');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.subTitle = 'licences_admin';
        $rootScope.helpLink = '';

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                angular.forEach(res, row => {
                    row.quarantine = !!row.quarantine;
                    $scope.gridData.push(row);
                });
            });
        };
        $scope.refresh();

        $scope.current = [];


        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {
                field: 'licence',
                cellEditableCondition: false,
                displayName: 'licence',
                headerCellFilter: 'translate'
            },
            {
                field: 'quarantine',
                type: 'boolean',
                displayName: 'quarantine',
                headerCellFilter: 'translate',
                editableCellTemplate: ''
            },
            {
                field: 'sla_level_id',
                cellEditableCondition: true,
                displayName: 'sla_level',
                headerCellFilter: 'translate'
            }
        ], true, false, 'high');

        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
            $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity => {
                $scope.update(rowEntity);
            });
        };

        $scope.update = element => {
            $rootScope.xhr = true;
            element.save();
            $timeout(() => {
                $rootScope.xhr = false;
            }, 1000);
        };
    }]);
