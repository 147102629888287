'use strict';

angular.module('portailWebApp')
  .controller('UsersDirController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', '$translate', '$uibModal', 'Monitoring', 'customFilter', ($scope, $location, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, $translate, $uibModal, Monitoring, customFilter) => {

    //Flash
    Flash.clear();
    //Title
    $rootScope.subTitle = 'users_directory';
    $rootScope.helpLink = 'yooportal/outils/annuaire-utilisateurs.html';
    //endpoint
    let users_endpoint = TokenRestAngularMS.all('tools/users'),
      relations_endpoint = TokenRestAngularMS.all('tools/relations'),
      organizations_endpoint = TokenRestAngularMS.all('tools/organizations');
    //filters functions
    $scope.relation_id = null;
    //init
    $scope.organizations = [];
    $scope.relations = [];
    $scope.users = [];
    $scope.filters = [];
    $scope.filters2 = [];
    // Monitoring service
    $scope.refresh_filters = () => {
      Monitoring.refreshFilters('view_users', data => {
        $scope.filters = data;
        if ($scope.filters.length > 0) {
          $scope.loadFilter($scope.filters[0].id);
        }
      });
    };
    $scope.loadIt = () => {
      Monitoring.loadFilters('view_users', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        let organizations = $scope.gridApi1.selection.getSelectedRows();
        $scope.relation_id = data.data.relation_id;
        $scope.gridApi1.saveState.restore($scope, data.data.grid1);
        if (data.deletable) {
          angular.forEach(organizations, row => {
            $scope.gridApi1.selection.selectRow(row);
          });
        }
        $scope.gridApi2.saveState.restore($scope, data.data.grid2);
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('view_users', {
        grid1: $scope.gridApi1.saveState.save(),
        grid2: $scope.gridApi2.saveState.save(),
        relation_id: $scope.relation_id
      });
    };

    $scope.organization_info = organization_id => {
      organizations_endpoint.get(organization_id).then(data => {
        $rootScope.openModal('small', 'modalOrganizationController', 'modal_organization.html', data);
      });
    };
    //Usefull

    //refresh functions
    $scope.refresh_relations = () => {
      $scope.relations = [];
      relations_endpoint.getList().then(data => {
        let vardefault = {id: null, name: '--'};
        $scope.relations = data;
        $scope.relations.unshift(vardefault);
        $scope.relation_id = vardefault.id;
        $scope.refresh_organizations();
      });
    };
    $scope.refresh_users = () => {
      let organizations = $scope.gridApi1.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (r) {
        users_endpoint.getList(filters).then(data => {
          $scope.users = [];
          angular.forEach(data, row => {
            row.admin = !!+row.admin;
            row.active = !!+row.active;
            $scope.users.push(row);
          });
          //calcul
          $scope.count = _.countBy(data, row => {
            return row.status;
          });
        });
      }
      return r;
    };

    $scope.refresh_organizations = () => {
      let filters = {};
      $scope.organizations = [];
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      organizations_endpoint.getList(filters).then(data => {
        $scope.organizations = data;
        $scope.refresh_users();
      });
    };
    //Init
    $scope.refresh_relations();
    $scope.refresh_filters();

    //Grids
    $scope.gridOptions1 = Monitoring.setGridOption('organizations');
    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_users();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_users();
      });
      $scope.gridApi1 = gridApi;
    };

    $scope.gridOptions2 = Monitoring.setGridOption('users', [
      {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
      {field: 'email', displayName: 'email', headerCellFilter: 'translate'},
      {field: 'phone', displayName: 'phone', headerCellFilter: 'translate'},
      {field: 'gsm', displayName: 'gsm', headerCellFilter: 'translate'},
      {field: 'fax', displayName: 'fax', headerCellFilter: 'translate'},
      {field: 'right', displayName: 'right', headerCellFilter: 'translate'},
      {field: 'organization', displayName: 'organization', headerCellFilter: 'translate'},
      {
        field: 'active',
        displayName: 'active',
        headerCellFilter: 'translate',
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterBool,
          condition: customFilter.bool.filterStatus
        },
        cellTemplate: customFilter.i18n.cellTemplate
      },
      {field: 'deleted_at', displayName: 'deleted_at', headerCellFilter: 'translate', type: 'date',
        filters: [
          {condition: customFilter.date.isBefore},
          {condition: customFilter.date.isAfter}
        ],
        filterHeaderTemplate: customFilter.date.template
      }
    ], false, true, 'high');
    $scope.gridOptions2.onRegisterApi = gridApi => {
      $scope.gridApi2 = gridApi;
    };

    //Grid operations
    $scope.selectAll = () => {
      $scope.gridApi1.selection.selectAllVisibleRows();
    };

    $scope.clearAll = () => {
      $scope.gridApi1.selection.clearSelectedRows();
    };

    $scope.removeOrganisationFilter = row => {
      $scope.gridApi1.selection.unSelectRow(row);
    };

  }]);
