/* eslint-disable no-unused-vars */
angular.module('portailWebApp')
  .controller('SupportTicketKpiController', ['$scope', '$rootScope', 'TokenRestAngularMS', 'moment', '_', ($scope, $rootScope, TokenRestAngularMS, moment, _) => {
    $rootScope.subTitle = 'kpi_support';

    const endpoint = {
        kpi_tickets: 'supportv2/kpi'
      },
      filters = {
        starting_date: moment().startOf('month'),
        ending_date: moment().endOf('month')
      },
      tk = TokenRestAngularMS;
    
    $scope.set_filters = () => {
      $scope.data = tk.restangularizeElement(null, {
        start: filters.starting_date,
        end: filters.ending_date
      }, endpoint.kpi_tickets);
    };

    // ----------------------------------------- Starting Components ----------------------------------------- //
    $scope.set_filters();
  }]);
