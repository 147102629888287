'use strict';

angular.module('portailWebApp')
    .controller('ForgetController', ['$sanitize', '$location', '$scope', '$rootScope', 'apiRest', 'localStorageService', '$timeout', 'Flash', 'userService', ($sanitize, $location, $scope, $rootScope, apiRest, localStorageService, $timeout, Flash, userService) => {
        if (userService.isAuth()) {
            $rootScope.redirector('/main');
        }
        //Layout
        $rootScope.pageClass = 'login';
        //Flash
        Flash.clear();
        //Title
        $rootScope.subTitle = '';
        $rootScope.helpLink = 'yooportal/generalites/authentification.html';

        //Go try
        $scope.genKey = () => {
            var endpoint = apiRest('passwords'),
                data = {
                    'url': $location.host(),
                    'email': $sanitize($scope.email)
                };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $rootScope.redirector('/login');
            });
        };

    }]);
