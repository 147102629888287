'use strict';

angular.module('portailWebApp')
    .controller('OrganizationsDirController', ['$scope', '$location', '$rootScope', 'Flash', 'apiRest', '$timeout', '_', 'uiGridConstants', '$translate', '$uibModal', 'Monitoring', ($scope, $location, $rootScope, Flash, apiRest, $timeout, _, uiGridConstants, $translate, $uibModal, Monitoring) => {
        let relations_endpoint = apiRest('tools/relations', true),
            organizations_endpoint = apiRest('tools/organizations', true);

        //Flash
        Flash.clear();
        //Title
        $rootScope.subTitle = 'organizations_directory';
        $rootScope.helpLink = 'yooportal/outils/annuaire-organisations.html';
        //endpoint

        //filters functions
        $scope.relation_id = null;
        //init
        $scope.organizations = [];
        $scope.relations = [];
        $scope.filters = [];
        // Monitoring service
        $scope.refresh_filters = () => {
            Monitoring.refreshFilters('organization_view', data => {
                $scope.filters = data;
                if ($scope.filters.length > 0) {
                    $scope.loadFilter($scope.filters[0].id);
                }
            });
        };
        $scope.loadIt = () => {
            Monitoring.loadFilters('organization_view', id => {
                $scope.loadFilter(id);
            });
        };

        $scope.loadFilter = id => {
            Monitoring.loadFilter(id, data => {
                $scope.relation_id = data.data.relation_id;
                $scope.gridApi1.saveState.restore($scope, data.data.grid1);
            });
        };

        $scope.saveIt = () => {
            Monitoring.saveFilter('organization_view', {
                grid1: $scope.gridApi1.saveState.save(),
                relation_id: $scope.relation_id
            });
        };

        $scope.organization_info = organization_id => {
            organizations_endpoint.get(organization_id).then(data => {
                $rootScope.openModal('small', 'modalOrganizationController', 'modal_organization.html', data);
            });
        };

        //refresh functions
        $scope.refresh_relations = () => {
            $scope.relations = [];
            relations_endpoint.getList().then(data => {
                let vardefault = {id: null, name: '--'};
                $scope.relations = data;

                $scope.relations.unshift(vardefault);
                $scope.relation_id = vardefault.id;
                $scope.refresh_organizations();
            });
        };

        $scope.refresh_organizations = () => {
            let filters = {};
            $scope.organizations = [];
            if ($scope.relation_id) {
                filters.relation_id = $scope.relation_id;
            }
            organizations_endpoint.getList(filters).then(data => {
                $scope.organizations = data;
            });
        };
        //Init
        $scope.refresh_relations();
        $scope.refresh_filters();
        //Grids
        // eslint-disable-next-line no-undefined
        $scope.gridOptions1 = Monitoring.setGridOption('organizations', undefined, false, true, 'high');
        $scope.gridOptions1.onRegisterApi = gridApi => {
            $scope.gridApi1 = gridApi;
        };
    }]);
