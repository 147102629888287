'use strict';

angular.module('portailWebApp')
    .controller('NotifsAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', '_', 'moment', '$filter', '$log', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, _, moment, $filter, $log) => {
        //Endpoint
        let endpoint = TokenRestAngular.all('admin/notifs');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $scope.datePickers = $rootScope.initDropdown('.yp-form__label--datepicker', {
            closeOut: true,
            multi: false
        });
        $scope.closeDatepicker = () => {
            $scope.datePickers.forEach(v => {
                v.closeAll();
            });
        };
        $rootScope.subTitle = 'notifs_admin';
        $rootScope.helpLink = '';

        //Data
        $scope.data = [];

        $scope.filterRefresh = () => {
            $rootScope.xhr = true;
            $scope.data = [];
            $scope.refresh();
        };

        $scope.refresh = () => {
            let last = $scope.data.length,
                data = {};
            $rootScope.xhr = true;

            if (last < 0) {
                last = 0;
            }

            if (last !== 0) {
                data.sp = last;
            }
            if ($scope.user_id !== 0) {
                data.user_id = $scope.user_id;
            }
            if ($scope.type_id !== 0) {
                data.type_id = $scope.type_id;
            }
            if ($scope.categorie_id !== 0) {
                data.categorie_id = $scope.categorie_id;
            }
            if ($scope.service_id !== 0) {
                data.service_id = $scope.service_id;
            }
            if ($scope.organization_id !== 0) {
                data.organization_id = $scope.organization_id;
            }
            if ($scope.date_deb && moment($scope.date_deb).isValid() && angular.isDefined($scope.date_deb)) {
                data.date_deb = moment.utc($scope.date_deb).format('YYYY-MM-DDTHH:mm');
            }
            if ($scope.date_fin && moment($scope.date_fin).isValid() && angular.isDefined($scope.date_fin)) {
                data.date_fin = moment.utc($scope.date_fin).format('YYYY-MM-DDTHH:mm');
            }
            if ($scope.licence_id) {
                data.licence_id = $scope.licence_id;
            }
            endpoint.getList(
                data
            ).then(res => {
                angular.forEach(res, item => {
                    if (item.level === 'info') {
                        item.class = 'panel-info';
                    } else if (item.level === 'warning') {
                        item.class = 'panel-warning';
                    } else if (item.level === 'error') {
                        item.class = 'panel-danger';
                    }
                    if (!item.user) {
                        item.user = 'Anonymous';
                    }
                    switch (item.categorie_var) {
                        case 'accounts':
                            item.icon = 'person';
                            break;
                        case 'alert':
                            item.icon = 'warning';
                            break;
                        case 'api_ext':
                            item.icon = 'globe';
                            break;
                        case 'dashboardaction':
                            item.icon = 'dashboard';
                            break;
                        case 'monitoring':
                            item.icon = 'poll';
                            break;
                        case 'other':
                            item.icon = 'label';
                            break;
                        case 'technical':
                            item.icon = 'build';
                            break;
                        case 'support':
                            item.icon = 'headset_mic';
                            break;
                        default:
                            item.icon = 'glyphicon-tag';
                            break;
                    }
                    item.created_at = $filter('utc2local')(item.created_at);
                    item.detail = false;
                    try {
                        item.context = angular.fromJson(item.context);
                        item.jsonContext = true;
                    } catch(e) {
                        $log.log('legacy context format', item.context);
                    }

                    $scope.data.push(item);
                });

                $rootScope.xhr = false;
            });

        };
        $scope.$watch('categorie_id', () => {
            if ($scope.categorie_id) {
                $scope.types = _.find($scope.categories, {
                    id: $scope.categorie_id
                }).types;
                $scope.types.unshift({
                    id: 0,
                    label: '----'
                });
                $scope.type_id = 0;
            } else {
                $scope.types = false;
            }
        });

        $scope.$watch('date_deb', () => {
            if (!moment($scope.date_deb, ['YYYY-MM-DD HH:mm']).isValid()
                && moment($scope.date_deb).isValid()
                && $scope.date_deb
                && angular.isDefined($scope.date_deb)
            ) {
                $scope.date_deb = moment($scope.date_deb).format('YYYY-MM-DD HH:mm');
            }
        });

        $scope.$watch('date_fin', () => {
            if (!moment($scope.date_fin, ['YYYY-MM-DD HH:mm']).isValid()
                && moment($scope.date_fin).isValid()
                && $scope.date_fin
                && angular.isDefined($scope.date_fin)
            ) {
                $scope.date_fin = moment($scope.date_fin).format('YYYY-MM-DD HH:mm');
            }
        });

        $scope.getFilters = () => {
            endpoint.get('filters').then(res => {
                $scope.categories = res.categories;
                $scope.categories.unshift({
                    id: 0,
                    label: '----'
                });
                $scope.categorie_id = 0;
                $scope.users = res.users;
                $scope.users.unshift({
                    id: 0,
                    name: '----'
                });
                $scope.user_id = 0;
                $scope.date_fin = moment().format('YYYY/MM/DD HH:mm');
                $scope.services = res.services;
                $scope.services.unshift({
                    id: 0,
                    name: '----'
                });
                $scope.service_id = 0;
                $scope.organizations = res.organizations;
                $scope.organizations.unshift({
                    id: 0,
                    name: '----'
                });
                $scope.organization_id = 0;
                $scope.licences = res.licences;
                $scope.licences.unshift({
                    id: 0,
                    licence: '----'
                });
                $scope.licence_id = 0;
            });
        };

        $scope.onSelect = ($item, $model) => {
            $scope.licence_id = $model;
            $scope.licence_id2 = '';
        };

        $scope.getFilters();
        $scope.refresh();
    }]);
