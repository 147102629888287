'use strict';
(function () {
  angular.module('portailWebApp')
    .controller('YbConfigFilesController', YbConfigFilesController);

  YbConfigFilesController.$inject = ['$window', '$scope', '$rootScope', 'apiRest', '$timeout', '_', 'uiGridConstants', 'customFilter', 'Monitoring', '$routeParams'];

  function YbConfigFilesController($window, $scope, $rootScope, apiRest, $timeout, _, uiGridConstants, customFilter, Monitoring, $routeParams) {
    //IS auth and admin
    const ybcf = this;
    let configfiles_endpoint = apiRest('yoobackup/configfiles', true),
      licences_endpoint = apiRest('monitoring/yoobackup/licences', true);

    $scope.licenceSelected = '';
    $scope.searchText = '';
    $scope.licence = [];

    $scope.searchLicence = (searchText) => {
      return $scope.licence.filter(l => l.licence.includes(searchText));
    };

    $scope.getConfigFileLicence = () => {
      const licencekey = $scope.licenceSelected || '';
      if (licencekey !== 'all' && licencekey !== '') {
        apiRest('yoobackup/configfiles', true).getList({
          'licence': licencekey.licence
        }).then(data => {
          let list = data.map(row => {
              row.status = parseInt(row.status, 10) ? true : false;
              row.sign_requested = row.fileIdYouSign ? true : false;
              return row;
            }),
            nb = data.length,
            stats = ybcf.configFileStats(list, nb);

          $scope.configFiles = {
            list: list,
            stats: stats,
            count: _.countBy(list, row => row.status)
          };
        });
      } else {
        $scope.refresh();
      }
    };

    ybcf.configFileStats = (files, licences) => {
      let list = [],
        byFilesSigned = files.filter(x => x.status).length,
        byFilesTotal = files.length,
        byLicenceTotal = licences,
        byLicenceSigned,
        byLicenceBalance,
        byLicenceState;

      licences_endpoint.getList().then((data) => {
        const datalicence = [];

        angular.forEach(data, (l) => {
          datalicence.push({
            licence: l.licence
          });
        });

        $scope.licence = datalicence;
      });

      files.forEach(item => {
        let byLicence = list.find(x => x.licence === item.licence);
        if (!byLicence) {
          list.push({
            licence: item.licence,
            organization: item.name,
            status: item.status ? 1 : 0,
            count: 1
          });
        } else {
          byLicence.status = (byLicence.status || item.status) ? 1 : 0;
          byLicence.count = byLicence.count + 1;
        }
      });

      byLicenceSigned = list.filter(x => x.status).length;
      byLicenceBalance = (byLicenceSigned && byLicenceTotal) ? ((byLicenceSigned / byLicenceTotal) * 100).toFixed(2) : 0;
      byLicenceState = (bal) => {
        switch (true) {
        case bal > 90 :
          return 'yp-callout--success';
        case bal > 70 :
          return 'yp-callout--warning';
        default:
          return 'yp-callout--danger';
        }
      };

      return {
        licence: {
          total: byLicenceTotal,
          signed: byLicenceSigned,
          balance: byLicenceBalance,
          state: byLicenceState(byLicenceBalance)
        },
        files: {
          total: byFilesTotal,
          signed: byFilesSigned,
          balance: (byFilesTotal && byFilesSigned) ? Math.round((byFilesSigned / byFilesTotal) * 100) : 0
        }
      };
    };

    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'yoobackup_configfiles';
    $rootScope.helpLink = 'yooportal/outils/rapport-config.html';
    $rootScope.initDropdown('.yp-accordion');
    //endpoint

    //init
    $scope.filters = [];
    // Monitoring service
    Monitoring.refreshFilters('yoobackup_configfiles', data => {
      $scope.filters = data;
      if (data.length > 0) {
        $scope.loadFilter(data[0].id);
      }
      $timeout(() => {
        let type = $routeParams.filterType,
          value = $routeParams.filterValue || null;

        if (type) {
          switch (type) {
          case 'offline':
            $scope.filterColumnStatus('off');
            break;
          case 'capacity':
            $scope.filterColumnCapacity(79);
            break;
          case 'licence':
            $scope.filterColumnLicence(value);
            break;
          default:
          }
        }
      }, 1000);
    });

    $scope.loadIt = () => {
      Monitoring.loadFilters('yoobackup_configfiles', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //if filters
        var columns = $scope.gridApi.saveState.save();
        angular.forEach(columns.columns, (item, j) => {
          angular.forEach(item.filters, (filter, i) => {
            if (filter.term) {
              data.data.grid.columns[i].filters[j].term = filter.term;
            }
          });
        });
        $scope.relation_id = data.data.relation_id;
        $scope.gridApi.saveState.restore($scope, data.data.grid);
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('yoobackup_configfiles', {
        grid: $scope.gridApi.saveState.save()
      });
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };

    //refresh functions

    $scope.refresh = () => {
      Promise.all([
        configfiles_endpoint.getList(),
        apiRest('yoobackup/licences', true).get('')
      ]).then(data => {
        let list = data[0].map(row => {
            row.status = parseInt(row.status, 10) ? true : false;
            row.sign_requested = row.fileIdYouSign ? true : false;
            return row;
          }),
          nb = list.length,
          stats = ybcf.configFileStats(list, nb);

        $scope.configFiles = {
          list: list,
          stats: stats,
          count: _.countBy(list, row => row.status)
        };
      });
    };

    $scope.getFile = file => {
      configfiles_endpoint.withHttpConfig({responseType: 'blob'}).get(file.id).then(data => {
        $window.saveAs(data, 'config_' + file.id + '.pdf');
      });
    };

    //Init
    $scope.refresh();
    //Grids

    $scope.gridOptions = Monitoring.setGridOption('configFiles.list', [
      {
        field: 'licence',
        type: 'number',
        displayName: 'licence',
        headerCellFilter: 'translate',
        filter: {
          placeholder: 'licence'
        },
        cellTemplate: '<div class="ui-grid-cell-contents yp-cell_graph-icn"><a ng-href="support/ticketnew/licence/{{ row.entity.licence }}" ng-show="grid.appScope.isAction(\'ticket_create\')"><i class="material-icons md-18" >note_add</i></a> {{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.getFile(row.entity)"><i class="material-icons md-18">attachment</i></a></div>'
      },
      {
        field: 'name',
        displayName: 'organization',
        type: 'date',
        headerCellFilter: 'translate',
        enableFiltering: true
      },
      {
        field: 'created_at',
        displayName: 'created_at',
        type: 'date',
        headerCellFilter: 'translate',
        enableFiltering: true,
        filters: [
          {
            condition: customFilter.date.isBefore
          },
          {
            condition: customFilter.date.isAfter
          }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'signatory_firstname',
        displayName: 'signatory_firstname',
        headerCellFilter: 'translate',
        enableFiltering: true
      },
      {
        field: 'signatory_lastname',
        displayName: 'signatory_lastname',
        headerCellFilter: 'translate',
        enableFiltering: true
      },
      {
        field: 'signatory_email',
        displayName: 'signatory_email',
        headerCellFilter: 'translate',
        enableFiltering: true
      },
      {
        field: 'signatory_gsm',
        displayName: 'signatory_gsm',
        headerCellFilter: 'translate',
        enableFiltering: true
      },
      {
        field: 'status',
        displayName: 'signed',
        headerCellFilter: 'translate',
        enableFiltering: true,
        cellClass: (grid, row, col) => {
          let sc = grid.getCellValue(row, col) ? 'on' : 'off';
          return 'status_' + sc;
        },
        cellTemplate: customFilter.i18n.cellTemplate,
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterBool,
          condition: customFilter.bool.filterStatus
        }
      },
      {
        field: 'sign_date',
        type: 'date',
        displayName: 'signed_at',
        headerCellFilter: 'translate',
        enableFiltering: true,
        filters: [
          {
            condition: customFilter.date.isBefore
          },
          {
            condition: customFilter.date.isAfter
          }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'sign_requested',
        displayName: 'esign_requested',
        headerCellFilter: 'translate',
        enableFiltering: true,
        cellClass: (grid, row, col) => {
          let sc = grid.getCellValue(row, col) ? 'on' : 'off';
          return 'status_' + sc;
        },
        cellTemplate: customFilter.i18n.cellTemplate,
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterBool,
          condition: customFilter.bool.filterStatus
        }
      }
    ], true, true, 'high');
    $scope.gridOptions.onRegisterApi = gridApi => {
      $scope.gridApi = gridApi;
    };

    $scope.cancelSign = () => {
      let cols = $scope.gridApi.selection.getSelectedRows();
      cols.forEach(row => {
        if (!row.status) {
          row.remove().then(() => {
            $scope.refresh();
          });
        }
      });
    };


    $scope.getFiles = () => {
      let cols = $scope.gridApi.selection.getSelectedRows();
      cols.forEach(row => {
        $scope.getFile(row);
      });
    };
    

    $scope.filterColumnStatus = val => {
      let col = _.find($scope.gridApi.grid.columns, {field: 'status'});
      if (col) {
        col.filters[0].term = val;
        $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };
    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi.grid.columns, {
        field: 'licence'
      });

      if (col) {
        col.filter.term = val;
        $scope.gridApi.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };
  }
}());

