((function () {
    angular.module('portailWebApp')
        .controller('MeetingAdmController', MeetingAdminController);

    MeetingAdminController.$inject = ['$rootScope', '$scope', 'moment', '$log', 'Flash', '_', 'apiRest', 'TokenRestAngularMS', 'userService'];

    function MeetingAdminController($rootScope, $scope, moment, $log, Flash, _, apiRest, TokenRestAngularMS, userService) {
        const vm = this,
            route = {
                meetings: 'support/meetings',
                openinghours: 'support/meetings/openinghours',
                ics: 'support/meetings/linkIcs',
                types: 'support/ressources/category'
            },
            meetingFilters = {
                status: [0, 1, 2, 3],
                special: true,
                fn: meetingFilterCB
            },
            tk = TokenRestAngularMS;

        let dbreak,
            businessHours,
            viewReady,
            types;

        vm.countStatusSpecial = countStatusSpecial;
        vm.currentPage = 0;
        vm.deleteEvent = deleteEvent;
        vm.event = {};
        vm.myMeetings = [];
        vm.filterStatusActive = filterStatusActive;
        vm.meetingFilters = meetingFilters;
        vm.numberOfPages = numberOfPages;
        vm.notAvailable = [];
        vm.eventSources = [vm.notAvailable, vm.myMeetings];
        vm.pageSize = 10;
        vm.ready = {
            bh: false,
            meetings: false
        };

        vm.saveMeeting = saveSpecialMeeting;
        vm.setTime = setTime;
        vm.toggleFilterStatus = toggleFilterStatus;
        vm.toggleFilterSpecial = toggleFilterSpecial;
        vm.uiConfig = {
            calendar: {
                timezone: 'local',
                allDaySlot: false,
                slotDuration: '00:30:00',
                defaultView: 'agendaWeek',
                firstDay: 1,
                ignoreTimezone: true,
                slotEventOverlap: false,
                minTime: '06:00:00',
                maxTime: '17:00:00',
                header: {
                    left: 'title',
                    center: '',
                    right: 'today prev,next'
                },
                weekends: false,
                eventConstraint: 'businessHours',
                viewRender: viewRender,
                dayClick: alertOnEventClick
            }
        };

        apiRest(route.types, 1).getList()
            .then(manageTypes)
            .catch(error);

        apiRest('support/meetings/openinghours', 1)
            .customGET('')
            .then(manageOpeningHour)
            .catch(error);

        viewReady = $scope.$watchGroup(['mad.ready.bh'], viewReadyFn);

        /////////////////////////////////////////////////////////////////

        function error(err) {
            $log.log(err);
        }

        function validEvent(data) {
            // set the receive id to the object
            let item = _.findWhere(vm.myMeetings, {
                id: null
            });
            item.id = data.id;
            item.fromServer = true;
            Flash.show(data.success, 'success');
            item.status = data.status;
            changeClassEventStatus(item);
            item.title = item.organization.name;
            updateEventSource(vm.myMeetings);
        }

        function meetingFactory(o) {
            let Meeting;
            if (!moment.isMoment(o.start)) {
                o.start = moment.utc(o.start);
            }

            Meeting = {
                id: o.id || null,
                request_text: o.request_text || null,
                close_text: o.close_text || null,
                start: o.start,
                end: moment.utc(o.end),
                status: o.status || 0,
                created_at: o.created_at ? o.created_at : null,
                updated_at: o.updated_at ? o.updated_at : null,
                organization: o.organization || null,
                licence: o.licence || null,
                type: o.type || null,
                contacts: o.contacts || [],
                stick: true,
                special: o.special || false
            };
            /*
             * Needed for hollyday and such...
             * special interval, sopecified from the super admin to represent when the service is closed
             */
            if (Meeting.special) {
                Meeting.title = o.request_text || 'special';
            } else {
                Meeting.title = o.organization ? o.organization.name : 'preview';
            }

            //if (o.fromServer) {
            Meeting = tk.restangularizeElement(null, Meeting, route.meetings);
            Meeting.fromServer = o.fromServer || false;

            //}
            changeClassEventStatus(Meeting);
            return Meeting;
        }

        function changeClassEventStatus(Meeting) {
            let r = ['is-preview'];
            if (Meeting.fromServer) {
                switch (Meeting.status) {
                    case 1:
                        r = ['is-accepted'];
                        break;
                    case 2:
                        r = ['is-closed'];
                        break;
                    case 3:
                        r = ['is-refused'];
                        break;
                    case 4:
                        r = ['is-canceled'];
                        break;
                    default:
                        r = ['is-waiting'];
                }
            }
            if (Meeting.special) {
                r.push('is-special');
            }
            Meeting.className = r;

        }

        function dateOk(date) {
            return !(_.find(_.flatten(vm.eventSources, true), d => {
                let r;
                if (date.isBetween(d.start, d.end)
                    || date.isSame(d.start, 'hour')) {
                    r = !(d.status > 2);
                }
                if (d.status === 2
                    && date.isAfter(moment())
                    && date.isSame(d.start)) {
                    r = false;
                }
                return r;
            }) || (dbreak
            && (date.hour() >= dbreak.start.slice(0, 2)
            && date.hour() < dbreak.end.slice(0, 2)))
            || date.isBefore(moment()));
        }

        function setTime(el, period) {
            let dayStart = moment().hour(parseInt(businessHours.start.substr(0, 2), 10)).utc().hour(),
                dayEnd = moment().hour(parseInt(businessHours.end.substr(0, 2), 10)).utc().hour(),
                breakStart = moment()
                    .hour(parseInt(dbreak.start.substr(0, 2), 10))
                    .utc()
                    .subtract(1, 'hours')
                    .hour(),
                breakEnd = moment().hour(parseInt(dbreak.end.substr(0, 2), 10)).utc().hour();

            switch (period) {
                case 'morning':
                    el.start.hour(dayStart);
                    el.end.hour(breakStart);
                    break;
                case 'afternoon':
                    el.start.hour(breakEnd);
                    el.end.hour(dayEnd);
                    break;
                default:
                    el.start.hour(dayStart);
                    el.end.hour(dayEnd);
            }
            updateEventSource(vm.myMeetings);
        }

        function manageTypes(data) {
            types = data.map(x => x.plain());
        }

        function manageOpeningHour(data) {
            data.closed.start = moment.utc(data.closed.start, 'HH:mm:ss').local().format('HH:mm:ss');
            data.closed.end = moment.utc(data.closed.end, 'HH:mm:ss').local().format('HH:mm:ss');
            dbreak = data.closed;
            businessHours = {
                start: moment.utc(data.start, 'HH:mm:ss').local().format('HH:mm:ss'),
                end: moment.utc(data.end, 'HH:mm:ss').local().format('HH:mm:ss'),
                dow: [1, 2, 3, 4, 5]
            };
            vm.ready.bh = true;
        }

        function displayMeetings(data) {
            vm.myMeetings = [];
            vm.notAvailable = [];

            if (vm.calendarView) {
                vm.calendarView.calendar.removeEvents();
            }

            vm.myMeetings =
                data.map(m => meetingFactory(m));

            if (dbreak) {
                vm.notAvailable.push({
                    title: '-',
                    start: dbreak.start,
                    end: dbreak.end,
                    dow: [1, 2, 3, 4, 5],
                    className: ['notAvailable'],
                    stick: true
                });
                vm.ready.dispo = true;
            }

            vm.calendarView.calendar.addEventSource(vm.myMeetings);
            vm.calendarView.calendar.addEventSource(vm.notAvailable);
            vm.calendarView.calendar.render();
            vm.ready.meetings = true;
        }

        function addEvent(mD) {
            let start = moment.utc(mD.startOf('hour')),
                end = angular.copy(mD).endOf('hour'),
                newEvent,
                alreadyOne = _.find(vm.myMeetings, {
                    fromServer: false
                });

            newEvent = meetingFactory({
                start: start,
                end: end,
                special: 1
            });
            if (userService.user().id) {
                newEvent.organization = {
                    id: userService.user().membership[0].organization_id,
                    name: userService.user().membership[0].organization_name
                };
            }
            newEvent.type = _.findWhere(vm.types, {value: 'YooPortal'});
            if (alreadyOne) {
                vm.myMeetings.splice(0, 1);
            }
            vm.myMeetings.unshift(newEvent);

            newEvent.details = true;
            updateEventSource(vm.myMeetings);
        }

        function alertOnEventClick(date) {
            let sD = date.startOf('hour');
            if (dateOk(sD, true)) {
                addEvent(sD);
            } else {
                Flash.show('meeting_not_available', 'warning');
            }
        }

        function deleteEvent(el) {
            let index = _.indexOf(vm.myMeetings, el);
            if (el.fromServer) {
                el.remove()
                    .then(validEvent)
                    .catch(error);
                el.status = 4;
                changeClassEventStatus(el);
            } else {
                vm.myMeetings.splice(index, 1);
            }
        }

        function viewRender(view) {
            vm.calendarView = view;
        }

        function statusDispatch(data, cb) {
            var r;
            switch (data) {
                case 'waiting':
                    r = cb(0);
                    break;
                case 'accepted':
                    r = cb(1);
                    break;
                case 'closed':
                    r = cb(2);
                    break;
                case 'refused':
                    r = cb(3);
                    break;
                case 'canceled':
                    r = cb(4);
                    break;
                default :
                    r = null;
            }
            return r;
        }

        function statusIsActive(id) {
            return (_.indexOf(meetingFilters.status, id) !== -1);
        }

        function toggleFilterStatus(data) {
            let toggle = id => {
                let sf = vm.meetingFilters.status;
                if (statusIsActive(id)) {
                    sf.splice(_.indexOf(sf, id), 1);
                } else {
                    sf.push(id);
                }
            };
            return statusDispatch(data, toggle);
        }

        function filterStatusActive(data) {
            return statusDispatch(data, statusIsActive);
        }

        function toggleFilterSpecial() {
            if (meetingFilters.special) {
                meetingFilters.special = false;
            } else if (meetingFilters.special === false) {
                meetingFilters.special = null;
            } else {
                meetingFilters.special = true;
            }
        }

        function countStatusSpecial(data) {
            return statusDispatch(data, id => {
                let count = _.where(vm.myMeetings, {
                    status: id,
                    special: true
                });
                return count.length;
            });
        }

        function saveSpecialMeeting(myForm, meeting) {
            if (myForm.$valid) {
                meeting.special = true;
                meeting.type = types.find(x => x.value === 'YooPortal');
                meeting.save()
                    .then(validEvent)
                    .catch(error);
            } else {
                error(myForm);
            }
        }

        function meetingFilterCB(item) {
            return ((_.indexOf(meetingFilters.status, item.status) >= 0) && item.special === meetingFilters.special);
        }

        function numberOfPages() {
            return Math.ceil(vm.myMeetings.length / vm.pageSize);
        }

        function viewReadyFn(nv) {
            let calConfig;
            if (nv.reduce((c, p) => c && p)) {
                calConfig = vm.uiConfig.calendar;
                calConfig.lang = $rootScope.currentLang;
                calConfig.minTime = businessHours.start;
                calConfig.maxTime = businessHours.end;
                calConfig.businessHours = businessHours;

                apiRest(route.meetings, 1).getList()
                    .then(displayMeetings)
                    .catch(error);

                vm.pageReady = true;
                // with all thios we can load and create the ticket
                // Unregister the watcher because we wont need it anymore
                viewReady();
            }
        }

        function updateEventSource(source) {
            vm.calendarView.calendar.removeEventSource(source);
            vm.calendarView.calendar.addEventSource(source);
            vm.calendarView.calendar.render();
        }
    }

})());
