'use strict';

angular.module('portailWebApp')
  .controller('MonitoringSystemImageController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', '$translate', '$uibModal', 'Monitoring', '$routeParams', ($scope, $location, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, $translate, $uibModal, Monitoring, $routeParams) => {
    const licences_endpoint = TokenRestAngularMS.all('monitoring/systemimages/licences'),
      jobs_endpoint = TokenRestAngularMS.all('monitoring/systemimages/agents'),
      relations_endpoint = TokenRestAngularMS.all('monitoring/relations'),
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations'),
      griddef = {
        licences: [
          {
            field: 'licence',
            type: 'number',
            displayName: 'licence',
            headerCellFilter: 'translate',
            filter: {
              term: '',
              placeholder: 'licence'
            },
            cellTemplate: `<yp-fill-search class="yp-grid__btn ui-grid-cell-contents" terms="{{row.entity.licence}}">{{COL_FIELD CUSTOM_FILTERS}}</yp-fill-search>`
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><i class="material-icons md-18">infos</i></a></div>'
          },
          {
            field: 'seller',
            displayName: 'reseller',
            headerCellFilter: 'translate',
            visible: false
          },
          {
            field: 'expiration',
            type: 'date',
            displayName: 'licence_expiration',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }],
            filterHeaderTemplate: customFilter.date.template,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.dateDif(grid.getCellValue(row, col), 30);
            }
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS }}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          }
        ],
        jobs: [
          {
            field: 'licence',
            displayName: 'licence',
            headerCellFilter: 'translate'
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a class="yp-table__link" href ng-click="grid.appScope.organization_info(row.entity.organization_id)"><i class="material-icons md-18">infos</i></a></div>'
          },
          {
            field: 'name',
            displayName: 'name',
            headerCellFilter: 'translate'
          },
          {
            field: 'licence_agent',
            displayName: 'licence_agent',
            headerCellFilter: 'translate'
          },
          {
            field: 'last_backup',
            displayName: 'backup_last_date',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            enableFiltering: true,
            cellTemplate: '<div class="ui-grid-cell-contents yp-cell_graph-icn" ng-click="grid.appScope.graph2(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}" title="{{row.entity.status_detailed}}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          }
        ]
      };

    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'systemimage';
    $rootScope.helpLink = 'yooportal/monitoring/systemimage.html';
    $rootScope.initDropdown('.yp-table--dd, .yp-table__toolbar');
    //endpoint

    //filters functions
    $scope.relation_id = null;
    //init
    $scope.organizations = [];
    $scope.relations = [];
    $scope.licences = [];
    $scope.jobs = [];
    $scope.filters = [];
    $scope.filters2 = [];
    // Monitoring service

    Monitoring.refreshFilters('systemimage', data => {
      $scope.filters = data;

      $timeout(() => {
        let type = $routeParams.filterType,
          value = $routeParams.filterValue || null;

        if (type) {
          switch (type) {
          case 'offline':
            $scope.filterColumnStatus('off');
            break;
          case 'capacity':
            $scope.filterColumnCapacity(79);
            break;
          case 'licence':
            $scope.filterColumnLicence(value);
            break;
          default:
          }
        }
      }, 1000);
    });
    $scope.loadIt = () => {
      Monitoring.loadFilters('systemimage', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //If selection grid1
        var organizations = $scope.gridApi1.selection.getSelectedRows(),
          columns1 = $scope.gridApi1.saveState.save(),
          columns2 = $scope.gridApi2.saveState.save(),
          columns3 = $scope.gridApi3.saveState.save();
        if (organizations.length) {
          data.data.grid1.selection = false;
        }
        //if filters  grid1?


        angular.forEach(columns1.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid1.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid2?

        angular.forEach(columns2.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid2.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid3?
        angular.forEach(columns3.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid3.columns[i].filters[j].term = filter.term;
            }
          });
        });
        $scope.relation_id = data.data.relation_id;
        $scope.gridApi1.saveState.restore($scope, data.data.grid1);
        $scope.gridApi2.saveState.restore($scope, data.data.grid2);
        $scope.gridApi3.saveState.restore($scope, data.data.grid3);
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('systemimage', {
        grid1: $scope.gridApi1.saveState.save(),
        grid2: $scope.gridApi2.saveState.save(),
        grid3: $scope.gridApi3.saveState.save(),
        relation_id: $scope.relation_id
      });
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };
    //Usefull
    $scope.graph = (type, licence) => {
      Monitoring.graph('systemimages/licences', type, licence.id, licence.licence);
    };
    $scope.graph2 = (type, licence) => {
      Monitoring.graph('systemimages/agents', type, licence.id, licence.name);
    };
    //refresh functions
    $scope.refresh_licences = () => {
      var organizations = $scope.gridApi1.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }

      if (r) {
        licences_endpoint.getList(filters).then(data => {
          $scope.licences = [];
          angular.forEach(data, row => {
            row.status = Monitoring.convert_status(row.status);
            $scope.licences.push(row);
          });
          //calcul
          $scope.count = _.countBy(data, row => {
            return row.status;
          });
        });
      }
      return r;
    };
    $scope.refresh_jobs = () => {
      var organizations = $scope.gridApi1.selection.getSelectedRows(),
        licences = $scope.gridApi2.selection.getSelectedRows(),
        filters = {},
        r = true;
      $scope.jobs = [];

      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }

      if (organizations.length > 100 || licences.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      } else if (r && (licences.length > 0 || organizations.length > 0)) {

        filters.organization_id = '';
        filters.licence_id = '';

        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });

        angular.forEach(licences, (item, i) => {
          filters.licence_id = filters.licence_id + item.id;
          if (i !== licences.length) {
            filters.licence_id = filters.licence_id + ',';
          }
        });
      }

      if (r && filters.licence_id) {
        jobs_endpoint.getList(filters).then(data => {
          angular.forEach(data, row => {
            $translate(row.status_detailed).then(msg => {
              row.status_detailed = msg;
            });
            row.status = Monitoring.convert_status(row.status);
            $scope.jobs.push(row);
          });
          $scope.countJob = _.countBy(data, row => {
            return row.status;
          });
        });
      }

      return r;

    };
    $scope.refresh_relations = () => {
      $scope.relations = [];
      relations_endpoint.getList().then(data => {

        var vardefault = {
          id: null,
          name: '--'
        };

        $scope.relations = data;
        $scope.relations.unshift(vardefault);
        $scope.relation_id = vardefault.id;
        $scope.refresh_organizations();
      });
    };

    $scope.refresh_organizations = () => {
      var filters = {};
      $scope.organizations = [];

      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      organizations_endpoint.getList(filters).then(data => {
        $scope.organizations = data;
        $scope.refresh_licences();
      });
    };
    //Init
    $scope.refresh_relations();

    //Grids


    $scope.gridOptions1 = Monitoring.setGridOption('organizations');
    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_licences();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_licences();
      });
      $scope.gridApi1 = gridApi;
    };

    $scope.gridOptions2 = Monitoring.setGridOption('licences', griddef.licences, true, true);
    $scope.gridOptions2.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_jobs();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_jobs();
      });
      $scope.gridApi2 = gridApi;
    };

    $scope.gridOptions3 = Monitoring.setGridOption('jobs', griddef.jobs, false, true);
    $scope.gridOptions3.onRegisterApi = gridApi => {
      $scope.gridApi3 = gridApi;
    };

    //Grid operations
    $scope.selectAll = () => {
      $scope.gridApi1.selection.selectAllVisibleRows();
    };

    $scope.clearAll = () => {
      $scope.gridApi1.selection.clearSelectedRows();
    };

    $scope.removeOrganisationFilter = row => {
      $scope.gridApi1.selection.unSelectRow(row);
    };

    $scope.removeLicenceFilter = row => {
      $scope.gridApi2.selection.unSelectRow(row);
    };
    $scope.filterColumnStatus = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'status'
      });
      if (col) {
        col.filters[0].term = val;
      }
      $scope.gridApi2.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnStatus2 = val => {
      var col = _.find($scope.gridApi3.grid.columns, {
        field: 'status'
      });
      col.filters[0].term = val;
      $scope.gridApi3.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'licence'
      });
      if (col) {
        col.filter.term = val;
        $scope.gridApi2.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };

  }]);
