angular.module('portailWebApp')
  .controller('ProblemViewController', ['$scope', '$location', '$rootScope', 'TokenRestAngularMS', '$routeParams', 'localStorageService', '_', '$filter', 'moment', '$window', 'Acl', function ($scope, $location, $rootScope, TokenRestAngularMS, $routeParams, localStorageService, _, $filter, moment, $window, Acl) {
    $rootScope.subTitle = 'problem_view';
    Acl.isAction('create_intervention');
    let vm = this,
      tk = TokenRestAngularMS,
      ep = {
        comment: tk.all('supportv2/problem/comment'),
        problems: tk.all('supportv2/problems/'),
        origins: tk.all('support/ressources/origin'),
        categories: tk.all('support/ressources/category'),
        users: tk.all('supportv2/wooxo_user')
      };
    vm.inputs = {};
    vm.ready = {};

    vm.addTime = (key, hour) => {
      let d = vm.details;
      if (d && d[key]) {
        d[key] = moment.utc(d[key])
          .add(moment.duration(hour, 'hours'))
          .toDate();
      } else {
        d[key] = moment.utc().add(1, 'hour').toDate();
      }

      d[key].setSeconds(0);
      d[key].setMilliseconds(0);
      if (key === 'review_date') {

        vm.details.setReviewDate(d[key]);
      }
      if (key === 'expected_close_date') {
        vm.details.setExpectedCloseDate(d[key]);
      }
    };

    vm.getCategories = () => {
      let storage = localStorageService.get(ep.categories.route);

      if (!storage) {
        ep.categories.getList().then(data => {
          vm.inputs.categories = _.map(data, v => {
            return v;
          });
          vm.ready.categories = true;
          vm.saveLocal(ep.categories.route, vm.inputs.categories);
        });
      } else {
        vm.inputs.categories = storage;
        vm.ready.categories = true;
      }

    };

    vm.getDetails = function (o) {
      let d = this;
      d.id = o.id || null;
      d.created_at = o.created_at || null;
      d.corrective_plan = o.corrective_plan || null;
      d.description = o.description || null;
      d.expected_close_date = o.expected_close_date || null;
      d.category = o.category || null;
      d.origin = o.origin || null;
      d.last_verification = o.last_verification || null;
      d.last_verify_by = o.last_verify_by || null;
      d.name = o.name || null;
      d.owner = o.owner || null;
      d.root_cause = o.root_cause || null;
      d.status = o.status;
      d.updated_at = o.updated_at || null;
      d.review_date = o.review_date || null;

      d.setExpectedCloseDate = function (date, force) {
        var valid;
        date = $filter('utc2local')(date);
        valid = d.isComing(date);
        if (valid || force) {
          d.expected_close_date = date.toDate();
        } else {
          d.expected_close_date = null;
        }
      };
      d.setReviewDate = function (date, force) {
        var valid;
        date = $filter('utc2local')(date);
        valid = d.isComing(date);
        if (valid || force) {
          d.review_date = date.toDate();
        } else {
          d.review_date = null;
        }
      };
      d.isComing = function (date) {
        var now = moment.utc();
        return date.isAfter(now);
      };
      if (o.expected_close_date) {
        d.setExpectedCloseDate(o.expected_close_date, true);
      }
      if (o.review_date) {
        d.setReviewDate(o.review_date, true);
      }
      return d;
    };

    vm.getOrigins = () => {
      var storage = localStorageService.get(ep.origins.route);

      if (!storage) {
        ep.origins.getList().then(data => {
          vm.inputs.origins = _.map(data, v => {
            return v;
          });
          vm.saveLocal(ep.origins.route, vm.inputs.origins);
          vm.ready.origins = true;
        });
      } else {
        vm.inputs.origins = storage;
        vm.ready.origins = true;
      }

    };

    vm.getProblem = () => {
      if ($routeParams.id) {
        ep.problems.get($routeParams.id).then(data => {
          vm.details = vm.getDetails(data);
          vm.comments = data.comment;
        });
      } else {
        vm.details = vm.getActualProblem();
        vm.comments = [];
      }

    };

    vm.getUsers = () => {
      ep.users.getList().then(data => {
        vm.inputs.users = _.map(data, v => {
          return v;
        });
      });
    };

    vm.init = () => {

      vm.datePickers = $rootScope.initDropdown('.yp-dateMod__wrapper', {
        closeOut: true,
        multi: false
      });
      vm.getProblem();
      vm.getOrigins();
      vm.getCategories();
      vm.getUsers();
    };


    vm.init();
    vm.saveLocal = (key, value) => {
      return localStorageService.set(key, value);
    };

    vm.submitChanges = () => {
      let problem = vm.getActualProblem();
      tk.all('supportv2/problems').post(problem)
        .then(response => {

          vm.details = vm.getDetails(response);
          if (vm.message) {
            vm.submitComment(vm.verification);
          }
          if (problem.id) {
            $window.location.reload();
          } else {
            $location.path('/support/problemview/' + vm.details.id);
          }

        });
    };
    vm.submitComment = (verification = false) => {
      let comment = {};
      comment.message = vm.message;
      comment.verification = verification;
      comment.problem_id = vm.details.id;
      ep.comment.post(comment);
    };
    vm.getActualProblem = () => {
      let problem = {};

      problem.id = vm.details.id || null;
      problem.corrective_plan = vm.details.corrective_plan || null;
      problem.description = vm.details.description || null;
      problem.expected_close_date = vm.details.expected_close_date || null;
      problem.category = vm.details.category.id || null;
      problem.origin = vm.details.origin.id || null;
      problem.last_verification = moment.utc(vm.details.last_verification) || null;
      problem.last_verify_by = vm.details.last_verify_by ? vm.details.last_verify_by.id : null;
      problem.name = vm.details.name;
      problem.owner = vm.details.owner.id || $rootScope.currentUser;
      problem.root_cause = vm.details.root_cause || null;
      problem.status = vm.details.status;
      problem.review_date = vm.details.review_date || null;

      return problem;
    };

    vm.toggleStatus = () => {
      vm.details.status = !vm.details.status;
      vm.submitChanges();
    };
    vm.updateDate = key => {
      let d = vm.details;
      vm.datePickers.forEach(v => {
        v.closeAll();
      });

      if (key === 'review_date') {
        d.setReviewDate(d.review_date);
      }

      if (key === 'expected_close_date') {
        d.setExpectedCloseDate(d.expected_close_date);
      }
    };
  }]);
