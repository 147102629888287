'use strict';

angular.module('portailWebApp')
    .controller('CommentsAdminController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        let endpoint = TokenRestAngular.all('admin/comments');
        //Flash
        Flash.clear();
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'comments_admin';
        $rootScope.helpLink = '';
        //Endpoint


        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(data => {
                $scope.gridData = data;
            });
        };
        $scope.refresh();


        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {field: 'comment_name', displayName: 'comment_name', headerCellFilter: 'translate'},
            {field: 'comment_text', displayName: 'comment_text', headerCellFilter: 'translate'},
        {field: 'addToFollow', displayName: 'follow_time', headerCellFilter: 'translate'}], true, false, 'high');
        $scope.gridOptions.multiSelect = false;
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };
        $scope.record = () => {
            let data = {
                'comment_name': $scope.comment_name,
                'comment_text': $scope.comment_text,
                'addToFollow': $scope.addToFollow
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.comment_name = null;
                $scope.comment_text = null;
                $scope.addToFollow = null;
                $scope.new = null;
            });
        };
    }]);
