((function () {


    angular.module('portailWebApp')
        .controller('MeetingController', MeetingController);

    MeetingController.$inject = ['$scope', 'Acl', '$rootScope', 'Flash', 'TokenRestAngularMS', 'apiRest', 'localStorageService', '_', '$translate', 'moment', 'userService'];

    function MeetingController($scope, Acl, $rootScope, Flash, TokenRestAngularMS, apiRest, localStorageService, _, $translate, moment, userService) {
        const vm = this;

        //IS auth and admin
        Acl.isAction('create_intervention');

        vm.updateDate = updateDate;
        vm.datePickersConfig = {
            minView: 'day'
        };
        vm.datePickers = $rootScope.initDropdown('.yp-dateMod__wrapper', {
            closeOut: true,
            multi: false
        });

        $rootScope.pageClass = '';
        $rootScope.subTitle = 'meeting_management';
        $rootScope.helpLink = 'yooportal/support/meetings.html';

        // pagination
        vm.currentPage = 0;
        vm.pageSize = 10;
        vm.numberOfPages = () => {
            return Math.ceil(vm.myMeetings.length / vm.pageSize);
        };
        vm.inputs = {};
        vm.ready = {};
        vm.orderIcon = 'down';
        let viewReady,
            tk = TokenRestAngularMS,
            route = {
                meetings: 'support/meetings',
                meetingByRange: 'support/meetings/meetingByRange',
                openinghours: 'support/meetings/openinghours',
                ics: 'support/meetings/linkIcs',
                types: 'support/ressources/category'
            },
            ep = {
                meetings: apiRest(route.meetings, 1),
                meetingByRange: apiRest(route.meetingByRange, 1),
                openinghours: apiRest(route.openinghours, 1, 1),
                ics: apiRest(route.ics, 1, 1),
                types: apiRest(route.types, 1),
                licences: apiRest('support/ressources/licences', 1),
                orga: apiRest('support/ressources/organizations', 1),
                users: apiRest('supportv2/contacts', 1)
            },
            langChange;


        getBasicData();
        langChange = $scope.$watch('meeting.calendarView', langChanged);

        viewReady = $scope.$watchGroup(
            ['userService.user().id', 'meeting.ready.users', 'meeting.ready.bh'],
            viewReadyWatcher
        );

        vm.meetingFilters = {
            status: [0, 1, 2, 3, 4],
            reverse: 1,
            fn: item => {
                return (_.indexOf(vm.meetingFilters.status, item.status) >= 0);
            },
            reverseDateFn: () => {
                return vm.meetingFilters.reverse;
            }
        };

        vm.saveMeeting = saveMeeting;
        vm.toggleMeetingStatus = toggleMeetingStatus;
        vm.toggleFilterStatus = toggleFilterStatus;
        vm.filterStatusActive = filterStatusActive;
        vm.countStatus = countStatus;
        //vm.pageReady = false;
        vm.filterLicence = filterLicence;
        vm.events = [];
        vm.myMeetings = [];
        vm.notAvailable = [];


        vm.alertOnEventClick = alertOnEventClick;

        vm.addEvent = addEvent;
        vm.updateEventSource = updateEventSource;
        /**
         * Contact
         */
        vm.toggleUser = toggleUser;
        vm.addContact = addContact;
        vm.delContact = delContact;
        vm.addToContact = addToContact;
        vm.initAuto = initAuto;
        vm.disabledDd = disabledDd;
        vm.callTo = callTo;
        vm.refresh = {
            orgas: refreshOrganizations,
            licences: refreshLicences
        };
        vm.uiConfig = {
            calendar: {
                timezone: 'local',
                allDaySlot: false,
                slotDuration: '00:30:00',
                defaultView: 'agendaWeek',
                firstDay: 1,
                ignoreTimezone: true,
                slotEventOverlap: false,
                minTime: '06:00:00',
                maxTime: '17:00:00',
                header: {
                    left: 'title',
                    center: '',
                    right: 'today prev,next'
                },
                viewRender: viewRender,
                weekends: false,
                eventConstraint: 'businessHours',
                dayClick: vm.alertOnEventClick
            }
        };

        vm.applyLicenceToOrga = applyLicenceToOrga;
        vm.isCurrentUser = isCurrentUser;
        vm.getRange = getRange;
        vm.toggleReverse = toggleReverse;

        vm.dateStartFilter = dateStartFilter;
        /////////////////////////////////////////////////////////////


        function addContact(meeting) {
            meeting.contacts.push({
                contact: true
            });
            disabledDd(meeting);
        }

        function addEvent(mD) {
            let start = moment.utc(mD.startOf('hour')),
                end = angular.copy(mD).endOf('hour'),
                newEvent,
                alreadyOne = _.find(vm.myMeetings, {
                    fromServer: false
                });


            newEvent = meetingFactory({
                start: start,
                end: end
            });
            vm.myMeetings.forEach(m => {
                m.acContacts = null;
            });

            if (vm.currentUser) {
                newEvent.contacts.push(vm.currentUser);
                newEvent.organization = vm.currentUser.organization;
            }

            newEvent.acContacts = angular.copy(vm.backupUsers);
            if (alreadyOne) {
                vm.myMeetings.splice(0, 1);
            }
            vm.myMeetings.unshift(newEvent);

            newEvent.details = true;
            updateEventSource(vm.myMeetings);
        }

        function addToContact(contact, user) {
            if (!user.disabled) {
                contact.id = user.id;
                contact.name = user.name;
                contact.phone = user.phone;
                contact.email = user.email;
                contact.active = true;
                contact.contact = user.contact;
                user.disabled = true;
            }
        }

        function alertOnEventClick(date) {
            let sD = date.startOf('hour');
            if (dateOk(sD)) {
                addEvent(sD);
            } else {
                Flash.show('meeting_not_available', 'warning');
            }
        }

        function applyLicenceToOrga(elem) {
            let match = _.findWhere(vm.inputs.orga, {
                id: elem.licence.orga_id
            });
            elem.organization = match || elem.organization;
        }

        function callTo(num) {
            return num ? num.replace(/[\D]/g, '') : null;
        }

        function changeClassEventStatus(Meeting) {
            let r = ['is-preview'];
            if (Meeting.fromServer) {
                switch (Meeting.status) {
                    case 1:
                        r = ['is-accepted'];
                        break;
                    case 2:
                        r = ['is-closed'];
                        break;
                    case 3:
                        r = ['is-refused'];
                        break;
                    case 4:
                        r = ['is-canceled'];
                        break;
                    default:
                        r = ['is-waiting'];
                }
            }
            Meeting.className = r;
        }

        function countStatus(data) {
            return statusDispatch(data, id => {
                let count = _.where(vm.myMeetings, {
                    status: id
                });
                return count.length;
            });
        }

        function dateStartFilter(item) {
            return moment(item.start).unix();
        }

        function dateOk(date) {
            let hd = angular.copy(date).add(10, 'm'),
                alreadyOne = (vm.notAvailable.find(x => hd.isBetween(x.start, x.end)) || vm.myMeetings.find(x => hd.isBetween(x.start, x.end)));

            return !(alreadyOne || (vm.break
            && (date.hour() >= vm.break.start.slice(0, 2)
            && date.hour() < vm.break.end.slice(0, 2)))
            || date.isBefore(moment()));
        }

        function delContact(meeting, contact) {
            let list = meeting.contacts,
                i = _.indexOf(list, contact),
                match,
                raContact;
            /**
             * Si l'objet a moins de deux propriétée, il a été vidé ou jamais remplis et on peux le supprimer
             **/
            if (Object.keys(contact).length <= 3) {
                list.splice(i, 1);
            } else {
                // Si le contact a un id la relation doit etre détruite en base
                if (contact.id && meeting.id) {
                    raContact = TokenRestAngularMS.restangularizeElement(null, contact, 'supportv2/tickets/' + meeting.id + '/contacts');
                    raContact.fromServer = true;
                    raContact.remove();
                }
                /* If the person is present in the suggestion list, we change his
                 * status there to allow him to be present in the suggestions
                 */
                match = _.findWhere(vm.inputs.users, {
                    id: contact.id
                });
                if (match) {
                    match.disabled = false;
                }
                // finaly, we can empty the fields
                list[i] = {
                    contact: true
                };
            }
        }

        function disabledDd(meeting, except) {
            meeting.contacts.forEach(v => {
                if (v !== except) {
                    v.current = false;
                }
            });
        }

        function displayMeetings(data) {
            vm.myMeetings = [];
            vm.notAvailable = [];

            if (vm.calendarView) {
                vm.calendarView.calendar.removeEvents();
            }

            vm.myMeetings =
                data.filter(m => m.organization_id && !m.special)
                    .map(m => meetingFactory(m));

            vm.notAvailable =
                data.filter(m => m.special || !m.hasOwnProperty('organization_id'))
                    .map(m => ({
                        title: '-',
                        start: moment.utc(m.start),
                        end: moment.utc(m.end),
                        className: ['notAvailable']
                    }));

            if (vm.break) {
                vm.notAvailable.push({
                    title: '-',
                    start: vm.break.start,
                    end: vm.break.end,
                    dow: [1, 2, 3, 4, 5],
                    className: ['notAvailable'],
                    stick: true
                });
                vm.ready.dispo = true;
            }

            vm.calendarView.calendar.addEventSource(vm.myMeetings);
            vm.calendarView.calendar.addEventSource(vm.notAvailable);
            vm.calendarView.calendar.render();
            vm.ready.meetings = true;
        }

        function error(data) {
            if (!data.statusText) {
                if (data.$error && data.$error.required) {
                    let dte = data.$error.required,
                        s = null;
                    dte.forEach((v, i) => {
                        s = v.$name;
                        s += (!!i && i !== dte.length) ? ', ' : ' required';
                    });
                    Flash.show(s, 'warning');
                }
                if (angular.isString(data)) {
                    Flash.show(data, 'warning');
                }
            }
        }

        function filterLicence(elem) {
            /**
             * We want to filter the licence autocompletion list  if an organisation is selected
             *
             * need a ticket to be created and the licence list to be loaded
             * should be triggered any time an orga is chosen
             */
            if (elem.organization && elem.organization.id) {
                vm.inputs.licences = _.where(vm.backuplicences, {
                    orga_id: elem.organization.id
                });
                if (elem.licence && (elem.organization.id !== elem.licence.orga_id)) {
                    elem.licence = null;
                }
            } else {
                vm.inputs.licences = angular.copy(vm.backuplicences);
            }
            $translate('none').then(value => {
                vm.inputs.licences.unshift({
                    id: null,
                    licence: value
                });
            });
        }

        function filterStatusActive(data) {
            return statusDispatch(data, statusIsActive);
        }

        function getBasicData() {
            ep.types.getList()
                .then(manageTypes)
                .catch(error);

            ep.users.getList()
                .then(manageUsers)
                .catch(error);

            ep.ics.get()
                .then(data => {
                    vm.icslink = data.url;
                })
                .catch(error);

            ep.openinghours.get()
                .then(manageOpeningHour)
                .catch(error);
        }

        function getRange() {
            let dateFilter = {},
                from,
                to;

            from = vm.dateBeg ? moment(vm.dateBeg) : null;
            to = vm.dateEnd ? moment(vm.dateEnd) : null;
            if (from && from.isValid()) {
                dateFilter.dateBeg = from.format('YYYY-MM-DD');
            }
            if (to && to.isValid()) {
                dateFilter.dateEnd = to.format('YYYY-MM-DD');
            }
            if (!dateFilter.dateBeg && !dateFilter.dateEnd) {
                dateFilter = null;
            }

            ep.meetingByRange.getList(dateFilter)
                .then(displayMeetings)
                .catch(error);
        }

        function isCurrentUser(author) {
            if (vm.currentUser && author) {
                return author.id === vm.currentUser.id;
            }
            return false;
        }

        function initAuto($event, meeting, contact) {
            disabledDd(meeting);
            contact.current = true;
            $event.preventDefault();
        }

        function langChanged() {
            if (vm.calendarView) {
                vm.uiConfig.calendar.lang = $rootScope.currentLang;
                //vm.calendarView.render();
                langChange();
            }
        }

        function manageLicencesList(licences) {
            vm.backuplicences = licences;
            vm.myMeetings.forEach(d => {
                if (!d.fromServer) {
                    filterLicence(d);
                }
            });
            vm.ready.licences = true;
        }

        function manageOrganizationsList(organizations) {
            vm.inputs.orga = _.map(organizations, v => ({
                id: v.id,
                name: v.name
            }));
            saveLocal(ep.orga.route, vm.inputs.orga);
            vm.ready.orgas = true;
        }

        function manageOpeningHour(data) {
            data.closed.start = moment.utc(data.closed.start, 'HH:mm:ss').local().format('HH:mm:ss');
            data.closed.end = moment.utc(data.closed.end, 'HH:mm:ss').local().format('HH:mm:ss');
            vm.break = data.closed;
            vm.businessHours = {
                start: moment.utc(data.start, 'HH:mm:ss').local().format('HH:mm:ss'),
                end: moment.utc(data.end, 'HH:mm:ss').local().format('HH:mm:ss'),
                dow: [1, 2, 3, 4, 5]
            };
            vm.ready.bh = true;
        }

        function manageTypes(data) {
            vm.inputs.types = data;
        }

        function manageUsers(users) {
            vm.backupUsers = _.map(users, u => {
                return new Person(u);
            });
            vm.ready.users = true;
        }

        function meetingFactory(o) {
            let Meeting;
            if (!moment.isMoment(o.start)) {
                o.start = moment.utc(o.start);
            }

            Meeting = {
                id: o.id || null,
                request_text: o.request_text || null,
                close_text: o.close_text || null,
                start: o.start,
                end: moment.utc(o.end),
                status: o.status || 0,
                created_at: o.created_at ? o.created_at : null,
                updated_at: o.updated_at ? o.updated_at : null,
                organization: o.organization || null,
                licence: o.licence || null,
                type: o.type || null,
                contacts: o.contacts || [],
                stick: true,
                special: o.special || false
            };
            /*
             * Needed for hollyday and such...
             * special interval, sopecified from the super admin to represent when the service is closed
             */
            if (Meeting.special) {
                Meeting.title = o.request_text || 'special';
            } else {
                Meeting.title = o.organization ? o.organization.name : 'preview';
            }

            //if (o.fromServer) {
            Meeting = tk.restangularizeElement(null, Meeting, route.meetings);
            Meeting.fromServer = o.fromServer || false;

            //}
            changeClassEventStatus(Meeting);
            return Meeting;
        }

        function Person(o) {
            this.id = o.id || null;
            this.contact = parseInt(o.contact, 10) ? true : false;
            this.name = o.name || null;
            this.email = o.email || null;
            this.organization = o.organization || null;
            this.support = o.support || null;
            this.phone = o.phone || null;
            this.gsm = o.gsm || null;
            this.fax = o.fax || null;
            this.active = o.active || true;
        }

        function refreshLicences() {
            ep.licences.getList()
                .then(manageLicencesList)
                .catch(error);
        }

        function refreshOrganizations() {
            ep.orga.getList()
                .then(manageOrganizationsList)
                .catch(error);
        }

        function saveLocal(key, value) {
            return localStorageService.set(key, value);
        }

        function saveMeeting(myForm, meeting) {
            if (myForm.$valid) {
                if (meeting.acContacts) {
                    delete meeting.acContacts;
                }

                meeting.save()
                    .then(validEvent)
                    .catch(error);
            } else {
                error(myForm);
            }
        }

        function statusDispatch(data, cb) {
            var r;
            switch (data) {
                case 'waiting':
                    r = cb(0);
                    break;
                case 'accepted':
                    r = cb(1);
                    break;
                case 'closed':
                    r = cb(2);
                    break;
                case 'refused':
                    r = cb(3);
                    break;
                case 'canceled':
                    r = cb(4);
                    break;
                default :
                    r = null;
            }
            return r;
        }

        function statusIsActive(id) {
            return (_.indexOf(vm.meetingFilters.status, id) !== -1);
        }

        function success(data) {
            Flash.show(data.success, 'success');
        }

        function toggleFilterStatus(data) {
            let toggle = id => {
                let sf = vm.meetingFilters.status;
                if (statusIsActive(id)) {
                    sf.splice(_.indexOf(sf, id), 1);
                } else {
                    sf.push(id);
                }
            };
            return statusDispatch(data, toggle);
        }

        function toggleMeetingStatus(meeting, ns) {
            if (meeting.status !== ns) {
                switch (ns) {
                    case 'accepted':
                        meeting.status = 1;
                        meeting.save()
                            .then(success)
                            .catch(error);
                        break;
                    case 'closed':
                        if (meeting.close_text) {
                            meeting.status = 2;
                            meeting.save()
                                .then(success)
                                .catch(error);
                        } else {
                            error('close_text_required');
                        }
                        break;
                    case 'refused':
                        if (meeting.close_text) {
                            meeting.remove()
                                .then(success)
                                .catch(error);
                            meeting.status = 3;
                        } else {
                            error('close_text_required');
                        }
                        break;
                    case 'canceled':
                        meeting.remove()
                            .then(success)
                            .catch(error);
                        meeting.status = 4;
                        break;
                    default:
                        error('invalid_value');
                }
                changeClassEventStatus(meeting);
            }
        }

        function toggleReverse() {
            if (vm.orderIcon === 'down') {
                vm.orderIcon = 'up';
            } else {
                vm.orderIcon = 'down';
            }

            vm.meetingFilters.reverse = !vm.meetingFilters.reverse;
        }

        //
        function toggleUser(user) {
            user.active = !user.active;
        }

        function updateDate() {
            vm.datePickers.forEach(v => {
                v.closeAll();
            });
        }

        function updateEventSource(source) {
            vm.calendarView.calendar.removeEventSource(source);
            vm.calendarView.calendar.addEventSource(source);
            vm.calendarView.calendar.render();
        }

        function validEvent(data) {
            // set the receive id to the object
            let item = _.findWhere(vm.myMeetings, {
                id: null
            });

            item.id = data.id;
            item.fromServer = true;
            item.status = data.status;
            item.title = item.organization.name;

            changeClassEventStatus(item);
            updateEventSource(vm.myMeetings);

            Flash.show(data.success, 'success');
        }

        function viewReadyWatcher(nv) {
            let calconf;
            if (nv.reduce((c, p) => c && p)) {
                calconf = vm.uiConfig.calendar;
                vm.currentUser = new Person(userService.user());
                vm.currentUser.organization = {
                    id: $rootScope.currMs.organization_id,
                    name: $rootScope.currMs.organization
                };
                if ($rootScope.isAction('create_intervention')) {
                    vm.currentUser.isSupport = true;
                }
                calconf.lang = $rootScope.currentLang;
                calconf.minTime = vm.businessHours.start;
                calconf.maxTime = vm.businessHours.end;
                calconf.businessHours = vm.businessHours;

                ep.meetings.getList()
                    .then(displayMeetings)
                    .catch(error);

                vm.refresh.orgas();
                vm.refresh.licences();
                vm.pageReady = true;

                // with all those we can load and create the ticket
                // Unregister the watcher because we wont need it anymore
                viewReady();
            }
        }

        function viewRender(view) {
            vm.calendarView = view;
        }

    }

})());
