'use strict';
((function () {
    angular.module('portailWebApp')
        .controller('EmailTextsAdmController', EmailTextsAdmController);

    EmailTextsAdmController.$inject = ['$scope', '$rootScope', 'TokenRestAngular', '$timeout', 'Monitoring'];

    function EmailTextsAdmController($scope, $rootScope, TokenRestAngular, $timeout, Monitoring) {
        let endpoint = TokenRestAngular.all('admin/emailtexts');

        // Binding
        //////////////////////////////////////////////////////

        $scope.gridData = [];
        $scope.selected = false;

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {field: 'email.name', displayName: 'email', headerCellFilter: 'translate'},
            {field: 'email.type', displayName: 'type', headerCellFilter: 'translate'},
            {field: 'whitelabel.name', displayName: 'whitelabel', headerCellFilter: 'translate'},
            {field: 'i18nlanguage.name', displayName: 'language', headerCellFilter: 'translate'}
        ], true, false, 'high');
        $scope.gridOptions.multiSelect = false;
        $scope.gridOptions.onRegisterApi = onRegisterApi;

        $scope.refresh = refresh;
        $scope.update = update;
        $scope.refresh_text = refreshText;
        $scope.sample = sample;
        $scope.perfomUpdate = doUpdate;

        $rootScope.subTitle = 'emailtexts_admin';
        $rootScope.helpLink = '';

        // Init
        ///////////////////////////////////////////

        refresh();

        // Functions
        /////////////////////////////////////////////////////////////

        function update() {
            angular.forEach($scope.gridApi.selection.getSelectedRows(), item => {
                $scope.selected = item;
                $scope.old = item.clone();
            });
        }

        function doUpdate() {
            $rootScope.xhr = true;
            $scope.selected.save().then(() => {
                $scope.refresh();
                $scope.selected = false;
                $scope.old = false;
            });
            $timeout(() => {

                $rootScope.xhr = false;
            }, 1000);
        }

        function refresh() {
            endpoint.getList().then(data => {
                $scope.gridData = data;
            });
        }

        function refreshText() {
            $scope.selected = $scope.old;
        }

        function sample() {
            let endpoints = TokenRestAngular.all('admin/emailtexts/sample/' + $scope.selected.id);
            endpoints.post({
                'text': $scope.selected.text,
                'object': $scope.selected.object
            });
        }

        function onRegisterApi(gridApi) {
            $scope.gridApi = gridApi;
        }

    }
})());

