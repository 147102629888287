'use strict';

angular.module('portailWebApp')
    .controller('PinsController', ['$scope', '$rootScope', 'Flash', '$translate', '$interval', '$window', ($scope, $rootScope, Flash, $translate, $interval, $window) => {
        var parentscope = $window.opener.angular.element('#thecontainer').scope();

        //Flash
        Flash.clear();
        //Title
        $rootScope.subTitle = 'pins';
        $rootScope.helpLink = '';
        $scope.pins = parentscope.pins;

        parentscope.$on('PINS_CHANGED', () => {
            $scope.pins = parentscope.pins;
        });

        $interval(() => {
            parentscope = $window.opener.angular.element('#thecontainer').scope();
            parentscope.$on('PINS_CHANGED', () => {
                $scope.pins = parentscope.pins;
            });
        }, 2000);

        $scope.clearPanel = () => {
            parentscope.clearPanel();
        };

        $scope.deletePin = pin => {
            parentscope.deletePin(pin);
        };

    }]);
