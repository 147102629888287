'use strict';

angular.module('portailWebApp')
  .controller('MonitoringSyntheticController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', '$translate', '$uibModal', 'Monitoring', 'brand', '$routeParams', function ($scope, $location, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, $translate, $uibModal, Monitoring, brand, $routeParams) {
    const vm = this;
    //endpoint
    var boxes_endpoint = TokenRestAngularMS.all('monitoring/synthetic'),
      relations_endpoint = TokenRestAngularMS.all('monitoring/relations'),
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations'),
      boxesDef = [
        {
          field: 'name',
          displayName: 'organization',
          headerCellFilter: 'translate',
          cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.name }}</div>'
        },
        {
          field: 'seller',
          displayName: 'reseller',
          headerCellFilter: 'translate',
          visible: false,
          cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certifiedSeller ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certifiedSeller" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.seller }}</div>'
        },
        {
          field: 'licence',
          displayName: 'licence',
          enableFiltering: true,
          filter: {
            term: '',
            placeholder: 'licence'
          },
          headerCellFilter: 'translate',
          cellTemplate: `<yp-fill-search class="yp-grid__btn ui-grid-cell-contents" terms="{{row.entity.licence}}">{{COL_FIELD CUSTOM_FILTERS}}</yp-fill-search>`
        },
        {
          field: 'serial_number',
          displayName: 'serial_number',
          enableFiltering: true,
          headerCellFilter: 'translate'
        },
        {
          field: 'boxe_status',
          displayName: 'boxe_status',
          headerCellFilter: 'translate',
          cellTemplate: customFilter.i18n.cellTemplate,
          filterHeaderTemplate: customFilter.bool.template,
          filter: {
            term: [],
            options: customFilter.bool.filterBoxesOptions,
            condition: customFilter.bool.filterStatus
          },
          cellClass: (grid, row, col) => {
            return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
          }
        },
        {
          field: 'boxe_warranty',
          displayName: 'warranty',
          type: 'date',
          headerCellFilter: 'translate',
          enableFiltering: true,
          filters: [
            {
              condition: customFilter.date.isBefore
            },
            {
              condition: customFilter.date.isAfter
            }
          ],
          filterHeaderTemplate: customFilter.date.template,
          cellClass: (grid, row, col) => {
            return Monitoring.colorHelper.dateDif(grid.getCellValue(row, col), 30);
          }
        },
        {
          field: 'yb_status',
          displayName: brand.trad('yb') + ' status',
          headerCellFilter: 'translate',
          enableFiltering: true,
          filterHeaderTemplate: customFilter.bool.template,
          filter: {
            term: [],
            options: customFilter.bool.filterOption,
            condition: customFilter.bool.filterStatus
          },
          cellTemplate: customFilter.i18n.cellTemplate,
          cellClass: (grid, row, col) => {
            return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
          }
        },
        {
          field: 'yf_status',
          displayName: brand.trad('yf') + ' status',
          enableFiltering: true,
          headerCellFilter: 'translate',
          cellTemplate: customFilter.i18n.cellTemplate,
          filterHeaderTemplate: customFilter.bool.template,
          filter: {
            term: [],
            options: customFilter.bool.filterOption,
            condition: customFilter.bool.filterStatus
          },
          cellClass: (grid, row, col) => {
            return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
          }
        },
        {
          field: 'warranty',
          displayName: 'expiration',
          type: 'date',
          headerCellFilter: 'translate',
          enableFiltering: true,
          filters: [
            {
              condition: customFilter.date.isBefore
            },
            {
              condition: customFilter.date.isAfter
            }],
          filterHeaderTemplate: customFilter.date.template,
          cellClass: (grid, row, col) => {
            return Monitoring.colorHelper.dateDif(grid.getCellValue(row, col), 30);
          }
        },
        {
          field: 'nb_ticket',
          displayName: 'nb_tickets',
          enableFiltering: true,
          headerCellFilter: 'translate',
          cellTemplate: '<div class="ui-grid-cell-contents"><a ng-href="/support/ticketlist/licence/{{row.entity.licence}}"><i class="material-icons md-18">list</i> {{COL_FIELD CUSTOM_FILTERS}}</a></div>'
        },
        {
          field: 'contracts',
          displayName: 'contracts',
          enableFiltering: true,
          headerCellFilter: 'translate'
        }
      ];

    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'monitoring_synthetic';
    $rootScope.helpLink = 'yooportal/monitoring/synthese.html';
    $rootScope.initDropdown('.yp-table--dd');
    //filters functions
    $scope.relation_id = null;
    //init
    $scope.organizations = [];
    $scope.relations = [];
    $scope.boxes = [];
    $scope.filters = [];

    // Monitoring service
    $scope.refresh_filters = () => {
      Monitoring.refreshFilters('monitoring_synthetic', data => {
        $scope.filters = data;
        if ($scope.filters.length > 0) {
          $scope.loadFilter($scope.filters[0].id);
        }
        $timeout(() => {
          let type = $routeParams.filterType,
            value = $routeParams.filterValue || null;

          if (type) {
            switch (type) {
              case 'offline':
                $scope.filterColumnStatus('off');
                break;
              case 'capacity':
                $scope.filterColumnCapacity(79);
                break;
              case 'licence':
                $scope.filterColumnLicence(value);
                break;
              default:
            }
          }
        }, 1000);
      });
    };
    $scope.loadIt = () => {
      Monitoring.loadFilters('monitoring_synthetic', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //If selection grid1
        var organizations = $scope.gridApi1.selection.getSelectedRows(),
          columns1 = $scope.gridApi1.saveState.save(),
          columns2 = $scope.gridApi2.saveState.save();
        if (organizations.length) {
          data.data.grid1.selection = false;
        }
        //if filters  grid1?
        angular.forEach(columns1.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid1.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid2?

        angular.forEach(columns2.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid2.columns[i].filters[j].term = filter.term;
            }
          });
        });

        $scope.relation_id = data.data.relation_id;

        $scope.gridApi1.saveState.restore($scope, data.data.grid1);
        $scope.gridApi2.saveState.restore($scope, data.data.grid2);
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('monitoring_synthetic', {
        grid1: $scope.gridApi1.saveState.save(),
        grid2: $scope.gridApi2.saveState.save(),
        relation_id: $scope.relation_id
      });
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };
    //Usefull
    $scope.getColor = () => {
      return $scope.color;
    };
    //refresh functions
    $scope.refresh_boxes = () => {
      var organizations = $scope.gridApi1.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (r) {
        boxes_endpoint.getList(filters).then(data => {
          $scope.boxes = [];
          angular.forEach(data, row => {
            row.boxe_status = Monitoring.convert_status_boxe(row.boxe_status);
            row.yb_status = Monitoring.convert_status(row.yb_status);
            row.si_status = Monitoring.convert_status(row.si_status);
            $scope.boxes.push(row);
          });
        });
      }
      return r;
    };
    $scope.refresh_relations = () => {
      $scope.relations = [];
      relations_endpoint.getList().then(data => {

        var vardefault = {
          id: null,
          name: '--'
        };
        $scope.relations = data;
        $scope.relations.unshift(vardefault);
        $scope.relation_id = vardefault.id;
        $scope.refresh_organizations();
      });
    };

    $scope.refresh_organizations = () => {
      var filters = {};
      $scope.organizations = [];
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      organizations_endpoint.getList(filters).then(data => {
        $scope.organizations = data;
        $scope.refresh_boxes();
      });
    };
    //Init
    $scope.refresh_relations();
    $scope.refresh_filters();

    //Grids
    $scope.gridOptions1 = Monitoring.setGridOption('organizations');

    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_boxes();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_boxes();
      });
      $scope.gridApi1 = gridApi;
    };
    $scope.gridOptions2 = Monitoring.setGridOption('boxes', boxesDef, true, true, 'high');
    $scope.gridOptions2.multiSelect = false;
    $scope.gridOptions2.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        vm.selectedLicence = gridApi.selection
          .getSelectedRows() ?
          gridApi.selection.getSelectedRows()[0] : null;
      });
      $scope.gridApi2 = gridApi;
    };

    //Grid operations
    $scope.selectAll = () => {
      $scope.gridApi1.selection.selectAllVisibleRows();
    };
    $scope.clearAll = () => {
      $scope.gridApi1.selection.clearSelectedRows();
    };
    $scope.removeOrganisationFilter = row => {
      $scope.gridApi1.selection.unSelectRow(row);
    };
    $scope.filterColumnStatus = val => {
      $scope.gridApi2.grid.columns[8].filters[0] = {
        term: val
      };
      $scope.gridApi2.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnCapacity = val => {
      $scope.gridApi2.grid.columns[13].filters[0] = {
        term: val
      };
      $scope.gridApi2.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'licence'
      });
      if (col) {
        col.filter.term = val;
        $scope.gridApi2.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };

  }]);
