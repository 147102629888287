angular.module('portailWebApp')
    .controller('ProblemListController', ['$scope', '$location', '$rootScope', 'TokenRestAngularMS', '_', 'Acl', 'Flash', function ($scope, $location, $rootScope, TokenRestAngularMS, _, Acl, Flash) {
        $rootScope.subTitle = 'problem_list';
        Acl.isAction('create_intervention');
        const vm = this,
            tk = TokenRestAngularMS,
            ep = {
                problems: tk.all('supportv2/problems'),
                massAssign: tk.all('supportv2/problems/assign')
            };

        vm.filterProblems = () => {
            vm.problems = vm.problemList;
            if (!vm.withClosed) {
                vm.problems = vm.problemList.filter(problem => {
                    return problem.status;
                });
            }
        };

        vm.gotoProblem = (idProblem = null) => {
            let urlproblem = '';
            if (idProblem) {
                urlproblem = '/support/problemview/' + idProblem;
            } else {
                urlproblem = '/support/problemview/new';
            }
            $location.url(urlproblem);
        };
        vm.gotoTicket = (idTicket) => {
            $location.url('/support/ticketview/' + idTicket);

        };
        vm.getProblems = () => {
            ep.problems.getList().then(response => {
                vm.problemList = _.map(response, v => {
                    return v;
                });
                vm.filterProblems();
            });

        };


        vm.init = () => {
            vm.getProblems();
        };

        vm.massAssign = (problemTicket, action) => {
            if (action !== 'intervention') {
                delete problemTicket.message;
            }
            let ticketList = problemTicket.map(data => {
                    return data.id;
                }),
                postData = {
                    'type': action,
                    'ticketList': ticketList,
                    'message': problemTicket.message ? problemTicket.message.value : null
                };
            ep.massAssign.post(postData).then(() => {
                problemTicket.displayOk = true;
                delete problemTicket.message;
            }).catch(() => {
                Flash.show('error');
            });

        };
        vm.toggleWithClosed = () => {
            vm.withClosed = !vm.withClosed;
            vm.filterProblems();
        };

        vm.withClosed = false;
        vm.init();
    }]);
