'use strict';

angular.module('portailWebApp')
  .controller('MonitoringWooxobackupController', ['$window', '$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', '$translate', '$uibModal', 'Monitoring', '$routeParams', 'brand', ($window, $scope, $location, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, $translate, $uibModal, Monitoring, $routeParams, brand) => {
    //IS auth and admin
    const licences_endpoint = TokenRestAngularMS.all('monitoring/wooxobackup/licences'),
      jobs_endpoint = TokenRestAngularMS.all('monitoring/wooxobackup/jds'),
      relations_endpoint = TokenRestAngularMS.all('monitoring/relations'),
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations'),
      griddef = {
        licences: [
          {
            field: 'licence',
            type: 'number',
            displayName: 'licence',
            headerCellFilter: 'translate',
            filter: {
              term: '',
              placeholder: 'licence'
            },
            cellTemplate: `<yp-fill-search class="yp-grid__btn ui-grid-cell-contents" terms="{{row.entity.licence}}">{{COL_FIELD CUSTOM_FILTERS}}</yp-fill-search>`
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><i class="material-icons md-18">infos</i></a></div>'
          },
          {
            field: 'seller',
            displayName: 'reseller',
            headerCellFilter: 'translate',
            visible: false
          },
          {
            field: 'activation',
            type: 'date',
            displayName: 'setup_at',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'expiration',
            type: 'date',
            displayName: 'licence_expiration',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template,
            cellClass: function (grid, row, col) {
              return Monitoring.colorHelper.dateDif(grid.getCellValue(row, col), 30);
            }
          },
          {
            field: 'local_size',
            type: 'number',
            displayName: 'local_size',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents yp-cell_graph-icn" ng-click="grid.appScope.graph(\'local_size\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }} <i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'cloud_size_quota',
            type: 'string',
            displayName: 'cloud_size_quota',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS  | humanizeFilesize }}</div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'cloud_size_cloud',
            type: 'string',
            displayName: 'cloud_size_cloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents yp-cell_graph-icn" ng-click="grid.appScope.graph(\'cloud_size_cloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS  | humanizeFilesize }} <i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS }}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: function (grid, row, col) {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'remote_link',
            displayName: 'remote_link',
            headerCellFilter: 'translate',
            enableFiltering: true,
            cellTemplate: '<div class="ui-grid-cell-contents text-center" ng-click="grid.appScope.remote(row.entity)"><i class="material-icons md-18">build</i></div>'
          },
          {
            field: 'contracts',
            displayName: 'contracts',
            enableFiltering: true,
            headerCellFilter: 'translate'
          }
        ],
        jobs: [
          {
            field: 'licence',
            displayName: 'licence',
            headerCellFilter: 'translate'
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a class="yp-table__link" href ng-click="grid.appScope.organization_info(row.entity.organization_id)"><i class="material-icons md-18">infos</i></a></div>'
          },
          {
            field: 'name',
            displayName: 'name',
            headerCellFilter: 'translate'
          },
          {
            field: 'local_size',
            displayName: 'local_size',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph2(\'local_size\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }} <i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'local_size_zip',
            displayName: 'local_size_compression',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents yp-cell_graph-icn" ng-click="grid.appScope.graph2(\'local_size_zip\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }} <i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'history',
            displayName: 'history_label',
            headerCellFilter: 'translate',
            enableFiltering: true
          },
          {
            field: 'retention',
            displayName: 'retention_label',
            headerCellFilter: 'translate',
            enableFiltering: true
          },
          {
            field: 'cron',
            displayName: 'backup_recurence',
            headerCellFilter: 'translate',
            enableFiltering: true,
            cellTooltip: '{{\'next_backup_label\' | translate}} : {{row.entity.next_backup.date}}',
            cellTemplate: '<div class="ui-grid-cell-contents"><span title="{{\'next_backup_date\' | translate}} : {{row.entity.next_backup.date | humanDate}}">{{COL_FIELD}}</span></div>'
          },
          {
            field: 'cloud',
            displayName: 'cloud',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterBool,
              condition: customFilter.bool.filterStatus
            },
            cellTemplate: customFilter.i18n.cellTemplate,
            cellClass: function (grid, row, col) {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            enableFiltering: true,
            cellTemplate: '<div class="ui-grid-cell-contents yp-cell_graph-icn" ng-click="grid.appScope.graph2(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: function (grid, row, col) {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'last_backup',
            type: 'date',
            displayName: 'backup_last_date',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          }
        ]
      };

    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'monitoring ' + brand.trad('wb');
    $rootScope.helpLink = 'yooportal/monitoring/wooxobackup.html';
    $rootScope.initDropdown('.yp-table--dd, .yp-table__toolbar');
    //endpoint

    //filters functions
    $scope.relation_id = null;
    //init
    $scope.organizations = [];
    $scope.relations = [];
    $scope.licences = [];
    $scope.jobs = [];
    $scope.filters = [];
    $scope.filters2 = [];
    // Monitoring service

    Monitoring.refreshFilters('monitoring_wooxobackup', data => {
      $scope.filters = data;

      $timeout(() => {
        let type = $routeParams.filterType,
          value = $routeParams.filterValue || null;

        if (type) {
          switch (type) {
            case 'offline':
              $scope.filterColumnStatus('off');
              break;
            case 'capacity':
              $scope.filterColumnCapacity(79);
              break;
            case 'licence':
              $scope.filterColumnLicence(value);
              break;
            default:
          }
        }
      }, 1000);
    });
    $scope.loadIt = () => {
      Monitoring.loadFilters('monitoring_wooxobackup', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //If selection grid1
        let organizations = $scope.gridApi1.selection.getSelectedRows(),
          columns1 = $scope.gridApi1.saveState.save(),
          columns2 = $scope.gridApi2.saveState.save(),
          columns3 = $scope.gridApi3.saveState.save();
        if (organizations.length) {
          data.data.grid1.selection = false;
        }
        //if filters  grid1?

        angular.forEach(columns1.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid1.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid2?

        angular.forEach(columns2.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid2.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid3?

        angular.forEach(columns3.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid3.columns[i].filters[j].term = filter.term;
            }
          });
        });
        $scope.relation_id = data.data.relation_id;
        $scope.gridApi1.saveState.restore($scope, data.data.grid1);
        $scope.gridApi2.saveState.restore($scope, data.data.grid2);
        $scope.gridApi3.saveState.restore($scope, data.data.grid3);
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('monitoring_wooxobackup', {
        grid1: $scope.gridApi1.saveState.save(),
        grid2: $scope.gridApi2.saveState.save(),
        grid3: $scope.gridApi3.saveState.save(),
        relation_id: $scope.relation_id
      });
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };
    //Usefull
    $scope.graph = (type, licence) => {
      Monitoring.graph('wooxobackup/licences', type, licence.id, licence.licence);
    };
    $scope.graph2 = (type, licence) => {
      Monitoring.graph('wooxobackup/jds', type, licence.id, licence.name);
    };

    $scope.remote = licence => {
      let gendpoint = TokenRestAngularMS.all('monitoring/wooxobackup/licences/remote');
      gendpoint.withHttpConfig({
        responseType: 'blob'
      }).get(licence.id).then(data => {
        $window.saveAs(data, licence.licence + '.jnlp');
      });
    };
    //refresh functions
    $scope.refresh_licences = () => {
      let organizations = $scope.gridApi1.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (r) {
        licences_endpoint.getList(filters).then(data => {
          $scope.licences = [];
          angular.forEach(data, row => {

            row.local_size = parseInt(row.local_size, 10);
            row.local_number = parseInt(row.local_number, 10);
            row.cloud_size_quota = parseInt(row.cloud_size_quota || 0, 10);
            row.cloud_size_cloud = parseInt(row.cloud_size_cloud || 0, 10);
            row.status = Monitoring.convert_status(row.status);
            $scope.licences.push(row);
          });
          //calcul
          $scope.count = _.countBy(data, row => {
            return row.status;
          });
        });
      }
      return r;
    };
    $scope.refresh_jobs = () => {
      let organizations = $scope.gridApi1.selection.getSelectedRows(),
        licences = $scope.gridApi2.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (licences.length > 0 && licences.length <= 100) {
        filters.licence_id = '';
        angular.forEach(licences, (item, i) => {
          filters.licence_id = filters.licence_id + item.id;
          if (i !== licences.length) {
            filters.licence_id = filters.licence_id + ',';
          }
        });
      } else if (licences.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        return false;
      }
      $scope.jobs = [];
      if (r && filters.licence_id) {
        jobs_endpoint.getList(filters).then(data => {
          angular.forEach(data, row => {
            row.size_cloud = parseInt(row.size_cloud, 10);
            row.cloud = !!row.cloud;
            row.status = Monitoring.convert_status(row.status);
            $scope.jobs.push(row);
          });
          $scope.countJob = _.countBy(data, row => {

            return row.status;
          });
        });
      }
      return r;
    };
    $scope.refresh_relations = () => {
      $scope.relations = [];
      relations_endpoint.getList().then(data => {
        let vardefault = {
          id: null,
          name: '--'
        };
        $scope.relations = data;
        $scope.relations.unshift(vardefault);
        $scope.relation_id = vardefault.id;
        $scope.refresh_organizations();
      });
    };

    $scope.refresh_organizations = () => {

      let filters = {};
      $scope.organizations = [];
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      organizations_endpoint.getList(filters).then(data => {
        $scope.organizations = data;
        $scope.refresh_licences();
      });
    };
    //Init
    $scope.refresh_relations();

    $scope.gridOptions1 = Monitoring.setGridOption('organizations');
    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_licences();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_licences();
      });
      $scope.gridApi1 = gridApi;
    };

    $scope.gridOptions2 = Monitoring.setGridOption('licences', griddef.licences, true, true);
    $scope.gridOptions2.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_jobs();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_jobs();
      });
      $scope.gridApi2 = gridApi;
    };

    $scope.gridOptions3 = Monitoring.setGridOption('jobs', griddef.jobs, false, true);
    $scope.gridOptions3.onRegisterApi = gridApi => {
      $scope.gridApi3 = gridApi;
    };

    //Grid operations
    $scope.selectAll = () => {
      $scope.gridApi1.selection.selectAllVisibleRows();
    };

    $scope.clearAll = () => {
      $scope.gridApi1.selection.clearSelectedRows();
    };

    $scope.removeOrganisationFilter = row => {
      $scope.gridApi1.selection.unSelectRow(row);
    };

    $scope.removeLicenceFilter = row => {
      $scope.gridApi2.selection.unSelectRow(row);
    };
    $scope.filterColumnStatus = val => {
      let col = _.find($scope.gridApi2.grid.columns, {
        field: 'status'
      });
      if (col) {
        col.filters[0].term = val;
      }
      $scope.gridApi2.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnStatus2 = val => {
      let col = _.find($scope.gridApi3.grid.columns, {
        field: 'status'
      });
      col.filters[0].term = val;
      $scope.gridApi3.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };

    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'licence'
      });
      if (col) {
        col.filter.term = val;
        $scope.gridApi2.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };


  }]);
