'use strict';

angular.module('portailWebApp')
    .controller('SlaLevelAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        let endpoint = TokenRestAngular.all('admin/slalevel');

        //Flash
        Flash.clear();
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'sla_level';
        $rootScope.helpLink = '';
        //Endpoint


        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(data => {
                $scope.gridData = data;
            });
        };
        $scope.refresh();

        $scope.update = element => {
            $rootScope.xhr = true;
            element.save();
            $timeout(() => {
                $rootScope.xhr = false;
            }, 1000);
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.record = () => {
            let data = {
                'name': $scope.name,
                'git': $scope.git,
                'grt': $scope.grt
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.name = null;
                $scope.git = null;
                $scope.grt = null;
                $scope.creation = false;
            });
        };

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
            {field: 'git', displayName: 'git', headerCellFilter: 'translate'},
            {field: 'grt', displayName: 'grt', headerCellFilter: 'translate'}
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
            $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity => {
                $scope.update(rowEntity);
            });
        };

    }]);
