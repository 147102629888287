'use strict';

angular.module('portailWebApp')
    .controller('AclAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'apiRest', ($scope, $location, $rootScope, Flash, apiRest) => {
        //IS auth and admin
        let epAclAdm = apiRest('admin/acl', true);
        //Layout
        $rootScope.pageClass = '';
        //Flash
        Flash.clear();
        //Title
        $rootScope.subTitle = 'acl_admin';
        $rootScope.helpLink = '';

        //Get the data
        $scope.refresh = () => {
            epAclAdm.getList().then(res => {
                $scope.data = res;
                $scope.head = $scope.data[0].resources[0].relation_types;
            });
        };


        $scope.change = (rt, res, right) => {
            let data = {
                'relation_type_id': rt.id,
                'resource_id': res.id,
                'right_id': right.id,
                'value': rt.value
            };
            Flash.clear();
            epAclAdm.post(data).then(resp => {
                Flash.show(resp.success, 'success');
            });
        };

        $scope.refresh();
    }]);
