'use strict';

angular.module('portailWebApp')
    .controller('ActionTypesAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'apiRest', 'Monitoring', '$timeout', ($scope, $location, $rootScope, Flash, apiRest, Monitoring, $timeout) => {
        let endpoint = apiRest('admin/actiontypes', true),
            endpp = apiRest('admin/actiontypetranslations', true);
        //Layout
        $rootScope.pageClass = '';
        //Flash
        Flash.clear();
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'actiontypes_admin';
        $rootScope.helpLink = '';
        $scope.translation = false;

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                angular.forEach(res, item => {
                    item.visible = !!+item.visible;
                });
                $scope.gridData = res;
            });
        };
        $scope.refresh();

        $scope.actions = [
            {
                'id': 'instant',
                'label': 'Instant'
            },
            {
                'id': 'scheduled',
                'label': 'Scheduled'
            },
            {
                'id': 'once',
                'label': 'Once alert'
            }
        ];

        $scope.formCreate = () => {
            $scope.creation = true;
        };

        $scope.updatetrans = () => {

            if($scope.translation.length) {
                $rootScope.xhr = true;
                angular.forEach($scope.translation, item => {
                    angular.forEach(item.translations, trad => {
                        Flash.clear();
                        $rootScope.xhr = true;
                        endpp.post(trad).then(res => {
                            Flash.show(res.success, 'success');
                            $rootScope.xhr = false;
                        });
                    });
                    $scope.translation = false;
                });
            }
        };

        $scope.translate = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            if (cols.length) {
                $rootScope.xhr = true;
                $scope.translation = [];
                angular.forEach(cols, item => {
                    let endp = apiRest('admin/actiontypetranslations/' + item.id);
                    $rootScope.xhr = true;
                    endp.getList().then(res => {
                        $scope.translation.push({
                            'to_translate': item.label,
                            'translations': res
                        });
                        $rootScope.xhr = false;
                    });
                });

            }
        };

        $scope.current = [];
        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
            {field: 'action', displayName: 'action', headerCellFilter: 'translate'},
            {field: 'visible', displayName: 'visible', headerCellFilter: 'translate'}
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.record = () => {
            let data = {
                'name': $scope.name,
                'action': $scope.action,
                'visible': $scope.visible
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.name = null;
                $scope.action = null;
                $scope.visible = false;
                $scope.creation = false;
            });
        };
        $scope.visible = false;
    }]);
