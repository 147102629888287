/**
 * Created by acorre on 28/06/2016.
 */
((function () {
  let ypMainNavConfig = {
    templateUrl: '../scripts/components/ypMainNav/yp-mainNav.tpl.html',
    controller: ypMainNav,
    bindings: {
      lang: '<'
    }
  };

  angular.module('portailWebApp')
    .component('ypMainNav', ypMainNavConfig);

  ypMainNav.$inject = ['$scope', '$element', '$attrs', '$location', 'RoutesManager'];

  function ypMainNav($scope, $element, $attrs, $location, RoutesManager) {
    const ctrl = this;
    // method
    ///////////////////////////////////////////////////
    ctrl.getClass = getClass;
    ctrl.closeGroup = closeGroup;
    ctrl.toggleMenu = toggleMenu;
    ctrl.build = build;
    ctrl.$onInit = ctrl.build;
    ctrl.$onChanges = ctrl.build;


    function build() {
      let menuItems = RoutesManager.getMenu();
      ctrl.menu = buildMenu(menuItems, RoutesManager.getMenuGroups());
      ctrl.rootMenuItems = menuItems.filter(i => !i.group);
    }

    function toggleMenu(group) {
      const toOpen = group.closed;
      ctrl.closeGroup();
      group.closed = toOpen ? false : true;
    }

    function closeGroup() {
      ctrl.menu
        .filter(i => !i.closed)
        .forEach(i => {
          i.closed = true;
        });
    }

    function getClass(path) {
      return $location.path().substr(0, path.length) === path;
    }

    function buildMenu(menuItems, group) {

      return group.map(g => {
        g.items = menuItems.filter(i => i.group === g.type);
        g.closed = true;
        return g;
      }).filter(i => i.items.length);
    }
  }

})());
