((function () {
    angular.module('portailWebApp')
        .directive('pinIt', pinItDirective);

    pinItDirective.$inject = ['Pin', '$rootScope'];

    function pinItDirective(Pin, $rootScope) {
        return {
            restrict: 'E',
            scope: {
                name: '@',
                data: '='
            },
            template: '<a class="yp-pinBtn" ng-click="pinIt()" href><i class="material-icons md-18">bookmark_outline</i></a>',
            link: (scope, element) => {
                scope.pinIt = () => {
                    Pin.pinIt(scope.data, scope.name);
                };
                if (!$rootScope.user.pinpanel) {
                    element.hide();
                }
            }
        };
    }
})());
