/**
 * Created by acorre on 07/06/2016.
 */
((function () {

  angular.module('portailWebApp')
    .factory('QuickAccess', QuickAccess);

  QuickAccess.$inject = ['apiRest', '$location', '$filter', '$translate', 'brand', 'Acl', 'RoutesManager'];

  function QuickAccess(apiRest, $location, $filter, $translate, brand, Acl, RoutesManager) {
    let basicAction = [],
      list = [];

    return {
      fetch: fetch,
      list: getActions,
      trigger: trigger
    };

    ////////////////////////////////////////////////////

    function Action(o, t) {
      return {
        id: o.id,
        data: {
          licence: o.licence,
          organization: o.organization,
          filter: o.filterType || 'licence'
        },
        trigger: t
      };
    }

    function assignAction(response) {
      list = response[0];
      return response;
    }

    function assignTickets(response) {
      response[1].forEach(ticket => {
        let item = list.find(x => x.data.organization === ticket.organization.name),
          update = $filter('utc2local')(ticket.updated_at),
          category = ticket.category ? ticket.category.value : null,
          more = [
            {field: 'category', value: category},
            {field: 'updated_at', value: $filter('fromNow')(update)}
          ];
        item.services.push({
          title: 'open_ticket',
          cb: 'openTicket',
          ticketId: ticket.id,
          more: more.map(i => {
            i.field = $translate.instant(i.field);
            i.value = $translate.instant(i.value);
            return i;
          })
        });
      });
      return response;
    }

    function fetch() {

      let data = Promise.all([
        apiRest('helper/quickAccess', true)
          .getList(),
        apiRest('supportv2/tickets', true)
          .getList({status: 1})
      ]);

      data.then(r => r.map(c => c.plain()))
        .then(assignAction)
        .then(assignTickets);

      if (Acl.isAction('ticket_create')) {
        basicAction.push({title: 'ticket_create', cb: 'createTicket'});
      }

      if (Acl.isAction('ticket_list')) {
        basicAction.push({title: 'ticket_list', cb: 'viewTicketsList'});
      }
      if (Acl.isView('monitoring_synthetic')) {
        basicAction.push({title: 'monitoring_synthetic', cb: 'synthetic'});
      }
      if (Acl.isView('view_remote')) {
        basicAction.push({title: 'remote_access', cb: 'remote'});
      }
    }

    function getActions() {
      let
        tempList = angular.copy(list),
        actionList = [];

      tempList.forEach(i => {
        basicAction.forEach(v => {
          i.services.push(v);
        });
        i.services.forEach(s => {
          s.title = $translate.instant(s.title);
          if (s.product) {
            s.product = brand.trad(s.product);
          } else {
            s.product = null;
          }
          actionList.push(new Action(i.data, s));
        });
      });
      RoutesManager.getMenu().forEach(i => {
        i.data.menu = $translate.instant(i.data.menu);

        /**
                 * if there isnt any acl or if the user is authorized to see it
                 */
        if (!i.acl || Acl.isView(i.acl)) {
          actionList.unshift(i);
        }
      });
      return actionList;
    }

    function trigger(item) {
      let path = null,
        filter = null;

      if (item.trigger) {
        path = RoutesManager.getRoutes()[item.trigger.cb];
        filter = !item.trigger.ticketId ? item.data.filter + '/' + item.data[item.data.filter] : '/' + item.trigger.ticketId;
      }

      if (path && filter) {
        $location.url(path + filter);
      }

      if (item.path) {
        $location.url(item.path);
      }
    }
  }
})());

