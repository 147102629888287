/**
 * Created by acorre on 04/07/2016.
 */
angular.module('portailWebApp')
    .controller('DeliveryStatisticsController', ['$window', '$rootScope', '$scope', 'apiRest', '$translate', 'moment', 'brand', function ($window, $rootScope, $scope, apiRest, $translate, moment, brand) {

        const vm = this,
            auth = true,
            routes = {
                statsDelivery: 'reporting/delivery/statistics'
            },
            ep = {
                statsDelivery: apiRest(routes.statsDelivery, auth)
            },
            now = moment(),
            dateRange = {
                from: angular.copy(now).subtract(1, 'month').toDate(),
                to: now.toDate()
            },
            dateFilter = i => {
                let res = true,
                    range = vm.dateRange || {from: moment.invalid(), to: moment.invalid()},
                    from = moment(range.from),
                    to = moment(range.to),
                    test = moment(i.created);

                if (from.isValid() && to.isValid()) {
                    res = test.isBetween(from, to);
                } else if (from.isValid()) {
                    res = test.isAfter(from);
                } else if (to.isValid()) {
                    res = test.isBefore(to);
                }
                return res;
            },
            isInstalled = i => i.setup,
            isWaiting = i => !i.hasOwnProperty('validated'),
            isShipped = i => i.shipped,
            isValidated = i => i.validated,
            applyFilters = reports => {
                let list = {};
                list.reportFiltered = reports.filter(dateFilter).map(x => {
                    x.order_waiting = 0;
                    x.order_validated = 0;
                    x.valid_shipped = 0;
                    x.shipped_installed = 0;
                    x.order_last = 0;
                    return x;
                });

                list.waitingOrderList = list.reportFiltered
                    .filter(isWaiting)
                    .map(i => {
                        i.order_waiting = moment()
                            .diff(moment(i.created), 'days');
                        i.order_last = i.order_waiting;
                        return i;
                    });

                list.validatedOrderList = list.reportFiltered
                    .filter(isValidated)
                    .map(i => {
                        i.order_validated = moment(i.validated)
                            .diff(moment(i.created), 'days');
                        i.order_last = i.order_validated;
                        return i;
                    });
                list.shippedOrderList = list.validatedOrderList
                    .filter(isShipped)
                    .map(i => {
                        i.valid_shipped = moment(i.shipped)
                            .diff(moment(i.validated), 'days');
                        i.order_last = moment(i.shipped)
                            .diff(moment(i.created), 'days');
                        return i;
                    });

                list.installedOrderList = list.shippedOrderList
                    .filter(isInstalled)
                    .map(i => {
                        i.shipped_installed = moment(i.setup)
                            .diff(moment(i.shipped), 'days');

                        i.order_last = moment(i.setup)
                            .diff(moment(i.created), 'days');
                        return i;
                    });

                return list;
            },
            getStats = (list) => {
                const avgWaitingDelay = list.waitingOrderList
                        .map(i => i.order_waiting)
                        .reduce((pi, ci) => pi + ci, 0) / list.waitingOrderList.length,

                    avgValidatedDelay = list.validatedOrderList
                            .map(i => i.order_validated)
                            .reduce((pi, ci) => pi + ci, 0) / list.validatedOrderList.length,

                    avgShippedDelay = list.shippedOrderList
                            .map(i => i.valid_shipped)
                            .reduce((pi, ci) => pi + ci, 0) / list.shippedOrderList.length,

                    avgSetupDelay = list.installedOrderList
                            .map(i => i.shipped_installed)
                            .reduce((pi, ci) => pi + ci, 0) / list.installedOrderList.length,

                    avgGlobalDelay = list.installedOrderList
                            .map(i => i.order_last)
                            .reduce((pi, ci) => pi + ci, 0) / list.installedOrderList.length;

                return {
                    total: list.reportFiltered.length || 0,
                    totalInstalled: list.installedOrderList.length || 0,
                    totalValidated: list.validatedOrderList.length || 0,
                    totalShipped: list.shippedOrderList.length || 0,
                    totalWaiting: list.waitingOrderList.length || 0,
                    range: [].concat(
                        list.installedOrderList,
                        list.shippedOrderList,
                        list.validatedOrderList,
                        list.waitingOrderList)
                        .map(x => x.order_last)
                        .sort((a, b) => b - a)[0] || 0,
                    avgWaitingDelay: moment.duration(avgWaitingDelay, 'days').days(),
                    avgValidatedDelay: moment.duration(avgValidatedDelay, 'days').days(),
                    avgShippedDelay: moment.duration(avgShippedDelay, 'days').days(),
                    avgSetupDelay: moment.duration(avgSetupDelay, 'days').days(),
                    avgGlobalDelay: moment.duration(avgGlobalDelay, 'days').days()
                };
            },
            getChart = (reportFiltered, stats) => {
                let charts = {
                    averageDelay: [
                        {
                            key: 'average_delay',
                            values: [
                                [$translate.instant('waiting_label'), stats.avgWaitingDelay],
                                [$translate.instant('validated_label'), stats.avgValidatedDelay],
                                [$translate.instant('shipped_label'), stats.avgShippedDelay],
                                [$translate.instant('installed_label'), stats.avgSetupDelay]
                            ]
                        }
                    ],
                    orders: [
                        {
                            key: 'waitingDelay',
                            values: reportFiltered
                                .map((x, i) => [i, x.order_waiting])
                        },
                        {
                            key: 'validationDelay',
                            values: reportFiltered
                                .map((x, i) => [i, x.order_validated])
                        },
                        {
                            key: 'shippedDelay',
                            values: reportFiltered
                                .map((x, i) => [i, x.valid_shipped])
                        },
                        {
                            key: 'setupDelay',
                            values: reportFiltered
                                .map((x, i) => [i, x.shipped_installed])
                        }
                    ],
                    repartition: [
                        {
                            key: $translate.instant('waiting_label'),
                            y: stats.total - stats.totalValidated
                        },
                        {
                            key: $translate.instant('validated_label'),
                            y: stats.totalValidated - stats.totalShipped
                        },
                        {
                            key: $translate.instant('shipped_label'),
                            y: stats.totalShipped - stats.totalInstalled
                        },
                        {
                            key: $translate.instant('installed_label'),
                            y: stats.totalInstalled
                        }
                    ],
                    xStack: () => d => d ? d[0] : 0,
                    xFn: () => d => d[0],
                    yFn: () => d => d[1],
                    xPie: () => d => d.key,
                    yPie: () => d => d.y,
                    range: [vm.stats.range],
                    xFnMonth: () => d => {
                        return reportFiltered[d] ? moment(reportFiltered[d].created).format('DD MMMM Y') : '';
                    }
                };

                return charts;
            },
            performStats = reports => {
                const mappedLists = applyFilters(reports);
                vm.reports = reports;
                vm.stats = getStats(mappedLists);
                vm.chart = getChart(mappedLists.reportFiltered, vm.stats);
            };

        vm.getStats = getStats;
        vm.getChart = getChart;
        vm.applyFilters = applyFilters;
        vm.now = now;
        vm.colorPick = () => (d, i) => brand.getGraphColors()[i];
        vm.dateFilter = dateFilter;
        vm.dateRange = dateRange;
        vm.avgTime = $translate.instant('avg_time_days');
        $rootScope.subTitle = $translate.instant('delivery_statistics');
        $rootScope.helpLink = '';

        vm.datePickersConfig = {
            minView: 'day'
        };
        vm.datePickers = $rootScope.initDropdown('.yp-dateMod__wrapper', {
            closeOut: true,
            multi: false
        });
        vm.updateDate = () => {
            vm.datePickers.forEach(v => {
                v.closeAll();
            });
            performStats(vm.reports);
        };
        vm.refresh = () => {
            let requestFilter = {},
                from,
                to;
            if (vm.dateRange) {
                from = moment(vm.dateRange.from);
                to = moment(vm.dateRange.to);
                if (from.isValid()) {
                    requestFilter.begin_date = from.format('YYYY-MM-DD');
                }
                if (to.isValid()) {
                    requestFilter.end_date = to.format('YYYY-MM-DD');
                }
                vm.loadedRange = {
                    from: from.calendar(),
                    to: to.calendar()
                };
            }
            ep.statsDelivery.getList(requestFilter)
                .then(performStats)
                .catch();
        };
        vm.refresh();

    }])
;
