'use strict';

angular.module('portailWebApp')
  .controller('WhitelabelsAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', 'TokenRestAngularMS', '$timeout', '$sce', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, TokenRestAngularMS, $timeout, $sce, Monitoring) => {
    let endpoint = TokenRestAngular.all('admin/whitelabels');
    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title

    $rootScope.initDropdown('.yp-toolbar');
    $rootScope.subTitle = 'whitelabels_admin';
    $rootScope.helpLink = '';
    //Get the data
    $scope.gridData = [];

    $scope.refresh = () => {
      endpoint.getList().then(res => {
        $scope.gridData = res;
      });
    };
    $scope.refresh();

    $scope.current = [];

    $scope.gridOptions = Monitoring.setGridOption('gridData', [
      {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
      {field: 'short', displayName: 'short', headerCellFilter: 'translate'},
      {field: 'email_from', displayName: 'email_from', headerCellFilter: 'translate'},
      {field: 'reply_to', displayName: 'reply_to', headerCellFilter: 'translate'},
      {field: 'header_mail', displayName: 'header_mail', headerCellFilter: 'translate'},
      {field: 'footer_mail', displayName: 'footer_mail', headerCellFilter: 'translate'}
    ], true, false, 'high');
    $scope.gridOptions.onRegisterApi = gridApi => {
      $scope.gridApi = gridApi;
      $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity => {
        $scope.update(rowEntity);
      });
    };

    $scope.update = element => {
      $rootScope.xhr = true;
      element.save();
      $timeout(() => {
        $rootScope.xhr = false;
      }, 1000);
    };

    $scope.record = () => {
      let data = {
        'name': $scope.name,
        'short': $scope.short,
        'email_from': $scope.email_from,
        'reply_to': $scope.reply_to,
        'header_mail': $scope.header_mail,
        'footer_mail': $scope.footer_mail
      };
      endpoint.post(data).then(res => {
        Flash.show(res.success, 'success');
        $scope.refresh();
        $scope.name = null;
        $scope.short = null;
      });
    };

    $scope.delete = () => {
      $rootScope.xhr = true;
      let cols = $scope.gridApi.selection.getSelectedRows();
      angular.forEach(cols, item => {
        item.remove().then(data => {
          Flash.show(data.success, 'success');
        });
      });
      $timeout(() => {
        $scope.refresh();
        $rootScope.xhr = false;
      }, 1500);
    };
  }]);
