angular.module('portailWebApp')
    .factory('Person', PersonFactory);

PersonFactory.$inject = ['apiRest'];

function PersonFactory(apiRest) {
    return (infos) => {
        if (infos.route) {
            let person = apiRest(0, 1).restangularizeElement(0, new Person(infos), infos.route);
            person.fromServer = infos.fromServer || false;
            return person;
        }
        return new Person(infos);
    };

    function Person(o) {
        let p = this;
        p.id = o.id || null;
        p.contact = !(o.hasOwnProperty('contact') && (o.contact === 0 || o.contact === false || o.contact === '0'));

        p.name = o.name || null;
        //$log.warn(o, 'A name must be specified');
        p.email = o.email || null;
        //$log.warn(o, 'An email must be specified');
        p.organization = o.organization || null;
        p.support = o.support || null;
        p.phone = o.phone || null;
        p.gsm = o.gsm || null;
        p.fax = o.fax || null;
        p.active = o.active || true;
        p.fromServer = o.fromServer || false;
        p.route = o.route || null;

        p.isSame = isSamePerson;

        p.clear = clearPerson;

        p.reset = resetPerson;

        function isSamePerson(oP) {
            return (p.name === oP.name && p.email === oP.email && p.phone === oP.phone && p.gsm === oP.gsm && p.fax === oP.fax && p.organization === oP.organization);
        }

        function clearPerson() {
            p.reset({});
        }

        function resetPerson(data) {
            p.name = data.name || null;
            p.email = data.email || null;
            p.organization = data.organization || null;
            p.support = data.support || null;
            p.phone = data.phone || null;
            p.gsm = data.gsm || null;
            p.fax = data.fax || null;
        }
    }

}
