angular.module('portailWebApp')
    .directive('ypBtnCheck', [function () {

        function link(scope, element) {
            element.addClass('yp-btn');
            element.bind('click', () => {
                scope.data = !scope.data;
            });
        }

        return {
            restrict: 'A',
            scope: {
                'data': '&'
            },
            templateUrl: '../scripts/directives/form/checkBtn.tpl.html',
            link: link
        };
    }]);
