((function () {
  angular.module('portailWebApp')
    .factory('customFilter', customFilterService);

  customFilterService.$inject = ['moment'];

  function customFilterService(moment) {
    var f = 'YYYY-MM-DD HH:mm',
      uGO = 1073741824;
    return {
      date: {
        isBefore: dateIsBefore,
        isAfter: dateIsAfter,
        template: dateGetTemplate()
      },
      octet: {
        isGreaterGiga: octetIsGreaterGO,
        isLowerGiga: octetIsLowerGO,
        template: octetGetTemplate()
      },
      number: {
        templateMore: numberGetTemplate()
      },
      bool: {
        template: boolGetTemplate(),
        filterStatus: statusIsInclude,
        filterBool: [
          { value: '', name: 'all' },
          { value: 'false', name: 'false' },
          { value: 'true', name: 'true' }
        ],
        filterOption: [
          { value: '', name: 'all' },
          { value: 'off', name: 'off' },
          { value: 'issue', name: 'issue' },
          { value: 'on', name: 'on' },
          { value: 'running', name: 'running' },
          { value: 'undefined', name: 'undefined' }
        ],
        filterBoxesOptions: [
          { value: '', name: 'all' },
          { value: 'off', name: 'off' },
          { value: 'issue', name: 'issue' },
          { value: 'on', name: 'on' },
          { value: 'undefined', name: 'undefined' }
        ]
      },
      i18n: {
        cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD | translate}}</div>'
      }
    };

    /////////////////////////////////////////////////////////////

    function dateIsAfter(term, value) {
      let dterm = moment(term, f),
        dval = moment(value, f);
      return (dterm.isValid() && dval.isAfter(dterm, 'minute'));
    }

    function dateIsBefore(term, value) {
      let dterm = moment(term, f),
        dval = moment(value, f);
      return (dterm.isValid() && dval.isBefore(dterm, 'minute'));
    }

    function dateGetTemplate() {
      return '<div class="ui-grid-filter-container"><input class="ui-grid-filter-input ui-grid-filter-input-0" ng-model="col.filters[0].term" placeholder="{{\'before\' | translate}}" type="text"/></div><div class="ui-grid-filter-container"><input class="ui-grid-filter-input ui-grid-filter-input-0" ng-model="col.filters[1].term" placeholder="{{\'after\' | translate}}" type="text"/></div>';
    }

    function statusIsInclude(term, value) {
      if (value === null) {
        return false;
      }
      
      if (term.includes('') || term.length === 0) {
        return true;
      }

      return term.includes(value.toString());
    }

    function boolGetTemplate() {
      return '<div class="ui-grid-filter-container yp-table__customfilter--select">' + 
        '<md-select name="filter[]" aria-label="multiple-filter" ng-model="col.filter.term" multiple>' + 
          '<md-option ng-repeat="option in col.filter.options" value={{option.value}}>{{ option.name | translate }}</md-option>' + 
        '</md-select>' + 
      '</div>';
    }

    function octetIsGreaterGO(t, v) {
      t = t * uGO;
      return (v > t);
    }

    function octetIsLowerGO(t, v) {
      t = t * uGO;
      return (v < t);
    }

    function numberGetTemplate() {
      return '<div class="ui-grid-filter-container"><input class="ui-grid-filter-input ui-grid-filter-input-0" ng-model="col.filters[0].term" placeholder="{{\'more\' | translate}}" type="text"/></div><div class="ui-grid-filter-container"><input class="ui-grid-filter-input ui-grid-filter-input-0" ng-model="col.filters[1].term" placeholder="{{\'less\' | translate}}" type="text"/></div>';
    }

    function octetGetTemplate() {
      return '<div class="ui-grid-filter-container"><input class="ui-grid-filter-input ui-grid-filter-input-0" ng-model="col.filters[0].term" placeholder="{{\'more\' | translate}} (go)" type="text"/></div><div class="ui-grid-filter-container"><input class="ui-grid-filter-input ui-grid-filter-input-0"ng-model="col.filters[1].term" placeholder="{{\'less\' | translate}} (go)" type="text"/></div>';
    }
  }
})());
