/**
 * Created by acorre on 07/06/2016.
 */
((function () {
    let ypSearchControllerConfig = {
        templateUrl: '../scripts/components/ypSearch/yp-search.tpl.html',
        controller: ypSearchController,
        bindings: {
            service: '&'
        }
    };

    angular.module('portailWebApp')
        .component('ypSearch', ypSearchControllerConfig);

    ypSearchController.$inject = ['$scope', '$element', '$attrs', '$filter', '$timeout', '$log'];

    function ypSearchController($scope, $element, $attrs, $filter, $timeout, $log) {
        this.trigger = null;
        this.selected = null;
        this.limit = 0;
        this.selected = null;
        this.watcher = null;
        const ctrl = this,

        // indexed dom (jquery) elements
        ////////////////////////////////////////////////////////
            delayedTiming = 300,
            combo = $element.find('[role=combobox]'),
            listBtn = $element.find('[name=actionsSearch] + button'),
            actionsField = $element.find('[name=actionsSearch]'),
            actionsList = $element.find('[role=listbox][aria-hidden]');

        // Assignations
        ///////////////////////////////////////////////////////////

        listBtn.on('click', toggleList);
        actionsField.on('focus', activate);
        $scope.$on('perform:search', catchCustomEvent);
        ctrl.$onInit = initComp;


        //  methods
        ////////////////////////////////////////////////////////////
        function activeItem(event) {
            if (event.target.hasAttribute('data-index')) {
                let index = parseInt(event.target.getAttribute('data-index'), 10),
                    item = ctrl.filteredList[index];
                if (item) {
                    ctrl.trigger(item);
                }
            }
        }

        function filterList() {
            const terms = ctrl.actionsSearch ? ctrl.actionsSearch.split(', ') : [];
            ctrl.filteredList = ctrl.backupList;
            $log.log('terms' , terms);
            terms.forEach(f => {
                ctrl.filteredList = $filter('filter')(
                    ctrl.filteredList,
                    {$: f},
                    false
                );

                ctrl.filteredList = $filter('limitTo')(ctrl.filteredList, 30);
            });

            ctrl.filteredList = $filter('limitTo')(
                ctrl.filteredList, 30
            );

            $scope.$apply();
        }

        function displayList() {
            filterList();
            combo.attr('aria-expanded', true);
            actionsList.attr('aria-hidden', false);
        }

        function hideList() {
            combo.attr('aria-expanded', false);
            actionsList.attr('aria-hidden', true);
            if (ctrl.filteredList) {
                let selected = ctrl.filteredList.find(i => i.selected);
                if (selected) {
                    delete selected.selected;
                    ctrl.selected = null;
                    $scope.$apply();
                }
            }
        }

        function toggleList() {
            const cb = (combo.attr('aria-expanded') === 'true') ?
                hideList : displayList;
            cb();
            actionsField.focus();
        }

        function assignSelect(up) {
            let list = ctrl.filteredList,
                index = list.findIndex(i => i.selected),
                last = list.length - 1,
                first = 0;

            if (index < first || index > last) {
                index = up ? last : first;
            } else {
                delete list[index].selected;
                index = up ? (index - 1) : index + 1;
            }
            if (list[index]) {
                list[index].selected = true;
                ctrl.selected = list[index];
            } else {
                ctrl.selected = null;
            }
            $scope.$apply();
        }

        function isDisplayKey(key) {
            return [
                {name: 'down arrow', value: 40},
                {name: 'up arrow', value: 38}
            ].find(k => k.value === key);
        }

        function isOpen() {
            return actionsList.attr('aria-hidden') === 'false';
        }

        function handleInputs(event) {
            const hideKeys = [
                {name: 'escape', value: 27}
            ];

            // if the list is already displayed, that mean
            // the arrow key are used to navigate the list
            if (isOpen() && isDisplayKey(event.keyCode)) {
                assignSelect(event.keyCode === 38);
            }

            // if the list is closed and there is a value in
            // the searchField or an arrowKey have been used
            if (!isOpen()
                && (ctrl.actionsSearch.length
                || isDisplayKey(event.keyCode))) {
                displayList();
            }

            // if we trigger a close event
            if (hideKeys.find(k => k.value === event.keyCode)) {
                hideList();
            }

            if (isOpen() && ctrl.selected && event.keyCode === 13) {

                $scope.$apply(ctrl.service.trigger(ctrl.selected));
                ctrl.actionsSearch = '';
                hideList();
            }
        }

        function deactivate() {
            $timeout(() => {
                actionsField.off('blur', deactivate);
                combo.removeClass('isActive');
                $element
                    .off('click', activeItem)
                    .off('keydown', handleInputs)
                    .off('keyup', filterList);

                hideList();
            }, delayedTiming);
        }

        function activate() {
            ctrl.backupList = ctrl.service.list();
            combo.addClass('isActive');

            $element
                .click(activeItem)
                .on('keydown', handleInputs)
                .on('keyup', filterList);
            actionsField.on('blur', deactivate);
        }

        function catchCustomEvent(event, terms) {
            ctrl.actionsSearch = terms;
            $timeout(() => {
                actionsField.focus();
                filterList();
                displayList();
            }, delayedTiming + 50);
        }

        function initComp() {
            ctrl.actionsSearch = '';
            ctrl.service = ctrl.service();
            ctrl.activeItem = activeItem;
            ctrl.trigger = ctrl.service.trigger;
            ctrl.trad = {
                placeholder: ctrl.service.placeholder
            };
        }

    }

})());
