'use strict';

angular.module('portailWebApp')
    .controller('toolsDeliveryController', ['$scope', '$location', '$rootScope', 'Flash', 'apiRest', '$timeout', '_', 'uiGridConstants', 'customFilter', '$translate', '$uibModal', 'Monitoring', '$routeParams', ($scope, $location, $rootScope, Flash, apiRest, $timeout, _, uiGridConstants, customFilter, $translate, $uibModal, Monitoring, $routeParams) => {
        let delivery_endpoint = apiRest('tools/orders', true);

        //Title
        $rootScope.subTitle = 'tools_delivery';
        $rootScope.helpLink = 'yooportal/tools/delivery.html';
        //endpoint

        //filters functions
        //init

        $scope.delivery = [];
        $scope.filters = [];
        // Monitoring service
        $scope.refresh_filters = () => {
            Monitoring.refreshFilters('view_deliveries', data => {
                $scope.filters = data;
                if ($scope.filters.length > 0) {
                    $scope.loadFilter($scope.filters[0].id);
                }
            });

            $timeout(() => {
                let type = $routeParams.filterType,
                    value = $routeParams.filterValue || null;

                if (type) {
                    switch (type) {
                        case 'licence':
                            $scope.filterColumnLicence(value);
                            break;
                        default:
                    }
                }
            }, 1000);
        };

        $scope.loadIt = () => {
            Monitoring.loadFilters('view_deliveries', id => {
                $scope.loadFilter(id);
            });
        };

        $scope.loadFilter = (id) => {
            Monitoring.loadFilter(id, data => {
                //if filters  grid?
                let columns = $scope.gridApi.saveState.save();
                angular.forEach(columns.columns, (item, i) => {
                    angular.forEach(item.filters, (filter, j) => {
                        if (filter.term) {
                            data.data.grid.columns[i].filters[j].term = filter.term;
                        }
                    });
                });
                $scope.gridApi.saveState.restore($scope, data.data.grid);
            });
        };

        $scope.saveIt = () => {
            Monitoring.saveFilter('view_deliveries', {
                grid: $scope.gridApi.saveState.save(),
                relation_id: $scope.relation_id
            });
        };

        //refresh functions
        $scope.refresh_delivery = () => {
            let filters = {};
            delivery_endpoint.getList(filters).then(data => {
                angular.forEach(data, row => {
                    switch (row.shipped_state) {
                        case 0:
                            row.shipped_state = 'error';
                            row.delivered_date = null;
                            break;
                        case 1:
                            row.shipped_state = 'local_shipping';
                            row.delivered_date = null;
                            break;
                        case 2:
                            row.shipped_state = 'check';
                            break;
                        default:
                            row.shipped_state = null;
                            row.delivered_date = null;
                            break;
                    }
                });
                $scope.delivery = data;
            });
        };


        //Init
        $scope.refresh_delivery();

        //Grids
        $scope.gridOptions = Monitoring.setGridOption('delivery', [
            {
                field: 'id',
                type: 'number',
                displayName: 'order_id',
                headerCellFilter: 'translate',
                filtering: true
            },
            {
                field: 'ref',
                type: 'number',
                displayName: 'ref',
                headerCellFilter: 'translate',
                filtering: true
            },
            {
                field: 'master.name',
                displayName: 'master',
                headerCellFilter: 'translate',
                filtering: true
            },
            {
                field: 'reseller.name',
                displayName: 'reseller',
                headerCellFilter: 'translate',
                filtering: true
            },
            {
                field: 'organization.name',
                displayName: 'organization',
                headerCellFilter: 'translate',
                filtering: true
            },
            {
                field: 'licence.licence',
                type: 'number',
                displayName: 'licence',
                headerCellFilter: 'translate',
                filter: {
                    placeholder: 'licence'
                },
                cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <pin-it name="yoobackup_licences" data="row.entity"></pin-it></div>'
            },
            {
                field: 'serial_number',
                displayName: 'serial_number',
                headerCellFilter: 'translate',
                filtering: true
            },
            {
                field: 'created_date',
                type: 'date',
                displayName: 'order_created_date',
                headerCellFilter: 'translate',
                enableFiltering: true,
                filterHeaderTemplate: customFilter.date.template,
                filters: [
                    {
                        condition: customFilter.date.isBefore
                    },
                    {
                        condition: customFilter.date.isAfter
                    }
                ]
            },
            {
                field: 'validated_date',
                type: 'date',
                displayName: 'order_validated_date',
                headerCellFilter: 'translate',
                enableFiltering: true,
                filterHeaderTemplate: customFilter.date.template,
                filters: [
                    {
                        condition: customFilter.date.isBefore
                    },
                    {
                        condition: customFilter.date.isAfter
                    }
                ]
            },
            {
                field: 'shipped_date',
                type: 'date',
                displayName: 'order_shipped_date',
                headerCellFilter: 'translate',
                enableFiltering: true,
                filterHeaderTemplate: customFilter.date.template,
                filters: [
                    {
                        condition: customFilter.date.isBefore
                    },
                    {
                        condition: customFilter.date.isAfter
                    }
                ]
            },
            {
                field: 'shipped_state',
                displayName: 'shipped_state',
                headerCellFilter: 'translate',
                enableFiltering: true,
                filterHeaderTemplate: customFilter.date.template,
                filters: [
                    {
                        condition: customFilter.date.isBefore
                    },
                    {
                        condition: customFilter.date.isAfter
                    }
                ],
                cellTemplate: '<div class="ui-grid-cell-contents text-center"><i class="material-icons">{{COL_FIELD}}</i></span></div>'
            },
            {
                field: 'ups_tracking_id',
                type: 'number',
                displayName: 'ups_tracking_id',
                headerCellFilter: 'translate',
                enableFiltering: true,
                cellTemplate: '<div class="ui-grid-cell-contents"><a ng-if="row.entity.ups_tracking_id" ng-href="http://www.ups.com/WebTracking/track?trackNums={{row.entity.ups_tracking_id}}&track.x=Track" target="_blank"><i class="material-icons">local_shipping</i></a></span> {{COL_FIELD CUSTOM_FILTERS}} </div>'
            },
            {
                field: 'delivered_date',
                type: 'date',
                displayName: 'delivery_date',
                headerCellFilter: 'translate',
                enableFiltering: true,
                filterHeaderTemplate: customFilter.date.template,
                filters: [
                    {
                        condition: customFilter.date.isBefore
                    },
                    {
                        condition: customFilter.date.isAfter
                    }
                ]
            },
            {
                field: 'setup_date',
                type: 'date',
                displayName: 'setup_date',
                headerCellFilter: 'translate',
                enableFiltering: true,
                filterHeaderTemplate: customFilter.date.template,
                filters: [
                    {
                        condition: customFilter.date.isBefore
                    },
                    {
                        condition: customFilter.date.isAfter
                    }
                ]
            }
        ], true, true, 'high');
        $scope.gridOptions.onRegisterApi = function (gridApi) {
            $scope.gridApi = gridApi;
        };
        $scope.filterColumnLicence = val => {
            var col = _.find($scope.gridApi.grid.columns, {
                displayName: 'licence'
            });
            if (col) {
                col.filter.term = val;
                $scope.gridApi.core
                    .notifyDataChange(uiGridConstants.dataChange.COLUMN);
            }
        };
    }]);
