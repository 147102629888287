'use strict';

angular.module('portailWebApp')
    .controller('I18NvariablesController', ['$scope', '$translate', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $translate, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        //Defining the endpoint
        let endpoint = TokenRestAngular.all('admin/variables');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'i18n_variables';
        $rootScope.helpLink = '';

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                $scope.gridData = res;
            });
        };
        $scope.refresh();

        $scope.current = [];

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {
                field: 'name',
                displayName: 'name',
                headerCellFilter: 'translate'
            }
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;

            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.record = () => {
            let data = {
                'name': $scope.name
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.name = null;
                $scope.creation = false;
            });
        };
    }]);
