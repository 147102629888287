'use strict';

angular.module('portailWebApp')
    .controller('SupportRessourcesAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        //IS auth and admin
        let endpoint = TokenRestAngular.all('admin/supportressources');
        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'support_ressources';
        $rootScope.helpLink = '';
        //Endpoint

        //
        $scope.translation = false;

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                $scope.gridData = res;
            });
        };
        $scope.refresh();


        $scope.formCreate = () => {
            $scope.creation = true;
        };
        $scope.update = element => {
            $rootScope.xhr = true;
            element.save();
            $timeout(() => {
                $rootScope.xhr = false;
            }, 1000);
        };

        $scope.updatetrans = () => {
            let endpp = TokenRestAngular.all('admin/supportressourcetranslations');
            if ($scope.translation.length) {
                $rootScope.xhr = true;
                angular.forEach($scope.translation, item => {
                    angular.forEach(item.translations, trad => {
                        Flash.clear();
                        $rootScope.xhr = true;
                        endpp.post(trad).then(res => {
                            Flash.show(res.success, 'success');
                            $rootScope.xhr = false;
                        });
                    });
                    $scope.translation = false;
                });
            }
        };

        $scope.translate = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            if (cols.length !== 0) {
                $rootScope.xhr = true;
                $scope.translation = [];
                angular.forEach(cols, item => {
                    let endp = TokenRestAngular.all('admin/supportressourcetranslations/' + item.id);
                    $rootScope.xhr = true;
                    endp.getList().then(data => {
                        $scope.translation.push({
                            'to_translate': item.label,
                            'translations': data
                        });
                        $rootScope.xhr = false;
                    });
                });

            }
        };

        $scope.current = [];

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {
                field: 'type',
                displayName: 'categories',
                headerCellFilter: 'translate',
                cellEditableCondition: false
            },
            {
                field: 'var',
                displayName: 'label',
                headerCellFilter: 'translate'
            },
            {
                field: 'multiplicator',
                displayName: 'multiplicator',
                headerCellFilter: 'translate'
            }
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
            $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity => {
                $scope.update(rowEntity);
            });
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.record = () => {
            let data = {
                'var': $scope.var,
                'type': $scope.type,
                'multiplicator': $scope.multiplicator
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.type = 'origin';
                $scope.var = null;
                $scope.multiplicator = 0;
                $scope.creation = false;
            });
        };
    }]);
