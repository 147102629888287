'use strict';

angular.module('portailWebApp')
    .controller('I18NtranslationsController', ['$scope', '$translate', '$rootScope', 'Flash', 'apiRest', '_', ($scope, $translate, $rootScope, Flash, apiRest, _) => {
        let translation = apiRest('admin/translations', 'tokenms');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.subTitle = 'i18n_translations';
        $rootScope.helpLink = '';
        //Defining the endpoint
        //Go query lang api for languages list
        apiRest('lang/languages').customGET('')
            .then(res => {
                let currentLang = _.findWhere(res.lang, {
                    id: $scope.user.i18nlanguage_id
                });
                $scope.languages = res.langs;
                $scope.language = res.langs[0];
                if (currentLang) {
                    $scope.language = currentLang;
                }
            });

        apiRest('admin/translations/en', 1).getList().then(res => {
            $scope.ref_translations = res;
        });

        $scope.$watch('language', thelang => {
            let lang = _.findWhere($scope.languages, {
                id: thelang
            });
            if (lang) {
                apiRest('admin/translations/' + lang.code, 1).getList()
                    .then(res => {
                        angular.forEach($scope.ref_translations, item => {
                            let match = _.findWhere(res, {
                                i18nvariable_id: item.i18nvariable_id
                            });

                            if (match) {
                                match.ref = item.value;
                            }
                        });
                        $scope.translations = res;
                    });
            }
        });

        $scope.update = row => {
            translation.post(row).then(() => {
                $translate.refresh();
            });
        };
    }]);
