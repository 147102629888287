'use strict';

angular.module('portailWebApp')
    .controller('AboutController', ['$scope', '$rootScope', 'Flash', 'moment', ($scope, $rootScope, Flash, moment) => {
        //Layout
        $rootScope.pageClass = '';
        //Flash
        Flash.clear();
        //Title
        $rootScope.subTitle = 'about';
        $rootScope.helpLink = '';

        $scope.locale = moment.locale();

        $scope.zone = moment.duration(moment().utcOffset(), 'minutes').humanize();
    }]);
