((function () {
  angular.module('portailWebApp')
    .factory('Monitoring', MonitoringService);

  MonitoringService.$inject = ['$rootScope', '_', '$uibModal', 'TokenRestAngularMS', 'Flash', 'moment'];

  function MonitoringService($rootScope, _, $uibModal, TokenRestAngularMS, Flash, moment) {
    let filters_endpoint = TokenRestAngularMS.all('filters'),
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations');

    return {
      convert_status: convertStatus,
      convert_status_boxe: convertStatusBoxe,
      refreshFilters: refreshFilters,
      loadFilter: loadFilter,
      loadFilters: loadFilters,
      saveFilter: saveFilter,
      graph: getGraphModal,
      organization: getOrganizationModal,
      colorHelper: getColorHelper(),
      setGridOption: setGridOption
    };

    /////////////////////////////////////////////////////////

    function convertStatus(status) {
      switch (parseInt(status, 10)) {
      case 0:
        return 'off';
      case 1:
        return 'issue';
      case 2:
        return 'on';
      case 4:
        return 'running';
      default:
        return 'undefined';
      }
    }
    function convertStatusBoxe(status) {
      switch (parseInt(status, 10)) {
      case 0:
        return 'off';
      case 1:
        return 'issue';
      case 2:
        return 'on';
      default:
        return 'undefined';
      }
    }

    function setGridOption(dataLabel, cols, selection, exp, size) {
      let options = {
        minRowsToShow: 10,
        paginationPageSizes: [25, 50, 100, 500, 1000],
        paginationPageSize: 25,
        enableSorting: true,
        enableFiltering: true,
        showFooter: true
      };
      // get zize of grid
      //1 small 5 rows to show
      //2 normal 10 rows to show
      //3 tall 25 rows to show
      switch (size) {
      case 'small':
        options.minRowsToShow = 5;
        break;
      case 'normal':
        options.minRowsToShow = 10;
        break;
      case 'high':
        options.minRowsToShow = 25;
        break;
      default:
        options.minRowsToShow = 10;
        break;
      }
      options.data = dataLabel;
      options.enableRowSelection = (selection || angular.isUndefined(cols)) ? true : false;
      options.saveSelection = (selection || angular.isUndefined(cols)) ? true : false;
      options.saveRowIdentity = row => {
        return row.id;
      };
      options.enableFullRowSelection = options.enableRowSelection;
      options.enableRowHeaderSelection = false;
      if (angular.isUndefined(cols)) {
        cols = [
          {
            field: 'name',
            displayName: 'name',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.id)"><i class="material-icons md-18">info</i></a></div>'
          },
          {
            field: 'town',
            displayName: 'town',
            headerCellFilter: 'translate'
          },
          {
            field: 'zipcode',
            displayName: 'zipcode',
            type: 'number',
            headerCellFilter: 'translate'
          },
          {
            field: 'country',
            displayName: 'country',
            headerCellFilter: 'translate'
          }
        ];
      }
      options.columnDefs = cols;


      if (exp) {
        options.enableGridMenu = true;
        options.exporterCsvFilename = dataLabel + '.csv';
        options.exporterPdfHeader = {
          text: dataLabel,
          style: 'headerStyle'
        };
        options.exporterPdfDefaultStyle = {
          fontSize: 9
        };
        options.exporterPdfTableStyle = {
          margin: [30, 30, 30, 30]
        };
        options.exporterPdfTableHeaderStyle = {
          fontSize: 10,
          bold: true,
          italics: true,
          color: 'red'
        };
        options.exporterPdfFooter = function (currentPage, pageCount) {
          return {
            text: currentPage.toString() + ' of ' + pageCount.toString(),
            style: 'footerStyle'
          };
        };
        options.exporterPdfCustomFormatter = docDefinition => {
          docDefinition.styles.headerStyle = {
            fontSize: 22,
            bold: true
          };
          docDefinition.styles.footerStyle = {
            fontSize: 10,
            bold: true
          };
          return docDefinition;
        };
        options.exporterPdfOrientation = 'landscape';
        options.exporterPdfPageSize = 'LETTER';
        options.exporterPdfMaxGridWidth = 500;
        options.exporterCsvLinkElement = angular.element('.custom-csv-link-location');
      }
      return options;
    }

    function getColorHelper() {
      return {
        /*less is green */
        degress: v => {
          let r;
          switch (true) {
          case (!v || v < 0):
            r = 'status_undefined';
            break;
          case (v < 80):
            r = 'status_on';
            break;
          case (v < 100 && v >= 80):
            r = 'status_issue';
            break;
          default:
            r = 'status_off';
          }
          return r;
        },
        /* More is green */
        progress: v => {
          switch (true) {
          case (!v || v < 0):
            return 'status_undefined';
          case (v < 33):
            return 'status_off';
          case (v < 66 && v >= 33):
            return 'status_issue';
          default:
            return 'status_on';
          }
        },
        pipeClass: value => {
          return 'status_' + value;
        },
        dateDif: (testDate, difference) => {
          let now,
            calc,
            date,
            s = 'status_undefined';

          if (angular.isDefined(testDate)) {
            now = moment();
            date = moment(testDate);
            calc = date.diff(now, 'days');

            if (calc < 0) {
              s = 'status_off';
            }
            if (calc < difference) {
              s = 'status_issue';
            }
            if (calc >= difference) {
              s = 'status_on';
            }
          }
          return s;
        },
        dateBet: (testDate, alert, error) => {
          let now,
            calc,
            date,
            s = 'status_undefined';

          if (angular.isDefined(testDate)) {
            now = moment();
            date = moment(testDate);
            calc = date.diff(now, 'days');

            if (calc < -alert) {
              s = 'status_issue';
            }
            if (calc < -error) {
              s = 'status_off';
            }
          }
          return s;
        }
      };
    }

    function getOrganizationModal(organization_id) {
      organizations_endpoint.get(organization_id)
        .then(data => {
          $rootScope.openModal(
            'small',
            'modalOrganizationController',
            'modal_organization.html',
            data
          );
        });
    }

    function getGraphModal(endpoint, column, id, licence) {
      let data = {},
        gendpoint = TokenRestAngularMS.all('monitoring/' + endpoint + '/' + column),
        cols_status = ['status', 'raid_status', 'mount_status', 'yoobackup', 'samba', 'backup_status', 'cloud_status', 'restoration_status', 'suppression_status'],
        size_status = ['local_size', 'cloud_size_quota', 'cloud_size_cloud', 'cloud_size_tocloud', 'size_cloud', 'cloud_size_quota'];

      if (_.indexOf(cols_status, column) < 0 && _.indexOf(size_status, column) < 0) {
        data.class = 'chart2';
        data.status = false;
        data.capacity = true;
        data.capacity_c = false;
      } else if (_.indexOf(cols_status, column) < 0) {
        data.class = 'chart2';
        data.status = false;
        data.capacity = false;
        data.capacity_c = true;
      } else {
        data.class = 'chart1';
        data.status = true;
        data.capacity = false;
        data.capacity_c = false;
      }
      data.title = column;
      data.gtitle = column;
      data.licence = licence;
      if (gendpoint) {
        gendpoint.get(id).then(values => {
          data.values = values;
          $rootScope.openModal('small', 'modalGraphController', 'modal_graph.html', data);
        });
      }
    }

    function saveFilter(resource, attributes) {
      let modal = $uibModal.open({
          templateUrl: 'scripts/ctrl/modal/modal_save_filter.html',
          controller: 'modalSaveFilterController',
          backdrop: 'static',
          size: 'sm',
          resolve: {}
        }),
        data = {};

      modal.result.then(name => {
        data.name = name;
        data.resource = resource;
        data.data = attributes;
        filters_endpoint.post(data).then(response => {
          Flash.show(response.success, 'success');
        });
      });
    }

    function loadFilters(resource, callback) {
      filters_endpoint
        .getList({
          resource: resource
        })
        .then(data => {
          let modal = $uibModal.open({
            templateUrl: 'scripts/ctrl/modal/modal_load_filter.html',
            controller: 'modalLoadFilterController',
            backdrop: 'static',
            size: 'sm',
            resolve: {
              'data': () => {
                return data;
              }
            }
          });
          modal.result.then(callback);
        });
    }

    function loadFilter(id, callback) {
      filters_endpoint.get(id)
        .then(callback);
    }

    function refreshFilters(resource, callback) {
      filters_endpoint.getList({
        deletable: 0,
        resource: resource
      }).then(callback);
    }
  }

})());
