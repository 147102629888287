angular.module('portailWebApp')
    .controller('toolsDocController', ['$window', '$scope', '$location', '$rootScope', 'Flash', 'apiRest', 'brand', ($window, $scope, $location, $rootScope, Flash, apiRest, brand) => {
        let epDoc = apiRest('tools/documents', true),
            endpoint_files = apiRest('tools/documents', true);

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.subTitle = 'documentations';
        $rootScope.helpLink = 'yooportal/outils/documentation.html';

        //endpoint


        $scope.refresh = () => {
            epDoc.getList().then(data => {
                angular.forEach(data, item => {
                    item.name = brand.filter(item.name);
                });
                $scope.services = data;
            });
        };
        $scope.getFile = file => {
            endpoint_files.withHttpConfig({
                responseType: 'blob'
            }).get(file.id).then(data => {
                let date = file.created_at,
                    name2, date2;
                date2 = date.split(' ');
                name2 = date2[0] + '_' + file.filename;
                $window.saveAs(data, name2);
            });
        };
        //Init
        $scope.refresh();
    }]);
