'use strict';

angular.module('portailWebApp')
  .controller('OrganizationsAdmController', ['$scope', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
    let endpoint = TokenRestAngular.all('admin/organizations');

    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.initDropdown('.yp-toolbar');
    $rootScope.subTitle = 'organizations_admin';
    $rootScope.helpLink = '';

    //Get the data
    $scope.gridData = [];

    $scope.refresh = () => {
      endpoint.getList().then(res => {
        res.forEach(v => {
          v.group = !!v.group;
          v.certified = !!v.certified;
          v.vip = !!v.vip;
          v.formation = !!v.formation;
        });
        $scope.gridData = res;
      });
    };
    $scope.refresh();

    $scope.current = [];

    $scope.update = element => {
      $rootScope.xhr = true;
      element.save();
      $timeout(() => {
        $rootScope.xhr = false;
      }, 1000);
    };

    $scope.gridOptions = Monitoring.setGridOption('gridData', [
      {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
      {field: 'register_number', displayName: 'register_number', headerCellFilter: 'translate'},
      {field: 'group', displayName: 'group', headerCellFilter: 'translate', type: 'boolean'},
      {field: 'certified', displayName: 'certified', headerCellFilter: 'translate', type: 'boolean'},
      {field: 'vip', displayName: 'vip', headerCellFilter: 'translate', type: 'boolean'},
      {field: 'formation', displayName: 'formation', headerCellFilter: 'translate', type: 'boolean'}
    ], true, false, 'high');
    $scope.gridOptions.onRegisterApi = gridApi => {
      $scope.gridApi = gridApi;
      $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity =>{
        $scope.update(rowEntity);
      });
    };

    $scope.delete = () => {
      let cols = $scope.gridApi.selection.getSelectedRows();
      $rootScope.xhr = true;
      angular.forEach(cols, item => {
        item.remove();
      });
      $timeout(() => {
        $scope.refresh();
        $rootScope.xhr = false;
      }, 1500);
    };

    $scope.record = () => {
      let data = {
        'name': $scope.name,
        'register_number': $scope.register_number
      };
      endpoint.post(data).then(res => {
        Flash.show(res.success, 'success');
        $scope.refresh();
        $scope.name = null;
        $scope.register_number = null;
        $scope.creation = false;
      });
    };
  }]);
