angular.module('portailWebApp')
    .controller('ContactsAdmController', [
        '$rootScope',
        '$log',
        'apiRest',
        'Person',
        function ($rootScope, $log, apiRest, Person) {
            let vm = this,
                route = 'admin/contact',
                endpoint = apiRest(route, 1);
            //IS auth and admin

            endpoint.getList()
                .then(data => {
                    vm.contactsList = data.map(i => {
                        let r = Person(i);
                        r.extra = {
                            label: 'tickets_nb',
                            value: i.tickets_nb
                        };
                        return r;
                    });
                });
            //Layout
            $rootScope.pageClass = '';
            //Title
            $rootScope.subTitle = 'contacts_admin';
            $rootScope.helpLink = '';

            vm.test = 'test 1';

            vm.newContact = () => {
                vm.contactsList.unshift(Person({route: route}));
            };

            vm.clearCB = index => {
                vm.contactsList.splice(index, 1);
            };

            vm.add2merge = item => {
                vm.merge = vm.hasOwnProperty('merge') ? vm.merge : vm.merge = {};
                if (!vm.merge.from || !vm.merge.into) {
                    if (!vm.merge.from && (!vm.merge.into || vm.merge.into.id !== item.id)) {
                        vm.merge.from = item;
                    } else if (vm.merge.from && vm.merge.from.id !== item.id) {
                        vm.merge.into = item;
                    }
                } else {
                    vm.merge = {
                        from: item
                    };
                }
            };

            vm.mergeOK = () => {
                let origin = vm.contactsList.find(v => v.id === vm.mergeO.from),
                    target = vm.contactsList.find(v => v.id === vm.mergeO.into);

                // update view of the merge target
                target.extra.value += origin.extra.value;
                // remove from list the contact item who has been deleted during the merge
                vm.contactsList.splice(vm.contactsList.indexOf(origin), 1);
                // clear the merge module
                // we dont need mergeO anymore
                delete vm.mergeO;
                vm.clear();
            };

            vm.saveMerge = () => {
                vm.mergeO = {
                    from: vm.merge.from.id,
                    into: vm.merge.into.id
                };

                endpoint
                    .customPOST(vm.mergeO, 'merge')
                    .then(vm.mergeOK)
                    .catch();
            };
            vm.swap = () => {
                const a = angular.copy(vm.merge);
                vm.merge = {
                    from: a.into,
                    into: a.from
                };
            };
            vm.clear = () => delete vm.merge;

            vm.clearEmpty = () => {
                vm.contactsList = vm.contactsList.filter(v => v.id);
            };
        }]);

