'use strict';

angular.module('portailWebApp')
    .controller('MembershipsAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        let endpoint = TokenRestAngular.all('admin/memberships'),
            oendpoint = TokenRestAngular.all('admin/organizations'),
            uendpoint = TokenRestAngular.all('admin/users'),
            rendpoint = TokenRestAngular.all('admin/rights');

        //Layout
        $rootScope.pageClass = '';
        //Flash
        Flash.clear();
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'memberships_admin';
        $rootScope.helpLink = '';
        //Endpoint


        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                angular.forEach(res, item => {
                    item.active = !!+item.active;
                });
                $scope.gridData = res;
            });
        };
        $scope.refresh();

        $scope.current = [];

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {field: 'user.name', displayName: 'name', headerCellFilter: 'translate'},
            {field: 'organization.name', displayName: 'organization', headerCellFilter: 'translate'},
            {field: 'right.name', displayName: 'right', headerCellFilter: 'translate'},
            {field: 'active', displayName: 'active', headerCellFilter: 'translate'}
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = function (gridApi) {
            $scope.gridApi = gridApi;
        };

        $scope.actdes = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.active = !item.active;
                item.save();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.record = () => {
            let data = {
                'organization_id': $scope.organization_id,
                'user_id': $scope.user_id,
                'right_id': $scope.right_id
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.organization_id = null;
                $scope.user_id = null;
                $scope.right_id = null;
                $scope.creation = false;
            });
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;

            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        //get data

        oendpoint.getList().then(data => {
            $scope.organizations = data;
        });

        uendpoint.getList().then(data => {
            $scope.users = data;
        });

        rendpoint.getList().then(data => {
            $scope.rights = data;
        });

    }]);
