((function () {

    angular.module('portailWebApp')
        .factory('Acl', AclService);

    AclService.$inject = ['$rootScope', '_'];

    function AclService($rootScope, _) {
        $rootScope.acl = [];
        return {
            construct: construct,
            canIDoIN: canIDoIn,
            isAction: isAction,
            isMenu: isMenu,
            isView: isView,
            destroy: destroy
        };

        function canIDoIn(org, action) {
            var theOrganization = _.find($rootScope.acl, {id: org.id});
            if (theOrganization && theOrganization.acl && theOrganization.acl.action) {
                if (_.find(theOrganization.acl.action, row => row === action)) {
                    return true;
                }
            }
            return false;
        }

        function construct(acl) {
            $rootScope.acl = acl;
        }

        function destroy() {
            $rootScope.acl = [];
        }

        function isAction(action) {
            var retour = false;
            angular.forEach($rootScope.acl, org => {
                if (org.acl && org.acl.action) {
                    if (_.find(
                            org.acl.action,
                            row => {
                                return (row === action);
                            })) {
                        retour = true;
                    }
                }
            });
            return retour;
        }

        function isMenu(menu) {
            var retour = false;
            angular.forEach($rootScope.acl, org => {
                if (org.acl && org.acl.menu) {
                    if (_.find(
                            org.acl.menu, row => {
                                return (row === menu);
                            }
                        )) {
                        retour = true;
                    }
                }
            });
            return retour;
        }

        function isView(view) {
            const aclLists = $rootScope.acl;
            return !!aclLists.map(list => list.acl.view)
                .reduce((precedent, current) => precedent.concat(current), [])
                .find(v => v === view);
        }
    }

})());

((function () {

    angular.module('portailWebApp')
        .filter('canIDoIN', canIDoIn);

    canIDoIn.$inject = ['Acl'];

    function canIDoIn(Acl) {
        return (organisationsArray, action) => {
            return organisationsArray.map(org => Acl.canIDoIN(org, action));
        };
    }
})());

