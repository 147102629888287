((function () {
    angular.module('portailWebApp')
        .controller('modalOrganizationController', ModalOrganizationController);

    ModalOrganizationController.$inject = ['$scope', '$log', '$uibModalInstance', 'data', 'apiRest', 'Flash'];

    function ModalOrganizationController($scope, $log, $uibModalInstance, data, apiRest, Flash) {

        $scope.data = data.plain();
        $scope.olderData = angular.copy($scope.data);

        let organizations_endpoint = apiRest('tools/organizations/sendRequest', 1);

        $scope.ok = () => {
            if ($scope.updating) {
                $scope.updating = false;
            } else {
                $uibModalInstance.close();
            }
        };

        $scope.sendRequest = sendRequest;

        $scope.toggleEdit = toggleEdit;
        $scope.toggleTab = toggleTab;

        function toggleEdit() {
            $scope.updating = !$scope.updating;
        }

        function toggleTab(elem) {
            let tabSelected,
                tabPanelId,
                tabPanel;

            tabSelected = angular.element('#' + elem.currentTarget.id);
            //deselect all the tabs
            angular.element('#updateOrga [role="tab"]').attr('aria-selected', 'false');
            // select this tab
            tabSelected.attr('aria-selected', 'true');
            //find out what tab panel this tab controls
            tabPanelId = tabSelected.attr('aria-controls');
            tabPanel = angular.element('#' + tabPanelId);
            //hide all the panels
            angular.element('#updateOrga [role="tabpanel"]')
                .attr('aria-hidden', 'true');
            // show our panel
            tabPanel.attr('aria-hidden', 'false');
        }

        function sendRequest() {
            organizations_endpoint
                .post($scope.data)
                .then(res => {
                    $log.log('asSuccess', res);
                    Flash.show(res.success, 'success');
                    $scope.updating = false;
                })
                .catch(res => {
                    $log.log('asError', res);
                    Flash.show(res.data.error, 'warning');
                });
        }
    }

})());


