/**
 * Created by antoine on 28/05/2016.
 */

angular.module('portailWebApp')
    .directive('uiSelectRequired', function () {
        return {
            require: ['ngModel'],
            link: function (scope, elm, attrs, ctrl) {
                ctrl['0'].$validators.uiSelectRequired = modelValue => {
                    return (modelValue && modelValue.length > 0) ? true : false;
                };
            }
        };
    });
