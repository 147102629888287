'use strict';

angular.module('portailWebApp')
  .controller('MonitoringBoxesController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', '$translate', '$uibModal', 'Monitoring', '$routeParams', ($scope, $location, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, $translate, $uibModal, Monitoring, $routeParams) => {

    var boxes_endpoint = TokenRestAngularMS.all('monitoring/boxes'),
      relations_endpoint = TokenRestAngularMS.all('monitoring/relations'),
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations'),
      orgacols = [{
        field: 'name',
        displayName: 'name',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.id)"><i class="material-icons md-18">info</i></a></div>'
      },
      {
        field: 'town',
        displayName: 'town',
        headerCellFilter: 'translate'
      },
      {
        field: 'zipcode',
        displayName: 'zipcode',
        type: 'number',
        headerCellFilter: 'translate'
      },
      {
        field: 'country',
        displayName: 'country',
        headerCellFilter: 'translate'
      }
      ],
      boxescols = [{
        field: 'licence',
        displayName: 'licence',
        type: 'number',
        filter: {
          term: '',
          placeholder: 'licence'
        },
        headerCellFilter: 'translate',
        cellTemplate: `<yp-fill-search class="yp-grid__btn ui-grid-cell-contents" terms="{{row.entity.licence}}">{{COL_FIELD CUSTOM_FILTERS}}</yp-fill-search>`
      },
      {
        field: 'organization',
        displayName: 'organization',
        headerCellFilter: 'translate',
        cellTemplate: `<div class="ui-grid-cell-contents ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified"  class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><i class="material-icons md-18">infos</i></a></div>`
      },
      {
        field: 'seller',
        displayName: 'reseller',
        headerCellFilter: 'translate',
        visible: false,
        cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.sellerCertified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.sellerCertified" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.seller }}</div>'
      },
      {
        field: 'software',
        displayName: 'software',
        headerCellFilter: 'translate',
        visible: false
      },
      {
        field: 'warranty',
        displayName: 'warranty',
        type: 'date',
        headerCellFilter: 'translate',
        enableFiltering: true,
        filters: [{
          condition: customFilter.date.isBefore
        },
        {
          condition: customFilter.date.isAfter
        }
        ],
        filterHeaderTemplate: customFilter.date.template,
        cellClass: (grid, row, col) => {
          var date = grid.getCellValue(row, col);
          return Monitoring.colorHelper.dateDif(date, 30);
        }
      },
      {
        field: 'setup',
        displayName: 'setup',
        type: 'date',
        headerCellFilter: 'translate',
        enableFiltering: true,
        filters: [{
          condition: customFilter.date.isBefore
        },
        {
          condition: customFilter.date.isAfter
        }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'web_version',
        displayName: 'web_version',
        headerCellFilter: 'translate',
        enableFiltering: true
      },
      {
        field: 'maj_date',
        type: 'date',
        displayName: 'maj_date',
        headerCellFilter: 'translate',
        enableFiltering: true,
        filters: [{
          condition: customFilter.date.isBefore
        },
        {
          condition: customFilter.date.isAfter
        }
        ],
        filterHeaderTemplate: customFilter.date.template

      },
      {
        field: 'serial_number',
        displayName: 'serial_number',
        headerCellFilter: 'translate',
        enableFiltering: true
      },

      {
        field: 'updated_at',
        type: 'date',
        displayName: 'updated_at',
        headerCellFilter: 'translate',
        enableFiltering: true,
        filters: [
          { condition: customFilter.date.isBefore },
          { condition: customFilter.date.isAfter }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'status',
        displayName: 'status',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterBoxesOptions,
          condition: customFilter.bool.filterStatus
        },
        cellClass: (grid, row, col) => {
          var v = grid.getCellValue(row, col);
          return Monitoring.colorHelper.pipeClass(v);
        }
      },
      {
        field: 'last_up',
        type: 'date',
        displayName: 'last_up',
        headerCellFilter: 'translate',
        enableFiltering: true,
        filters: [{
          condition: customFilter.date.isBefore
        },
        {
          condition: customFilter.date.isAfter
        }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'raid_status',
        displayName: 'raid_status',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'raid_status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterOption,
          condition: customFilter.bool.filterStatus
        },
        cellClass: (grid, row, col) => {
          var v = grid.getCellValue(row, col);
          return Monitoring.colorHelper.pipeClass(v);
        }
      },
      {
        field: 'raid_down',
        type: 'date',
        displayName: 'raid_down',
        headerCellFilter: 'translate',
        enableFiltering: true,
        filters: [{
          condition: customFilter.date.isBefore
        },
        {
          condition: customFilter.date.isAfter
        }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'mount_status',
        displayName: 'mount_status',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'mount_status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterOption,
          condition: customFilter.bool.filterStatus
        },
        cellClass: (grid, row, col) => {
          var v = grid.getCellValue(row, col);
          return Monitoring.colorHelper.pipeClass(v);
        }
      },
      {
        field: 'hd_capacity',
        type: 'number',
        displayName: 'hd_capacity',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'hd_capacity\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}} % <i class="material-icons md-18">poll</i></div>',
        cellClass: (grid, row, col) => {
          var v = grid.getCellValue(row, col);
          return Monitoring.colorHelper.degress(v);
        },
        filters: [{
          condition: uiGridConstants.filter.GREATER_THAN
        },
        {
          condition: uiGridConstants.filter.LESS_THAN
        }
        ],
        filterHeaderTemplate: customFilter.number.templateMore
      },
      {
        field: 'ups_status',
        displayName: 'ups_status',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'ups_status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterOption,
          condition: customFilter.bool.filterStatus
        },
        cellClass: (grid, row, col) => {
          var v = grid.getCellValue(row, col);
          return Monitoring.colorHelper.pipeClass(v);
        }
      },
      {
        field: 'hd_data_size',
        displayName: 'hd_data_size',
        headerCellFilter: 'translate',
        type: 'number',
        filters: [{
          condition: uiGridConstants.filter.GREATER_THAN
        },
        {
          condition: uiGridConstants.filter.LESS_THAN
        }
        ],
        filterHeaderTemplate: customFilter.number.templateMore
      },
      {
        field: 'beemo',
        displayName: 'wbext_status',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'beemo\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterOption,
          condition: customFilter.bool.filterStatus
        },
        cellClass: (grid, row, col) => {
          var v = grid.getCellValue(row, col);
          return Monitoring.colorHelper.pipeClass(v);
        }
      },
      {
        field: 'contracts',
        displayName: 'contracts',
        enableFiltering: true,
        headerCellFilter: 'translate'
      }
      ];

    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'monitoring_boxes';
    $rootScope.helpLink = 'yooportal/monitoring/boxes.html';
    $rootScope.initDropdown('.yp-table__toolbar, .yp-table--dd');
    //endpoint
    //filters functions
    $scope.relation_id = null;
    //init
    $scope.organizations = [];
    $scope.relations = [];
    $scope.boxes = [];
    $scope.filters = [];
    // Monitoring service
    Monitoring.refreshFilters('monitoring_boxes', data => {
      $scope.filters = data;
      if ($scope.filters.length > 0) {
        $scope.loadFilter($scope.filters[0].id);
      }
      $timeout(() => {
        let type = $routeParams.filterType,
          value = $routeParams.filterValue || null;

        if (type) {
          switch (type) {
          case 'offline':
            $scope.filterColumnStatus('off');
            break;
          case 'capacity':
            $scope.filterColumnCapacity(79);
            break;
          case 'licence':
            $scope.filterColumnLicence(value);
            break;
          default:
          }
        }
      }, 1000);
    });

    $scope.loadIt = () => {
      Monitoring.loadFilters('monitoring_boxes', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //If selection grid1
        var organizations = $scope.gridApi1.selection.getSelectedRows(),
          columns1 = $scope.gridApi1.saveState.save(),
          columns2 = $scope.gridApi2.saveState.save();
        if (organizations.length) {
          data.data.grid1.selection = false;
        }
        //if filters  grid1?

        angular.forEach(columns1.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid1.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid2?

        angular.forEach(columns2.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid2.columns[i].filters[j].term = filter.term;
            }
          });
        });
        $scope.relation_id = data.data.relation_id;
        $scope.gridApi1.saveState.restore($scope, data.data.grid1);
        $scope.gridApi2.saveState.restore($scope, data.data.grid2);
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('monitoring_boxes', {
        grid1: $scope.gridApi1.saveState.save(),
        grid2: $scope.gridApi2.saveState.save(),
        relation_id: $scope.relation_id
      });
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };
    //Usefull
    $scope.graph = (type, box) => {
      Monitoring.graph('boxes', type, box.id, box.licence);
    };

    //refresh functions
    $scope.refresh_boxes = () => {
      var organizations = $scope.gridApi1.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (r) {
        boxes_endpoint.getList(filters).then(data => {
          $scope.boxes = [];
          angular.forEach(data, row => {
            // eslint-disable-next-line angular/log, no-console
            console.log(row);
            row.hd_capacity = parseInt(row.hd_capacity, 10);

            row.status = Monitoring.convert_status_boxe(row.status);
            row.raid_status = Monitoring.convert_status(row.raid_status);
            row.mount_status = Monitoring.convert_status(row.mount_status);
            row.winsave_status = Monitoring.convert_status(row.winsave_status);
            //row.last_up = row.last_up ? $filter('utc2local')(row.last_up).format('YYYY-MM-DD HH:mm') : $translate.instant('never');
            if (row.last_up === '0000-00-00 00:00:00') {
              row.last_up = 'Never';
            }
            //row.raid_down = row.raid_down ? $filter('utc2local')(row.last_up).format('YYYY-MM-DD HH:mm') : null;
            if (row.raid_down === '0000-00-00 00:00:00') {
              row.raid_down = null;
            }
            row.yoobackup = Monitoring.convert_status(row.yoobackup);
            row.samba = Monitoring.convert_status(row.samba);
            row.beemo = Monitoring.convert_status(row.beemo);
            row.yoofind = Monitoring.convert_status(row.yoofind);
            row.ups_status = Monitoring.convert_status(row.ups_status);

            $scope.boxes.push(row);
          });
          //calcul
          $scope.count = _.countBy(data, row => {
            return row.status;
          });
        });
      }
      return r;
    };
    $scope.refresh_relations = () => {
      $scope.relations = [];
      relations_endpoint.getList().then(data => {

        var vardefault = {
          id: null,
          name: '--'
        };
        $scope.relations = data;
        $scope.relations.unshift(vardefault);
        $scope.relation_id = vardefault.id;
        $scope.refresh_organizations();
      });
    };

    $scope.refresh_organizations = () => {

      var filters = {};
      $scope.organizations = [];
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      organizations_endpoint.getList(filters).then(data => {
        $scope.organizations = data;
        $scope.refresh_boxes();
      });
    };
    //Init
    $scope.refresh_relations();

    //Grids

    $scope.gridOptions1 = Monitoring.setGridOption('organizations', orgacols, true);
    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_boxes();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_boxes();
      });
      $scope.gridApi1 = gridApi;
    };

    $scope.gridOptions2 = Monitoring.setGridOption('boxes', boxescols, false, true, 'high');
    $scope.gridOptions2.onRegisterApi = gridApi => {
      $scope.gridApi2 = gridApi;
    };
    //Grid operations
    $scope.selectAll = () => {
      $scope.gridApi1.selection.selectAllVisibleRows();
    };

    $scope.clearAll = () => {
      $scope.gridApi1.selection.clearSelectedRows();
    };

    $scope.removeOrganisationFilter = row => {
      $scope.gridApi1.selection.unSelectRow(row);
    };

    $scope.filterColumnStatus = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'status'
      });
      if (col) {
        col.filter.term = val;
        $scope.gridApi2.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }

    };

    $scope.filterColumnCapacity = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'hd_capacity'
      });
      if (col) {
        col.filters[0] = {
          term: val,
          condition: uiGridConstants.filter.GREATER_THAN,
          placeholder: 'greater than'
        };
      }
      $scope.gridApi2.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };

    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'licence'
      });
      if (col) {
        col.filter.term = val;
        $scope.gridApi2.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };

  }]);