angular.module('portailWebApp')
  .controller('BasketAssignAdmController', ['$location', '$rootScope', 'TokenRestAngularMS', function ($location, $rootScope, TokenRestAngularMS) {
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'basket_assign_admin';
    $rootScope.helpLink = '';

    let vm = this,
      tk = TokenRestAngularMS,
      ep = {
        users: tk.all('ladmin/users?basket=true'),
        baskets: tk.all('admin/basketscrud')
      };
    vm.getUsers = () => {
      vm.listUsers = [];
      ep.users.getList().then(data => {
        vm.users = data;
        angular.forEach(data, row => {
          let d = vm.listUsers.filter(user => {
            return user.id === vm.getAssignedUsers(row).id;
          });
          if (d.length === 0) {
            vm.listUsers.push(vm.getAssignedUsers(row));
          }
        });
      });
    };
    vm.getBaskets = () => {
      ep.baskets.getList().then(data => {
        vm.baskets = data;
        vm.getUsers();
      });

    };
    vm.sortArrayByName = (array) => {
      return array.sort((a, b) => {
        var nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    };
    vm.getAssignedUsers = (user) => {
      let userBasket = [];

      vm.assignedUsers = vm.baskets.map(basket => {
        if (user.assigned_basket && user.assigned_basket.length > 0) {
          userBasket = user.assigned_basket.filter((data) => {
            return data.basket_id === basket.id;
          });
          if (userBasket.length === 0) {
            user.assigned_basket.push({
              basket_id: basket.id,
              name: basket.name,
              type: 'null'
            });
          }
        } else {
          user.assigned_basket.push({
            basket_id: basket.id,
            name: basket.name,
            type: 'null'
          });
        }
      });
      user.assigned_basket = vm.sortArrayByName(user.assigned_basket);
      return user;

    };

    vm.saveAssignment = (basket, user) => {
      let request = tk.all('admin/users/basket/' + user.id);
      request.post({
        basket: basket
      });
    };
    vm.init = () => {
      vm.getBaskets();
    };

    vm.init();

  }]);
