/* eslint-disable no-unused-vars */
(function () {
  angular.module('portailWebApp')
    .controller('MonitoringTinaController', MonitoringTinaController);

  MonitoringTinaController.$inject = ['$scope', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', 'Monitoring', '$window', 'brand', '$routeParams', '$filter'];

  function MonitoringTinaController($scope, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, Monitoring, $window, brand, $routeParams, $filter) {
    // ----------------------------------------- Define Endpoints ----------------------------------------- //
    const 
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations'),
      licences_endpoint = TokenRestAngularMS.all('monitoring/yoobackup/tina-agents/licenses'),
      tina_type_endpoint = TokenRestAngularMS.all('monitoring/yoobackup/tina-agents/agentsList'),
      tina_agents_endpoint = TokenRestAngularMS.all('monitoring/yoobackup/tina-agents'),
      gendpoint = TokenRestAngularMS.all('monitoring/tina/licences/remote'),

      // ----------------------------------------- Define values in datatable ----------------------------------------- //
      data_grid = {
        licences: [
          {
            field: 'licence',
            type: 'number',
            displayName: 'licence',
            headerCellFilter: 'translate',
            filter: {
              placeholder: 'licence'
            },
            cellTemplate: `<yp-fill-search class="yp-grid__btn ui-grid-cell-contents" terms="{{row.entity.licence}}">{{COL_FIELD CUSTOM_FILTERS}}</yp-fill-search>`
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified"  class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><span class="glyphicon glyphicon-info-sign"></a></span></div>'
          },
          {
            field: 'seller',
            displayName: 'reseller',
            headerCellFilter: 'translate',
            visible: false,
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certifiedSeller ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certifiedSeller" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.seller }}</div>'
          },
          {
            field: 'contacts_email',
            displayName: 'contacts_email',
            headerCellFilter: 'translate',
            enableFiltering: true
          },
          {
            field: 'expiration',
            type: 'date',
            displayName: 'expiration',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'updated_at',
            type: 'date',
            displayName: 'updated_at',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'remote_link',
            displayName: 'remote_link',
            headerCellFilter: 'translate',
            enableFiltering: false,
            cellTemplate: '<div class="ui-grid-cell-contents text-center" ng-click="grid.appScope.remote(row.entity)"><i ng-hide="row.entity.standalone" class="material-icons md-18">build</i> <span ng-hide="row.entity.standalone" translate="remote_link"></span></div>'
          },
          {
            field: 'contracts',
            displayName: 'contracts',
            enableFiltering: true,
            headerCellFilter: 'translate'
          }
        ],
        tina_type: [
          // Organization name
          {
            field: 'organizations',
            type: 'string',
            displayName: 'organizations',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.organizations }}</div>'
          },
          // Type
          {
            field: 'type',
            type: 'string',
            displayName: 'tina_type',
            headerCellFilter: 'translate'
          },
          // Application type
          {
            field: 'app_type',
            type: 'string',
            displayName: 'app_type',
            headerCellFilter: 'translate'
          },
          // Global Status
          {
            field: 'status',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            displayName: 'tina_status',
            headerCellFilter: 'translate',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          // Last backup
          {
            field: 'last_backup',
            type: 'string',
            displayName: 'last_backup',
            headerCellFilter: 'translate'
          },
          // Treated Volume Total
          {
            field: 'treated_volume',
            type: 'string',
            displayName: 'treated_volume',
            headerCellFilter: 'translate'
          },
          // Treated Object Total
          {
            field: 'treated_object',
            type: 'string',
            displayName: 'treated_object',
            headerCellFilter: 'translate'
          },
          // Last Treated Volume
          {
            field: 'last_treated_volume',
            type: 'string',
            displayName: 'last_treated_volume',
            headerCellFilter: 'translate'
          },
          // Last Treated Object
          {
            field: 'last_treated_object',
            type: 'string',
            displayName: 'last_treated_object',
            headerCellFilter: 'translate'
          }
        ],
        tina_agents: [
          // Agent Name
          {
            field: 'type',
            type: 'string',
            displayName: 'agent_type',
            headerCellFilter: 'translate'
          },
          // Object Name
          {
            field: 'path',
            type: 'string',
            displayName: 'object_name',
            headerCellFilter: 'translate'
          },
          // Status
          {
            field: 'status',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            displayName: 'agent_status',
            headerCellFilter: 'translate',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          // Last Success Backup
          {
            field: 'last_success_backup',
            type: 'number',
            displayName: 'last_success_backup',
            headerCellFilter: 'translate',
            cellTooltip: function (row) {
              return row.entity.bckp_last_success_backup;
            }
          },
          // Last Backup
          {
            field: 'last_backup',
            type: 'date',
            displayName: 'agent_last_backup',
            headerCellFilter: 'translate',
            cellTooltip: function (row) {
              return row.entity.bckp_last_backup;
            }
          },
          // Last Duration Backup
          {
            field: 'last_duration_backup',
            type: 'string',
            displayName: 'duration_last_backup',
            headerCellFilter: 'translate'
          },
          // Last Treated Volumes
          {
            field: 'last_treated_volume',
            type: 'number',
            displayName: 'last_treated_volumes',
            headerCellFilter: 'translate'
          },
          // Treated Volumes
          {
            field: 'treated_volume',
            type: 'number',
            displayName: 'treated_volumes',
            headerCellFilter: 'translate'
          },
          // Last Treated Objects
          {
            field: 'last_treated_object',
            type: 'number',
            displayName: 'last_treated_objects',
            headerCellFilter: 'translate'
          },
          // Traited Object
          {
            field: 'treated_object',
            type: 'number',
            displayName: 'treated_objects',
            headerCellFilter: 'translate'
          },
          // Last Update
          {
            field: 'updated_at',
            type: 'date',
            displayName: 'last_updated',
            headerCellFilter: 'translate',
            visible: false
          }
        ]
      };


    // ----------------------------------------- Define Root Scope ----------------------------------------- //
    $rootScope.pageClass = '';
    
    $rootScope.initDropdown('.yp-table--dd');
    $rootScope.initDropdown('.yp-accordion', {
      closeOut: true
    });
    $rootScope.subTitle = 'monitoring_tina';


    // ----------------------------------------- Define Scope ----------------------------------------- //
    $scope.relation_id = null;
    $scope.filters = [];

    $scope.organizations = [];
    $scope.grid_organizations = Monitoring.setGridOption('organizations');

    $scope.licences = [];
    $scope.grid_licences = Monitoring.setGridOption('licences', data_grid.licences, true, true);
    
    $scope.tina_type = [];
    $scope.grid_tina_type = Monitoring.setGridOption('tina_type', data_grid.tina_type, true, true);

    $scope.tina_agents = [];
    $scope.grid_tina_agents = Monitoring.setGridOption('tina_agents', data_grid.tina_agents, false, true);


    // ----------------------------------------- Define Function ----------------------------------------- //

    $scope.remote = licence => {
      gendpoint.get(licence.id).then(data => {
        $window.open(`${data.url}Tina/webui/`, '_blank');
      });
    };

    // ***************** Organizations ***************** //
    $scope.select_all = () => {
      $scope.selected_organizations.selection.selectAllVisibleRows();
    };

    $scope.clear_all = () => {
      $scope.selected_organizations.selection.clearSelectedRows();
    };

    $scope.unselect_row = (parent, row) => {
      let scope = null;

      switch (parent) {
      case 'licences':
        scope = $scope.selected_licences;
        break;

      default:
        scope = $scope.selected_organizations;
      }
      
      scope.selection.unSelectRow(row);
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };


    // ***************** Filters ***************** //
    $scope.set_filter = (array) => {
      const { length } = `${array}`;

      if (length > 100) {
        Flash.show('no_more_selection');
        return false;
      }

      if (length > 0) {
        let filters = '';
        angular.forEach(array, (row) => {
          filters += `${row.id},`;
        });
        return filters;
      }

      return false;
    };

    $scope.set_tina_filter = (array) => {
      const { length } = `${array}`;

      if (length > 100) {
        Flash.show('no_more_selection');
        return false;
      }

      if (length > 0) {
        let res = [];

        angular.forEach(array, (row) => {
          res.push(
            {
              app_type: row.id[0],
              types: row.id[1],
              organizations: row.id[2],
              licences: row.id[3]
            }
          );
        });

        return res;
      }

      return false;
    };

    
    // ***************** Convert Data Bytes ***************** //
    // eslint-disable-next-line consistent-return
    $scope.convert_bytes = (bytes) => {
      if (!+bytes) {
        return '0 Bytes';
      }

      const k = 1024,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

      let dm = 0;
      if (i >= 4) {
        dm = 2;
      }
      if (i === 3) {
        dm = 1;
      }

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    // ***************** Define type in datatable ***************** //
    $scope.table_license = (data) => {
      const lic = [];

      angular.forEach(data, row => {
        row.status = Monitoring.convert_status(row.status);
        row.local_size = parseInt(row.local_size, 10);
        row.local_number = parseInt(row.local_number, 10);
        row.cloud_size_quota = parseInt(row.cloud_size_quota, 10);
        row.cloud_size_cloud = parseInt(row.cloud_size_cloud, 10);
        row.cloud_size_tocloud = parseInt(row.cloud_size_tocloud, 10);
        row.cloud_percent = Math.round((1 - (row.cloud_size_tocloud / (row.cloud_size_tocloud + row.cloud_size_cloud))) * 10000) / 100;
        row.cloud_usage = row.cloud ? Math.round(row.cloud_size_cloud / row.cloud_size_quota * 100) : null;

        if (!row.cloud_percent) {
          row.cloud_percent = 100;
        }
        row.cloud_number_cloud = parseInt(row.cloud_number_cloud, 10);
        row.cloud_number_tocloud = parseInt(row.cloud_number_tocloud, 10);
        row.cloud = !!+row.cloud;
        row.box2box = !!+row.box2box;
        row.standalone = !!+row.standalone;

        lic.push(row);
      });

      return lic;
    };

    $scope.table_tina_type = (data) => {
      const types = [];
      angular.forEach(data, (row) => {
        let status = '';
        switch (row.status) {
        case 'ok':
          status = 2;
          break;

        case 'warning':
          status = 1;
          break;
          
        case 'error':
          status = 0;
          break;

        case 'running':
          status = 4;
          break;

        default:
          status = 3;
          break;
        }

        row.status = Monitoring.convert_status(status);
        row.treated_volume = $scope.convert_bytes(row.treated_volume);
        row.treated_object = row.treated_object ? row.treated_object.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
        row.last_treated_volume = $scope.convert_bytes(row.last_treated_volume);
        row.last_treated_object = row.last_treated_object ? row.last_treated_object.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
        types.push(row);
      });

      return types;
    };

    $scope.table_tina = (data) => {
      const agents = [];
      angular.forEach(data, (row) => {
        let status = '';
        switch (row.status) {
        case 'ok':
          status = 2;
          break;

        case 'warning':
        case 'skipped':
          status = 1;
          break;
          
        case 'error':
        case 'NA':
        case 'unreachable_agent':
          status = 0;
          break;

        case 'running':
          status = 4;
          break;

        default:
          status = 3;
          break;
        }

        row.status = Monitoring.convert_status(status);

        if (row.last_backup !== '0000-00-00 00:00:00') {
          const last_backup = Math.floor((new Date().getTime() - new Date(row.last_backup).getTime()) / (1000 * 3600 * 24)),
            last_success_backup = Math.floor((new Date().getTime() - new Date(row.last_success_backup).getTime()) / (1000 * 3600 * 24)),
            [hours, minutes, seconds] = row.last_duration_backup.split(':');
          row.bckp_last_success_backup = row.last_success_backup;
          row.bckp_last_backup = row.last_backup;
          row.last_success_backup = `${last_success_backup} day${last_success_backup > 1 ? 's' : ''} ago`;
          row.last_backup = `${last_backup} day${last_backup > 1 ? 's' : ''} ago`;
          row.last_duration_backup = `${hours !== '00' ? hours + 'h' : ''} ${minutes !== '00' ? minutes + 'm' : ''} ${seconds !== '00' ? seconds + 's' : ''}`;
        } else {
          row.bckp_last_success_backup = '';
          row.bckp_last_backup = '';
          row.last_success_backup = '';
          row.last_backup = '';
          row.last_duration_backup = '';
        }

        row.treated_volume = $scope.convert_bytes(row.treated_volume);
        row.last_treated_volume = $scope.convert_bytes(row.last_treated_volume);
        row.treated_object = row.treated_object ? row.treated_object.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';
        agents.push(row);
      });

      return agents;
    };

    
    // ----------------------------------------- Fetch array ----------------------------------------- //
    $scope.fetch_filters = () => {
      Monitoring.refreshFilters('monitoring_yoobackup', data => {
        $scope.filters = data;
      });
    };

    // ***************** Fetch and Set Organizations ***************** //
    $scope.fetch_organizations = () => {
      organizations_endpoint.getList()
        .then(data => {
          $scope.organizations = data;
          $scope.fetch_licences();
        });
    };

    $scope.grid_organizations.onRegisterApi = grid_api => {
      grid_api.selection.on.rowSelectionChanged($scope, () => {
        $scope.fetch_licences();
      });

      $scope.selected_organizations = grid_api;
    };

    
    // ***************** Fetch and Set Licences ***************** //
    $scope.fetch_licences = () => {
      $scope.tina_agents = [];
      $scope.licences = [];
      let organizations = $scope.selected_organizations.selection.getSelectedRows(),
        filters = $scope.set_filter(organizations);

      if (filters) {
        filters = {organization_id: filters};
      }

      licences_endpoint.getList(filters)
        .then(data => {
          $scope.licences = $scope.table_license(data);
        });
    };

    $scope.grid_licences.onRegisterApi = grid_api => {
      grid_api.selection.on.rowSelectionChanged($scope, () => {
        $scope.fetch_tina_type();
      });

      $scope.selected_licences = grid_api;
    };
    
    // ***************** Fetch Tina Type ***************** //
    $scope.fetch_tina_type = () => {
      let licences = $scope.selected_licences.selection.getSelectedRows(),
        filters = $scope.set_filter(licences);
      $scope.tina_agents = [];

      if (filters) {
        filters = {licence_id: filters};
      }
  
      tina_type_endpoint.getList(filters)
        .then(data => {
          let rs = [];
          angular.forEach(data, (v) => {
            rs.push(v);
          });
          $scope.tina_type = $scope.table_tina_type(rs);
        });
    };

    $scope.grid_tina_type.onRegisterApi = grid_api => {
      grid_api.selection.on.rowSelectionChanged($scope, () => {
        $scope.fetch_tina_agents();
      });

      $scope.selected_type = grid_api;
    };

    // ***************** Fetch Tina Agents ***************** //
    $scope.fetch_tina_agents = () => {
      let licences = $scope.selected_licences.selection.getSelectedRows(),
        type = $scope.selected_type.selection.getSelectedRows(),
        filters = $scope.set_filter(licences),
        types = $scope.set_tina_filter(type);
      
      if (filters) {
        // eslint-disable-next-line angular/json-functions
        filters = {licence_id: filters, filters: JSON.stringify(types)};
      }

      tina_agents_endpoint.getList(filters)
        .then(data => {
          $scope.tina_agents = $scope.table_tina(data);
        });
    };

    $scope.grid_tina_agents.onRegisterApi = grid_api => {
      $scope.selected_tina_agents = grid_api;
    };


    // ----------------------------------------- Starting Components ----------------------------------------- //
    $scope.fetch_organizations();
  }
}());
