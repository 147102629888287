'use strict';

angular.module('portailWebApp')
    .controller('ServicesAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        let endpoint = TokenRestAngular.all('admin/services');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'services_admin';
        $rootScope.helpLink = '';
        //Endpoint


        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(data => {
                $scope.gridData = data;
            });
        };
        $scope.refresh();

        $scope.current = [];


        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
            {field: 'cle', displayName: 'key', headerCellFilter: 'translate'}
        ], true, false, 'high');

        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };


        $scope.record = () => {
            let data = {
                'name': $scope.name,
                'cle': $scope.cle
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.name = null;
                $scope.cle = null;
                $scope.creation = false;
            });
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };
    }]);
