angular.module('portailWebApp')
.controller('modalLoadFilterController', ['$scope', '$uibModalInstance', '$translate', 'data', 'TokenRestAngularMS', 'Flash', '_', ($scope, $uibModalInstance, $translate, data, TokenRestAngularMS, Flash, _) => {
    var filters_endpoint = TokenRestAngularMS.all('filters');
    $scope.data = data;
    $scope.delete = row => {
        filters_endpoint.get(row.id).then(row2 => {
            row2.remove().then(response => {
                $scope.data = _.without($scope.data, _.findWhere($scope.data, {id: row2.id}));
                Flash.show(response.success, 'success');
            });
        });
    };
    $scope.ok = row => {
        $uibModalInstance.close(row.id);
    };
    $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
    };
}]);
