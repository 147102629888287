((function () {
  angular.module('portailWebApp')
    .factory('TokenRestAngularMS', TokenRestAngularMsService);

  TokenRestAngularMsService.$inject = ['Restangular', 'localStorageService', '$rootScope'];

  function TokenRestAngularMsService(Restangular, localStorageService, $rootScope) {
    return Restangular.withConfig(config);

    function config(RestangularConfigurer) {
      RestangularConfigurer.setDefaultHeaders({
        Authorization: 'Bearer ' + localStorageService.get('access_token'),
        Membership: localStorageService.get('currMs')
      });
      $rootScope.onMS = $rootScope.$on('MembershipChanged', onMembershipChanged);
      // eslint-disable-next-line no-undef
      RestangularConfigurer.setBaseUrl(Config.get('apiRoot') + '/v1');
      Restangular.addRequestInterceptor(toggleXHR);

      Restangular.addResponseInterceptor(toggleXHR);

      function onMembershipChanged() {
        RestangularConfigurer.setDefaultHeaders({
          Authorization: 'Bearer ' + localStorageService.get('access_token'),
          Membership: localStorageService.get('currMs')
        });
      }
    }

    function toggleXHR(flx) {
      $rootScope.xhr = !$rootScope.xhr;
      return flx;
    }
  }

})());

