/**
 * Created by acorre on 28/06/2016.
 */

((function () {
  angular.module('portailWebApp')
    .factory('RoutesManager', RouteManagerService);

  RouteManagerService.$inject = ['$translate', 'Acl', 'brand', 'userService', '$location'];

  function RouteManagerService($translate, Acl, brand, userService, $location) {
    const
      routesItem = {
        createTicket: '/support/ticketnew/',
        viewTicketsList: '/support/ticketlist/',
        openTicket: '/support/ticketview/',
        kpiTicket: '/support/kpi/',
        synthetic: '/monitoring/synthetic/',
        configFiles: '/tools/ybconfigfiles/',
        delivery: '/tools/delivery/',
        yoobackup: '/monitoring/yoobackup/',
        tina: '/monitoring/tina/',
        lina: '/monitoring/lina/',
        lina_continuity: '/monitoring/continuity/lina/',
        boxes: '/monitoring/boxes/',
        remote: 'tools/boxeaccess/'
      },
      menuItems = [
        // HomePage
        { data: { menu: 'homepage' }, path: '/main', icon: 'dashboard' },

        // Local Administration
        {
          group: 'adminl',
          acl: 'local_admin_users',
          path: '/adminl/userslocaladmin',
          icon: 'supervisor_account',
          data: {
            menu: 'users_admin'
          }
        },
        {
          group: 'adminl',
          acl: 'local_admin_events',
          path: '/adminl/notifsladmin',
          icon: 'event',
          data: {
            menu: 'events_admin'
          }
        },
        {
          group: 'adminl',
          acl: 'local_admin_actions',
          path: '/adminl/actionslocaladmin',
          icon: 'alarm',
          data: {
            menu: 'actions_admin'
          }
        },
        {
          group: 'adminl',
          acl: 'local_admin_license',
          path: '/adminl/license',
          icon: 'storage',
          data: {
            menu: 'license_admin'
          }
        },

        // Monitoring
        {
          group: 'monitoring',
          acl: 'monitoring_synthetic',
          path: '/monitoring/synthetic',
          icon: 'dashboard',
          data: {
            menu: 'monitoring_synthetic'
          }
        },
        {
          group: 'monitoring',
          acl: 'monitoring_boxes',
          path: '/monitoring/boxes',
          // icon: 'all_inclusive',
          icon: 'storage',
          data: {
            menu: 'monitoring_boxes'
          }
        },
        {
          group: 'monitoring',
          acl: 'monitoring_lina',
          path: '/monitoring/lina',
          icon: 'cloud',
          data: {
            menu: 'monitoring_lina'
          }
        },
        {
          group: 'monitoring',
          acl: 'monitoring_yoobackup',
          path: '/monitoring/yoobackup',
          icon: '../../images/products/yoobackup.svg',
          customicon: true,
          data: {
            menu: 'monitoring_yoobackup'
          }
        },
        {
          group: 'monitoring',
          acl: 'monitoring_tina',
          path: '/monitoring/tina',
          icon: '../../images/products/tina.svg',
          customicon: true,
          data: {
            menu: 'monitoring_tina'
          }
        },
        {
          group: 'monitoring',
          acl: 'monitoring_lina_continuity',
          path: '/monitoring/continuity/lina',
          icon: '../../images/products/lina.svg',
          customicon: true,
          data: {
            menu: 'monitoring_lina_continuity'
          }
        },

        // Support
        {
          group: 'support',
          acl: 'ticket_list',
          path: '/support/ticketlist',
          icon: 'list',
          data: {
            menu: 'ticket_list'
          }
        },
        {
          group: 'support',
          acl: 'ticket_create',
          path: '/support/ticketnew',
          icon: 'note_add',
          data: {
            menu: 'ticket_create'
          }
        },
        {
          group: 'support',
          acl: 'support_monitoring_criticalities',
          path: '/support/ticketmonitoring',
          icon: 'assessment',
          data: {
            menu: 'ticket_monitoring'
          }
        },
        {
          group: 'support',
          acl: 'problem_list',
          path: '/support/problemlist',
          icon: 'report_problem',
          data: {
            menu: 'problem_list'
          }
        },
        {
          group: 'support',
          acl: 'kpi_support',
          path: '/support/kpi',
          icon: 'assessment',
          data: {
            menu: 'kpi_support'
          }
        },

        // Reporting
        {
          group: 'reporting',
          acl: 'reporting_inventory',
          path: '/reporting/inventory',
          icon: 'info_outline',
          data: {
            menu: 'inventory_reports'
          }
        },
        {
          group: 'reporting',
          acl: 'reporting_support_statistics',
          path: '/reporting/support/statistics',
          icon: 'assessment',
          data: {
            menu: 'support_statistics_reports'
          }
        },
        {
          group: 'reporting',
          acl: 'reporting_delivery_statistics',
          path: '/reporting/delivery/statistics',
          icon: 'assessment',
          data: {
            menu: 'delivery_statistics_reports'
          }
        },

        // Tools
        {
          group: 'tools',
          acl: 'organization_view',
          path: '/tools/organizationsdirectory',
          icon: 'business',
          data: {
            menu: 'organizations_directory'
          }
        },
        {
          group: 'tools',
          acl: 'view_users',
          path: '/tools/usersdirectory',
          icon: 'contact_phone',
          data: {
            menu: 'users_directory'
          }
        },
        {
          group: 'tools',
          acl: 'yoobackup_configfiles',
          path: '/tools/ybconfigfiles',
          icon: '&#xE862;',
          data: {
            menu: 'yoobackup_configfiles'
          }
        },
        {
          group: 'tools',
          acl: 'view_deliveries',
          path: '/tools/delivery',
          icon: 'search',
          data: {
            menu: 'tools_delivery'
          }
        },
        {
          group: 'tools',
          acl: 'view_documentations',
          path: 'https://docs.atempo.com/continuity',
          icon: 'library_books',
          data: {
            menu: 'doc_directory'
          }
        },
        {
          group: 'tools',
          acl: 'view_remote',
          path: '/tools/boxeaccess',
          icon: 'open_in_browser',
          data: {
            menu: 'remote_access'
          }
        },
        {
          notIncluded: true,
          acl: 'ticket_view',
          path: '/support/ticketview'
        }
      ],
      aclLess = [
        '/profile',
        '/about',
        '/main'
      ],
      menuGroups = [
        { type: 'adminl', icon: 'settings', name: 'local_admin' },
        { type: 'monitoring', icon: 'assessment', name: 'monitoring' },
        { type: 'support', icon: 'headset_mic', name: 'support' },
        { type: 'reporting', icon: 'view_list', name: 'reporting' },
        { type: 'tools', icon: 'build', name: 'tools' }
      ],
      openRoute = [
        '/login',
        '/forget'
      ],
      accessGranted = i => i.acl ? Acl.isView(i.acl) || Acl.isAction(i.acl) : true,
      getRoutes = () => routesItem,
      getMenu = () => menuItems.filter(accessGranted)
        .filter(x => !x.notIncluded)
        .map(i => {
          let txt = $translate.instant(i.data.menu);
          i.data.menu = brand.filter(txt);
          return i;
        }),
      getMenuGroups = () => menuGroups.map(i => {
        let txt = $translate.instant(i.name);
        i.name = brand.filter(txt);
        return i;
      }),
      isOpenRoute = route => {
        let forgetCode = new RegExp(/^\/forget\/.*/);
        return !!route.match(forgetCode) || (openRoute.indexOf(route) + 1);
      },
      isAclLess = route => (aclLess.indexOf(route) + 1),
      secureRoute = (event, route) => {
        /**
         * If the route pattern is'nt found in the whiteList,
         * we check if the user is authenticated
         */
        if (!isOpenRoute(route)) {
          let currentAcl = menuItems.find(x => new RegExp(x.path, 'i').test(route) && x.acl) || false;

          // if the user is not connected we register the target and ask him to log
          if (!userService.isAuth()) {
            $location.path('/login');
          } else if (!currentAcl || (currentAcl.acl && accessGranted(currentAcl))) {
            if (!currentAcl && !isAclLess(route)) {
              userService.isAdmin(true);
            } else {
              userService.checkAuth(event);
            }
          } else {
            event.preventDefault();
          }
        }
      };

    return {
      getMenuGroups: getMenuGroups,
      getMenu: getMenu,
      getRoutes: getRoutes,
      secureRoute: secureRoute,
      isOpenRoute: isOpenRoute
    };

  }

})());


((function () {

  angular.module('portailWebApp')
    //Configuration of Route
    .config(RouteProviderConfig);

  RouteProviderConfig.$inject = ['$routeProvider', '$locationProvider'];

  function RouteProviderConfig($routeProvider, $locationProvider) {
    $routeProvider
      .when('/main', {
        templateUrl: 'scripts/ctrl/ui/main/main.html',
        controller: 'MainController',
        controllerAs: 'main'
      })
      .when('/login', {
        templateUrl: 'scripts/ctrl/ui/login/login.html',
        controller: 'LoginController',
        controllerAs: 'login'
      })
      .when('/logout', {
        templateUrl: 'scripts/ctrl/ui/logout.html',
        controller: 'LogoutController',
        controllerAs: 'logout'
      })
      .when('/profile', {
        templateUrl: 'scripts/ctrl/ui/profile/profile.html',
        controller: 'ProfileController',
        controllerAs: 'profile'
      })
      .when('/forget/:key', {
        templateUrl: 'scripts/ctrl/ui/forget2.html',
        controller: 'Forget2Controller',
        controllerAs: 'forget2'
      })
      .when('/forget', {
        templateUrl: 'scripts/ctrl/ui/forget.html',
        controller: 'ForgetController',
        controllerAs: 'forget'
      })
      .when('/pins', {
        templateUrl: 'scripts/ctrl/ui/pins.html',
        controller: 'PinsController',
        controllerAs: 'pins'
      })
      .when('/about', {
        templateUrl: 'scripts/ctrl/ui/about/about.html',
        controller: 'AboutController',
        controllerAs: 'about'
      })
      .when('/maintenance', {
        templateUrl: 'scripts/ctrl/ui/maintenance.html',
        controller: 'MaintenanceController',
        controllerAs: 'maintenance'
      })
      .when('/uinotifs', {
        templateUrl: 'scripts/ctrl/ui/uinotifs.html',
        controller: 'UiNotifsController',
        controllerAs: 'notifs'
      })
      /* Admin local ******************************/

      .when('/adminl/userslocaladmin', {
        templateUrl: 'scripts/ctrl/adminlocal/admin_local_users.html',
        controller: 'UsersLAdmController',
        controllerAs: 'usersla'
      })
      .when('/adminl/notifsladmin', {
        templateUrl: 'scripts/ctrl/adminlocal/admin_local_notifs.html',
        controller: 'NotifsLAdmController',
        controllerAs: 'notifsla'
      })
      .when('/adminl/actionslocaladmin', {
        templateUrl: 'scripts/ctrl/adminlocal/admin_local_actions.html',
        controller: 'ActionsLAdmController',
        controllerAs: 'actionsla'
      })
      .when('/adminl/license', {
        templateUrl: 'scripts/ctrl/adminlocal/admin_local_license.html',
        controller: 'LicensesLAdmController',
        controllerAs: 'licenseesla'
      })

      /* Monitoring ******************************/
      .when('/monitoring/boxes/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/monit/monitoring_boxes.html',
        controller: 'MonitoringBoxesController',
        controllerAs: 'mbox'
      })

      .when('/monitoring/yoobackup/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/monit/monitoring_yoobackup.html',
        controller: 'MonitoringYoobackupController',
        controllerAs: 'myb'
      })

      .when('/monitoring/tina/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/monit/monitoring_tina.html',
        controller: 'MonitoringTinaController',
        controllerAs: 'mtin'
      })

      .when('/monitoring/lina/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/monit/monitoring_lina.html',
        controller: 'MonitoringLinaController',
        controllerAs: 'mlin'
      })
      .when('/monitoring/continuity/lina/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/monit/monitoring_lina_continuity.html',
        controller: 'MonitoringLinaContinuityController',
        controllerAs: 'mlinctn'
      })
      .when('/monitoring/synthetic/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/monit/monitoring_synthetic.html',
        controller: 'MonitoringSyntheticController',
        controllerAs: 'msynt'
      })

      /* Support *******************************/
      .when('/support/ticketlist/filter/:typefiltre/:param1/:param2', {
        templateUrl: 'scripts/ctrl/support/support_ticket_list.html',
        controller: 'SupportTicketListController',
        controllerAs: 'ticketlist'
      })
      .when('/support/ticketlist/filter/:typefiltre/:param1', {
        templateUrl: 'scripts/ctrl/support/support_ticket_list.html',
        controller: 'SupportTicketListController',
        controllerAs: 'ticketlist'
      })
      .when('/support/ticketlist/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/support/support_ticket_list.html',
        controller: 'SupportTicketListController',
        controllerAs: 'ticketlist'
      })
      .when('/support/ticketnew/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/support/support_ticket_view.html',
        controller: 'SupportTicketViewController',
        controllerAs: 'ticket'
      })
      .when('/support/ticketview/:id', {
        templateUrl: 'scripts/ctrl/support/support_ticket_view.html',
        controller: 'SupportTicketViewController',
        controllerAs: 'ticket'
      })
      .when('/support/ticketmonitoring', {
        templateUrl: 'scripts/ctrl/support/support_ticket_monitoring.html',
        controller: 'SupportTicketMonitoringController',
        controllerAs: 'mTicket'
      })
      .when('/support/kpi', {
        templateUrl: 'scripts/ctrl/support/kpi.html',
        controller: 'SupportTicketKpiController',
        controllerAs: 'kpiTicket'
      })
      .when('/support/problemlist', {
        templateUrl: 'scripts/ctrl/support/problem_list.html',
        controller: 'ProblemListController',
        controllerAs: 'problemList'
      })
      .when('/support/problemview/:id', {
        templateUrl: 'scripts/ctrl/support/problem_view.html',
        controller: 'ProblemViewController',
        controllerAs: 'problem'
      })
      .when('/support/problemview/new', {
        templateUrl: 'scripts/ctrl/support/problem_view.html',
        controller: 'ProblemViewController',
        controllerAs: 'problem'
      })
      /* Reporting **************************/
      .when('/reporting/inventory', {
        templateUrl: 'scripts/ctrl/reporting/inventory.html',
        controller: 'InventoryController',
        controllerAs: 'inv'
      })
      .when('/reporting/support/statistics', {
        templateUrl: 'scripts/ctrl/reporting/support-statistics.html',
        controller: 'SupportStatisticsController',
        controllerAs: 'stats'
      })
      .when('/reporting/delivery/statistics', {
        templateUrl: 'scripts/ctrl/reporting/stats-delivery.html',
        controller: 'DeliveryStatisticsController',
        controllerAs: 'deli'
      })
      /* Tools *******************************/
      .when('/tools/usersdirectory', {
        templateUrl: 'scripts/ctrl/tools/users_directory.html',
        controller: 'UsersDirController',
        controllerAs: 'userdir'
      })
      .when('/tools/organizationsdirectory', {
        templateUrl: 'scripts/ctrl/tools/organizations_directory.html',
        controller: 'OrganizationsDirController',
        controllerAs: 'ticket'
      })
      .when('/tools/ybconfigfiles/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/tools/yb_configfiles.html',
        controller: 'YbConfigFilesController',
        controllerAs: 'ybconfig'
      })
      .when('/tools/delivery/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/tools/tools_delivery.html',
        controller: 'toolsDeliveryController',
        controllerAs: 'delivery'
      })
      .when('/tools/boxeaccess/:filterType?/:filterValue?', {
        templateUrl: 'scripts/ctrl/tools/tools_boxeDirectAccess.html',
        controller: 'toolsRemoteController',
        controllerAs: 'remote'
      })
      .when('/tools/documentation', {
        templateUrl: 'scripts/ctrl/tools/tools_documentation.html',
        controller: 'toolsDocController',
        controllerAs: 'docu'
      })

      /* Admin *****************************/
      .when('/meetingadmin', {
        templateUrl: 'scripts/ctrl/admin/meetingAdm.html',
        controllerAs: 'mad',
        controller: 'MeetingAdmController'
      })
      .when('/i18nvariables', {
        templateUrl: 'scripts/ctrl/admin/admin_i18n_variables.html',
        controller: 'I18NvariablesController',
        controllerAs: 'i18nVar'
      })
      .when('/cmsadmin', {
        templateUrl: 'scripts/ctrl/admin/cms_admin.html',
        controller: 'CmsAdmController',
        controllerAs: 'cmsad'
      })
      .when('/i18nlanguages', {
        templateUrl: 'scripts/ctrl/admin/admin_i18n_languages.html',
        controller: 'I18NlanguagesController',
        controllerAs: 'langs'
      })
      .when('/i18ntranslations', {
        templateUrl: 'scripts/ctrl/admin/admin_i18n_translations.html',
        controller: 'I18NtranslationsController',
        controllerAs: 'trads'
      })
      .when('/organizationsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_organizations.html',
        controller: 'OrganizationsAdmController',
        controllerAs: 'orgaAdm'
      })
      .when('/usersadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_users.html',
        controller: 'UsersAdmController',
        controllerAs: 'userAdm'
      })
      .when('/servicesadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_services.html',
        controller: 'ServicesAdmController',
        controllerAs: 'servAdm'
      })
      .when('/emailsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_emails.html',
        controller: 'EmailsAdmController',
        controllerAs: 'mailAdm'
      })
      .when('/emailtextsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_email_texts.html',
        controller: 'EmailTextsAdmController',
        controllerAs: 'mailTxtAdm'
      })
      .when('/rightsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_rights.html',
        controller: 'RightsAdmController',
        controllerAs: 'rightAdm'
      })
      .when('/relationtypesadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_relationtypes.html',
        controller: 'RelationTypesAdmController',
        controllerAs: 'reltypAdm'
      })
      .when('/membershipsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_memberships.html',
        controller: 'MembershipsAdmController',
        controllerAs: 'msAdm'
      })
      .when('/relationsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_relations.html',
        controller: 'RelationsAdmController',
        controllerAs: 'relAdm'
      })
      .when('/resourcesadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_resources.html',
        controller: 'ResourcesAdmController',
        controllerAs: 'ressAdm'
      })
      .when('/acladmin', {
        templateUrl: 'scripts/ctrl/admin/admin_acl.html',
        controller: 'AclAdmController',
        controllerAs: 'aclAdm'
      })
      .when('/commentsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_comments.html',
        controller: 'CommentsAdminController',
        controllerAs: 'commentsAdm'
      })
      .when('/logcategoriesadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_logcategories2.html',
        controller: 'LogCategoriesAdmController',
        controllerAs: 'logcatAdm'
      })
      .when('/logtypesadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_logtypes.html',
        controller: 'LogTypesAdmController',
        controllerAs: 'logTypAdm'
      })
      .when('/slaleveladmin', {
        templateUrl: 'scripts/ctrl/admin/admin_slalevel.html',
        controller: 'SlaLevelAdmController',
        controllerAs: 'slalevel'
      })
      .when('/notifsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_notifs.html',
        controller: 'NotifsAdmController',
        controllerAs: 'notifsAdm'
      })
      .when('/whitelabelsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_whitelabels.html',
        controller: 'WhitelabelsAdmController',
        controllerAs: 'wlblAdm'
      })
      .when('/actiontypesadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_actiontypes.html',
        controller: 'ActionTypesAdmController',
        controllerAs: 'actTypAdm'
      })
      .when('/templaterelationsadmin', {
        templateUrl: 'scripts/ctrl/admin/template_relations_admin.html',
        controller: 'TemplateRelationsAdmController',
        controllerAs: 'tplRelAdm'
      })
      .when('/firewalladmin', {
        templateUrl: 'scripts/ctrl/admin/admin_firewall.html',
        controller: 'FirewallAdmController',
        controllerAs: 'fwAdm'
      })
      .when('/supportressources', {
        templateUrl: 'scripts/ctrl/admin/admin_supportressources.html',
        controller: 'SupportRessourcesAdmController',
        controllerAs: 'spRessAdm'
      })
      .when('/supportbaskets', {
        templateUrl: 'scripts/ctrl/admin/admin_supportbaskets.html',
        controller: 'SupportBasketsAdmController',
        controllerAs: 'basketAdm'
      })
      .when('/licencesadmin', {
        templateUrl: 'scripts/ctrl/admin/licences_admin.html',
        controller: 'LicencesAdmController',
        controllerAs: 'licencesAdm'
      })
      .when('/docsadmin', {
        templateUrl: 'scripts/ctrl/admin/docs_admin.html',
        controller: 'DocsAdmController',
        controllerAs: 'docsAdm'
      })
      .when('/newsadmin', {
        templateUrl: 'scripts/ctrl/admin/admin_news.html',
        controller: 'NewsAdmController',
        controllerAs: 'newsAdm'
      })
      .when('/contactsadmin', {
        templateUrl: 'scripts/ctrl/admin/contactsAdm.html',
        controller: 'ContactsAdmController',
        controllerAs: 'ctc'
      })
      .when('/blacklistmail', {
        templateUrl: 'scripts/ctrl/admin/admin_blacklistmail.html',
        controller: 'BlacklistMailAdmController',
        controllerAs: 'bkmailAdm'
      })
      .when('/assignbaskets', {
        templateUrl: 'scripts/ctrl/admin/admin_basket_assign.html',
        controller: 'BasketAssignAdmController',
        controllerAs: 'basketAssignAdm'
      })
      .otherwise({
        redirectTo: '/main'
      });
    $locationProvider.html5Mode(true);
  }

})());
