/*eslint-disable angular/controller-as */

((function () {
    angular.module('portailWebApp')
        .controller('ActionsLAdmController', ActionsLAdmController);

    ActionsLAdmController.$inject = ['$scope', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '$translate', '_'];

    function ActionsLAdmController($scope, $rootScope, Flash, TokenRestAngularMS, $timeout, $translate, _) {
        const vm = this,
            tk = TokenRestAngularMS,
            ep = {
                alertList: tk.all('ladmin/alertactions'),
                organizations: tk.all('ladmin/organizations/actions'),
                licences: tk.all('ladmin/licences'),
                events: tk.all('ladmin/logtypes'),
                rigths: tk.all('ladmin/rights'),
                users: tk.all('ladmin/users'),
                actions: tk.all('ladmin/actiontypes')
            };

        $rootScope.isView('local_admin_actions');
        $rootScope.pageClass = '';
        //Layout

        $rootScope.subTitle = 'actions_admin';
        $rootScope.helpLink = 'yooportal/organisation/alertes.html';
        $rootScope.initDropdown('.yp-toolbar');

        vm.newAlert = {};
        vm.inputAlert = {};
        vm.refresh = {
            actions: refreshActions,
            event: refreshEvents,
            licencesOrga: refreshLicencesOrganizations,
            rights: refreshRights,
            users: refreshUsers,
            alertList: refreshAlertList,
            all: refreshAll
        };

        $scope.$watch('newAlert.organizations', watchOrganizationsSelect);


        /**
         * @param alert
         * active le mode edition et genere les liste d'options personalisé
         */
        vm.editAlert = editAlert;

        /**
         * formate l'alerte pour la sauvegarde et supprim les elements d'edition
         * @param {Object} alert objet alerte a traiter
         */
        vm.updateAlert = updateAlert;
        vm.toggleActive = toggleActive;
        vm.delete = deleteAlert;

        // Enregistre l'alerte du formulaire
        vm.record = record;

        // Reinitialise les filtres
        vm.resetFilters = resetFilters;

        // Supprime les données du formulaire de creations d'alerte
        vm.resetForm = resetForm;

        vm.toggleMoreFilters = toggleMoreFilters;

        /* Init */
        refreshAll();

        function editAlert(alert) {
            if (!alert.editMode) {
                alert.editMode = true;
                $scope.$watch(() => alert.alert_rule_organization, (nv, ov) => {
                    let arr = [];
                    if (ov !== nv) {
                        if (nv && nv.length > 0) {
                            nv.forEach(i => {
                                arr.push(_.where(vm.fullListLicences, {
                                    orga_id: i.id
                                }));
                            });
                            alert.updl.licences = _.flatten(arr, true);
                        } else {
                            alert.updl.licences = angular.copy(vm.fullListLicences);
                        }
                        alert.alert_rule_licence = _.intersection(alert.alert_rule_licence, alert.updl.licences);
                    }
                });
            } else {
                alert.editMode = false;
            }
        }

        function updateAlert(alert) {
            delete alert.updl;
            alert.alert_type_id = alert.action;
            alert.save().then(data => {
                refreshAlertList();
                Flash.show(data.success, 'success');
            });
        }

        function deleteAlert(alert) {
            alert.remove();
            $timeout(() => {
                refreshAlertList();
            }, 1500);
        }

        function toggleMoreFilters() {
            vm.moreFilters = !vm.moreFilters;
            resetFilters();
        }

        function toggleActive(alert) {
            alert.active = !alert.active;
            if (alert.editMode) {
                updateAlert(alert);
            } else {
                alert.save();
            }
        }

        function resetFilters() {
            vm.query = {};
        }

        function record() {
            let na = new Alert(vm.newAlert);
            ep.alertList.post(na).then(data => {
                Flash.show(data.success, 'success');
                refreshAlertList();
                vm.resetForm();
                vm.createAlert = !vm.createAlert;
            });
        }

        function refreshActions() {
            ep.actions.getList().then(actions => {
                vm.inputAlert.actions = [];
                actions.forEach(v => {
                    $translate(v.name)
                        .then(trad => {
                            vm.inputAlert.actions.push({
                                id: v.id,
                                name: trad
                            });
                        })
                        .catch(() => {
                            vm.inputAlert.actions.push({
                                id: v.id,
                                name: v.name
                            });
                        });
                });
                vm.newAlert.action = actions[0].id;
            });
        }

        function refreshEvents() {
            ep.events.getList().then(events => {
                vm.inputAlert.events = events;
            });
        }

        function refreshLicencesOrganizations() {
            ep.organizations.getList().then(orga => {
                vm.inputAlert.organizations = _.map(orga, i => {
                    return {
                        id: i.id,
                        name: i.name
                    };
                });
                ep.licences.getList().then(licences => {
                    vm.inputAlert.licences = [];
                    licences.forEach(i => {

                        var currentOrga = _.findWhere(vm.inputAlert.organizations, {
                                id: i.organization_id
                            }),
                            orga_name;
                        if (currentOrga) {
                            orga_name = currentOrga.name;
                        }
                        vm.inputAlert.licences.push({
                            id: i.id,
                            licence: i.licence,
                            orga_id: i.organization_id,
                            orga_name: orga_name
                        });
                    });
                }).then(() => {
                    vm.fullListLicences = angular.copy(vm.inputAlert.licences);
                }).then(() => {
                    refreshAlertList();
                    vm.formDispo = true;
                });
            });
        }

        function refreshRights() {
            ep.rigths.getList().then(rigths => {
                vm.inputAlert.rights = rigths;
            });
        }

        function refreshUsers() {
            ep.users.getList().then(users => {
                let userslist = [];
                users.forEach(v => {
                    if (!(_.findWhere(userslist, {id: v.id}))) {
                        userslist.push({
                            id: v.id,
                            name: v.name
                        });
                    }
                });
                vm.inputAlert.users = userslist;
            });
        }

        function refreshAlertList() {
            ep.alertList.getList().then(alerts => {
                alerts.forEach(val => {
                    let evAr = [],
                        arr = [];
                    val.updl = angular.copy(vm.inputAlert);
                    val.alert_rule_event.forEach(v => {
                        let fn;
                        if (angular.isDefined(v.app_log_type_id)) {
                            fn = _.findWhere(vm.inputAlert.events, {
                                id: v.app_log_type_id
                            });
                            if (fn) {
                                fn = fn.name;
                            }
                            evAr.push({
                                id: v.app_log_type_id,
                                name: fn
                            });
                        }
                        if (v.name) {
                            evAr.push({
                                name: v.name
                            });
                        }
                    });

                    val.alert_rule_event = evAr;

                    val.alert_type = _.findWhere(
                        vm.inputAlert.actions, {
                            id: val.alert_type_id
                        }
                    );
                    val.action = val.alert_type_id;
                    /* Suppression des objets relationel */
                    val.alert_rule_organization = _.map(val.alert_rule_organization, o => {
                        return {
                            id: o.organization.id,
                            name: o.organization.name
                        };
                    });
                    /*
                     * si il y a des organization selectionnée,
                     * on met a jour la liste des licences selectionable
                     */
                    if (val.alert_rule_organization.length) {

                        val.alert_rule_organization.forEach(i => {
                            arr.push(_.where(vm.fullListLicences, {
                                orga_id: i.id
                            }));
                        });
                        val.updl.licences = _.flatten(arr, true);
                    }
                    val.alert_rule_licence = _.map(val.alert_rule_licence, o => {
                        let licence = _.findWhere(val.updl.licences, {
                            id: o.licence.id
                        });
                        return licence;
                    });

                    val.alert_rule_target_user = _.map(val.alert_rule_target_user, o => {
                        return {
                            id: o.user.id,
                            name: o.user.name
                        };
                    });
                    val.alert_rule_target_right = _.map(val.alert_rule_target_right, o => {
                        return {
                            id: o.right.id,
                            name: o.right.name
                        };
                    });
                    val.active = parseInt(val.active, 10);
                });
                vm.alertManagement = alerts;
            });
        }

        function refreshAll() {
            refreshActions();
            refreshEvents();
            refreshRights();
            refreshUsers();
            refreshLicencesOrganizations();
        }

        function resetForm() {
            vm.newAlert = {};
            if (vm.inputAlert.organizations) {
                vm.inputAlert.organizations
                    .forEach(v => {
                        v.ticked = false;
                    });
            }
            if (vm.inputAlert.licences) {
                vm.inputAlert.licences
                    .forEach(v => {
                        v.ticked = false;
                    });
            }
            if (vm.inputAlert.rights) {
                vm.inputAlert.rights
                    .forEach(v => {
                        v.ticked = false;
                    });
            }
            if (vm.inputAlert.users) {
                vm.inputAlert.users
                    .forEach(v => {
                        v.ticked = false;
                    });
            }
            if (vm.inputAlert.actions) {
                vm.newAlert.action = vm.inputAlert.actions[0].id;
            }
            if (vm.inputAlert.events) {
                vm.inputAlert.events.forEach(v => {
                    v.ticked = false;
                });
            }
        }

        function watchOrganizationsSelect(nv) {
            let arr = [];
            if (nv && nv.length > 0) {
                nv.forEach(i => {
                    arr.push(_.where(vm.fullListLicences, {
                        orga_id: i.id
                    }));
                });
                vm.inputAlert.licences = _.flatten(arr, true);
            } else {
                vm.inputAlert.licences = angular.copy(vm.fullListLicences);
            }
            vm.newAlert.licences = _.intersection(vm.newAlert.licences, vm.inputAlert.licences);
        }

        function Alert(o) {
            let getIdArray = t => {
                let nt = [];

                if (t && t.length) {
                    t.forEach(v => {
                        nt.push(v.id);
                    });
                } else {
                    nt = null;
                }
                return nt;
            };
            return {
                id: o.id || null,
                description: o.description,
                alert_rule_organization: getIdArray(o.organizations),
                alert_rule_licence: getIdArray(o.licences),
                alert_rule_event: getIdArray(o.events),
                alert_type_id: o.action,
                alert_rule_target_right: getIdArray(o.rights),
                alert_rule_target_user: getIdArray(o.users),
                active: o.active || 1
            };
        }
    }

})());
