'use strict';

angular.module('portailWebApp')
    .controller('DocsAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', '_', 'Upload', 'localStorageService', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, _, Upload, localStorageService) => {
        let endpoint_services = TokenRestAngular.all('admin/services'),
            endpoint_langs = TokenRestAngular.all('admin/languages'),
            endpoint_docs = TokenRestAngular.all('admin/documentations'),
            endpoint_docsv = TokenRestAngular.all('admin/documentation_versions');

        //Flash
        Flash.clear();
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'docs_admin';
        $rootScope.helpLink = '';
        //Init
        $scope.name = [];
        //endpoints
        endpoint_langs.getList().then(res => {
            angular.forEach(res, row => {
                row.value = null;
            });
            $scope.langs = res;
        });

        endpoint_services.getList().then(res => {
            $scope.services = res;
        });


        //methods
        $scope.refresh = () => {
            endpoint_docs.getList().then(data => {
                angular.forEach(data, row => {
                    row.name = '';
                    angular.forEach(row.translation, row2 => {
                        row.name += row2.name + ' ';
                    });
                });

                $scope.docs = data;
            });
        };
        $scope.refresh();

        $scope.delete = el => {
            el.remove().then(() => {
                $scope.refresh();
            });
        };

        $scope.deleteV = version => {
            endpoint_docsv.get(version.id).then(data => {
                data.remove().then(res => {
                    Flash.show(res.success, 'success');
                    $scope.refresh();
                });
            });
        };

        $scope.upload = (files, doc) => {
            if (files && files.length) {
                files.forEach((file) => {
                    if (doc.i18nlanguage_id) {
                        Upload.upload({
                            // eslint-disable-next-line
                            url: Config.get('apiRoot') + '/v1/admin/documentation_versions',
                            fields: {
                                'document_id': doc.id,
                                'i18nlanguage_id': doc.i18nlanguage_id
                            },
                            headers: {
                                Authorization: 'Bearer ' + localStorageService.get('access_token')
                            },
                            file: file
                        }).success(data => {
                            Flash.show(data.success, 'success');
                            $scope.refresh();
                        }).error(data => {
                            $rootScope.xhr = false;
                            Flash.show(data.error, 'error');
                        });
                    }
                });
            }
        };


        $scope.record = () => {
            let data = {
                'service_id': $scope.service_id,
                'translations': []
            };
            angular.forEach($scope.langs, lang => {
                let translation = {
                    'i18nlanguage_id': lang.id,
                    'name': lang.value
                };
                data.translations.push(translation);
            });
            endpoint_docs.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
            });
        };
    }]);
