angular.module('portailWebApp')
  .controller('modalGraphController', ['$scope', '$uibModalInstance', '$translate', 'data', '$timeout', '$filter', 'moment', ($scope, $uibModalInstance, $translate, data, $timeout, $filter, moment) => {
    $scope.data = data;
    $timeout(() => {
      angular.forEach($scope.data.values, val => {
        var r;
        if (angular.isDefined(val[0]) || angular.isDefined(val[1])) {
          r = val;
        } else {
          $scope.data.values.slice($scope.data.values.indexOf(val));
        }
        return r;
      });
      $scope.graphData = [{
        key: $scope.data.gtitle,
        values: $scope.data.values
      }];
    });

    $scope.formatDate = () => {
      return d => {
        return moment(d).fromNow();
      };
    };

    $scope.formatStatus = () => {
      return s => (s === 2 ? 'on' : (s === 1 ? 'issue' : (s === 0 ? 'off' : null)));
    };

    $scope.formatCapacity = () => {
      return s => {
        return $filter('humanizeFilesize')(s);

      };
    };

    $scope.forceStatus = () => {
      return ['off', 'issue', 'on'];
    };

    $scope.forceCapacity = () => {
      return [0, 100];
    };

    $scope.ok = () => {
      $uibModalInstance.close();
    };
  }]);
