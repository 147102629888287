'use strict';

angular.module('portailWebApp')
    .controller('LogTypesAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        //Endpoint
        let endpoint = TokenRestAngular.all('admin/logtypes'),
            endpoint2 = TokenRestAngular.all('admin/logcategories');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'logtypes_admin';
        $rootScope.helpLink = '';

        //
        $scope.translation = false;

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                $scope.gridData = res;
            });
        };
        $scope.refresh();


        $scope.formCreate = () => {
            $scope.creation = true;
            endpoint2.getList().then(res => {
                $scope.categories = res;
            });
        };
        $scope.update = element => {
            $rootScope.xhr = true;
            element.save();
            $timeout(() => {
                $rootScope.xhr = false;
            }, 1000);
        };

        $scope.updatetrans = () => {
            let endpp = TokenRestAngular.all('admin/logtypetranslations');
            if ($scope.translation.length) {
                $rootScope.xhr = true;
                angular.forEach($scope.translation, item => {
                    angular.forEach(item.translations, trad => {
                        Flash.clear();
                        $rootScope.xhr = true;
                        endpp.post(trad).then(res => {
                            Flash.show(res.success, 'success');
                            $rootScope.xhr = false;
                        });
                    });
                    $scope.translation = false;
                });
            }
        };

        $scope.translate = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            if (cols.length !== 0) {
                $rootScope.xhr = true;
                $scope.translation = [];
                angular.forEach(cols, item => {
                    let endp = TokenRestAngular.all('admin/logtypetranslations/' + item.id);
                    $rootScope.xhr = true;
                    endp.getList().then(res => {
                        $scope.translation.push({
                            'to_translate': item.label,
                            'translations': res
                        });
                        $rootScope.xhr = false;
                    });
                });
            }
        };

        $scope.current = [];

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {
                field: 'applogcategorie.label',
                displayName: 'categories',
                headerCellFilter: 'translate',
                cellEditableCondition: false
            },
            {
                field: 'label',
                displayName: 'label',
                headerCellFilter: 'translate'
            }
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
            $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity => {
                $scope.update(rowEntity);
            });
        };
        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.record = () => {
            let data = {
                'label': $scope.label,
                'app_log_categorie_id': $scope.categorie_id
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.label = null;
                $scope.categorie_id = null;
                $scope.creation = false;
            });
        };
    }]);
