angular.module('portailWebApp')
  .controller('licenceInfosController', ['$scope', 'Flash', 'TokenRestAngularMS', 'moment', 'Monitoring', '_', ($scope, Flash, TokenRestAngularMS, moment, Monitoring, _) => {
    let tk = TokenRestAngularMS,
      ep = {
        licence: tk.oneUrl('support/ressources/licenceinfos')
      },
      processDetails = (response) => {

        $scope.details = response;

        $scope.validWarranty = moment(response.dates.warranty).isAfter(moment());
        $scope.care = response.services && response.services.contracts && /MONCARE/.test(response.services.contracts);
      },
      error = (response) => {
        Flash.show(response);
        // $scope.details = infos;

      },
      getDetails = () => {
        let currentLicence = $scope.$parent.ticket.licence;
        ep.licence
          .get({licence_id: currentLicence.id})
          .then(processDetails)
          .catch(error);
      },
      togglePanel = (panel) => {
        $scope.panels[panel] = !$scope.panels[panel];
      },
      addToTicket = (contact) => {
        let user = _.findWhere($scope.$parent.inputs.users, {phone: contact.phone});
        if (user && !user.disabled) {
          user.disabled = true;
        }
        $scope.$parent.ticket.contacts.push(contact);

      },
      getOrgaDetails = (orgaId) => {
        Monitoring.organization(orgaId);
      };

    $scope.hideempty = false;
    $scope.$parent.$watch('ticket.licence.id', nv => {
      if (nv) {
        getDetails();
      } else {
        $scope.details = null;
      }
    });
    $scope.getOrgaDetails = getOrgaDetails;
    $scope.getDetails = getDetails;
    $scope.panels = {};
    $scope.togglePanel = togglePanel;
    $scope.addToTicket = addToTicket;
  }]);
