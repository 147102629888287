/**
 * Created by acorre on 24/05/2016.
 */

((function () {
    angular.module('portailWebApp')
        .directive('ypRule', ypRuleDirective);


    ypRuleDirective.$inject = ['Flash'];

    function ypRuleDirective(Flash) {

        return {
            restrict: 'E',
            scope: {
                disabled: '=',
                rule: '=data',
                inputs: '=',
                onRemove: '&',
                onSave: '&',
                getFile: '&'
            },
            templateUrl: '../scripts/directives/rule.tpl.html',
            link: link,
            controller: $scope => {
                if ($scope.rule && $scope.disabled) {
                    $scope.rule.disabled = $scope.disabled;
                }
            }
        };

        function link(scope, element) {


            element.on('$destroy', () => {

            });
            scope.remove = remove;
            scope.toggleEdit = edit;
            scope.toggleActive = active;

            function error(res) {
                let failed = res.data.failed,
                    mess = '';
                if (failed) {
                    for (let text in failed) {
                        if (failed.hasOwnProperty(text)) {
                            mess += failed[text] + ' ';
                        }
                    }
                }
                Flash.show(mess, 'warning');
            }
            function remove() {

                if (scope.rule.fromServer) {
                    scope.rule.remove()
                        .then(scope.onRemove());
                } else {
                    scope.onRemove();
                }
            }
            function edit() {
                scope.rule.disabled = !scope.rule.disabled;
                /*
                 We send the save request only if the rule is correctly described
                 */
                if (scope.rule.disabled && scope.ruleForm.$dirty && scope.ruleForm.$valid) {

                    if (!scope.rule.id) {
                        scope.rule.save()
                            .then(id => {
                                scope.rule.id = id;
                            })
                            .then(scope.onSave)
                            .then(scope.ruleForm.$setSubmitted())
                            .catch(error);
                    } else {
                        scope.rule.customPUT()
                            .then(scope.onSave)
                            .then(scope.ruleForm.$setSubmitted())
                            .catch(error);
                    }
                }
            }
            function active() {
                const item = scope.rule;
                item.active = !item.active;
                item.save()
                    .then(i => {
                        scope.rule.id = i;
                    })
                    .catch(error);
            }
        }

    }
})());
