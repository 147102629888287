((function () {
    angular.module('portailWebApp')
        .directive('ngEnter', ngEnterDirective);

    function ngEnterDirective() {
        return (scope, element, attrs) => {
            element.bind('keydown keypress', onKeyDownPress);

            function onKeyDownPress(event) {
                if(event.which === 13) {
                    scope.$apply(() => {
                        scope.$eval(attrs.ngEnter);
                    });

                    event.preventDefault();
                }
            }
        };
    }

})());
