'use strict';

angular.module('portailWebApp')
  .controller('MonitoringYoofindController', ['moment', '$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', '$translate', '$uibModal', 'Monitoring', 'brand', '$routeParams', (moment, $scope, $location, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, $translate, $uibModal, Monitoring, brand, $routeParams) => {

    //Flash
    Flash.clear();
    //Title
    $rootScope.subTitle = 'monitoring ' + brand.trad('yf');
    $rootScope.helpLink = 'yooportal/monitoring/yoofind.html';
    $rootScope.initDropdown('.yp-accordion, .yp-table--dd');
    //endpoint
    let licences_endpoint = TokenRestAngularMS.all('monitoring/yoofind/licences'),
      relations_endpoint = TokenRestAngularMS.all('monitoring/relations'),
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations');

    //filters functions
    $scope.relation_id = null;
    //init
    $scope.organizations = [];
    $scope.relations = [];
    $scope.licences = [];
    $scope.agents = [];
    $scope.filters = [];
    $scope.filters2 = [];
    // Monitoring service
    $scope.refresh_filters = () => {
      Monitoring.refreshFilters('monitoring_yoofind', data => {
        $scope.filters = data;
        if ($scope.filters.length > 0) {
          $scope.loadFilter($scope.filters[0].id);
        }
      });

      $timeout(() => {
        let type = $routeParams.filterType,
          value = $routeParams.filterValue || null;

        if (type) {
          switch (type) {
          case 'offline':
            $scope.filterColumnStatus('off');
            break;
          case 'capacity':
            $scope.filterColumnCapacity(79);
            break;
          case 'licence':
            $scope.filterColumnLicence(value);
            break;
          default:
          }
        }
      }, 1000);
    };
    $scope.loadIt = () => {
      Monitoring.loadFilters('monitoring_yoofind', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //If selection grid1
        var organizations = $scope.gridApi1.selection.getSelectedRows();
        if (organizations.length) {
          data.data.grid1.selection = false;
        }
        //if filters  grid1?
        let columns1 = $scope.gridApi1.saveState.save(), columns2 = $scope.gridApi2.saveState.save();
        angular.forEach(columns1.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid1.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid2?
        angular.forEach(columns2.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid2.columns[i].filters[j].term = filter.term;
            }
          });
        });
        $scope.relation_id = data.data.relation_id;
        $scope.gridApi1.saveState.restore($scope, data.data.grid1);
        $scope.gridApi2.saveState.restore($scope, data.data.grid2);
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('monitoring_yoofind', {
        grid1: $scope.gridApi1.saveState.save(),
        grid2: $scope.gridApi2.saveState.save(),
        relation_id: $scope.relation_id
      });
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };
    //Usefull
    $scope.graph = (type, licence) => {
      Monitoring.graph('yoofind/licences', type, licence.id, licence.licence);
    };

    $scope.getColor = () => $scope.color;

    //refresh functions
    $scope.refresh_licences = () => {
      let organizations = $scope.gridApi1.selection.getSelectedRows(),
        filters = {};
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        return false;
      }
      licences_endpoint.getList(filters).then(data => {
        $scope.licences = [];
        angular.forEach(data, row => {
          row.documents_in_queue = parseInt(row.documents_in_queue, 10);
          row.documents_outstanding = parseInt(row.documents_outstanding, 10);
          row.documents_processed = parseInt(row.documents_processed, 10);
          row.nb_files_to_crawl = parseInt(row.nb_files_to_crawl, 10);
          row.outstanding_exact = !!+row.outstanding_exact;
          row.processed_exact = !!+row.processed_exact;
          row.queue_exact = !!+row.queue_exact;
          row.start_time = moment(row.start_time);
          row.start_time = row.start_time.fromNow();
          if (row.end_time !== 0) {
            row.end_time = moment(row.end_time);
            row.end_time = row.end_time.fromNow();
          }
          if (row.estimated_end_time !== 0) {
            row.estimated_end_time = moment(row.estimated_end_time);
            row.estimated_end_time = row.estimated_end_time.fromNow();
          }
          row.delay = moment.duration(row.delay, 'seconds').humanize();
          row.status = row.status;
          $scope.licences.push(row);
        });
        //calcul
        $scope.count = _.countBy(data, row => {
          return row.status;
        });
      });
      return false;
    };
    $scope.refresh_relations = () => {
      $scope.relations = [];
      relations_endpoint.getList().then(data => {
        $scope.relations = data;
        let vardefault = {id: null, name: '--'};
        $scope.relations.unshift(vardefault);
        $scope.relation_id = vardefault.id;
        $scope.refresh_organizations();
      });
    };

    $scope.refresh_organizations = () => {
      var filters = {};
      $scope.organizations = [];
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      organizations_endpoint.getList(filters).then(data => {
        $scope.organizations = data;
        $scope.refresh_licences();
      });
    };
    //Init
    $scope.refresh_relations();
    $scope.refresh_filters();

    $scope.gridOptions1 = Monitoring.setGridOption('organizations');
    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_licences();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_licences();
      });
      $scope.gridApi1 = gridApi;
    };


    $scope.gridOptions2 = Monitoring.setGridOption('licences', [
      {
        field: 'licence',
        type: 'number',
        displayName: 'licence',
        headerCellFilter: 'translate',
        filter: {
          placeholder: 'licence'
        },
        cellTemplate: `<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><i class="material-icons md-18">infos</i></a></div>`
      },
      {
        field: 'organization',
        displayName: 'organization',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><i class="material-icons md-18">infos</i></a></div>'
      },
      {
        field: 'seller',
        displayName: 'reseller',
        headerCellFilter: 'translate',
        visible: false
      },
      {
        field: 'status',
        displayName: 'status',
        headerCellFilter: 'translate',
        filtering: true,
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: [
            {value: '', name: 'all'},
            {value: 'done', name: 'done'},
            {value: 'running', name: 'running'}
          ],
          condition: customFilter.bool.filterStatus
        },
        cellTemplate: customFilter.i18n.cellTemplate,
        cellClass: (grid, row, col) => {
          return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
        }
      },
      {
        field: 'documents_in_queue',
        type: 'number',
        displayName: 'documents_in_queue',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'documents_in_queue\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}}<i class="material-icons md-18>poll</i>"</div>',
        enableFiltering: true,
        filters: [
          {
            condition: uiGridConstants.filter.GREATER_THAN
          },
          {
            condition: uiGridConstants.filter.LESS_THAN
          }
        ],
        filterHeaderTemplate: customFilter.number.templateMore
      },
      {
        field: 'documents_outstanding',
        type: 'number',
        displayName: 'documents_outstanding',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'documents_outstanding\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}}<i class="material-icons md-18>poll</i></div>',
        enableFiltering: true,
        filters: [
          {
            condition: uiGridConstants.filter.GREATER_THAN
          },
          {
            condition: uiGridConstants.filter.LESS_THAN
          }
        ],
        filterHeaderTemplate: customFilter.number.templateMore
      },
      {
        field: 'documents_processed',
        type: 'number',
        displayName: 'documents_processed',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'documents_processed\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}}<i class="material-icons md-18>poll</i></div>',
        enableFiltering: true,
        filters: [
          {
            condition: uiGridConstants.filter.GREATER_THAN
          },
          {
            condition: uiGridConstants.filter.LESS_THAN
          }
        ],
        filterHeaderTemplate: customFilter.number.templateMore
      },
      {
        field: 'nb_files_to_crawl',
        type: 'number',
        displayName: 'nb_files_to_crawl',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'nb_files_to_crawl\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}}<i class="material-icons md-18>poll</i></div>',
        enableFiltering: true,
        filters: [
          {
            condition: uiGridConstants.filter.GREATER_THAN
          },
          {
            condition: uiGridConstants.filter.LESS_THAN
          }
        ],
        filterHeaderTemplate: customFilter.number.templateMore
      },
      {
        field: 'outstanding_exact',
        type: 'number',
        displayName: 'outstanding_exact',
        headerCellFilter: 'translate',
        filtering: true
      },
      {
        field: 'processed_exact',
        type: 'number',
        displayName: 'processed_exact',
        headerCellFilter: 'translate',
        filtering: true
      },
      {
        field: 'queue_exact',
        type: 'number',
        displayName: 'queue_exact',
        headerCellFilter: 'translate',
        filtering: true
      },
      {
        field: 'start_time',
        type: 'date',
        displayName: 'start_time',
        headerCellFilter: 'translate',
        filtering: true,
        filters: [
          {
            condition: customFilter.date.isBefore
          },
          {
            condition: customFilter.date.isAfter
          }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'end_time',
        type: 'date',
        displayName: 'end_time',
        headerCellFilter: 'translate',
        filtering: true,
        filters: [
          {
            condition: customFilter.date.isBefore
          },
          {
            condition: customFilter.date.isAfter
          }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'estimated_end_time',
        type: 'date',
        displayName: 'estimated_end_time',
        headerCellFilter: 'translate',
        filtering: true,
        filters: [
          {
            condition: customFilter.date.isBefore
          },
          {
            condition: customFilter.date.isAfter
          }
        ],
        filterHeaderTemplate: customFilter.date.template
      },
      {
        field: 'delay',
        type: 'number',
        displayName: 'delay',
        headerCellFilter: 'translate',
        cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'delay\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}}<i class="material-icons md-18>poll</i></div>',
        enableFiltering: true,
        filters: [
          {
            condition: uiGridConstants.filter.GREATER_THAN
          },
          {
            condition: uiGridConstants.filter.LESS_THAN
          }
        ],
        filterHeaderTemplate: customFilter.octet.template
      }
    ], false, true, 'high');
    $scope.gridOptions2.onRegisterApi = gridApi => {
      $scope.gridApi2 = gridApi;
    };
    //Grid operations
    $scope.selectAll = () => {
      $scope.gridApi1.selection.selectAllVisibleRows();
    };

    $scope.clearAll = () => {
      $scope.gridApi1.selection.clearSelectedRows();
    };

    $scope.removeOrganisationFilter = row => {
      $scope.gridApi1.selection.unSelectRow(row);
    };

    $scope.removeLicenceFilter = row => {
      $scope.gridApi2.selection.unSelectRow(row);
    };
    $scope.filterColumnStatus = val => {
      $scope.gridApi2.grid.columns[4].filters[0] = {term: val};
      $scope.gridApi2.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'licence'
      });
      if (col) {
        col.filter.term = val;
        $scope.gridApi2.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };
  }]);
