'use strict';

angular.module('portailWebApp')
    .controller('UiNotifsController', ['$scope', '$rootScope', 'Flash', 'apiRest', '$translate', 'uiGridConstants', ($scope, $rootScope, Flash, apiRest, $translate, uiGridConstants) => {
        var endpoint = apiRest('uialerts', true);

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.subTitle = 'uinotifs';
        $rootScope.helpLink = '';
        //Alerts
        $scope.alerts = [];


        endpoint.getList().then(data => {
            $scope.alerts = data;
        });


        $scope.gridOptions = {
            data: 'alerts',
            columnDefs: [
                {
                    field: 'created_at',
                    cellClass: (grid, row) => {
                        let r;
                        if (row.entity.etat === '1') {
                            r = 'text-primary';
                        }
                        return r;
                    }
                },
                {
                    field: 'text',
                    cellClass: (grid, row) => {
                        let r;
                        if (row.entity.etat === '1') {
                            r = 'text-primary';
                        }
                        return r;
                    }
                }
            ],
            paginationPageSizes: [10, 50, 100, 500, 1000],
            paginationPageSize: 10,
            enableSelection: true,
            enableSorting: true,
            enableGridMenu: true,
            exporterCsvFilename: 'notifications.csv',
            exporterPdfDefaultStyle: {
                fontSize: 9
            },
            exporterPdfTableStyle: {
                margin: [30, 30, 30, 30]
            },
            exporterPdfTableHeaderStyle: {
                fontSize: 10,
                bold: true,
                italics: true,
                color: 'red'
            },
            exporterPdfHeader: {
                text: 'Notifications',
                style: 'headerStyle'
            },
            exporterPdfFooter: (currentPage, pageCount) => {
                return {
                    text: currentPage.toString() + ' of ' + pageCount.toString(),
                    style: 'footerStyle'
                };
            },
            exporterPdfCustomFormatter: docDefinition => {
                docDefinition.styles.headerStyle = {
                    fontSize: 22,
                    bold: true
                };
                docDefinition.styles.footerStyle = {
                    fontSize: 10,
                    bold: true
                };
                return docDefinition;
            },
            modifierKeysToMultiSelectCells: true,
            exporterPdfOrientation: 'landscape',
            exporterPdfPageSize: 'LETTER',
            exporterPdfMaxGridWidth: 500,
            exporterCsvLinkElement: angular.element('.custom-csv-link-location'),
            onRegisterApi: gridApi => {
                $scope.gridApi = gridApi;
            }
        };

        $scope.readThis = () => {
            angular.forEach($scope.gridApi.selection.getSelectedRows(), row => {
                if (row.etat === '1') {
                    $rootScope.readAlert(row, true);
                    row.etat = 2;
                    $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
                }
            });
        };

    }]);
