(function () {

  angular.module('portailWebApp')
    .controller('MonitoringLinaController', MonitoringLinaController);

  MonitoringLinaController.$inject = ['$scope', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', 'Monitoring', '$window', 'brand', '$routeParams', '$log', '$filter'];

  function MonitoringLinaController($scope, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, Monitoring, $window, brand, $routeParams, $log, $filter) {
    //IS auth and admin
    // jobs_endpoint = TokenRestAngularMS.all('monitoring/lina/jobs'),
    const licences_endpoint = TokenRestAngularMS.all('monitoring/lina/licences'),
      agents_endpoint = TokenRestAngularMS.all('monitoring/lina/agents'),
      relations_endpoint = TokenRestAngularMS.all('monitoring/relations'),
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations'),
      gendpoint = TokenRestAngularMS.all('monitoring/lina/licences/remote'),
      griddefs = {
        licences: [
          {
            field: 'licence',
            type: 'number',
            displayName: 'licence',
            headerCellFilter: 'translate',
            filter: {
              placeholder: 'licence'
            },
            cellTemplate: `<yp-fill-search class="yp-grid__btn ui-grid-cell-contents" terms="{{row.entity.licence}}">{{COL_FIELD CUSTOM_FILTERS}}</yp-fill-search>`
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified"  class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><span class="glyphicon glyphicon-info-sign"></a></span></div>'
          },
          {
            field: 'seller',
            displayName: 'reseller',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certifiedSeller ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certifiedSeller" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.seller }}</div>'
          },
          {
            field: 'updated_at',
            type: 'date',
            displayName: 'updated_at',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            // Missing LicenceStatus in api
            // cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            cellTemplate: '<div class="ui-grid-cell-contents"><i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          }
        ],
        agents: [
          {
            field: 'licence_agent',
            displayName: 'licence_agent',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <pin-it name="lina_agents_status" data="row.entity"></pin-it></div>'
          },
          {
            field: 'licence',
            displayName: 'licence',
            headerCellFilter: 'translate'
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified"  class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><span class="glyphicon glyphicon-info-sign"></a></span></div>'
          },
          {
            field: 'expiration',
            type: 'date',
            displayName: 'expiration',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.dateDif(grid.getCellValue(row, col), 30);
            }
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            // Missing LicenceAgentStatus in api
            // cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph2(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            cellTemplate: '<div class="ui-grid-cell-contents"><i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'cloud_last_date',
            displayName: 'cloud_last_date',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [{ condition: customFilter.date.isBefore }, { condition: customFilter.date.isAfter }],
            filterHeaderTemplate: customFilter.date.template,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.dateBet(grid.getCellValue(row, col), 5, 15);
            }
          },
          {
            field: 'updated_at',
            displayName: 'updated_at',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [{ condition: customFilter.date.isBefore }, { condition: customFilter.date.isAfter }],
            filterHeaderTemplate: customFilter.date.template,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.dateBet(grid.getCellValue(row, col), 5, 15);
            }
          },
          // {
          //   field: 'seller',
          //   displayName: 'reseller',
          //   headerCellFilter: 'translate'
          // },
          {
            field: 'cloud_size_cloud',
            type: 'number',
            displayName: 'cloud_size_cloud',
            headerCellFilter: 'translate',
            // Missing LicenceAgentStatus in api
            // cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'cloud_size_cloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }} <i class="material-icons md-18">poll</i></div>',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}</div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'cloud_size_quota',
            displayName: 'cloud_size_quota',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}</div>',
            enableFiltering: true,
            //cellFilter :
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'cloud_usage',
            type: 'number',
            displayName: 'cloud_usage',
            headerCellFilter: 'translate',
            enableFiltering: true,
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD}} %</div>',
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.degress(grid.getCellValue(row, col));
            }
          },
          {
            field: 'name',
            displayName: 'name',
            headerCellFilter: 'translate'
          }
        ]
      };

    //Layout
    $rootScope.pageClass = '';

    $rootScope.initDropdown('.yp-table--dd');
    $rootScope.initDropdown('.yp-accordion', {
      closeOut: true
    });

    //Title
    $rootScope.subTitle = 'monitoring ' + brand.trad('li');

    $rootScope.helpLink = 'yooportal/monitoring/lina.html'; // vince: comment this line to remove help link in topbar top right menu
    //endpoint

    //filters functions
    $scope.relation_id = null;
    //init
    $scope.organizations = [];
    $scope.relations = [];
    $scope.licences = [];
    $scope.agents = [];
    $scope.filters = [];
    $scope.filters2 = [];
    // Monitoring service
    $scope.refresh_filters = () => {
      Monitoring.refreshFilters('monitoring_lina', data => {
        $scope.filters = data;
        if ($scope.filters.length > 0) {
          $scope.loadFilter($scope.filters[0].id);
        }

        $timeout(() => {
          let type = $routeParams.filterType,
            value = $routeParams.filterValue || null;

          if (type) {
            switch (type) {
              case 'offline':
                $scope.filterColumnStatus('off');
                break;
              case 'capacity':
                $scope.filterColumnCapacity(78);
                break;
              case 'licence':
                $scope.filterColumnLicence(value);
                break;
              default:
            }
          }
        }, 1000);
      });
    };
    $scope.loadIt = () => {
      Monitoring.loadFilters('monitoring_lina', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //If selection grid1
        var organizations = $scope.gridApi1.selection.getSelectedRows(),
          columns1 = $scope.gridApi1.saveState.save(),
          columns2 = $scope.gridApi2.saveState.save(),
          columns3 = $scope.gridApi3.saveState.save();
        if (organizations.length) {
          data.data.grid1.selection = false;
        }
        //if filters  grid1?


        angular.forEach(columns1.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid1.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid2?
        angular.forEach(columns2.columns, (item, i) => {

          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid2.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid3?

        angular.forEach(columns3.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
             if (filter.term) {
              data.data.grid3.columns[i].filters[j].term = filter.term;
            }
          });
        });

        //if filters  grid3?

        $scope.relation_id = data.data.relation_id;
        if (data.data.grid1) {
          $scope.gridApi1.saveState.restore($scope, data.data.grid1);
        }
        if (data.data.grid2) {
          $scope.gridApi2.saveState.restore($scope, data.data.grid2);
        }
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('monitoring_lina', {
        grid1: $scope.gridApi1.saveState.save(),
        grid2: $scope.gridApi2.saveState.save(),
        grid3: $scope.gridApi3.saveState.save(),
        grid4: $scope.gridApi4.saveState.save(),
        relation_id: $scope.relation_id
      });
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };
    //Usefull
    $scope.graph = (type, licence) => {
      Monitoring.graph('lina/licences', type, licence.id, licence.licence);
    };
    $scope.graph2 = (type, licence) => {
      Monitoring.graph('lina/agents', type, licence.id, licence.name);
    };

    $scope.remote = licence => {
      gendpoint.get(licence.id).then(data => {
        $window.open(data.url, '_blank');
      });
    };
    //refresh functions
    $scope.refresh_licences = () => {
      let organizations = $scope.gridApi1.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (r) {
        licences_endpoint.getList(filters).then(data => {
          $scope.licences = [];
          angular.forEach(data, row => {
            row.status = Monitoring.convert_status(row.status);
            // row.local_size = parseInt(row.local_size, 10);
            // row.local_number = parseInt(row.local_number, 10);
            // row.cloud_size_quota = parseInt(row.cloud_size_quota, 10);
            // row.cloud_size_cloud = parseInt(row.cloud_size_cloud, 10);
            // row.cloud_size_cloud = parseInt(row.cloud_size_cloud, 10);
            // row.cloud_usage = Math.round((1 - (row.cloud_size_cloud / (row.cloud_size_cloud + row.cloud_size_cloud))) * 10000) / 100;
            // row.cloud_usage = row.cloud ? Math.round(row.cloud_size_cloud / row.cloud_size_quota * 100) : null;

            if (!row.cloud_usage) {
              row.cloud_usage = 100;
            }
            row.cloud_number_cloud = parseInt(row.cloud_number_cloud, 10);
            row.cloud_number_tocloud = parseInt(row.cloud_number_tocloud, 10);
            row.cloud = !!+row.cloud;
            row.box2box = !!+row.box2box;
            $scope.licences.push(row);
          });
          //calcul
          $scope.count = _.countBy(data, row => {
            return row.status;
          });
        });
      }
      return r;
    };

    $scope.refresh_agents = () => {
      let organizations = $scope.gridApi1.selection.getSelectedRows(),
        licences = $scope.gridApi2.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (licences.length > 0 && licences.length <= 100) {
        filters.licence_id = '';
        angular.forEach(licences, (item, j) => {
          filters.licence_id = filters.licence_id + item.id;
          if (j !== licences.length) {
            filters.licence_id = filters.licence_id + ',';
          }
        });
      } else if (licences.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      $scope.agents = [];
      if (r && filters.licence_id) {
        agents_endpoint.getList(filters).then(data => {
          angular.forEach(data, row => {
            row.cloud_size_cloud = parseInt(row.cloud_size_cloud, 10);//  *10000;
            row.cloud_size_quota = parseInt(row.cloud_size_quota, 10);
            row.status = Monitoring.convert_status(row.status);
            row.cloud_usage = Math.round( row.cloud_size_cloud / row.cloud_size_quota * 100 ) ;
            row.cloud_usage = row.cloud_usage > 100 ? 100 : row.cloud_usage ;
            row.expiration = $filter('fromNow')(row.expiration);
            $scope.agents.push(row);
            $log.log('');
          });
          $scope.countAgent = _.countBy(data, row => {
            return row.status;
          });
        });
      }
      return r;
    };
    $scope.refresh_relations = () => {
      $scope.relations = [];
      relations_endpoint.getList().then(data => {

        var vardefault = {
          id: null,
          name: '--'
        };
        $scope.relations = data;
        $scope.relations.push(vardefault);
        $scope.relation_id = vardefault.id;
        $scope.refresh_organizations();
      });
    };
    $scope.refresh_organizations = () => {

      let filters = {};
      $scope.organizations = [];
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      organizations_endpoint.getList(filters).then(data => {
        $scope.organizations = data;
        $scope.refresh_licences();
      });
    };
    //Init
    $scope.refresh_relations();
    $scope.refresh_filters();
    //Grids

    /* Organizations  */

    $scope.gridOptions1 = Monitoring.setGridOption('organizations');
    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_licences();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_licences();
      });
      $scope.gridApi1 = gridApi;
    };
    /*Licences*/

    $scope.gridOptions2 = Monitoring.setGridOption('licences', griddefs.licences, true, true);
    $scope.gridOptions2.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_agents();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_agents();
      });
      $scope.gridApi2 = gridApi;
    };


    $scope.gridOptions3 = Monitoring.setGridOption('agents', griddefs.agents, true, true);

    //Grid operations
    $scope.selectAll = () => {
      $scope.gridApi1.selection.selectAllVisibleRows();
    };

    $scope.clearAll = () => {
      $scope.gridApi1.selection.clearSelectedRows();
    };

    $scope.removeOrganisationFilter = row => {
      $scope.gridApi1.selection.unSelectRow(row);
    };

    $scope.removeLicenceFilter = row => {
      $scope.gridApi2.selection.unSelectRow(row);
    };
    $scope.filterColumnStatus = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'status'
      });
      col.filters[0].term = val;
      $scope.gridApi2.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnStatus2 = val => {
      var col = _.find($scope.gridApi3.grid.columns, {
        field: 'status'
      });
      col.filters[0].term = val;
      $scope.gridApi3.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'licence'
      });
      if (col) {
        col.filter.term = val;
        $scope.gridApi2.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };

  }

}());

