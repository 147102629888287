'use strict';

angular.module('portailWebApp')
    .controller('FirewallAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring) => {
        let endpoint = TokenRestAngular.all('admin/firewall');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'firewall_admin';
        $rootScope.helpLink = '';
        //Endpoint

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(data => {
                $scope.gridData = data;
            });
        };
        $scope.refresh();

        $scope.current = [];

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
                {field: 'ip_address', displayName: 'ip_address', headerCellFilter: 'translate'}
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };

        $scope.record = () => {
            let data = {
                'ip_address': $scope.ip_address
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.ip_address = null;
                $scope.creation = false;
            });
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };
    }]);
