/* eslint-disable no-undef */
'use strict';
var angular,
  Accordion,
  api,
  pr,
  xdomain,
  slave = {},
  _environments = {
    // DEFAULT ENV
    default: {
      host: location.origin,
      config: {
        branding: 'wooxo',
        apiClientId: 'Rn86jKb5djCaAjHG',
        apiClientSecret: 'X5FvOng7yr7qw7GKVFWvFsLb0vRT1FUZ',
        apiRoot: location.origin.replace('yooportal.', 'yooportal-api.')
      }
    },

    // DOCKER_LOCAL ENV
    docker: {
      host: 'localhost',
      config: {
        branding: 'wooxo',
        apiClientId: 'Rn86jKb5djCaAjHG',
        apiClientSecret: 'X5FvOng7yr7qw7GKVFWvFsLb0vRT1FUZ',
        apiRoot: 'http://localhost:1234'
      }
    },

    // DEV ENV
    dev: {
      host: 'ypdev.atempo.dev',
      config: {
        branding: 'wooxo',
        apiClientId: 'Rn86jKb5djCaAjHG',
        apiClientSecret: 'X5FvOng7yr7qw7GKVFWvFsLb0vRT1FUZ',
        apiRoot: 'https://api-ypdev.atempo.dev/'
      }
    },

    // QA ENV
    qa: {
      host: 'yooportal.atempo.dev',
      config: {
        branding: 'atempo',
        apiClientId: 'Rn86jKb5djCaAjHG',
        apiClientSecret: 'X5FvOng7yr7qw7GKVFWvFsLb0vRT1FUZ',
        apiRoot: 'https://api-smb.atempo.dev',
        prod: true
      }
    },

    // PROD ENV WOOXO
    prod: {
      host: 'yooportal.wooxo.fr',
      config: {
        branding: 'wooxo',
        apiClientId: 'Rn86jKb5djCaAjHG',
        apiClientSecret: 'X5FvOng7yr7qw7GKVFWvFsLb0vRT1FUZ',
        apiRoot: 'https://api.wooxo.fr',
        prod: true
      }
    },

    // PROD ENV ATEMPO
    prodatempo: {
      host: 'yooportal.atempo.com',
      config: {
        branding: 'atempo',
        apiClientId: 'Rn86jKb5djCaAjHG',
        apiClientSecret: 'X5FvOng7yr7qw7GKVFWvFsLb0vRT1FUZ',
        apiRoot: 'https://api-smb.atempo.com',
        prod: true
      }
    }
  },
  Config = {
    getEnvironment: function () {
      // eslint-disable-next-line angular/window-service
      var env, _environment, host = window.location.hostname;
      if (_environment) {
        return _environment;
      }

      for (env in _environments) {
        if (
          _environments.hasOwnProperty(env) && typeof _environments[env].host && _environments[env].host === host
        ) {
          _environment = env;
          return _environment;
        }
      }

      return 'default';
    },
    get: function (property) {
      return _environments[this.getEnvironment()].config[property];
    }
  };
// eslint-disable-next-line angular/document-service
if (document.querySelector('html.ie9')) {
  api = Config.get('apiRoot');
  pr = '/proxy.html';
  slave = Object.create();
  slave[api] = pr;
  xdomain.slaves(slave);
}

angular
  .module('portailWebApp', [
    'ngCookies',
    'ngSanitize',
    // 'ngRaven',
    'ngAria',
    'ngRoute',
    'ngAnimate',
    'ngTouch',
    'restangular',
    'pascalprecht.translate',
    'LocalStorageModule',
    'ui.grid',
    'ui.grid.exporter',
    'ui.grid.selection',
    'ui.grid.edit',
    'ui.grid.pagination',
    'ui.grid.resizeColumns',
    'ui.grid.saveState',
    'ui.grid.cellNav',
    'ui.grid.moveColumns',
    'ui.grid.pinning',
    'ui.grid.autoResize',
    'ui.bootstrap',
    'ui.calendar',
    'ui.select',
    'angularMoment',
    'underscore',
    'nvd3ChartDirectives',
    'pageslide-directive',
    'angular-humanize',
    'ngFileUpload',
    'angulartics',
    'angulartics.google.analytics',
    'ui.bootstrap.datetimepicker',
    'angular-loading-bar',
    'angular-clipboard',
    'LocalForageModule',
    'ngMaterial',
    'ngMessages'
  ])
  .run(['$rootScope', '$log', '$location', '$route', 'localStorageService',
    '$timeout', 'TokenRestAngular', 'apiRest', 'TokenRestAngularMS', 'Flash',
    'Restangular', '$q', '$translate', '$anchorScroll', '$window',
    'Acl', '$uibModal', 'Pin', 'i18nService', 'moment',
    'amMoment', '$http', '_', '$document', 'QuickAccess', 'brand', 'userService', 'RoutesManager',
    ($rootScope, $log, $location, $route, localStorageService, $timeout, TokenRestAngular, apiRest, TokenRestAngularMS, Flash, Restangular, $q, $translate, $anchorScroll, $window, Acl, $uibModal, Pin, i18nService, moment, amMoment, $http, _, $document, QuickAccess, brand, userService, RoutesManager) => {
      let errorInterceptor,
        forceSSL = () => {
          let env = Config.getEnvironment();
          if ($location.protocol() !== 'https' && env !== 'docker') {
            $window.location.href = $location.absUrl().replace('http', 'https');
          }
        },
        performAuthCheck = (event, newUrl, oldUrl) => {

          // let current = prev && prev.$$route ? prev.$$route.originalPath : '',
          //   target = next && next.$$route ? next.$$route.originalPath : '';
          $rootScope.showNav = false;
          if (event && oldUrl !== newUrl) {
            RoutesManager.secureRoute(event, $location.url());
          }
        };

      $rootScope.title = 'YooPortal';
      $rootScope.subTitle = '';
      $rootScope.ie9Proxy = Config.get('apiRoot');
      /* SSL FORCING */
      forceSSL();
      Pin.clean();
      /* Version setting */
      $http.get('/version.json').success(data => {
        $rootScope.version = data.version;
      });

      /*
             * Accordion initiation function
             * @param   {string} sl      css selector of the wanted nodes
             * @param   {Object} options overide options for this nodes
             * @returns {Array}  Array of Accordion object allowing to access specific method for each
             *                   ( cf. doc js-a11y plugin for options and method )
             */
      $rootScope.initDropdown = (sl, options) => {
        var acc = [],
          dd, ic;
        sl = sl || '.yp-content .yp-dropdown';
        dd = $document.querySelectorAll(sl);

        for (ic = 0; ic < dd.length; ic = ic + 1) {
          if (!options) {
            options = {
              target: dd[ic],
              multi: false,
              closeOut: true
            };
          } else {
            options.target = dd[ic];
          }
          options.target = dd[ic];
          acc.push(new Accordion(options));
        }
        return acc;
      };
      $rootScope.initDropdown('.yp-mheader__toolbar', {
        multi: false,
        closeOut: true
      });

      /**
             * toggleMore
             * @param {Object} $event Event object of the emiter element
             *
             *  change aria-expanded state of the next sibling element from true to false or the other way arround
             */
      $rootScope.toggleMore = $event => {
        let e = $event.target.nextElementSibling,
          state = e.hasAttribute('aria-expanded') ? e.getAttribute('aria-expanded') : false,
          newState = (state !== 'true');
        angular.element('.yp-mheader [aria-expanded], .yp-nav [aria-expanded]').attr('aria-expanded', false);
        e.setAttribute('aria-expanded', newState);
      };
      /* --- */
      $rootScope.showReminder = false;
      $rootScope.showNav = false;

      $rootScope.panelStatus = false;

      $rootScope.locationChange = $rootScope.$on('$locationChangeStart', performAuthCheck);

      $rootScope.currentLang = i18nService.getCurrentLang();

      $rootScope.openPanel = () => {
        $rootScope.showReminder = !$rootScope.showReminder;
      };

      $rootScope.toggleNav = () => {
        $rootScope.showNav = !$rootScope.showNav;
      };
      $rootScope.clearPanel = () => {
        Pin.clean();
      };

      $rootScope.windowPanel = () => {
        $window.$windowScope = $rootScope;
        $rootScope.openPanel();
        $window.open('#/pins', 'New Window', 'width=1280,height=890,resizable=1');
      };
      $rootScope.deletePin = pin => {
        Pin.remove(pin);
      };


      /**
             * QuickAccess context executer
             * **********************************************
             *
             * provide the component with data from outside
             *
             * list : list to process
             * placeholder : what to display in the textinput
             * trigger which action perform when passed a value from the list
             * (look for a trigger property into the item)
             * @type {{list, placeholder: *, trigger: *}}
             */
      $rootScope.qa = QuickAccess;

      /**
             * Allow using brandService from the view
             * @type {brand|*}
             */
      $rootScope.brand = brand;

      apiRest('lang/languages')
        .customGET('')
        .then(data => {
          $rootScope.languages = data.langs;
        });

      //gototop

      $rootScope.goToTop = () => {
        // set the location.hash to the id of
        // the element you wish to scroll to.
        $location.hash('top');

        // call $anchorScroll()
        $anchorScroll();
      };
      //Flash clear
      $rootScope.closeAlert = () => {
        Flash.clear();
      };
      errorInterceptor = response => {
        $rootScope.xhr = false;
        const
          saveWanted = (cr, res) => {
            if (cr !== '/login' && cr !== '/main') {
              $log.warn('need to be loggedIn to access the page :', cr, res.config.url);
              $rootScope.wantedUrl = $rootScope.wantedUrl || cr;
            } else {
              if (res.data && res.data.error === 'invalid_credentials') {
                Flash.show(res.data.error);
              }
              $rootScope.wantedUrl = $rootScope.wantedUrl || null;
            }
          },
          /**
                     * Manage unauthorized error response
                     * @param res
                     */

          unauthorized = res => {

            let currentRoute = $location.url();
            if (res.config.url === Config.get('apiRoot') + '/v1/accounts') {
              //if we have a unauthorized response with the account endpoint,
              // the token is probably expired so we force logout to reset
              // localstorage an redirect to the login view
              saveWanted(currentRoute, res);
              userService.logout();
            } else if (!userService.isAuth()) {
              // if the user is not connected we register the target and ask him to log
              saveWanted(currentRoute, res);
              $location.url('/login');
            } else {
              // otherwise we only display an error message
              // and redirect him to main
              $log.log('redirect to main');
              Flash.show(res.data.error);
              $location.url('/main');
            }
          },
          notFound = res => {
            Flash.show(res.data.error);
          },
          maintenance = res => {
            $rootScope.lastErrorStatus = res.status;
            $location.url('/maintenance');
          };
        switch (response.status) {
        case 401:
          unauthorized(response);
          break;
        case 404:
          notFound(response);
          break;
        case 0:
        case 503:
          maintenance(response);
          break;
        default:
          /*if (response.data && response.data.error) {
                                                                   let message = response.data.error.message || response.data.error;
                                                                   Flash.show(message);
                                                                   }*/
          return response;
        }
        return false;
        // stop the promise chain
      };
      //interceptors
      Restangular.setErrorInterceptor(errorInterceptor);
      TokenRestAngularMS.setErrorInterceptor(errorInterceptor);
      TokenRestAngular.setErrorInterceptor(errorInterceptor);

      $rootScope.openModal = (size, controller, partial, data) => {
        $uibModal.open({
          templateUrl: 'scripts/ctrl/modal/' + partial,
          controller: controller,
          size: size,
          backdrop: 'static',
          resolve: {
            data: function () {
              return data;
            }
          }
        });
      };

      //Variables
      $rootScope.user = {};
      $rootScope.currMs = {};

      //Useful methods
      $rootScope.redirector = to => {
        $log.log('navigate to ' + to);
        $location.url(to);
      };
      $rootScope.scrollTo = to => {
        $anchorScroll(to);
      };
      $rootScope.setfocusOn = frag => {
        angular.element(frag).focus();
      };
      $rootScope.isOpenRoute = () => {
        return RoutesManager.isOpenRoute($location.path());
      };
      //Change current membership
      $rootScope.changeCurrentMembership = (ms, notif) => {
        if (($rootScope.currMs && ms) && ($rootScope.currMs.id !== ms.id) && userService.isAuth()) {
          $rootScope.currMs = ms;
          localStorageService.set('currMs', ms.id);
          if (notif) {
            Flash.show(ms.organization + ' : ' + ms.right, 'info');
          }
          brand.setBrand(ms.branding);
          Acl.construct(ms.rights);
          $rootScope.$broadcast('MembershipChanged');
          QuickAccess.fetch();
          Flash.clear();
        }
      };

      $rootScope.changeCurrentMembershipView = ms => {
        $rootScope.changeCurrentMembership(ms);
        $rootScope.xhr = true;
        $timeout(() => {
          //$window.location.reload();
          $route.reload();
          $rootScope.xhr = false;
        }, 1000);
      };

      $rootScope.readAllAlerts = alerts => {
        alerts.forEach(alert => {
          $rootScope.readAlert(alert, true);
        });
      };

      $rootScope.readAlert = (alert, count) => {
        let alertEndpoint = apiRest('uialerts', true);
        alertEndpoint.post({
          id: alert.id
        }).then(() => {
          if ($rootScope.user.nb_alerts_unread > 0 && count) {
            $rootScope.user.nb_alerts_unread -= 1;
          }
          $rootScope.user.alerts = _.without($rootScope.user.alerts, _.findWhere($rootScope.user.alerts, {
            id: alert.id
          }));
        });
      };

      //Choose the right language
      $rootScope.chooseRightLanguage = lang_id => {
        angular.forEach($rootScope.languages, lang => {
          if (lang.id === lang_id) {
            i18nService.setCurrentLang(lang.code);
            moment.locale(lang.code);
            amMoment.changeLocale(lang.code);

            $rootScope.currentLang = lang.code;
            $translate.use(lang.code);
          }
        });
      };

      /*User method assignation*/
      $rootScope.userService = userService;
      // on init we need to check auth information
      if (!RoutesManager.isOpenRoute($location.url())) {
        userService.checkAuth();
      }

      /* ACL assignation */
      $rootScope.isAction = Acl.isAction;
      $rootScope.isView = Acl.isView;
      $rootScope.isMenu = Acl.isMenu;
      $rootScope.refresh_view = () => {
        $route.reload();
      };

      brand.setBrand();
    }
  ])
//Unactive spinner loading bar
  .config(['cfpLoadingBarProvider', cfpLoadingBarProvider => {
    cfpLoadingBarProvider.includeSpinner = false;
  }])

//Localstorageconfig
  .config(['localStorageServiceProvider', localStorageServiceProvider => {
    localStorageServiceProvider
      .setPrefix('yooPortal');
  }])

//Configuration of translate module
  .config(['$translateProvider', $translateProvider => {
    $translateProvider
      .useUrlLoader(Config.get('apiRoot') + '/lang/translations')
      .useLocalStorage()
      .fallbackLanguage('fr')
      .useSanitizeValueStrategy('escape')
      .determinePreferredLanguage();
  }])
  .config(['$localForageProvider', $localForageProvider => {
    //eslint-disable-next-line angular/module-getter
    $localForageProvider.config({
      name: 'YooPortal'
      // name of the database and prefix for your data, it is "lf" by default
    });
  }])
//Configuration of RestAngular
  .config(['RestangularProvider', RestangularProvider => {
    RestangularProvider.setDefaultHeaders({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    });
    RestangularProvider.setBaseUrl(Config.get('apiRoot'));
  }])
// Protocol whitelist
  .config(['$compileProvider', $compileProvider => {
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|chrome-extension|ssh|tel|webcal):/);
    $compileProvider.debugInfoEnabled(!Config.get('prod'));
  }]);

/* eslint-disable */
(() => {
    if (!Array.prototype.find) {
        Array.prototype.find = function(predicate) {
            if (this === null) {
                throw new TypeError('Array.prototype.find a été appelé sur null ou undefined');
            }
            if (typeof predicate !== 'function') {
                throw new TypeError('predicate doit être une fonction');
            }
            var list = Object(this),
                length = list.length >>> 0,
                thisArg = arguments[1],
                value;

            for (var i = 0; i < length; i = i + 1) {
                value = list[i];
                if (predicate.call(thisArg, value, i, list)) {
                    return value;
                }
            }
            return undefined;
        };
    }
})();
/* eslint-enable */
