((function () {
    angular.module('underscore', [])
        .factory('_', _);

    _.$inject = ['$window'];

    function _($window) {
        // eslint-disable-next-line angular/window-service
        return $window._;
        // assumes underscore has already been loaded on the page
    }

})());
