angular.module('portailWebApp')
  .controller('MainController', ['$scope', '$rootScope', 'Flash', 'TokenRestAngularMS', 'apiRest', 'Monitoring', '$interval', '_', 'brand', 'userService', ($scope, $rootScope, Flash, TokenRestAngularMS, apiRest, Monitoring, $interval, _, brand, userService) => {
    var endpoint_dashboard = apiRest('dashboard', true),
      promise;
    //IS auth
    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = 'main-dashboard';
    //Title
    $rootScope.subTitle = 'homepage';
    $rootScope.helpLink = 'yooportal/generalites/dashboard.html';

    $scope.localDropdown = $rootScope.initDropdown('yp-widget__content--dd');
    $scope.autorefresh = {
      interval: 30000,
      intervalArray: [{
        value: false,
        label: 'static',
        id: 'refn'
      }, {
        value: 30000,
        label: '30s',
        id: 'ref1'
      }, {
        value: 60000,
        label: '1min',
        id: 'ref2'
      }, {
        value: 180000,
        label: '3min',
        id: 'ref3'
      }]
    };
    let tk = TokenRestAngularMS,
      e = {
        flushCache: tk.all('dashboard/flushDashboardCache')
      };
    //endpoint
    $scope.refreshCache = () => {
      e.flushCache.post();
    };

    $scope.toggleChat = () => {
      // eslint-disable-next-line no-undef
    };
    //GO
    $scope.xFunction = () => {
      return d => {
        return d.key;
      };
    };
    $scope.yFunction = () => {
      return d => {
        return d.y;
      };
    };

    $scope.colorFunction = () => {
      return d => {
        let r;
        switch (d.data.key) {
        case 'on':
        case 'running':
        case 'done':
          r = '#82b964';
          break;
        case 'off':
        case 'error':
          r = '#d24d33';
          break;
        case 'issue':
          r = '#f0ad4e';
          break;
        default:
          r = '#5bc0de';
        }
        return r;
      };
    };
    
    $scope.refresh = function () {
      let user = userService.user();
      if ($rootScope.currMs && user && user.id) {
        endpoint_dashboard.getList().then(res => {
          var data = res[0];
          angular.forEach(data.boxes, item => {
            item.key = Monitoring.convert_status(item.key);
          });
          angular.forEach(data.yoobackup, item => {
            item.key = Monitoring.convert_status(item.key);
          });
          angular.forEach(data.events, item => {
            if (item.level === 'alert') {
              item.level = 'warning';
            }
            if (item.level === 'success') {
              item.level = 'info';
            }
            item.type = brand.filter(item.type);
          });
          angular.forEach(data.flashes, item => {
            item.text = brand.filter(item.text);
            item.title = brand.filter(item.title);
          });
          $scope.data = data;

          //YooBackup
          if (_.findWhere(data.yoobackup, {
            key: 'on'
          })) {
            $scope.yoobackup_on = _.findWhere(data.yoobackup, {
              key: 'on'
            }).y;
          }
          if (_.findWhere(data.yoobackup, {
            key: 'issue'
          })) {
            $scope.yoobackup_issue = _.findWhere(data.yoobackup, {
              key: 'issue'
            }).y;
          }
          if (_.findWhere(data.yoobackup, {
            key: 'off'
          })) {
            $scope.yoobackup_off = _.findWhere(data.yoobackup, {
              key: 'off'
            }).y;
          }
          
          //Boxe
          if (_.findWhere(data.boxes, {
            key: 'on'
          })) {
            $scope.boxes_on = _.findWhere(data.boxes, {
              key: 'on'
            }).y;
          }
          if (_.findWhere(data.boxes, {
            key: 'issue'
          })) {
            $scope.boxes_issue = _.findWhere(data.boxes, {
              key: 'issue'
            }).y;
          }
          if (_.findWhere(data.boxes, {
            key: 'off'
          })) {
            $scope.boxes_off = _.findWhere(data.boxes, {
              key: 'off'
            }).y;
          }
          
          if ($scope.data.flashes) {
            $scope.data.flashes.forEach(v => {
              v.created_at = v.created_at.split(' ');
            });
          }
        });
      }
    };

    $scope.ms = $rootScope.$watch('currMs.organization', () => {
      $scope.refresh();
    });

    $scope.ar = $scope.$watch('autorefresh.interval', () => {
      var int = $scope.autorefresh.interval;

      if (promise) {
        $interval.cancel(promise);
      }
      if (int && int.value) {
        promise = $interval(() => {
          $scope.refresh();
        }, int);
      }
    });

    $scope.$on('$destroy', () => {
      $interval.cancel(promise);
    });

    $scope.refresh();

  }]);
