/**
 * Created by acorre on 24/05/2016.
 */

angular.module('portailWebApp')
    .controller('InventoryController', ['$window', '$rootScope', '$scope', 'Rule', 'apiRest', function ($window, $rootScope, $scope, Rule, apiRest) {
        const vm = this,
            auth = true,
            routes = {
                orgas: 'ladmin/organizations/actions',
                users: 'helper/usersList',
                subs: 'reporting/inventory'
            },
            ep = {
                orgas: apiRest(routes.orgas, auth),
                users: apiRest(routes.users, auth),
                subs: apiRest(routes.subs, auth),
                reports: apiRest(routes.reports, auth)
            };
        let viewReady;

        $rootScope.isView('reporting_inventory');
        $rootScope.subTitle = 'inventory_reports';
        $rootScope.helpLink = '';

        vm.formDispo = true;

        $scope.ready = {
            orgas: false,
            users: false,
            rules: false
        };
        vm.inputs = {
            frequency: [
                {type: 'none', value: null},
                {type: 'daily', value: 1},
                {type: 'weekly', value: 7},
                {type: 'monthly', value: 30}
            ]
        };
        vm.rulesList = [];
        vm.newRuleSet = () => {
            vm.rulesList.unshift(Rule({route: routes.subs}));
        };
        vm.onRemove = id => {
            vm.rulesList = vm.rulesList.filter(i => i.id !== id);
        };
        vm.clearEmpty = () => {
            vm.rulesList = vm.rulesList.filter(v => v.id);
        };

        function getOrgasList() {
            ep.orgas.getList()
                .then(res => {
                    vm.inputs.orgas = res;

                    vm.inputs.orgas.map(i => {
                        return {id: i.id, name: i.name};
                    });
                })
                .catch();
        }

        function getUsersList() {
            ep.users.getList()
                .then(res => {
                    vm.inputs.users = res;
                    vm.inputs.users.map(i => {
                        return {id: i.id, name: i.name, email: i.email};
                    });
                    $scope.ready.users = true;
                })
                .catch();
        }

        getOrgasList();
        getUsersList();

        ep.subs.getList()
            .then(res => {
                vm.rulesList = res.map(e => {
                    e.frequency = vm.inputs.frequency.find(i => i.value === e.frequency);
                    return Rule(e);
                });
                $scope.ready.rules = true;
            })
            .catch();

        viewReady = $scope.$watchGroup(['ready.users', 'ready.orgas', 'ready.rules'], nv => {
            if (nv && nv.reduce((p, c) => (p && c))) {
                vm.viewReady = true;
                viewReady();
            }
        });
    }]);
