((function () {
    angular.module('portailWebApp')
        .factory('TokenRestAngular', TokenRestAngularService);

    TokenRestAngularService.$inject = ['Restangular', 'localStorageService', '$rootScope'];

    function TokenRestAngularService(Restangular, localStorageService, $rootScope) {
        return Restangular.withConfig(config);

        function config(RestangularConfigurer) {

            RestangularConfigurer.setDefaultHeaders({Authorization: 'Bearer ' + localStorageService.get('access_token')});

            $rootScope.listenToken = $rootScope.$on('TokenChanged', onTokenChanged);
            // eslint-disable-next-line no-undef
            RestangularConfigurer.setBaseUrl(Config.get('apiRoot') + '/v1');

            Restangular.addRequestInterceptor(toggleXHR);
            Restangular.addResponseInterceptor(toggleXHR);

            function onTokenChanged() {
                RestangularConfigurer.setDefaultHeaders({Authorization: 'Bearer ' + localStorageService.get('access_token')});
            }
        }

        function toggleXHR(flx) {
            $rootScope.xhr = !$rootScope.xhr;
            return flx;
        }


    }
})());
