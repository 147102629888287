/**
 * Created by acorre on 01/07/2016.
 */
((function () {
  angular.module('portailWebApp')
    .controller('SupportStatisticsController', SupportStatisticsController);

  SupportStatisticsController.$inject = ['$rootScope', 'apiRest', 'moment', '$filter'];
  function SupportStatisticsController($rootScope, apiRest, moment, $filter) {
    const vm = this,
      auth = true,
      routes = {
        reports: 'reporting/support/statistics'

      },
      ep = {
        reports: apiRest(routes.reports, auth)
      };

    vm.updateDate = updateDate;
    vm.refresh = refresh;

    /// Init ////////////////////////////////////

    setDefaultPeriod();
    vm.datePickers = $rootScope.initDropdown('.yp-dateMod__wrapper', {
      closeOut: true,
      multi: false
    });
    vm.refresh();

    /// Page ////////////////////////////////////

    $rootScope.subTitle = 'reporting_support_statistics';
    $rootScope.helpLink = '';

    /// Functions ///////////////////////////////

    function performTreatment(res) {
      res.avgGrtHum = $filter('humanize_minute')(res.avgGrt);
      vm.reports = res;
    }

    function updateDate() {
      vm.datePickers.forEach(v => {
        v.closeAll();
      });
      vm.refresh();
    }

    function refresh() {
      let dateFilter = null,
        from,
        to;
      if (vm.filters) {
        dateFilter = {};
        from = moment(vm.filters.from);
        to = moment(vm.filters.to);
        if (from.isValid()) {
          dateFilter.begin_date = from.format('YYYY-MM-DD');
        }
        if (to.isValid()) {
          dateFilter.end_date = to.format('YYYY-MM-DD');
        }
        if (!dateFilter.begin_date && !dateFilter.end_date) {
          dateFilter = null;
        }
      }

      ep.reports.customGET('', dateFilter)
        .then(performTreatment)
        .catch();
    }


    function setDefaultPeriod() {

      vm.filters = {
        to: moment().toDate(),
        from: moment().subtract(30, 'days').toDate()
      };
    }
  }
})());
