'use strict';

angular.module('portailWebApp')
    .controller('RelationsAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'Monitoring', 'moment', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout, Monitoring, moment) => {
        let endpoint = TokenRestAngular.all('admin/relations'),
            orga_endpoint = TokenRestAngular.all('admin/organizations'),
            rel_endpoint = TokenRestAngular.all('admin/relationtypes');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'relations_admin';
        $rootScope.helpLink = '';

        //Get the data
        $scope.gridData = [];
        $scope.current = [];
        $scope.refresh = () => {
            endpoint.getList().then(res => {
                angular.forEach(res, row => {
                    row.date_deb = Date.parse(row.date_deb);
                    if (row.date_fin) {
                        row.date_fin = Date.parse(row.date_fin);
                    }
                });
                $scope.gridData = res;
            });
        };

        $scope.maxDate = moment().add(3, 'year').format('YYYY-MM-DD');


        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {
                field: 'theson',
                displayName: 'organization',
                headerCellFilter: 'translate'
            },
            {
                field: 'therelation_type',
                displayName: 'relation_type',
                headerCellFilter: 'translate'
            },
            {
                field: 'thefather',
                displayName: 'organization',
                headerCellFilter: 'translate'
            },
            {
                field: 'date_deb',
                displayName: 'date_deb',
                headerCellFilter: 'translate',
                type: 'date',
                cellFilter: 'amCalendar'
            },
            {
                field: 'date_fin',
                displayName: 'date_fin',
                headerCellFilter: 'translate',
                type: 'date',
                cellFilter: 'amCalendar'
            }
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };

        $scope.record = () => {
            let data = {
                'father_id': $scope.father_id,
                'son_id': $scope.son_id,
                'relation_type_id': $scope.relation_type_id,
                'date_deb': $scope.date_deb,
                'date_fin': $scope.date_fin
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.father_id = null;
                $scope.son_id = null;
                $scope.relation_type_id = null;
                $scope.date_deb = null;
                $scope.date_fin = null;
                $scope.creation = false;
            });
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;

            angular.forEach(cols, item => {
                item.remove();
            });

            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };


        $scope.refresh();
        orga_endpoint.getList().then(res => {
            $scope.organizations = res;
        });

        rel_endpoint.getList().then(res => {
            $scope.relationst = res;
        });
    }]);
