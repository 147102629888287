((function () {
    angular.module('portailWebApp')
        .factory('Flash', FlashFactory);

    FlashFactory.$inject = ['$uibModal', '$location', '$translate'];

    function FlashFactory($uibModal, $location, $translate) {

        return {
            flashModal: flashModal,
            show: show,
            clear: () => null
        };

        /////////////////////////////////////////////////////


        function show(message, type) {
            type = type || 'danger';

            if (angular.isDefined(message)) {
                $translate(message)
                    .then(msg => {
                        flashModal(msg, type, false);
                    })
                    .catch(() => {
                        flashModal(message, type, false);
                    });
            } else {
                $translate('issue').then(msg => {
                    type = 'danger';
                    flashModal(msg, type, false);
                });
            }
        }

        function flashModal(msg, type, back) {
            let flash = $uibModal.open({
                templateUrl: 'scripts/ctrl/modal/modal_flash.html',
                controller: 'modalFlashController',
                backdrop: 'static',
                size: 'sm',
                resolve: {
                    'text': function () {
                        return msg;
                    },
                    'type': function () {
                        return type;
                    }
                }
            });
            flash.result.then(() => {
                if (back) {
                    $location.path(back);
                }
            });
        }
    }
})());
