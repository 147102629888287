angular.module('portailWebApp')
    .controller('modalFusionTicketController', ['$scope', 'TokenRestAngularMS', '$uibModalInstance', '$translate', 'currentTicket', '$location', 'ticketsList', '_', ($scope, TokenRestAngularMS, $uibModalInstance, $translate, currentTicket, $location, ticketsList, _) => {
        var tk = TokenRestAngularMS,
            ep = {
                licences: tk.all('ladmin/licences'),
                tickets: tk.all('supportv2/tickets'),
                interventions: tk.all('support/interventions'),
                types: tk.all('support/ressources/types'),
                orga: tk.all('support/ressources/organizations'),
                organizations: tk.all('tools/organizations'),
                origins: tk.all('support/ressources/origin'),
                criticities: tk.all('support/ressources/criticity'),
                categories: tk.all('support/ressources/category'),
                baskets: tk.all('support/ressources/baskets'),
                actions: tk.all('support/ressources/action'),
                files: tk.all('support/attachments'),
                users: tk.all('ladmin/users')
            };


        $scope.refresh = () => {
            ep.tickets.getList().then(data => {
                $scope.ticketsList = _.filter(data, v => {
                    return v.id !== currentTicket.id;
                });
            });
        };

        $scope.refresh();

        $scope.ticket = null;

        $scope.cancel = () => {
            $uibModalInstance.dismiss('cancel');
        };
        $scope.merge = item => {
            $uibModalInstance.close(item.id);
        };
        $scope.urlTicket = $location.protocol() + '://' + $location.host() + '/support/ticketview/';
    }]);
