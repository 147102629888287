'use strict';

angular.module('portailWebApp')
    .controller('CmsAdmController', ['$scope', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', 'moment', ($scope, $rootScope, Flash, TokenRestAngular, $timeout, moment) => {
        let endpointCm = TokenRestAngular.all('admin/cmsync'),
            endpointOrga = TokenRestAngular.all('admin/organizations');
        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.subTitle = 'cms_admin';
        $rootScope.helpLink = '';
        //Endpoint

        $scope.refresh = () => {
            endpointCm.getList().then(data => {
                $scope.cmr = data;
            });
            endpointOrga.getList().then(data => {
                $scope.orgas = data;
            });
        };

        $scope.refresh();

        $scope.current = [];

        $scope.delete = el => {
            $rootScope.xhr = true;
            el.remove();
            $scope.refresh();
        };

        $scope.record = () => {
            let now = moment(),
                data = {
                    'organization_id': $scope.orga_id,
                    'cm_id': $scope.cm_id,
                    'created_at': now
                };
            endpointCm.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.orga_id = null;
                $scope.cm_id = null;
            });
        };
    }]);
