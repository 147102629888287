/*eslint-disable angular/controller-as */
angular.module('portailWebApp')
  .controller('UsersLAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'Monitoring', 'TokenRestAngular', 'customFilter', 'TokenRestAngularMS', '$timeout', 'userService', ($scope, $location, $rootScope, Flash, Monitoring, TokenRestAngular, customFilter, TokenRestAngularMS, $timeout, userService) => {
    var endpoint = TokenRestAngularMS.all('ladmin/users'),
      rendpoint = TokenRestAngularMS.all('ladmin/rights'),
      oendpoint = TokenRestAngularMS.all('ladmin/organizations/all');

    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'users_admin';
    $rootScope.helpLink = 'yooportal/organisation/utilisateurs.html';
    //$rootScope.helpLink =true;
    $rootScope.initDropdown('.yp-toolbar');
    //Get the data
    $scope.userManagement = [];

    $scope.refresh = () => {
      endpoint.getList().then(data => {
        angular.forEach(data, item => {
          item.admin = !!+item.admin;
          item.active = !!+item.active;
        });
        $scope.userManagement = data;
      });
    };
    $scope.refresh();

    $scope.current = [];

    $scope.gridOptions = Monitoring.setGridOption('userManagement', [
      {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
      {field: 'email', displayName: 'email', headerCellFilter: 'translate'},
      {field: 'phone', displayName: 'phone', headerCellFilter: 'translate'},
      {field: 'gsm', displayName: 'gsm', headerCellFilter: 'translate'},
      {field: 'fax', displayName: 'fax', headerCellFilter: 'translate'},
      {field: 'right', displayName: 'right', headerCellFilter: 'translate', cellEditableCondition: false},
      {
        field: 'organization',
        displayName: 'organization',
        headerCellFilter: 'translate',
        cellEditableCondition: false
      },
      {
        field: 'active',
        displayName: 'active',
        headerCellFilter: 'translate',
        cellEditableCondition: false,
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterBool,
          condition: customFilter.bool.filterStatus
        },
        cellTemplate: customFilter.i18n.cellTemplate
      },
      {
        field: 'deleted_at',
        type: 'date',
        displayName: 'deleted_at',
        headerCellFilter: 'translate',
        cellEditableCondition: false,
        enableFiltering: true,
        filters: [{
          condition: customFilter.date.isBefore
        },
        {
          condition: customFilter.date.isAfter
        }],
        filterHeaderTemplate: customFilter.date.template
      }], true, true, 'high');
    $scope.gridOptions.onRegisterApi = gridApi => {
      $scope.gridApi = gridApi;
      $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity => {
        $scope.update(rowEntity);
      });
    };
    $scope.update = element => {
      $rootScope.xhr = true;
      element.save();
      $timeout(() => {
        $rootScope.xhr = false;
      }, 1000);
    };

    $scope.record = () => {
      var data = {
        'name': $scope.name,
        'email': $scope.email,
        'right_id': $scope.right_id,
        'organization_id': $scope.organization_id,
        'i18nlanguage_id': userService.user().i18nlanguage_id,
        'url': $location.host()
      };
      endpoint.post(data).then(res => {
        Flash.show(res.success, 'success');
        $scope.refresh();
        $scope.name = null;
        $scope.email = null;
        $scope.right_id = null;
        $scope.creation = false;
      });
    };

    $scope.reset = () => {
      let cols = $scope.gridApi.selection.getSelectedRows();
      $rootScope.xhr = true;
      angular.forEach(cols, item => {
        let endpoint2 = TokenRestAngularMS.all('ladmin/users/reset/' + item.id);
        item.url = $location.host();
        endpoint2.post(item).then(data => {
          Flash.show(data.success, 'success');
        });
      });
      $timeout(() => {
        $scope.refresh();
        $rootScope.xhr = false;
      }, 1500);
    };

    $scope.changeRight = () => {
      let cols = $scope.gridApi.selection.getSelectedRows();
      $rootScope.xhr = true;
      angular.forEach(cols, item => {
        let endpoint2 = TokenRestAngularMS.all('ladmin/users/right/' + item.id);
        item.right_id = $scope.right_id2;
        endpoint2.post(item).then(data => {
          Flash.show(data.success, 'success');
          $scope.right = false;
          $scope.right_id2 = null;
        });
      });
      $timeout(() => {
        $scope.refresh();
        $rootScope.xhr = false;
      }, 1500);
    };

    $scope.actdes = () => {
      let cols = $scope.gridApi.selection.getSelectedRows();
      $rootScope.xhr = true;
      angular.forEach(cols, item => {
        let endpoint2 = TokenRestAngularMS.all('ladmin/users/active/' + item.id);
        item.active = !item.active;
        endpoint2.post(item).then(data => {
          Flash.show(data.success, 'success');
        });
      });
      $timeout(() => {
        $scope.refresh();
        $rootScope.xhr = false;
      }, 1500);
    };

    $scope.delete = () => {
      var cols = $scope.gridApi.selection.getSelectedRows();
      $rootScope.xhr = true;
      angular.forEach(cols, item => {
        item.remove().then(data => {
          Flash.show(data.success, 'success');
        });
      });
      $timeout(() => {
        $scope.refresh();
        $rootScope.xhr = false;
      }, 1500);
    };

    rendpoint.getList().then(data => {
      $scope.rights = data;
    });
    oendpoint.getList().then(data => {
      $scope.organizations = data;
    });
  }]);
/*eslint-enable angular/controller-as */
