((function () {
    angular.module('portailWebApp')
        .factory('Rule', RuleFactory);

    RuleFactory.$inject = ['$window', 'apiRest', 'moment'];

    function RuleFactory($window, apiRest, moment) {
        return (infos) => {
            let r;

            if (infos && infos.route) {
                r = apiRest(0, 1).restangularizeElement(0, new Rule(infos), infos.route);
                r.fromServer = infos.fromServer || false;
                r.getFile = function () {

                    this.one('csv')
                        .withHttpConfig({responseType: 'blob'})
                        .get()
                        .then(data => {
                            const name = moment().format('YYYYMMDD') + '_inventoryReport.csv';
                            $window.saveAs(data, name);
                        });
                };
            } else {
                r = new Rule(infos);
            }

            return r;
        };

        function Rule(o) {
            const rule = this,
                defFreq = {type: 'none', value: null};
            rule.id = o.id || null;
            rule.description = o.description || null;
            rule.organizations = o.organizations || [];
            rule.users = o.users || [];
            rule.fromServer = o.fromServer || false;
            rule.route = o.route || null;
            rule.frequency = o.frequency || defFreq;

            rule.isSame = ruleIsSame;

            rule.clear = ruleClear;

            rule.reset = ruleReset;


            function ruleClear() {
                rule.reset({});
            }

            function ruleIsSame(oP) {
                return (rule.description === oP.description
                && rule.organizations === oP.organizations
                && o.users === oP.users);
            }

            function ruleReset(data) {
                rule.description = data.description || null;
                rule.users = data.users || null;
                rule.organizations = data.organizations || null;
                rule.frequency = data.frequency || defFreq;
            }

        }

    }
})());
