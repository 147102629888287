(function () {

  angular.module('portailWebApp')
    .controller('MonitoringYoobackupController', MonitoringYoobackupController);

  MonitoringYoobackupController.$inject = ['$scope', '$rootScope', 'Flash', 'TokenRestAngularMS', '$timeout', '_', 'uiGridConstants', 'customFilter', 'Monitoring', '$window', 'brand', '$routeParams', '$filter'];

  function MonitoringYoobackupController($scope, $rootScope, Flash, TokenRestAngularMS, $timeout, _, uiGridConstants, customFilter, Monitoring, $window, brand, $routeParams, $filter) {
    //IS auth and admin
    const jobs_endpoint = TokenRestAngularMS.all('monitoring/yoobackup/jobs'),
      licences_endpoint = TokenRestAngularMS.all('monitoring/yoobackup/licences'),
      agents_endpoint = TokenRestAngularMS.all('monitoring/yoobackup/agents'),
      relations_endpoint = TokenRestAngularMS.all('monitoring/relations'),
      organizations_endpoint = TokenRestAngularMS.all('monitoring/organizations'),
      gendpoint = TokenRestAngularMS.all('monitoring/yoobackup/licences/remote'),
      griddefs = {
        licences: [
          {
            field: 'licence',
            type: 'number',
            displayName: 'licence',
            headerCellFilter: 'translate',
            filter: {
              placeholder: 'licence'
            },
            cellTemplate: `<yp-fill-search class="yp-grid__btn ui-grid-cell-contents" terms="{{row.entity.licence}}">{{COL_FIELD CUSTOM_FILTERS}}</yp-fill-search>`
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certified ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certified"  class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><span class="glyphicon glyphicon-info-sign"></a></span></div>'
          },
          {
            field: 'context',
            displayName: 'context',
            headerCellFilter: 'translate'
          },
          {
            field: 'seller',
            displayName: 'reseller',
            headerCellFilter: 'translate',
            visible: false,
            cellTemplate: '<div class="ui-grid-cell-contents ng-class: row.entity.certifiedSeller ? \'relative membershipButton\' : \'\'"><img ng-if="row.entity.certifiedSeller" class="badgeCertified py-1" src="../../images/organisations/certified.svg" />{{ row.entity.seller }}</div>'
          },
          {
            field: 'contacts_email',
            displayName: 'contacts_email',
            headerCellFilter: 'translate',
            enableFiltering: true
          },
          {
            field: 'expiration',
            type: 'date',
            displayName: 'expiration',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'updated_at',
            type: 'date',
            displayName: 'updated_at',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'cloud',
            displayName: 'cloud',
            headerCellFilter: 'translate',
            cellTemplate: customFilter.i18n.cellTemplate
          },
          {
            field: 'box2box',
            displayName: 'box2box',
            headerCellFilter: 'translate',
            cellTemplate: customFilter.i18n.cellTemplate
          },
          {
            field: 'standalone',
            displayName: 'standalone',
            headerCellFilter: 'translate',
            cellTemplate: customFilter.i18n.cellTemplate
          },
          {
            field: 'local_size',
            type: 'number',
            displayName: 'local_size',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'local_size\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }} <i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'local_number',
            type: 'number',
            displayName: 'local_number',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'local_number\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}} <i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },

          {
            field: 'cloud_usage',
            displayName: 'cloud_usage',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD}}%</div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.degress(grid.getCellValue(row, col));
            }
          },
          {
            field: 'cloud_size_quota',
            type: 'number',
            displayName: 'cloud_size_quota',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS  | humanizeFilesize }}</div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'cloud_size_cloud',
            type: 'number',
            displayName: 'cloud_size_cloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'cloud_size_cloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS  | humanizeFilesize }} <i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'cloud_size_tocloud',
            type: 'number',
            displayName: 'cloud_size_tocloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'cloud_size_tocloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }} <i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'cloud_number_cloud',
            type: 'number',
            displayName: 'cloud_number_cloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'cloud_number_cloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'cloud_number_tocloud',
            type: 'number',
            displayName: 'cloud_number_tocloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph(\'cloud_number_tocloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'cloud_percent',
            type: 'number',
            displayName: 'cloud_percent',
            headerCellFilter: 'translate',
            enableFiltering: true,
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD}} %</div>',
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore,
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.progress(grid.getCellValue(row, col));
            }
          },
          {
            field: 'remote_link',
            displayName: 'remote_link',
            headerCellFilter: 'translate',
            enableFiltering: false,
            cellTemplate: '<div class="ui-grid-cell-contents text-center" ng-click="grid.appScope.remote(row.entity)"><i ng-hide="row.entity.standalone" class="material-icons md-18">build</i> <span ng-hide="row.entity.standalone" translate="remote_link"></span></div>'
          },
          {
            field: 'contracts',
            displayName: 'contracts',
            enableFiltering: true,
            headerCellFilter: 'translate'
          }],
        agents: [
          {
            field: 'licence_agent',
            displayName: 'licence_agent',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <pin-it name="yoobackup_agents_status" data="row.entity"></pin-it></div>'
          },
          {
            field: 'licence',
            displayName: 'licence',
            headerCellFilter: 'translate',
            visible: false
          },
          {
            field: 'organization',
            displayName: 'organization',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.organization_id)"><span class="glyphicon glyphicon-info-sign"></a></span></div>'
          },
          {
            field: 'seller',
            displayName: 'reseller',
            headerCellFilter: 'translate',
            visible: false
          },
          {
            field: 'name',
            displayName: 'name',
            headerCellFilter: 'translate'
          },
          {
            field: 'size_cloud',
            displayName: 'size_cloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph2(\'size_cloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'cloud_size_quota',
            displayName: 'cloud_size_quota',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}</div>',
            enableFiltering: true,
            //cellFilter :
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'status',
            displayName: 'status',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph2(\'status\', row.entity)"><i class="material-icons md-18">poll</i> <i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'updated_at',
            displayName: 'updated_at',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [{ condition: customFilter.date.isBefore }, { condition: customFilter.date.isAfter }],
            filterHeaderTemplate: customFilter.date.template
          }
        ],
        jobs: [
          {
            field: 'device',
            displayName: 'jobs_devices',
            headerCellFilter: 'translate'
          },
          {
            field: 'name',
            displayName: 'name',
            headerCellFilter: 'translate'
          },
          {
            field: 'backup_start_date',
            displayName: 'backup_start_date',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'backup_last_date',
            displayName: 'backup_last_date',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'backup_status',
            displayName: 'backup_status',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'backup_status\', row.entity)"><i class="material-icons md-18">poll</i><i translate="{{COL_FIELD CUSTOM_FILTERS }}"></i></div>',
            headerCellFilter: 'translate',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'scheduler',
            displayName: 'scheduler',
            cellTemplate: '<div class="ui-grid-cell-contents" title="{{ row.entity.next_backup ? \'next_backup_label\' : \'\' | translate}} {{row.entity.next_backup}}">{{COL_FIELD CUSTOM_FILTERS}}</div>',
            headerCellFilter: 'translate',
            enableFiltering: true
          },

          {
            field: 'cloud_last_date',
            displayName: 'cloud_last_date',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'cloud_status',
            displayName: 'cloud_status',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'cloud_status\', row.entity)"><i class="material-icons md-18">poll</i><i translate="{{COL_FIELD CUSTOM_FILTERS }}"></i></div>',
            headerCellFilter: 'translate',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'suppression_last_date',
            displayName: 'suppression_last_date',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'suppression_status',
            displayName: 'suppression_status',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'suppression_status\', row.entity)"><i class="material-icons md-18">poll</i><i translate="{{COL_FIELD CUSTOM_FILTERS }}"></i></div>',
            headerCellFilter: 'translate',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'restoration_last_date',
            displayName: 'restoration_last_date',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.date.isBefore
              },
              {
                condition: customFilter.date.isAfter
              }
            ],
            filterHeaderTemplate: customFilter.date.template
          },
          {
            field: 'restoration_status',
            displayName: 'restoration_status',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'restoration_status\', row.entity)"><i class="material-icons md-18">poll</i><i translate="{{COL_FIELD CUSTOM_FILTERS }}"></i></div>',
            headerCellFilter: 'translate',
            filterHeaderTemplate: customFilter.bool.template,
            filter: {
              term: [],
              options: customFilter.bool.filterOption,
              condition: customFilter.bool.filterStatus
            },
            cellClass: (grid, row, col) => {
              return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
            }
          },
          {
            field: 'size_saved',
            displayName: 'size_saved',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'size_saved\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'number_saved',
            displayName: 'number_saved',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_saved\', row.entity)">{{COL_FIELD CUSTOM_FILTERS}}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'number_errorsaved',
            displayName: 'number_errorsaved',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_errorsaved\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'size_cloud',
            displayName: 'size_cloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'size_cloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'size_tocloud',
            displayName: 'size_tocloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'size_tocloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'number_cloud',
            displayName: 'number_cloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_cloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'number_tocloud',
            displayName: 'number_tocloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_tocloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'number_errorcloud',
            displayName: 'number_cloud',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_errorcloud\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'size_deleted',
            displayName: 'size_deleted',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'size_deleted\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'number_deleted',
            displayName: 'number_deleted',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_deleted\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'number_errordeleted',
            displayName: 'number_errordeleted',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_errordeleted\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'size_restored',
            displayName: 'size_restored',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'size_restored\', row.entity)">{{COL_FIELD CUSTOM_FILTERS | humanizeFilesize }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: customFilter.octet.isGreaterGiga
              },
              {
                condition: customFilter.octet.isLowerGiga
              }
            ],
            filterHeaderTemplate: customFilter.octet.template
          },
          {
            field: 'number_restored',
            displayName: 'number_restored',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_restored\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'number_errorestored',
            displayName: 'number_errorestored',
            headerCellFilter: 'translate',
            cellTemplate: '<div class="ui-grid-cell-contents" ng-click="grid.appScope.graph3(\'number_errorestored\', row.entity)">{{COL_FIELD CUSTOM_FILTERS }}<i class="material-icons md-18">poll</i></div>',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'max_version',
            displayName: 'max_version',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          },
          {
            field: 'retention_days',
            displayName: 'retention_days',
            headerCellFilter: 'translate',
            enableFiltering: true,
            filters: [
              {
                condition: uiGridConstants.filter.GREATER_THAN
              },
              {
                condition: uiGridConstants.filter.LESS_THAN
              }
            ],
            filterHeaderTemplate: customFilter.number.templateMore
          }
        ]
      };

    //Layout
    $rootScope.pageClass = '';

    $rootScope.initDropdown('.yp-table--dd');
    $rootScope.initDropdown('.yp-accordion', {
      closeOut: true
    });

    //Title
    $rootScope.subTitle = 'monitoring ' + brand.trad('yb');

    $rootScope.helpLink = 'yooportal/monitoring/yoobackup.html';
    //endpoint

    //filters functions
    $scope.relation_id = null;
    //init
    $scope.organizations = [];
    $scope.relations = [];
    $scope.licences = [];
    $scope.agents = [];
    $scope.jobs = [];
    $scope.tina_agents = [];
    $scope.filters = [];
    $scope.filters2 = [];
    // Monitoring service
    $scope.refresh_filters = () => {
      Monitoring.refreshFilters('monitoring_yoobackup', data => {
        $scope.filters = data;
        if ($scope.filters.length > 0) {
          $scope.loadFilter($scope.filters[0].id);
        }

        $timeout(() => {
          let type = $routeParams.filterType,
            value = $routeParams.filterValue || null;

          if (type) {
            switch (type) {
            case 'offline':
              $scope.filterColumnStatus('off');
              break;
            case 'capacity':
              $scope.filterColumnCapacity(79);
              break;
            case 'licence':
              $scope.filterColumnLicence(value);
              break;
            default:
            }
          }
        }, 1000);
      });
    };
    $scope.loadIt = () => {
      Monitoring.loadFilters('monitoring_yoobackup', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //If selection grid1
        var organizations = $scope.gridApi1.selection.getSelectedRows(),
          columns1 = $scope.gridApi1.saveState.save(),
          columns2 = $scope.gridApi2.saveState.save(),
          columns3 = $scope.gridApi3.saveState.save(),
          columns4 = $scope.gridApi4.saveState.save();
        if (organizations.length) {
          data.data.grid1.selection = false;
        }
        //if filters  grid1?


        angular.forEach(columns1.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid1.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid2?
        angular.forEach(columns2.columns, (item, i) => {

          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid2.columns[i].filters[j].term = filter.term;
            }
          });
        });
        //if filters  grid3?

        angular.forEach(columns3.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid3.columns[i].filters[j].term = filter.term;
            }
          });
        });

        //if filters  grid3?

        angular.forEach(columns4.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid4.columns[i].filters[j].term = filter.term;
            }
          });
        });

        $scope.relation_id = data.data.relation_id;
        if (data.data.grid1) {
          $scope.gridApi1.saveState.restore($scope, data.data.grid1);
        }
        if (data.data.grid2) {
          $scope.gridApi2.saveState.restore($scope, data.data.grid2);
        }
        if (data.data.grid3) {
          $scope.gridApi3.saveState.restore($scope, data.data.grid3);
        }
        if (data.data.grid4) {
          $scope.gridApi4.saveState.restore($scope, data.data.grid4);
        }
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('monitoring_yoobackup', {
        grid1: $scope.gridApi1.saveState.save(),
        grid2: $scope.gridApi2.saveState.save(),
        grid3: $scope.gridApi3.saveState.save(),
        grid4: $scope.gridApi4.saveState.save(),
        relation_id: $scope.relation_id
      });
    };

    $scope.organization_info = organization_id => {
      Monitoring.organization(organization_id);
    };
    //Usefull
    $scope.graph = (type, licence) => {
      Monitoring.graph('yoobackup/licences', type, licence.id, licence.licence);
    };
    $scope.graph2 = (type, licence) => {
      Monitoring.graph('yoobackup/agents', type, licence.id, licence.name);
    };
    $scope.graph3 = (type, licence) => {
      Monitoring.graph('yoobackup/jobs', type, licence.id, licence.name);
    };

    $scope.remote = licence => {
      gendpoint.get(licence.id).then(data => {
        $window.open(data.url, '_blank');
      });
    };
    //refresh functions
    $scope.refresh_licences = () => {
      let organizations = $scope.gridApi1.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (r) {
        licences_endpoint.getList(filters).then(data => {
          $scope.licences = [];
          angular.forEach(data, row => {
            row.status = Monitoring.convert_status(row.status);
            row.local_size = parseInt(row.local_size, 10);
            row.local_number = parseInt(row.local_number, 10);
            row.cloud_size_quota = parseInt(row.cloud_size_quota, 10);
            row.cloud_size_cloud = parseInt(row.cloud_size_cloud, 10);
            row.cloud_size_tocloud = parseInt(row.cloud_size_tocloud, 10);
            row.cloud_percent = Math.round((1 - (row.cloud_size_tocloud / (row.cloud_size_tocloud + row.cloud_size_cloud))) * 10000) / 100;
            row.cloud_usage = row.cloud ? Math.round(row.cloud_size_cloud / row.cloud_size_quota * 100) : null;

            if (!row.cloud_percent) {
              row.cloud_percent = 100;
            }
            row.cloud_number_cloud = parseInt(row.cloud_number_cloud, 10);
            row.cloud_number_tocloud = parseInt(row.cloud_number_tocloud, 10);
            row.cloud = !!+row.cloud;
            row.box2box = !!+row.box2box;
            row.standalone = !!+row.standalone;
            $scope.licences.push(row);
          });
          //calcul
          $scope.count = _.countBy(data, row => {
            return row.status;
          });
        });
      }
      return r;
    };

    $scope.refresh_agents = () => {
      let organizations = $scope.gridApi1.selection.getSelectedRows(),
        licences = $scope.gridApi2.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (licences.length > 0 && licences.length <= 100) {
        filters.licence_id = '';
        angular.forEach(licences, (item, j) => {
          filters.licence_id = filters.licence_id + item.id;
          if (j !== licences.length) {
            filters.licence_id = filters.licence_id + ',';
          }
        });
      } else if (licences.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      $scope.agents = [];
      $scope.jobs = [];
      if (r && filters.licence_id) {
        agents_endpoint.getList(filters).then(data => {
          angular.forEach(data, row => {
            row.size_cloud = parseInt(row.size_cloud, 10);
            row.status = Monitoring.convert_status(row.status);
            $scope.agents.push(row);
          });
          $scope.countAgent = _.countBy(data, row => {
            return row.status;
          });
        });
      }
      return r;
    };
    $scope.refresh_relations = () => {
      $scope.relations = [];
      relations_endpoint.getList().then(data => {

        var vardefault = {
          id: null,
          name: '--'
        };
        $scope.relations = data;
        $scope.relations.push(vardefault);
        $scope.relation_id = vardefault.id;
        $scope.refresh_organizations();
      });
    };
    $scope.refresh_jobs = () => {
      var organizations = $scope.gridApi1.selection.getSelectedRows(),
        licences = $scope.gridApi2.selection.getSelectedRows(),
        agents = $scope.gridApi3.selection.getSelectedRows(),
        filters = {},
        r = true;
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      if (organizations.length > 0 && organizations.length <= 100) {
        filters.organization_id = '';
        angular.forEach(organizations, (item, i) => {
          filters.organization_id = filters.organization_id + item.id;
          if (i !== organizations.length) {
            filters.organization_id = filters.organization_id + ',';
          }
        });
      } else if (organizations.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (licences.length > 0 && licences.length <= 100) {
        filters.licence_id = '';
        angular.forEach(licences, (item, k) => {
          filters.licence_id = filters.licence_id + item.id;
          if (k !== licences.length) {
            filters.licence_id = filters.licence_id + ',';
          }
        });
      } else if (licences.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      if (agents.length > 0 && agents.length <= 100) {
        filters.agents_id = '';
        angular.forEach(agents, (item, j) => {
          filters.agents_id = filters.agents_id + item.id;
          if (j !== agents.length) {
            filters.agents_id = filters.agents_id + ',';
          }
        });
      } else if (agents.length > 100) {
        $scope.clearAll();
        Flash.show('no_more_selection');
        r = false;
      }
      $scope.jobs = [];
      if (filters.agents_id && r) {
        jobs_endpoint.getList(filters).then(data => {
          angular.forEach(data, row => {
            row.status = Monitoring.convert_status(row.status);
            row.backup_status = Monitoring.convert_status(row.backup_status);
            row.cloud_status = Monitoring.convert_status(row.cloud_status);
            row.suppression_status = Monitoring.convert_status(row.suppression_status);
            row.restoration_status = Monitoring.convert_status(row.restoration_status);
            row.size_saved = parseInt(row.size_saved, 10);
            row.number_saved = parseInt(row.number_saved, 10);
            row.number_errorsaved = parseInt(row.number_errorsaved, 10);
            row.size_cloud = parseInt(row.size_cloud, 10);
            row.size_tocloud = parseInt(row.size_tocloud, 10);
            row.number_cloud = parseInt(row.number_cloud, 10);
            row.number_tocloud = parseInt(row.number_tocloud, 10);
            row.number_errorcloud = parseInt(row.number_errorcloud, 10);
            row.size_deleted = parseInt(row.size_deleted, 10);
            row.number_deleted = parseInt(row.number_deleted, 10);
            row.number_errordeleted = parseInt(row.number_errordeleted, 10);
            row.size_restored = parseInt(row.size_restored, 10);
            row.number_restored = parseInt(row.number_restored, 10);
            row.number_errorestored = parseInt(row.number_errorestored, 10);
            row.next_backup = $filter('cronParse')(row.scheduler);
            $scope.jobs.push(row);
          });
        });
      }
      return r;
    };

    $scope.refresh_organizations = () => {

      let filters = {};
      $scope.organizations = [];
      if ($scope.relation_id) {
        filters.relation_id = $scope.relation_id;
      }
      organizations_endpoint.getList(filters).then(data => {
        $scope.organizations = data;
        $scope.refresh_licences();
      });
    };
    //Init
    $scope.refresh_relations();
    $scope.refresh_filters();
    //Grids

    /* Organizations  */

    $scope.gridOptions1 = Monitoring.setGridOption('organizations');
    $scope.gridOptions1.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_licences();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_licences();
      });
      $scope.gridApi1 = gridApi;
    };
    /*Licences*/

    $scope.gridOptions2 = Monitoring.setGridOption('licences', griddefs.licences, true, true);
    $scope.gridOptions2.onRegisterApi = gridApi => {
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_agents();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_agents();
      });
      $scope.gridApi2 = gridApi;
    };

    $scope.gridOptions3 = Monitoring.setGridOption('agents', griddefs.agents, true, true);
    $scope.gridOptions3.onRegisterApi = gridApi => {
      $scope.gridApi3 = gridApi;
      gridApi.selection.on.rowSelectionChanged($scope, () => {
        $scope.refresh_jobs();
      });
      gridApi.selection.on.rowSelectionChangedBatch($scope, () => {
        $scope.refresh_jobs();
      });
    };

    $scope.gridOptions4 = Monitoring.setGridOption('jobs', griddefs.jobs, false, true);
    $scope.gridOptions4.onRegisterApi = gridApi => {
      $scope.gridApi4 = gridApi;
    };

    //Grid operations
    $scope.selectAll = () => {
      $scope.gridApi1.selection.selectAllVisibleRows();
    };

    $scope.clearAll = () => {
      $scope.gridApi1.selection.clearSelectedRows();
    };

    $scope.removeOrganisationFilter = row => {
      $scope.gridApi1.selection.unSelectRow(row);
    };

    $scope.removeLicenceFilter = row => {
      $scope.gridApi2.selection.unSelectRow(row);
    };
    $scope.filterColumnStatus = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'status'
      });
      col.filters[0].term = val;
      $scope.gridApi2.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnStatus2 = val => {
      var col = _.find($scope.gridApi3.grid.columns, {
        field: 'status'
      });
      col.filters[0].term = val;
      $scope.gridApi3.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };
    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi2.grid.columns, {
        field: 'licence'
      });
      if (col) {
        col.filter.term = val;
        $scope.gridApi2.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };

  }

}());

