/**
 * Created by acorre on 09/06/2016.
 */
((function () {
  angular.module('portailWebApp')
    .factory('brand', brandService);

  brandService.$inject = ['$log'];

  function brandService() {
    // eslint-disable-next-line no-alert, no-undef
    let brand = Config.get('branding') || 'atempo',
      images = getImagesList(),
      products = getProductsList(),
      graphColor = getGraphColor();

    return {
      trad: trad,
      filter: filter,
      setBrand: setBrand,
      getBrand: getBrand,
      getGraphColors: getGraphColors
    };

    //////////////////////////////////////////////////////////////////
    function brandLabels() {
      return products[brand];
    }

    function filter(str) {
      return str.replace(/(\$\{\[a-z-0-9\]*\})/gi, function (ignore, p1) {
        var v = p1.slice(2, -1);
        return brandLabels()[v];
      });
    }

    function trad(key) {
      return brandLabels()[key];
    }

    function getLogo() {
      let logos = angular.element('.brandedLogo');
      logos.attr('src', 'images/' + images[brand]);
    }

    function getImagesList() {
      return {
        'wooxo': 'logo-d-yooportal.svg',
        'atempo': 'logo-d-yooportal.svg'
      };
    }

    function getProductsList() {
      return {
        wooxo: {
          li: 'Lina',
          wb: 'WooxoBackup',
          yp: 'YooPortal',
          yb: 'YooBackup',
          yf: 'YooFind',
          ys: 'YooSync'
        },
        atempo: {
          li: 'Lina',
          wb: 'WooxoBackup',
          yp: 'YooPortal',
          yb: 'YooBackup',
          yf: 'YooFind',
          ys: 'YooSync'
        }
      };
    }

    function getGraphColor() {
      return {
        //info: ['gris', 'jaune', 'vertclair', 'vertfoncé']
        wooxo: ['#54534a', '#f0ad4e', '#4fc882', '#2a8751'],
        atempo: ['#54534a', '#f0ad4e', '#4fc882', '#2a8751']
      };
    }

    function getStyle() {
      angular.element('#mainCss')
        .attr('href', 'styles/css/' + brand + '-main.css');
      angular.element('#critical').remove();

    }

    function setBrand(newBrand) {
      brand = newBrand || brand;
      getLogo();
      getStyle();
    }

    function getGraphColors() {
      return graphColor[brand];
    }

    function getBrand() {
      return brand;
    }

  }
})());
