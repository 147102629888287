'use strict';

angular.module('portailWebApp').controller('LicensesLAdmController', ['$scope', '$rootScope', 'TokenRestAngularMS', '$timeout', 'Monitoring', 'Flash', ($scope, $rootScope, TokenRestAngularMS, $timeout, Monitoring, Flash) => {
  $rootScope.pageClass = '';
  
  //Title
  $rootScope.initDropdown('#modal-products');

  $rootScope.subTitle = 'licences_admin';
  $rootScope.helpLink = '';
  
  const endpoint = {
      licences: TokenRestAngularMS.all('ladmin/licenses'),
      products: TokenRestAngularMS.all('ladmin/licenses/products'),
      allproducts: TokenRestAngularMS.all('ladmin/licenses/fetchproducts'),
      email: TokenRestAngularMS.all('ladmin/emails')
    }, 
    grids = {
      licences: [
        {
          field: 'licence',
          cellEditableCondition: false,
          displayName: 'licence',
          headerCellFilter: 'translate'
        },
        {
          field: 'client.organization.name',
          cellEditableCondition: false,
          displayName: 'organization',
          headerCellFilter: 'translate'
        },
        {
          field: 'quarantine',
          type: 'boolean',
          displayName: 'quarantine',
          headerCellFilter: 'translate',
          editableCellTemplate: ''
        },
        {
          field: 'sla_level_id',
          cellEditableCondition: true,
          displayName: 'sla_level',
          headerCellFilter: 'translate'
        }
      ],
      products: [
        {
          field: 'licence.licence',
          cellEditableCondition: false,
          displayName: 'licence',
          headerCellFilter: 'translate'
        },
        {
          field: 'product.label',
          displayName: 'product',
          headerCellFilter: 'translate'
        }
      ],
      emails: [
        {
          field: 'licence.boxe.serial_number',
          cellEditableCondition: false,
          displayName: 'serial_number',
          headerCellFilter: 'translate'
        },
        {
          field: 'licence.licence',
          cellEditableCondition: false,
          displayName: 'licence',
          headerCellFilter: 'translate'
        },
        {
          field: 'contacts_email',
          cellEditableCondition: true,
          displayName: 'contact_email',
          headerCellFilter: 'translate',
          cellTemplate: '<div class="ui-grid-cell-contents flex"><i class="material-icons md-18">edit</i>{{COL_FIELD CUSTOM_FILTERS}}</div>'
        }
      ]
    };

  $scope.product_id = 0;
  $scope.allproducts = [];

  endpoint.allproducts.getList().then(data => {
    $scope.allproducts = data;
  });

  $scope.licences = [];
  $scope.grid_licences = Monitoring.setGridOption('licences', grids.licences, true, false);

  $scope.products = [];
  $scope.grid_products = Monitoring.setGridOption('products', grids.products, true, false);

  $scope.emails = [];
  $scope.grid_emails = Monitoring.setGridOption('emails', grids.emails, true, false);

  $scope.grid_licences.onRegisterApi = grid_api => {
    $scope.gridLicenceApi = grid_api;
    $scope.gridLicenceApi.edit.on.afterCellEdit($scope, rowEntity => {
      $scope.update(rowEntity);
    });
    $scope.gridLicenceApi.selection.on.rowSelectionChanged($scope, () => {
      $scope.getProducts();
      $scope.getEmail();
    });

    $scope.selected_licences = $scope.gridLicenceApi;
  };

  $scope.grid_products.onRegisterApi = grid_api => {
    $scope.gridProductsApi = grid_api;
    $scope.selected_products = $scope.gridProductsApi;
  };

  $scope.grid_emails.onRegisterApi = gridApi => {
    $scope.gridApi = gridApi;
    $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity => {
      $scope.updateEmail(rowEntity);
    });
  };

  $scope.record = () => {
    const licences = $scope.selected_licences.selection.getSelectedRows();
    if (licences.length === 0) {
      Flash.show('no_licences_selected');
    }

    angular.forEach(licences, (row) => {
      let data = {
        'licence_id': row.id,
        'product_id': $scope.product_id
      };

      endpoint.products.post(data).then(res => {
        Flash.show(res.success, 'success');
        $scope.getProducts();
        $scope.product_id = 0;
      });
    });
  };

  $scope.update = element => {
    $rootScope.xhr = true;
    element.save();
    $timeout(() => {
      $rootScope.xhr = false;
    }, 1000);
  };

  $scope.updateEmail = element => {
    $rootScope.xhr = true;
    element.save().then(data => {
      if (Object.keys(data).includes('success')) {
        Flash.show(data.success, 'success');
      }

      if (Object.keys(data).includes('error')) {
        Flash.show(data.error);
      }

      $rootScope.xhr = false;
    });
  };

  $scope.getProducts = () => {
    let licences = $scope.selected_licences.selection.getSelectedRows(),
      filters = $scope.set_filter(licences);
      
    if (filters) {
      filters = {licence_id: filters};
    }

    endpoint.products.getList(filters)
      .then(data => {
        $scope.products = data;
      });
  };

  $scope.getEmail = () => {
    let licences = $scope.selected_licences.selection.getSelectedRows(),
      filters = $scope.set_filter(licences);

    if (filters) {
      filters = {licence_id: filters};
    }

    endpoint.email.getList(filters)
      .then(data => {
        $scope.emails = data;
      });
  };

  $scope.delete = () => {
    let cols = $scope.selected_products.selection.getSelectedRows();
    angular.forEach(cols, item => {
      item.remove().then(() => {
        Flash.show('item_deleted', 'success');
        $scope.getProducts();
      });
    });
    $timeout(() => {
      $scope.refresh();
    }, 1500);
  };

  $scope.set_filter = (array) => {
    const { length } = `${array}`;

    if (length > 100) {
      Flash.show('no_more_selection');
      return false;
    }

    if (length > 0) {
      let filters = '';
      angular.forEach(array, (row) => {
        filters += `${row.id},`;
      });
      return filters;
    }

    return false;
  };

  $scope.unselect_row = (row) => {  
    $scope.selected_licences.selection.unSelectRow(row);
  };

  $scope.refresh = () => {
    endpoint.licences.getList().then(res => {
      angular.forEach(res, row => {
        row.quarantine = !!row.quarantine;
        $scope.licences.push(row);
      });
    });
  };
  $scope.refresh();
}]);
