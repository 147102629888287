'use strict';

angular.module('portailWebApp')
    .controller('I18NlanguagesController', ['$scope', '$rootScope', 'Flash', 'apiRest', '$timeout', 'Monitoring', ($scope, $rootScope, Flash, apiRest, $timeout, Monitoring) => {
        let endpoint = apiRest('admin/languages', 1);

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'i18n_languages';
        $rootScope.helpLink = '';
        //Defining the endpoint

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(data => {
                $scope.gridData = data;
            });
        };
        $scope.refresh();

        $scope.current = [];

        $scope.gridOptions = Monitoring.setGridOption('gridData', [
            {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
            {field: 'code', displayName: 'code', headerCellFilter: 'translate'}
        ], true, false, 'high');
        $scope.gridOptions.onRegisterApi = gridApi => {
            $scope.gridApi = gridApi;
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;
            angular.forEach(cols, item => {
                item.remove();

            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };

        $scope.record = () => {
            let data = {
                'name': $scope.name,
                'code': $scope.code
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.name = null;
                $scope.code = null;
                $scope.creation = false;
            });
        };
    }]);
