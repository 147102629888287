'use strict';

angular.module('portailWebApp')
    .controller('MaintenanceController', ['$scope', '$rootScope', 'Flash', '$window', ($scope, $rootScope, Flash, $window) => {
        var st = $rootScope.lastErrorStatus;
        if (st === 0) {
            $scope.message = {
                title: 'ERROR_CONNECT',
                text: 'ERROR_CONNECT_TEXT',
                img: '/images/error_lost_connection.svg',
                alt: 'ERROR_CONNECT_ALT'
            };
        } else if (st === 503) {
            $scope.message = {
                title: 'ERROR_MAINTENANCE',
                text: 'ERROR_MAINTENANCE_TEXT',
                img: '/images/error_maintenance.svg',
                alt: 'ERROR_MAINTENANCE_ALT'
            };
        } else {
            $scope.message = {
                title: 'ERROR_UNKNOWN',
                text: 'ERROR_UNKNOWN_TEXT',
                img: '/images/error_unknown.svg',
                alt: 'ERROR_UNKNOWN_ALT'
            };
        }

        $rootScope.subTitle = $scope.message.title;
        $scope.goBack = () => {
            $window.history.back();
        };
    }]);
