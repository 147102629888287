'use strict';

angular.module('portailWebApp')
  .controller('toolsRemoteController', ['$scope', '$location', '$rootScope', 'Flash', 'apiRest', '$timeout', '_', 'uiGridConstants', 'customFilter', '$translate', '$uibModal', 'Monitoring', '$routeParams', ($scope, $location, $rootScope, Flash, apiRest, $timeout, _, uiGridConstants, customFilter, $translate, $uibModal, Monitoring, $routeParams) => {
    let ep = {
      remote: apiRest('tools/remoteaccess', true)
    };

    //Flash
    Flash.clear();
    //Layout
    $rootScope.pageClass = '';
    //Title
    $rootScope.subTitle = 'remote_access';
    //$rootScope.helpLink = 'yooportal/outils/rapport-config.html';

    //init
    $scope.remoteRef = [];
    $scope.filters = [];
    // Monitoring service
    Monitoring.refreshFilters('view_remote', data => {
      $scope.filters = data;
      if (data.length > 0) {
        $scope.loadFilter(data[0].id);
      }
      $timeout(() => {
        let type = $routeParams.filterType,
          value = $routeParams.filterValue || null;
        if (type) {
          switch (type) {
          case 'status':
            $scope.filterColumnStatus('off');
            break;
          case 'organization':
            $scope.filterColumnCapacity(79);
            break;
          case 'licence':
            $scope.filterColumnLicence(value);
            break;
          default:
          }
        }
      }, 1000);
    });

    $scope.loadIt = () => {
      Monitoring.loadFilters('view_remote', id => {
        $scope.loadFilter(id);
      });
    };

    $scope.loadFilter = id => {
      Monitoring.loadFilter(id, data => {
        //if filters
        let columns = $scope.gridApi.saveState.save();
        angular.forEach(columns.columns, (item, i) => {
          angular.forEach(item.filters, (filter, j) => {
            if (filter.term) {
              data.data.grid.columns[i].filters[j].term = filter.term;
            }
          });
        });
        $scope.relation_id = data.data.relation_id;
        $scope.gridApi.saveState.restore($scope, data.data.grid);
      });
    };

    $scope.saveIt = () => {
      Monitoring.saveFilter('view_remote', {
        grid: $scope.gridApi.saveState.save()
      });
    };
    //refresh functions
    $scope.refresh = () => {
      ep.remote.getList().then(data => {
        $scope.remoteRef = [];
        data.forEach(v => {
          v.status = Monitoring.convert_status(v.status);
          if (v.status === 'on' || v.status === 'issue') {
            v.remote_access = 1;
          }
          $scope.remoteRef.push(v);
        });
        $scope.count = _.countBy(data, row => {
          return row.status;
        });
      });
    };

    //Init
    $scope.refresh();

    //Grids
    $scope.gridOptions = Monitoring.setGridOption('remoteRef', [
      {
        field: 'name',
        displayName: 'organizations',
        type: 'date',
        headerCellFilter: 'translate',
        enableFiltering: true,
        cellTemplate: '<div class="ui-grid-cell-contents">{{COL_FIELD CUSTOM_FILTERS}} <a ng-click="grid.appScope.organization_info(row.entity.id)"><i class="material-icons md-18">info</i></a></div>'
      },
      {
        field: 'licence',
        type: 'number',
        displayName: 'licence',
        headerCellFilter: 'translate'
      },
      {
        field: 'status',
        displayName: 'status',
        headerCellFilter: 'translate',
        enableFiltering: true,
        cellTemplate: '<div class="ui-grid-cell-contents"><i translate="{{COL_FIELD CUSTOM_FILTERS}}"></i></div>',
        filterHeaderTemplate: customFilter.bool.template,
        filter: {
          term: [],
          options: customFilter.bool.filterOption,
          condition: customFilter.bool.filterStatus
        },
        cellClass: (grid, row, col) => {
          return Monitoring.colorHelper.pipeClass(grid.getCellValue(row, col));
        }
      },
      {
        field: 'remote_access',
        displayName: 'remote_access',
        headerCellFilter: 'translate',
        enableFiltering: true,
        cellTemplate: '<div class="ui-grid-cell-contents text-center"><div ng-if="row.entity.remote_access" ng-click="grid.appScope.getRemoteOptions(row.entity.id)"><i class="material-icons md-18">build</i><span translate="remote_access"></span></div></div>'
      }
    ], false, true);
    $scope.gridOptions.onRegisterApi = gridApi => {
      $scope.gridApi = gridApi;
    };

    $scope.getRemoteOptions = id => {
      ep.remote.get(id).then(data => {
        $rootScope.openModal('small', 'modalRemoteOptionsController', 'modal_remote_options.html', data);
      });
    };
    $scope.filterColumnStatus = val => {
      let col = _.find($scope.gridApi.grid.columns, {
        field: 'status'
      });
      if (col) {
        col.filter.term = val;
      }
      $scope.gridApi.core.notifyDataChange(uiGridConstants.dataChange.COLUMN);
    };

    $scope.filterColumnLicence = val => {
      var col = _.find($scope.gridApi.grid.columns, {
        field: 'licence'
      });
      if (col) {
        col.filters[0].term = val;
        $scope.gridApi.core
          .notifyDataChange(uiGridConstants.dataChange.COLUMN);
      }
    };

  }]);
