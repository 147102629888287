'use strict';

angular.module('portailWebApp')
    .controller('RelationTypesAdmController', ['$scope', '$location', '$rootScope', 'Flash', 'TokenRestAngular', '$timeout', ($scope, $location, $rootScope, Flash, TokenRestAngular, $timeout) => {
        let endpoint = TokenRestAngular.all('admin/relationtypes');

        //Flash
        Flash.clear();
        //Layout
        $rootScope.pageClass = '';
        //Title
        $rootScope.initDropdown('.yp-toolbar');
        $rootScope.subTitle = 'relationtypes_admin';
        $rootScope.helpLink = '';

        //Get the data
        $scope.gridData = [];

        $scope.refresh = () => {
            endpoint.getList().then(res => {
                $scope.gridData = res;
            });
        };
        $scope.refresh();

        $scope.current = [];

        $scope.gridOptions = {
            data: 'gridData',
            columnDefs: [
                {field: 'name', displayName: 'name', headerCellFilter: 'translate'},
                {field: 'description', displayName: 'description', headerCellFilter: 'translate'}
            ],
            paginationPageSizes: [10, 50, 100, 500, 1000],
            paginationPageSize: 10,
            enableFiltering: true,
            enableSorting: true,
            enableRowSelection: true,
            onRegisterApi: gridApi => {
                $scope.gridApi = gridApi;
                $scope.gridApi.edit.on.afterCellEdit($scope, rowEntity =>{
                    $scope.update(rowEntity);
                });
            }
        };

        $scope.record = () => {
            let data = {
                'name': $scope.name,
                'description': $scope.description
            };
            endpoint.post(data).then(res => {
                Flash.show(res.success, 'success');
                $scope.refresh();
                $scope.name = null;
                $scope.description = null;
                $scope.creation = false;
            });
        };

        $scope.update = element => {
            $rootScope.xhr = true;
            element.save();
            $timeout(() => {
                $rootScope.xhr = false;
            }, 1000);
        };

        $scope.delete = () => {
            let cols = $scope.gridApi.selection.getSelectedRows();
            $rootScope.xhr = true;

            angular.forEach(cols, item => {
                item.remove();
            });
            $timeout(() => {
                $scope.refresh();
                $rootScope.xhr = false;
            }, 1500);
        };
    }]);
