angular.module('portailWebApp')
  .filter('readbleDate', ['moment', moment => {
    return input => {
      let d = input ? moment(input) : null;
      return (d && d.isValid()) ? d.local().format('YYYY-MM-DD HH:mm') : null;
    };
  }])
  /*.filter('translateFilter', ['$translate', $translate => {
   return () => {
   let r;
   $translate('name').then(value => {
   r = value;
   });
   return r;
   };
   }])*/
  .filter('fromNow', ['moment', moment => {
    return input => {
      let d = input ? moment(input) : null;
      return (d && d.isValid()) ? d.fromNow() : null;
    };
  }])
  .filter('humanize_minute', ['moment', moment => {
    return input => {
      let workDay = 7 * 60,
        minutesLeft = input % workDay,
        completeDays = (input - minutesLeft) / workDay,
        result = completeDays * (24 * 60) + minutesLeft;


      return result !== 0 ? moment.duration(result, 'minutes').humanize() : 0;
    };
  }])
  .filter('humanize_m', ['moment', moment => {
    return input => {
      return input ? moment.duration(input, 'minutes').humanize() : 0;
    };
  }])
  .filter('utc2local', ['moment', moment => {
    return input => {
      return input ? moment.utc(input).local() : null;
    };
  }])
  .filter('UTCDate2locale', ['moment', moment => {
    return input => {
      return input ? moment(input).local().calendar() : 0;
    };
  }])
  .filter('humanize_d', ['moment', moment => {
    return input => {
      return input ? moment.duration(input, 'days').humanize() : 0;
    };
  }])
  .filter('min2AccurateDuration', ['moment', moment => {
    return input => {
      let d = '',
        days = 7 * 60;
      d += Math.floor((input ? moment.duration(input, 'minutes').asMinutes() : 0) / days) + ' jours ';
      input = input - Math.floor(input / days) * days;
      d += Math.floor((input ? moment.duration(input, 'minutes').asMinutes() : 0) / 60) + ' heures ';
      input = input - Math.floor(input / 60) * 60;
      d += Math.floor((input ? moment.duration(input, 'minutes').asMinutes() : 0)) + ' minutes';
      return d;
    };
  }])
  .filter('humanDate', ['moment', moment => {
    return input => {
      let d = input ? moment(input) : null;
      return (d && d.isValid()) ? d.calendar() : null;
    };
  }])
  .filter('cronParse', ['$window', 'moment', ($window, moment) => {
    return input => {
      let scheduledCron = input ? $window.later.parse.cron(input) : null,
        d = input ? moment.utc($window.later.schedule(scheduledCron).next()) : null;
      return d && d.isValid() ? d.calendar() : null;
    };
  }])
  .filter('humanDateTime', ['moment', moment => {
    return input => {
      let d = input ? moment(input) : null;
      return (d && d.isValid()) ? d.format('DD/MM/YYYY HH:mm:ss') : null;
    };
  }])
  .filter('convertStatus', ['Monitoring', Monitoring => {
    return input => {
      if (angular.isUndefined(input)) {
        return 'not_installed';
      }
      return Monitoring.convert_status(input);
    };
  }])
  .filter('convertOrgaType', () => {
    return input => {
      let type;
      switch (input) {
        case 0:
          type = 'client';
          break;
        case 1:
          type = 'seller';
          break;
        case 2:
          type = 'master';
          break;
        case 3:
          type = 'receiver';
          break;
        case 4:
          type = 'installer';
          break;
        default:
          type = '';
      }
      return type;
    };
  })
  .filter('startFrom', () => {
    return (input, start) => {
      start = +start;
      return input.slice(start);
    };
  }).filter('humanSize', () => {
    function getReadableFileSizeString(fileSizeInBytes) {

      var i = -1,
        byteUnits = [' kB', ' MB', ' GB', ' TB', 'PB', 'EB', 'ZB', 'YB'];
      do {
        fileSizeInBytes = fileSizeInBytes / 1000;
        i += 1;
      } while (fileSizeInBytes > 1000);

      return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
    }
    return (input) => getReadableFileSizeString(input);
  });
