'use strict';

angular.module('portailWebApp')
    .controller('LoginController', ['$scope', '$location', '$rootScope', 'localStorageService', 'apiRest', '$timeout', 'Flash', 'userService', 'brand', '$log', function ($scope, $location, $rootScope, localStorageService, apiRest, $timeout, Flash, userService, brand, $log) {
        //Layout
        const vm = this;

        vm.tokensms = null;
        vm.rememberMe = false;
        vm.viewToken = false;
        vm.try = login;
        vm.reInitForm = reInit;

        if (userService.isAuth()) {
            $location.url('/main');
        }
        brand.setBrand();

        /////////////////////////////////////////////

        function authSuccess(item) {
            vm.viewToken = (item === 'need_token');
        }

        function displayError(err) {
            $log.log('err : ', err);
            Flash.show(err);
        }

        function getCredentials(inputs, sms) {
            let credentials = {
                username: inputs.email,
                password: inputs.password,
                // eslint-disable-next-line no-undef
                client_id: Config.get('apiClientId'),
                // eslint-disable-next-line no-undef
                client_secret: Config.get('apiClientSecret'),
                grant_type: inputs.rememberMe ? 'password_remember' : 'password'
            };
            if (inputs.token && sms) {
                credentials.token = inputs.token;
            }
            return $.param(credentials);
        }

        function login(inputs, wantedUrl, haveSMSToken) {
            let url = wantedUrl || '/main',
                credentials = getCredentials(inputs, !!haveSMSToken);

            if (haveSMSToken) {
                tryLoginWithToken(credentials, url);
            } else {
                tryLogin(credentials, url);
            }

        }

        function reInit() {
            vm.viewToken = false;
        }

        function tryLogin(credentials, wantedUrl) {
            userService
                .tryLogin(credentials, wantedUrl)
                .then(authSuccess)
                .catch(displayError);
        }

        function tryLoginWithToken(credentials, wantedUrl) {
            userService
                .tryToken(credentials, wantedUrl)
                .then(authSuccess)
                .catch(displayError);
        }


        $rootScope.pageClass = 'login-page';
        $rootScope.subTitle = '';
        $rootScope.helpLink = 'yooportal/generalites/authentification.html';

    }]);
