angular.module('portailWebApp')
  .controller('SupportTicketViewController', [
    '$window',
    '$scope',
    '$timeout',
    '$route',
    '$rootScope',
    'Flash',
    'TokenRestAngularMS',
    '$routeParams',
    'Upload',
    'localStorageService',
    '_',
    '$location',
    '$uibModal',
    '$translate',
    'clipboard',
    'moment',
    '$log',
    '$filter',
    'Person',
    'userService',
    '$interval', ($window, $scope, $timeout, $route, $rootScope, Flash, TokenRestAngularMS, $routeParams, Upload, localStorageService, _, $location, $uibModal, $translate, clipboard, moment, $log, $filter, Person, userService, $interval) => {
      var promise;
      //IS auth and admin
      $scope.createintervention = $rootScope.isAction('create_intervention');

      $scope.scale = false;
      $scope.kb_article = false;

      //Flash
      Flash.clear();
      //Layout
      $rootScope.pageClass = '';
      //Title
      $rootScope.subTitle = 'ticket_view';
      $rootScope.helpLink = 'yooportal/support/ticket-lecture.html';

      let viewReady,

        original$loca = $location.path,
        //endpoint
        tk = TokenRestAngularMS,
        route = {
          tickets: 'supportv2/tickets'
        },
        ep = {
          comments: tk.all('support/ressources/comments'),
          licences: tk.all('support/ressources/licences'),
          tickets: tk.all('supportv2/tickets'),
          problems: tk.all('supportv2/problems'),
          interventions: tk.all('support/interventions'),
          types: tk.all('support/ressources/types'),
          orga: tk.all('support/ressources/organizations'),
          origins: tk.all('support/ressources/origin'),
          impacts: tk.all('support/ressources/impact'),
          frozen_reasons: tk.all('support/ressources/frozen_reason'),
          criticities: tk.all('support/ressources/criticity'),
          categories: tk.all('support/ressources/category'),
          baskets: tk.all('support/ressources/baskets'),
          actions: tk.all('support/ressources/action'),
          files: tk.all('supportv2/attachments'),
          users: tk.all('supportv2/contacts'),
          ticket_types: tk.all('support/ressources/ticket_type')

        },
        saveLocal = (key, value) => {
          return localStorageService.set(key, value);
        },
        Intervention = function Intervention(o) {
          let i = this;
          i.author = o.author || null;
          i.support = o.support || null;
          i.external_comment = o.external_comment || null;
          i.internal_comment = o.internal_comment || null;
          i.attachment = o.attachment || [];
          i.created_at = o.created_at !== 0 ? $filter('utc2local')(o.created_at) : moment.utc().local().toDate();
          i.created_at_full = o.created_at ? moment(i.created_at).format('LLLL:s') : '';
          i.updated_at = o.updated_at !== 0 ? $filter('utc2local')(o.created_at) : moment.utc().local().toDate();
        },
        updateFreezeView = t => {
          let fl = t.freezelist ? t.freezelist.find(v => !v.end) : null;
          if (fl) {
            t.support2_froze_id = null;
            fl.end = moment.utc().local();
            fl.duration = (fl.end.diff(moment.utc(fl.begin).local()) / 1000) / 60;
          } else if (t.support2_froze_id) {
            t.freezelist = t.freezelist || [];
            t.freezelist.push({
              begin: moment.utc().local(),
              end: null,
              frozen_reason: t.frozen_reason
            });
          }
        },
        Ticket = function Ticket(o) {
          let t = this;
          t.id = o.id || null;
          t.planning_date = null;
          t.following_date = null;
          t.rma = o.rma || null;
          t.organization = o.organization || null;
          t.contracts = o.contracts || null;
          t.isCare = o.contracts && o.contracts && /MONCARE/.test(o.contracts);
          t.licence = o.licence || null;
          t.lock_date = $filter('utc2local')(o.lock_date) || null;
          t.lock_user_id = o.lock_user_id || null;
          t.origin = o.origin || null;
          t.category = o.category || null;
          t.basket = o.basket || null;
          t.frozen_reason = o.frozen_reason || null;
          t.criticity = o.criticity || null;
          t.impact = o.impact || null;
          t.priority = o.priority || 0;
          t.status = o.status || false;
          t.support2_froze_id = o.support2_froze_id || null;
          t.created_at = $filter('utc2local')(o.created_at) || null;
          t.git = o.git || null;
          t.grt = o.grt || null;
          t.problem = o.problem || null;
          t.slagit = o.slagit || null;
          t.slagrt = o.slagrt || null;
          t.ticket_type = o.ticket_type || null;
          t.freezelist = o.freeze ? _.map(o.freeze, v => {
            return {
              begin: $filter('utc2local')(v.frozen_begin),
              end: $filter('utc2local')(v.frozen_end),
              frozen_reason: v.frozen_reason,
              duration: moment
                .duration(moment(v.frozen_end)
                  .diff(moment(v.frozen_begin)), 'milliseconds')
                .asMinutes()
            };
          }) : null;
          t.updated_at = $filter('utc2local')(o.updated_at) || null;
          t.scale = !!o.scale;
          t.kb_article = !!o.kb_article;
          t.contacts = [];
          t.intervention = [];

          if (t.git > t.slagit && t.licence) {
            t.gitcolor = 'yp-ticket__meta--alert';
          } else {
            t.gitcolor = 'yp-ticket__meta';
          }
          if (t.grt > t.slagrt && t.licence) {
            t.grtcolor = 'yp-ticket__meta--alert';
          } else {
            t.gitcolor = 'yp-ticket__meta';
          }
          /**
           * Methodes
           */
          t.addIntervention = function (raw) {
            this.intervention.push(new Intervention(raw));
          };

          t.addContact = function (raw) {
            this.contacts.push(new Person(raw));
          };

          t.toggleStatus = function () {
            t.status = !t.status;
          };

          t.toggleFreeze = function () {
            let raTicket = tk.restangularizeElement(null, t, route.tickets);
            const reasonId = $scope.ticket.frozen_reason && $scope.ticket.frozen_reason.id;
            $log.log(reasonId);
            if (!$scope.ticket.support2_froze_id && !reasonId) {
              $log.warn('cant froze without reason');
              return;
            }
            raTicket.one('freeze').put({ frozen_reason_id: reasonId }).then(data => {
              if (data.froze_id) {
                t.support2_froze_id = data.froze_id;
              }
              updateFreezeView(t);
            });
          };

          t.setPlanningDate = function (date, force) {
            var valid;
            date = $filter('utc2local')(date);
            if (date) {
              valid = t.isComing(date);
            }

            if (valid || force || !$scope.currentUser.support) {
              t.planning_date = date.toDate();
            } else {
              t.planning_date = null;
              $scope.messages.submit.field.push('planning_date');
            }
          };

          t.setFollowingDate = function (date, force) {
            var valid;
            date = $filter('utc2local')(date);
            valid = t.isComing(date);
            if (valid || force || !$scope.currentUser.support) {
              t.following_date = date.toDate();
            } else {
              t.following_date = null;
              $scope.messages.submit.field.push('following_date');
            }
          };

          t.isComing = function (date) {
            var now = moment.utc();
            return date && date.isAfter(now);
          };

          if (o.intervention && o.intervention.length) {
            o.intervention.forEach(v => {
              t.addIntervention(v);
            });
          }
          if (o.contacts && o.contacts.length) {
            o.contacts.forEach(v => {
              t.addContact(v);
            });
          }

          if (o.planning_date) {
            t.setPlanningDate(o.planning_date, true);
          }
          if (o.following_date) {
            t.setFollowingDate(o.following_date, true);
          }
        },
        cloneTicket = () => angular.copy({
          following_date: $scope.ticket.following_date,
          planning_date: $scope.ticket.planning_date,
          organization: $scope.ticket.organization,
          rma: $scope.ticket.rma,
          licence: $scope.ticket.licence,
          origin: $scope.ticket.origin,
          category: $scope.ticket.category,
          criticity: $scope.ticket.criticity,
          impact: $scope.ticket.impact,
          frozen_reason: $scope.ticket.frozen_reason,
          priority: $scope.ticket.priority,
          basket: $scope.ticket.basket,
          contacts: $scope.ticket.contacts,
          status: $scope.ticket.status,
          ticket_type: $scope.ticket.ticket_type,
          problem: $scope.ticket.problem
        });

      $location.path = (path, reload) => {
        if (reload === false) {
          let lastRoute = $route.current,
            un = $rootScope.$on('$locationChangeSuccess', () => {
              $route.current = lastRoute;
              un();
            });
        }
        return original$loca.apply($location, [path]);
      };

      //methods
      $scope.inputs = {};
      $scope.ready = {};
      $scope.inter = {
        attachment: []
      };
      $scope.messages = {
        submit: {
          field: []
        }
      };

      $scope.setComment = (item) => {
        if ($scope.currentUser.support) {
          let soon = moment.utc().add(item.addToFollow, 'minute').toDate();
          $scope.inter.external_comment = item.text;
          soon.setSeconds(0);
          soon.setMilliseconds(0);
          $scope.dunningCB = true;
          $scope.ticket.dunning_mail = true;
          $scope.ticket.setFollowingDate(soon);
        }
      };

      $scope.createBlankTicket = () => {
        let ticket = {},
          userMatch;
        ticket.origin = _.findWhere($scope.inputs.origins, {
          value: 'YooPortal'
        });
        if ($scope.currentUser.support) {
          ticket.origin = _.findWhere($scope.inputs.origins, {
            id: userService.user().origin_id
          });
          ticket.basket = _.findWhere($scope.inputs.baskets, {
            id: userService.user().basket_id
          });
          ticket.category = _.findWhere($scope.inputs.categories, {
            id: userService.user().category_id
          });

        }

        ticket.criticity = $scope.inputs.criticities[0];
        ticket.status = true;
        $scope.ticket = new Ticket(ticket);

        $scope.editMod = true;

        if (!$scope.currentUser.support) {
          $scope.ticket.addContact($scope.currentUser);
          $scope.ticket.organization = $scope.currentUser.organization;
        }

        userMatch = _.findWhere($scope.inputs.contacts, {
          id: $scope.currentUser.id
        });
        if (userMatch) {
          userMatch.disabled = true;
        }
      };

      $scope.assignTicket = response => {
        $scope.ticket = new Ticket(response);
        $scope.ticketBasicBak = cloneTicket();

        if (!$scope.ticket.lock_date || moment($scope.ticket.lock_date).unix() < moment().unix() || $scope.ticket.status) {
          //Lock file
          let lockfile = TokenRestAngularMS.all('supportv2/tickets/lock/' + $scope.ticket.id);
          lockfile.post({
            user: userService.user().id
          }).then(() => {
            $scope.messages.lockstate = {
              user: 'you',
              date: moment($scope.ticket.lock_date).calendar()
            };
          });
        }
        disableActiveContact($scope.ticket.contacts);
        $scope.filterLicence();
      };

      $scope.refresh = {
        comment: () => {
          ep.comments.getList().then(data => {
            $scope.inputs.comment = _.map(data, v => {
              return {
                id: v.id,
                name: v.comment_name,
                text: v.comment_text,
                addToFollow: v.addToFollow
              };
            });
          });
        },
        problem: () => {
          ep.problems.getList().then(data => {
            $scope.inputs.problems = _.map(data, v => {
              return {
                id: v.id,
                name: v.name,
                status: v.status
              };
            }).filter(rawData => {
              return rawData.status === 1;
            });
            $scope.inputs.problems.push({
              id: null,
              name: 'none'
            });
          });
        },
        origins: () => {
          var storage = localStorageService.get(ep.origins.route);

          if (!storage) {
            ep.origins.getList().then(data => {
              $scope.inputs.origins = _.map(data, v => {
                return v;
              });
              saveLocal(ep.origins.route, $scope.inputs.origins);
              $scope.ready.origins = true;
            });
          } else {
            $scope.inputs.origins = storage;
            $scope.ready.origins = true;
          }
        },
        impacts: () => {
          var storage = localStorageService.get(ep.impacts.route);
          if (!storage) {
            ep.impacts.getList().then(data => {
              $scope.inputs.impacts = _.map(data, v => {
                return v;
              });
              saveLocal(ep.impacts.route, $scope.inputs.impacts);
              $scope.ready.impacts = true;
            });
          } else {
            $scope.inputs.impacts = storage;
            $scope.ready.impacts = true;
          }
        },
        frozen_reasons: () => {
          var storage = localStorageService.get(ep.frozen_reasons.route);
          if (!storage) {
            ep.frozen_reasons.getList().then(data => {
              $scope.inputs.frozen_reasons = [{ id: null, value: 'none' }, ..._.map(data, v => {
                return v;
              })];
              saveLocal(ep.frozen_reasons.route, $scope.inputs.frozen_reasons);
              $scope.ready.frozen_reasons = true;
            });
          } else {
            $scope.inputs.frozen_reasons = storage;
            $scope.ready.frozen_reasons = true;
          }

          $log.log($scope.inputs);
        },
        criticities: () => {
          let storage = localStorageService.get(ep.criticities.route);

          if (!storage) {
            ep.criticities.getList().then(data => {
              $scope.inputs.criticities = _.map(data, v => {
                return v;
              });
              saveLocal(ep.criticities.route, $scope.inputs.criticities);
              $scope.ready.criticities = true;
            });
          } else {
            $scope.inputs.criticities = storage;
            $scope.ready.criticities = true;
          }
        },
        ticket: (id) => {
          let reqId = id || parseInt($routeParams.id, 10);
          if (reqId) {
            ep.tickets.get(reqId)
              .then(response => {
                var nd = $filter('utc2local')(response.updated_at);
                if ($scope.ticket.updated_at) {
                  if ($scope.ticket.updated_at.isBefore(nd)) {
                    $scope.newResponse = response;
                    return;
                  }
                } else {
                  $timeout(() => {
                    $window.document.body.scrollTop = $window.document.body.scrollHeight;
                  }, 1500);
                }
                $scope.assignTicket(response);
              })
              .catch(() => {
                $location.path('support/ticketlist');
              });
          } else {
            $scope.createBlankTicket();
          }
        },
        orgas: () => {
          let storage = localStorageService.get(ep.orga.route);
          if (storage) {
            $scope.inputs.orga = storage;
            $scope.ready.orga = true;
          }

          ep.orga.getList().then(orgas => {
            $scope.inputs.orga = _.map(orgas, v => {
              return {
                id: v.id,
                name: v.name,
                certified: v.certified
              };
            });

            $translate('all').then(trad => {
              $scope.inputs.orga.unshift({
                id: null,
                name: trad
              });
            });

            saveLocal(ep.orga.route, $scope.inputs.orga);
            $scope.ready.orgas = true;
          });
        },
        licences: () => {
          var storage = localStorageService.get(ep.licences.route),
            predefinedEntry = () => {
              if (!$scope.ticket.id) {
                let type = $routeParams.filterType,
                  value = $routeParams.filterValue,
                  inpKey;
                if (type && $scope.ticket.hasOwnProperty(type)) {

                  if (type === 'licence' || type === 'organization') {

                    inpKey = type === 'licence' ? type + 's' : 'orga';
                    $scope.ticket[type] = $scope
                      .inputs[inpKey].find(x => x[type] === value);
                  }
                }

              }
            };
          if (storage) {
            $scope.backuplicences = storage;
            $scope.filterLicence();
            predefinedEntry();
          }
          ep.licences.getList().then(licences => {
            $scope.backuplicences = licences;

            $scope.filterLicence();
            predefinedEntry();
            $scope.ready.licences = true;
          });
        },
        users: () => {
          ep.users.getList().then(users => {
            $scope.inputs.users = _.map(users, u => {
              u = new Person(u);
              if ($scope.ticket && _.findWhere($scope.ticket.contacts, {
                email: u.email
              })) {
                u.disabled = true;
              }
              return u;
            });
            $scope.ready.users = true;
          });
        },
        categories: () => {
          let storage = localStorageService.get(ep.categories.route);

          if (!storage) {
            ep.categories.getList().then(data => {
              $scope.inputs.categories = _.map(data, v => {
                return v;
              });
              $scope.ready.categories = true;
              saveLocal(ep.categories.route, $scope.inputs.categories);
            });
          } else {
            $scope.inputs.categories = storage;
            $scope.ready.categories = true;
          }

        },
        baskets: () => {
          let storage = localStorageService.get(ep.baskets.route);
          $scope.inputs.baskets = [];
          if (!storage) {
            ep.baskets.getList().then(data => {
              $scope.inputs.baskets = _.map(data, v => {
                return v;
              });
              $scope.ready.baskets = true;
              saveLocal(ep.baskets.route, $scope.inputs.baskets);
            });
          } else {
            $scope.inputs.baskets = storage;
            $scope.ready.baskets = true;
          }
          $translate('none').then(msg => {
            $scope.inputs.baskets.unshift({
              id: null,
              name: msg
            });
            $scope.basket_id = 0;
          });
        },
        ticket_types: () => {
          var storage = localStorageService.get(ep.ticket_types.route);

          if (!storage) {
            ep.ticket_types.getList().then(data => {
              $scope.inputs.ticket_types = _.map(data, v => {
                return v;
              });
              saveLocal(ep.types.route, $scope.inputs.ticket_types);
              $scope.ready.ticket_types = true;
            });
          } else {
            $scope.inputs.ticket_types = storage;
            $scope.ready.ticket_types = true;
          }
        }
      };

      function disableActiveContact(contactList) {
        contactList.forEach(contact => {
          let match = $scope.inputs.users.find(user => user.name === contact.name);
          if (match) {
            match.disabled = true;
          }
        });
      }

      $scope.refresh.problem();
      $scope.refresh.users();
      $scope.refresh.origins();
      $scope.refresh.impacts();
      $scope.refresh.criticities();
      $scope.refresh.frozen_reasons();
      $scope.refresh.baskets();
      $scope.refresh.categories();
      $scope.refresh.ticket_types();

      /**
       * We want to filter the licence autocompletion list  if an organisation is selected
       *
       * need a ticket to be created and the licence list to be loaded
       * should be triggered any time an orga is chosen
       */
      $scope.filterLicence = () => {
        let t = $scope.ticket;

        if (t && t.organization && t.organization.id) {
          $scope.inputs.licences = _.where($scope.backuplicences, {
            orga_id: t.organization.id
          });
          if (t.licence && (t.organization.id !== t.licence.orga_id)) {
            t.licence = null;
          }
        } else {
          $scope.inputs.licences = angular.copy($scope.backuplicences);
        }
      };

      $scope.switchVersion = () => {
        $scope.assignTicket($scope.newResponse);
        $scope.newResponse = null;
      };

      /**
       * We want to populate the organization field accordingly if a licence is selected
       * before choosing an orga
       *
       * need a ticket to be created and the orgas list to be loaded
       * should be triggered any time a licence is chosen
       */
      $scope.applyLicenceToOrga = () => {
        let t = $scope.ticket,
          match = _.findWhere($scope.inputs.orga, {
            id: t.licence.orga_id
          });
        t.organization = match || t.organization;
      };

      /**
       * test if
       * - lock_date property is thrusty
       * - the user who locked it is no the current one
       * - lock_date is after now
       * @returns {Boolean}
       */
      $scope.checkLockState = ticket => {
        return (
          ticket.lock_date && ticket.lock_user_id !== $scope.currentUser.id && moment(ticket.lock_date).isAfter(moment())
        );
      };

      $scope.$watch('ticket.licence', nv => {
        if (nv && nv.licence) {
          $scope.applyLicenceToOrga();
        }
      });

      $scope.toggleStatus = () => {
        $scope.ticket.toggleStatus();
        $scope.submitChanges(true);
      };
      $scope.toggleFreeze = () => {
        $scope.ticket.toggleFreeze();
      };
      $scope.toggleIntern = () => {
        if ($scope.intern) {
          $scope.inter.internal_comment = null;
        } else {
          $scope.intern = false;
        }
        $scope.intern = !$scope.intern;
      };
      $scope.merge = () => {
        //Open the modal
        let modal = $uibModal.open({
          templateUrl: 'scripts/ctrl/modal/modal_fusion_ticket.html',
          controller: 'modalFusionTicketController',
          backdrop: 'static',
          size: 'dialog',
          resolve: {
            currentTicket: () => {
              return $scope.ticket;
            },
            ticketsList: () => {
              return $scope.ticketsList;
            }
          }
        });
        //Callback action
        modal.result.then(id => {
          ep.tickets2 = tk.all('supportv2/tickets/fusion/' + $scope.ticket.id);
          ep.tickets2.post({
            to: id
          }).then(data => {
            Flash.show(data.success, 'success');
            $location.path('/support/ticketview/' + data.id);
          });
        });
      };

      $scope.files = {
        send: files => {
          if (files && files.length) {
            files.forEach(file => {
              Upload.upload({
                // eslint-disable-next-line no-undef
                url: Config.get('apiRoot') + '/v1/' + ep.files.route,
                fields: {
                  'ticket_id': $scope.ticket.id
                },
                headers: {
                  Authorization: 'Bearer ' + localStorageService.get('access_token'),
                  Membership: localStorageService.get('currMs')
                },
                file: file
              }).progress(() => {
                $rootScope.xhr = true;
              }).success(data => {
                $rootScope.xhr = false;
                $scope.inter.attachment.push(data.file);
                Flash.show(data.success, 'success');
              }).error(data => {
                $rootScope.xhr = false;
                Flash.show(data.error, 'error');
              });
            });
          }
        },
        get: file => {
          ep.files.withHttpConfig({
            responseType: 'blob'
          }).get(file.id).then(data => {
            /* for unknown reasons, the implicit window(.saveAs()) was overwritten - fix : redeclaring it with the specific angular interface for window */
            $window.saveAs(data, file.filename);
          });
        },
        clear: file => {
          $scope.inter.attachment.splice(_.indexOf($scope.inter.attachment, file), 1);
        }
      };
      $scope.submitChanges = (force) => {
        let saveInter = (response, tUpdated) => {
            $scope.ticket.id = parseInt(response.id, 10);
            let raInter = tk.restangularizeElement(null, $scope.inter, route.tickets + '/' + response.id + '/interventions');

            if (raInter.external_comment || raInter.internal_comment) {
              raInter.author = $scope.currentUser;
              raInter.support = $scope.currentUser.support;
              raInter.save()
                .then(() => {
                  const t = $scope.ticket;
                  t.addIntervention($scope.inter);
                  updateFreezeView(t);
                  $scope.inter = new Intervention({});
                  $scope.messages.submit = {
                    field: []
                  };
                });
            } else {
              $scope.messages.submit = $scope.messages.submit || {
                valid: false,
                field: []
              };
              if (!tUpdated) {
                $scope.messages.submit.field
                  .push('intervention_requirement');
              } else {
                $scope.messages.submit = {
                  field: []
                };
              }

            }
          },
          saveTicket = () => {

            let raTicket = tk.restangularizeElement(null, $scope.ticket, route.tickets);
            if (raTicket.id) {
              raTicket.fromServer = true;
            }
            return raTicket.save();

          },
          checkValid = () => {
            let requiredSupport = [
                'following_date',
                'planning_date',
                'organization',
                'licence',
                'origin',
                'category',
                'criticity',
                'impact'
              ],
              requiredLambda = [
                'organization',
                'licence',
                'origin',
                'category',
                'criticity',
                'impact'
              ],
              res = {
                field: []
              };
            if ($scope.currentUser.support === true) {
              requiredSupport.forEach(v => {
                if ($scope.ticket[v] === null) {
                  res.field.push(v);
                }
              });
            } else {
              requiredLambda.forEach(v => {
                if ($scope.ticket[v] === null) {
                  res.field.push(v);
                }
              });
            }
            res.valid = !res.field.length;
            $scope.messages.submit = res;
            return res.valid;
          },
          checkUpdate = () => {

            /**
             * We dont check for id and support2_frozen_id as if they
             * have changed its a response from something that is already stored
             */
            let original = $scope.ticketBasicBak,
              subject = {
                following_date: $scope.ticket.following_date,
                planning_date: $scope.ticket.planning_date,
                organization: $scope.ticket.organization,
                rma: $scope.ticket.rma,
                licence: $scope.ticket.licence,
                origin: $scope.ticket.origin,
                category: $scope.ticket.category,
                criticity: $scope.ticket.criticity,
                impact: $scope.ticket.impact,
                ticket_type: $scope.ticket.ticket_type,
                basket: $scope.ticket.basket,
                contacts: $scope.ticket.contacts,
                status: $scope.ticket.status,
                problem: $scope.ticket.problem
              };

            if (!subject.organization.id) {
              subject.organization.id = $scope.currentUser.organization.id;
            }
            return !_.isEqual(subject, original);
          };

        if ((checkUpdate() || !$scope.ticket.id) && (checkValid() || force)) {
          if ($scope.ticket.id || (!$scope.ticket.id && $scope.inter.external_comment)) {
            saveTicket()
              .then(response => {
                $scope.ticketBasicBak = cloneTicket();
                if (!$scope.ticket.id) {
                  $location.path('/support/ticketview/' + response.id, false);
                }

                if (!force) {
                  saveInter(response, true);
                }
                Flash.show(response.success, 'success');
              });
          } else {
            $scope.messages.submit.field.push('Message');
          }
        } else if ($scope.ticket.id) {
          saveInter({
            id: $scope.ticket.id
          });
        }

      };
      $scope.updateDate = key => {
        let t = $scope.ticket;
        $scope.datePickers.forEach(v => {
          v.closeAll();
        });

        if (key === 'planning_date') {
          t.setPlanningDate(t.planning_date);
        }

        if (key === 'following_date') {
          t.setFollowingDate(t.following_date);
        }
      };

      $scope.addTime = (key, hour) => {
        let t = $scope.ticket;
        if (t && t[key]) {
          t[key] = moment.utc(t[key])
            .add(moment.duration(hour, 'hours'))
            .toDate();
          $scope.duration = null;
        } else {
          t[key] = moment.utc().add(1, 'hour').toDate();
        }

        t[key].setSeconds(0);
        t[key].setMilliseconds(0);
        if (key === 'planning_date') {
          $scope.ticket.setPlanningDate(t[key]);
        }
        if (key === 'following_date') {
          $scope.ticket.setFollowingDate(t[key]);
        }
      };
      $scope.checkDate = () => {
        let t = $scope.ticket;
        if (t && t.status) {
          t.setPlanningDate(t.planning_date);
          t.setFollowingDate(t.following_date);
        }
      };

      /**
       * Contact
       */

      $scope.toggleUser = user => {
        user.active = !user.active;
      };
      $scope.addContact = () => {
        $scope.ticket.contacts.push({
          contact: true
        });
        $scope.disabledDd();
      };
      $scope.delContact = contact => {
        let list = $scope.ticket.contacts,
          i = _.indexOf(list, contact),
          match,
          raContact;

        /**
         * Si l'objet a moins de deux propriÃ©tÃ©e, il a Ã©tÃ© vidÃ© ou jamais remplis et on peux le supprimer
         **/
        if (Object.keys(contact).length <= 3) {
          list.splice(i, 1);
        } else {
          // Si le contact a un id la relation doit etre dÃ©truite en base
          if (contact.id) {
            raContact = TokenRestAngularMS.restangularizeElement(null, contact, 'supportv2/tickets/' + $scope.ticket.id + '/contacts');
            raContact.fromServer = true;
            raContact.remove();
          }
          /* If the person is present in the suggestion list, we change his
           * status there to allow him to be present in the suggestions
           */
          match = _.findWhere($scope.inputs.users, {
            id: contact.id
          });
          if (match) {
            match.disabled = false;
          }
          // finaly, we can empty the fields
          list[i] = {};
        }
      };
      $scope.addToContact = (contact, user) => {
        if (!user.disabled) {

          contact.id = user.id;
          contact.name = user.name;
          contact.phone = user.phone;
          contact.email = user.email;
          contact.active = true;
          contact.contact = user.contact;
          user.disabled = true;
        }
      };
      $scope.initAuto = ($event, contact) => {
        $scope.disabledDd();
        contact.current = true;
        $event.preventDefault();
      };
      $scope.disabledDd = except => {
        $scope.ticket.contacts.forEach(v => {
          v.current = (v === except);
        });
      };
      $scope.callTo = num => {
        return num ? num.replace(/[\D]/g, '') : null;
      };

      /** Tools
       * Copy text to clipboard
       */
      $scope.copyText = value => {
        if (value) {
          clipboard.copyText(value);
        }
      };


      /**
       * INITIALISATION - PAGE READY
       * We need to wait until all the foreign data are ready to be used to initiate
       * the view and mechanic
       */

      viewReady = $scope.$watchGroup(['userService.user().id', 'ready.origins', 'ready.criticities', 'ready.users'], nv => {
        if (_.every(nv, _.identity)) {

          $scope.currentUser = new Person(userService.user());
          $scope.currentUser.support = null;
          $scope.currentUser.organization = {
            id: $rootScope.currMs.organization_id,
            name: $rootScope.currMs.organization
          };

          $scope.refresh.orgas();
          $scope.refresh.licences();
          // if the user is granted with support right, init all the dedicated functions
          if ($rootScope.isAction('create_intervention')) {

            $scope.refresh.comment();

            $scope.currentUser.support = true;
            $scope.editMod = true;
            $scope.refresh.baskets();

            $scope.datePickers = $rootScope.initDropdown('.yp-dateMod__wrapper', {
              closeOut: true,
              multi: false
            });

            $scope.ticketWatcher = $scope.$watch('ticket.id', nval => {

              /* if nv (new value) is trusthy & the ticket is open
               *  we perfom a validity test by using the Ticket MEthod WO  *
               *  the second argument.
               */
              if (nval && $scope.ticket.status) {
                $scope.ticket.setPlanningDate($scope.ticket.planning_date);
                $scope.ticket.setFollowingDate($scope.ticket.following_date);

                $scope.ticketWatcher();
              }
            });
          }
          // this fields are only needed at creation or can be changed by support member
          // so no need to load them if not.
          if ($rootScope.isAction('create_intervention') || angular.isUndefined($routeParams.id)) {
            $scope.refresh.categories();
          }
          // with all this we can load and create the ticket
          $scope.refresh.ticket();
          if ($routeParams.id) {
            //$scope.checkChange();
          }

          // Unregister the watcher because we wont need it anymore
          viewReady();
        }
      });

      $scope.checkChange = (stop) => {

        var refreshTicket = $scope.refresh.ticket,
          id = parseInt($routeParams.id, 10);
        if (stop) {
          $interval.cancel(promise);
          return;
        }

        promise = $interval(() => {
          refreshTicket(id);
        }, 1000 * 60);
      };

      $scope.isCurrentUser = author => {
        let r;
        if ($scope.currentUser && author) {
          r = author.id === $scope.currentUser.id;
        }
        return r;
      };
      $scope.$on('$destroy', function () {
        // Make sure that the interval is destroyed too
        // $scope.checkChange(true);
      });
    }
  ]);
